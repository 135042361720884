import React, { Component } from "react";
import propTypes from "prop-types";
import SectionHeader from "components/shared/sectionHeader";
import SocialFeedItem from "./SocialFeedItem";

class SocialFeed extends Component {
  render() {
    const { socialFeedItems } = this.props;

    const socialFeedComponents = socialFeedItems.map((item, index) => (
      <SocialFeedItem key={`${item.title}${index}`} item={item} />
    ));

    return (
      <div className="SocialFeed">
        <SectionHeader className="SocialFeed__header" descriptor="Social Feed" title="Stay in the know" />
        <div className="SocialFeed__items">{socialFeedComponents}</div>
      </div>
    );
  }
}

SocialFeed.propTypes = {
  socialFeedItems: propTypes.array.isRequired,
};

export default SocialFeed;
