import React from "react";
import propTypes from "prop-types";
import { IconExpand } from "assets/svg/expand";
import { IconDownload } from "assets/svg/download";
import { IconShare } from "assets/svg/share";
import RoundedButton from "components/shared/button/RoundedButton/";
import HoverComponent from "../../util/Hover";
import { Image } from "react-image-and-background-image-fade";
import { THEME_KEYS } from "helpers/constants";
import isMobile from "helpers/isMobile";
import ContextElement from "components/shared/elements/ContextElement";

const DocumentItem = props => {
  const { name, thumbnail, file_extension: fileExt, shareable } = props.document || {};
  const { handleDownloadClicked, handleExpandClicked, handleShareClicked } = props;
  return (
    <div className="DocumentItem">
      <div className="DocumentItem__imageWrap">
        {fileExt && (
          <ContextElement
            elType="div"
            style={{ borderColor: THEME_KEYS.PRIMARY }}
            className="DocumentItem__imageWrap__fileExt FileViewDocument__card__previewWrap__fileExt"
          >
            <p>.{fileExt}</p>
          </ContextElement>
        )}
        <Image src={thumbnail} alt={name} wrapperClassName="DocumentItem__imageWrap__image" />
        <div
          className="DocumentItem__imageWrap__overlay"
          style={{ backgroundColor: `${THEME_KEYS.PRIMARY}` || "#0f2245c0" }}
        >
          {!isMobile() &&
          <HoverComponent hoverFill={THEME_KEYS.SECONDARY}>
            <button className="DocumentItem__imageWrap__overlay__icon" onClick={handleExpandClicked}>
              <IconExpand />
            </button>
          </HoverComponent>
          }
          <HoverComponent hoverFill={THEME_KEYS.SECONDARY}>
            <button className="DocumentItem__imageWrap__overlay__icon" onClick={handleDownloadClicked}>
              <IconDownload height="26" width="26" />
            </button>
          </HoverComponent>
          {shareable &&
            <HoverComponent hoverFill={THEME_KEYS.SECONDARY}>
              <button className="DocumentItem__imageWrap__overlay__icon" onClick={handleShareClicked}>
                <IconShare height="26" width="24" />
              </button>
            </HoverComponent>
          }
        </div>
      </div>
      <div className="DocumentItem__titleWrap">
        <p className="DocumentItem__titleWrap__title">{name}</p>
      </div>
      <RoundedButton
        title="Download"
        buttonColor={THEME_KEYS.SECONDARY}
        buttonClass="DocumentItem__download"
        onClick={handleDownloadClicked}
        modifiers={["medium"]}
      />
    </div>
  );
};

DocumentItem.propTypes = {
  document: propTypes.object,
  handleDownloadClicked: propTypes.func,
  handleExpandClicked: propTypes.func,
  handleShareClicked: propTypes.func,
};

export default DocumentItem;
