import React, { useState } from "react";
import propTypes from "prop-types";
import { THEME_KEYS } from "helpers/constants";
import FileAccordionComponent from "./FileAccordionComponent";

function FileAccordion({ children, title }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <FileAccordionComponent
      title={title}
      open={isOpen}
      hoverFill={THEME_KEYS.SECONDARY}
      defaultFill={THEME_KEYS.PRIMARY}
      children={children}
      handleToggleButtonClick={() => setIsOpen(!isOpen)}
    />
  );
}

FileAccordion.defaultProps = {
  children: false,
};
FileAccordion.propTypes = {
  title: propTypes.string.isRequired,
  children: propTypes.oneOfType([propTypes.object, propTypes.bool]),
};

export default FileAccordion;
