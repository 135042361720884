import React from "react";
import propTypes from "prop-types";
import { Image } from "react-image-and-background-image-fade";
import ContextElement from "components/shared/elements/ContextElement";
import Loader from "components/shared/loader/Loader";

function AgentHeader({
  brandName,
  firstName,
  lastName,
  imagesrc = null,
  welcomeMsg,
  isFirstLogin,
  details = "Home to valuable information and resources for Oxford International Education Group agents.",
}) {
  const getWelcome = () => {
    return isFirstLogin
      ? (
        <>
          Welcome to the
          <br /> Agent Portal
        </>
      ) : welcomeMsg || (
        <>
          Welcome back to
          <br /> the Agent Portal
        </>
      );
  };

  return (
    <div className="Dashboard__agentHeader">
      <div className="Dashboard__agentHeader__details">
        {brandName && (
          <ContextElement
            config={{ color: "secondary" }}
            elType="p"
            className="Dashboard__agentHeader__details__brandName lightHeaderSansSerif"
          >
            {brandName}
          </ContextElement>
        )}
        <h1 className="Dashboard__agentHeader__details__welcome">
          {getWelcome()}
          {!!firstName && `, ${firstName}`}
          {!firstName && lastName && ` ${lastName}`}.
        </h1>
        <p>{details}</p>
      </div>
      {!!imagesrc && (
        <Image
          lazyLoad
          wrapperClassName="Dashboard__agentHeader__image"
          src={imagesrc}
          renderLoader={({ hasLoaded, hasFailed }) => (
            <>
              {!hasFailed && !hasLoaded && (
                <Loader
                  hasLoaded={hasLoaded}
                  hasFailed={hasFailed}
                  className="Dashboard__agentHeader__image__loader"
                />
              )}
            </>
          )}
        />
      )}
    </div>
  );
}

AgentHeader.propTypes = {
  brandName: propTypes.string,
  firstName: propTypes.string,
  lastName: propTypes.string,
  imagesrc: propTypes.string,
  isFirstLogin: propTypes.bool,
  welcomeMsg: propTypes.string || propTypes.element,
  details: propTypes.string,
};

export default AgentHeader;
