import React, { Component } from "react";

class Clock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
    };
  }

  MIN_IN_MIL = 60000;

  componentDidMount() {
    setInterval(this.update, this.MIN_IN_MIL);
  }

  update = () => {
    this.setState({
      date: new Date(),
    });
  };

  leadingZero = num => {
    return num < 10 ? "0" + num : num;
  };

  render() {
    const d = this.state.date.getDate();
    const M = this.state.date.getMonth() + 1;
    const Y = this.state.date.getYear() + 1900;
    const h = this.state.date.getHours();
    const m = this.state.date.getMinutes();

    return (
      <p className="Clock">
        {this.leadingZero(h) + ":" + this.leadingZero(m)}
        {h < 12 ? "am " : "pm "} {this.leadingZero(d) + "/" + this.leadingZero(M) + "/" + Y}
      </p>
    );
  }
}

export default Clock;
