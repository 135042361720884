import * as newsActionTypes from "redux/news/actionTypes";
import * as newsActions from "redux/news/actions";
import { put, takeEvery, call, select } from "redux-saga/effects";
import * as newsSelectors from "selectors/news";
import { mapWordpressResponse } from "helpers/mapWordpressResponse";
import * as brandsSelector from "selectors/brands";
import handleThrow from "helpers/handleThrow";
import waitFor from "./waitFor";
import handleBrandCategories from "../helpers/handleBrandCategories";

export const watchNewsRequestFactory = newsService => {
  function * watchNewsRequest() {
    yield takeEvery(`${newsActionTypes.ALL_NEWS}_REQUEST`, getAllNewsRequest);
    yield takeEvery(
      `${newsActionTypes.PAGINATED_NEWS}_REQUEST`,
      getPaginatedNewsRequest
    );
    yield takeEvery(
      `${newsActionTypes.SELECTED_ARTICLE}_REQUEST`,
      selectedArticleRequest
    );
    yield takeEvery(
      `${newsActionTypes.CATEGORIES}_REQUEST`,
      getCategoriesRequest
    );
    yield takeEvery(`${newsActionTypes.TAGS}_REQUEST`, getTagsRequest);
  }

  function * getAllNewsRequest(action) {
    try {
      const { postsOffset } = action.payload;
      const brand = yield call(waitFor, brandsSelector.selectedBrand);
      yield call(newsService.setUrl, brand.blogUrl);
      const response = yield call(
        newsService.getAllNews,
        null,
        null,
        postsOffset
      );
      const { articles, totalPosts } = yield response.json();
      yield put(
        newsActions.allNewsActions.success(mapWordpressResponse(articles))
      );
      yield put(newsActions.postsCountActions.request({ totalPosts }));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(newsActions.allNewsActions.fail(parsedError));
    }
  }

  function * getPaginatedNewsRequest(action) {
    try {
      const { postsOffset, categories, tags, orderby, order } = action.payload;
      const brand = yield call(waitFor, brandsSelector.selectedBrand);
      const fetchedCategories = yield call(waitFor, newsSelectors.getCategoryIDs);
      yield call(newsService.setUrl, brand.blogUrl);
      const response = yield call(
        newsService.getAllNews,
        categories && categories.length ? categories : fetchedCategories,
        tags,
        postsOffset,
        orderby,
        order
      );
      const { articles, totalPosts } = yield response.json();
      yield put(
        newsActions.paginatedNewsActions.success(mapWordpressResponse(articles))
      );
      yield put(newsActions.postsCountActions.request({ totalPosts }));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(newsActions.allNewsActions.fail(parsedError));
    }
  }

  function * selectedArticleRequest(action) {
    try {
      const articles = yield select(newsSelectors.getPaginatedNewsList);
      if (articles && articles.length) {
        const article = articles.find(
          item => Number(item.id) === Number(action.payload.id)
        );
        if (article) {
          yield put(newsActions.selectedArticleActions.success(article));
        } else {
          const singleArticle = yield call(newsService.getNewsItemByID, action.payload.id);
          if (singleArticle) {
            yield put(newsActions.selectedArticleActions.success(...mapWordpressResponse([singleArticle])));
          } else {
            throw new Error("No matching article");
          }
        }
      } else {
        if (!newsService.apiUrl) {
          const brand = yield select(brandsSelector.selectedBrand);
          yield call(newsService.setUrl, brand.blogUrl);
        }
        yield put(newsActions.allNewsActions.request());
        const articles = yield call(waitFor, newsSelectors.getAllNewsList);
        const article = articles.find(
          item => Number(item.id) === Number(action.payload.id)
        );
        yield put(newsActions.selectedArticleActions.success(article));
      }
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(newsActions.selectedArticleActions.fail(parsedError));
    }
  }

  function * getCategoriesRequest(action) {
    try {
      const brand = yield call(waitFor, brandsSelector.selectedBrand);
      if (!newsService.apiUrl) {
        yield call(newsService.setUrl, brand.blogUrl);
      }

      const response = yield call(newsService.getCategories);
      const categories = handleBrandCategories(response, brand);

      yield put(newsActions.categoriesActions.success(categories));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(newsActions.categoriesActions.fail(parsedError));
    }
  }

  function * getTagsRequest(action) {
    try {
      if (!newsService.apiUrl) {
        const brand = yield call(waitFor, brandsSelector.selectedBrand);
        yield call(newsService.setUrl, brand.blogUrl);
      }

      const response = yield call(newsService.getTags);

      const tags = response.map(element => {
        return {
          id: element.id,
          name: element.name,
          count: element.count,
        };
      });

      yield put(newsActions.tagsActions.success(tags));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(newsActions.tagsActions.fail(parsedError));
    }
  }

  return {
    watchNewsRequest,
    getAllNewsRequest,
    selectedArticleRequest,
  };
};
