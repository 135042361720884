import React, { Component } from "react";
import propTypes from "prop-types";
import { BackgroundImage } from "react-image-and-background-image-fade";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS, NOTIFICATION_TYPE } from "helpers/constants";
import { withRouter } from "react-router-dom";
import navigation from "./../../../navigation";
import timeSince from "helpers/timeSince";

export class NotificationItem extends Component {
  handleClicked = () => {
    let notificationURL;
    if (this.props.notification.type === NOTIFICATION_TYPE.EVENT) {
      notificationURL = this.getEventNotificationUrl();
    } else if (this.props.notification.type === NOTIFICATION_TYPE.DOCUMENT) {
      notificationURL = this.getDocumentNotificationUrl();
    }

    if (!this.props.notification.read) {
      this.props.setRead(this.props.notification.id);
    }
    this.props.history.push(notificationURL);
  };

  getEventNotificationUrl = () => {
    const notificationDate = new Date(this.props.notification.content.start_datetime);
    Array.isArray(this.props.notification.content.brand)
      ? this.props.setSelectedBrand(this.props.notification.content.brand[0].id, false)
      : this.props.setSelectedBrand(this.props.notification.content.brand.id, false);
    return `${navigation.calendar}/${notificationDate.getDate()}/${notificationDate.getMonth() +
      1}/${notificationDate.getFullYear()}/${this.props.notification.content.id}`;
  };

  getDocumentNotificationUrl = () => {
    return `${navigation.digitalResources}/${
      Array.isArray(this.props.notification.content.brand)
        ? this.props.notification.content.brand[0].name
        : this.props.notification.content.brand.name
    }/folder/${this.props.notification.content.directory.id}`;
  };

  render() {
    const { created_at: createdAt, content, read, type } = this.props.notification;
    const brand = content
      ? Array.isArray(content.brand)
        ? this.props.brandById(content.brand[0] && content.brand[0].id)
        : this.props.brandById(content.brand.id)
      : null;
    return brand
      ? (
        <button
          onClick={this.handleClicked}
          className={content && !read ? "NotificationItem NotificationItem--unread" : "NotificationItem"}
        >
          {content && (
            <React.Fragment>
              <div className="NotificationItem__imageWrap">
                <BackgroundImage
                  lazyLoad
                  className="NotificationItem__image"
                  src={brand.notificationImage}
                  alt={"Brand Logo"}
                />
              </div>
              <div className="NotificationItem__details">
                <ContextElement
                  config={{ color: THEME_KEYS.PRIMARY }}
                  elType="p"
                  className="NotificationItem__details__item NotificationItem__details__event"
                >
                  {type === "document" ? content.name + " uploaded" : content.name}
                </ContextElement>
                <ContextElement
                  config={{ color: THEME_KEYS.PRIMARY }}
                  elType="p"
                  className="NotificationItem__details__item NotificationItem__details__brand"
                >
                  {Array.isArray(content.brand) && content.brand.length > 1
                    ? `${content.brand[0].name} and 
${content.brand.length - 1} other${content.brand.length > 1 ? "s" : ""}`
                    : Array.isArray(content.brand) ? content.brand[0].name : content.brand.name}
                </ContextElement>
                <p className="NotificationItem__details__item NotificationItem__details__notifiedAt">
                  {timeSince(createdAt)}
                </p>
              </div>
            </React.Fragment>
          )}
        </button>
      )
      : <></>;
  }
}

NotificationItem.propTypes = {
  notification: propTypes.shape({
    content: propTypes.object,
    image: propTypes.string,
    type: propTypes.string.isRequired,
    imgalt: propTypes.string,
    event: propTypes.string,
    brand: propTypes.string,
    read: propTypes.bool.isRequired,
    created_at: propTypes.string.isRequired,
    id: propTypes.number,
  }),
  history: propTypes.object,
  brandById: propTypes.func,
  setRead: propTypes.func,
  setSelectedBrand: propTypes.func,
};

export default withRouter(NotificationItem);
