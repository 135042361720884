import React, { PureComponent } from "react";
import propTypes from "prop-types";
import ArticleCard from "components/shared/articles/ArticleCard";
import { NEWS_VIEW_TYPES } from "helpers/constants";
import navigation from "../../../../../../navigation";

class NewsPage extends PureComponent {
  render() {
    const { articles, history, usefulInformation, newsViewType = NEWS_VIEW_TYPES.NEWS, selectedBrand } = this.props;
    const noItemsComponent =
      <p key="noItems" className="News__articles__noItems">
        {newsViewType === NEWS_VIEW_TYPES.NEWS
          ? `Oh no! We don’t currently have any News available 
${selectedBrand ? `for ${selectedBrand.name}` : ""} at the moment.
 We plan to have some arriving in the near future though, so do check back soon! 
 Remember to explore our Useful Information tab to discover any relevant and important notices from us.`

          : `Oh no! We don’t currently have any Useful Information available 
${selectedBrand ? `for ${selectedBrand.name}` : ""} at the moment.
 We plan to have some arriving in the near future though, so do check back soon! 
 Remember to explore our News tab to discover any relevant and important notices from us.`}
      </p>;
    let articleComponents = [noItemsComponent];
    let usefulInformationComponents = [noItemsComponent];
    if (articles && Array.isArray(articles) && articles.length) {
      articleComponents = articles.map((article, index) => (
        <ArticleCard setAsHTML key={`${article.title}${index}`} history={history} article={article} />
      ));
    }

    if (usefulInformation && Array.isArray(usefulInformation) && usefulInformation.length) {
      usefulInformationComponents = usefulInformation.map((article, index) => (
        <ArticleCard articleType={navigation.usefulInformation}
          setAsHTML key={`${article.title}${index}`} history={history} article={article} />
      ));
    }

    return <div className="News__articles animated fadeIn">
      {newsViewType === NEWS_VIEW_TYPES.NEWS && articleComponents}
      {newsViewType === NEWS_VIEW_TYPES.USEFULINFO && usefulInformationComponents}
    </div>;
  }
}

NewsPage.propTypes = {
  history: propTypes.object,
  articles: propTypes.array,
  usefulInformation: propTypes.array,
  newsViewType: propTypes.string,
  selectedBrand: propTypes.object,
};

export default NewsPage;
