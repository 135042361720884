import React, { useState } from "react";
import classnames from "classnames";
import propTypes from "prop-types";
import { IconPlus } from "assets/svg/plus";
import { THEME_KEYS } from "helpers/constants";
import { sanitize } from "dompurify";
import ContextElement from "components/shared/elements/ContextElement";

const NeedHelpAccordion = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { question, answer } = props.item;

  const handleOpenClicked = () => setIsOpen(!isOpen);

  return (
    <div
      className={classnames([
        "NeedHelpAccordion",
        { "NeedHelpAccordion--open": isOpen },
      ])}
    >
      <div className="NeedHelpAccordion__topBar">
        <p>{question}</p>
        <ContextElement
          elType="button"
          config={{ borderColor: THEME_KEYS.SECONDARY, fill: THEME_KEYS.SECONDARY }}
          onClick={handleOpenClicked}
        >
          <IconPlus />
        </ContextElement>
      </div>
      <div className="NeedHelpAccordion__details">
        <p dangerouslySetInnerHTML={{ __html: sanitize(answer) }} />
      </div>
    </div>
  );
};

NeedHelpAccordion.propTypes = {
  item: propTypes.shape({
    question: propTypes.string.isRequired,
    answer: propTypes.string.isRequired,
  }),
};

export default React.memo(NeedHelpAccordion);
