import React from "react";
/* eslint max-len: 0 */
export const IconEmail = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11">
      <g fillRule="nonzero">
        <path d="M13.688 10.182H1.078A1.075 1.075 0 0 1 .005 9.108V1.194C.005.602.487.12 1.078.12h12.61c.592 0 1.073.481 1.073 1.073v7.914c0 .592-.481 1.074-1.073 1.074zM1.078.792a.403.403 0 0 0-.402.402v7.914c0 .222.18.403.402.403h12.61c.222 0 .403-.18.403-.403V1.194a.403.403 0 0 0-.403-.403H1.078z" />
        <path d="M7.383 6.518a.337.337 0 0 1-.217-.08l-5.16-4.385a.336.336 0 0 1 .435-.511l4.942 4.2 4.942-4.2a.336.336 0 0 1 .435.511L7.6 6.438a.334.334 0 0 1-.217.08z" />
        <path d="M2.224 8.84a.336.336 0 0 1-.245-.564l3.353-3.612a.336.336 0 0 1 .492.457L2.47 8.733a.336.336 0 0 1-.246.107zM12.543 8.84a.334.334 0 0 1-.246-.107L8.944 5.12a.336.336 0 0 1 .491-.457l3.354 3.612a.336.336 0 0 1-.246.564z" />
      </g>
    </svg>
  );
};
