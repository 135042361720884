import API from "../API";

let instance = null;

class usefulInformationService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getUsefulInformation = params => {
    return this.http.get(`useful-information/brand/${params.brandId}`).json();
  };
}

export default usefulInformationService;
