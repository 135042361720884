import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import SelectInput from "../SelectInput";
import * as GeneralActions from "redux/general/actions";
import * as GeneralSelectors from "selectors/general";
import ProfileSelect from "../ProfileSelect/ProfileSelect";

function withPhoneCode(WrappedComponent) {
  class PhoneCode extends React.Component {
    componentDidMount() {
      this.props.fetchCountries();
    }

    render() {
      let countries = this.props.countires;
      if (this.props.countryNamesAsValues) {
        countries = countries.map(({ name }) => ({
          value: name,
          name,
        }));
      }
      // eslint-disable-next-line max-len
      return this.props.formUsage ? (
        <ProfileSelect {...this.props} options={countries} />
      ) : (
        <WrappedComponent {...this.props} options={countries} />
      );
    }
  }

  PhoneCode.propTypes = {
    formUsage: propTypes.string, // Edit
    fetchCountries: propTypes.func.isRequired,
    countires: propTypes.array.isRequired,
    countryNamesAsValues: propTypes.bool,
  };
  return PhoneCode;
}

const mapDispatchToProps = dispatch => ({
  fetchCountries: () => dispatch(GeneralActions.getCountriesActions.request()),
});

const mapStateToProps = (state, ownProps) => ({
  countires: GeneralSelectors.countries(state),
  formUsage: ownProps.formUsage,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPhoneCode(SelectInput));
