import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import propTypes from "prop-types";

const Wrapper = styled.div(props => {
  switch (props.fillParent) {
    case true:
      return `
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        outline: none;
      `;
    default:
      return `
        position: static;
      `;
  }
});

function MyDropzone({ fillParent, setDropFocus, setLoading, onDropSuccess, base64 }) {
  async function fileListToBase64(acceptedFiles) {
    function getBase64(file) {
      const reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = ev => {
          resolve(ev.target.result);
        };
        reader.readAsDataURL(file);
      });
    }

    const promises = [];

    acceptedFiles.forEach(file => {
      promises.push(getBase64(file));
    });

    const array = await Promise.all(promises);
    return array.map((base64, index) => ({
      base64,
      info: acceptedFiles[index],
    }));
  }
  const onDrop = useCallback(async function(acceptedFiles) {
    setLoading(true);
    const files = await fileListToBase64(acceptedFiles);
    onDropSuccess(files);
    setLoading(false);
  }, [onDropSuccess, setLoading]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <Wrapper {...getRootProps()} fillParent={fillParent}>
      <input {...getInputProps()} />
      {isDragActive ? setDropFocus(true) : setDropFocus(false)}
    </Wrapper>
  );
}

MyDropzone.propTypes = {
  fillParent: propTypes.bool,
  base64: propTypes.bool,
  setDropFocus: propTypes.func,
  setLoading: propTypes.func,
  previewFile: propTypes.func,
  onDropSuccess: propTypes.func,
};

export default MyDropzone;
