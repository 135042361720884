import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "components/shared/util/PrivateRoute/privateRouteContainer";
import propTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import Dashboard from "./dashboard";
import {
  WrappedLogin,
  WrappedForgottenPassword,
  WrappedResetPassword,
} from "./login";
import Register from "./register";
import navigation from "../navigation";
import ScrollToTop from "./ScrollToTop";
import RegistrationService from "./../services/registration/registrationService";

class Router extends Component {
  render() {
    const { selectedBrand, loginSlides } = this.props;
    const theme = selectedBrand
      ? {
        primary: selectedBrand ? selectedBrand.primaryColourHex : "#0f2245",
        secondary: selectedBrand
          ? selectedBrand.secondaryColourHex
          : "#b89e66",
        secondaryEighty: selectedBrand
          ? `${selectedBrand.secondaryColourHex}DE`
          : "#b89e66DE",
        primaryOpacity: selectedBrand
          ? `${selectedBrand.primaryColourHex}c0`
          : "#0f2245c0",
      }
      : {
        primary: loginSlides ? loginSlides.primaryColourHex : "#0f2245",
        secondary: loginSlides ? loginSlides.secondaryColourHex : "#b89e66",
        secondaryEighty: loginSlides
          ? `${loginSlides.secondaryColourHex}DE`
          : "#b89e66DE",
        primaryOpacity: loginSlides
          ? `${loginSlides.primaryColourHex}c0`
          : "#0f2245c0",
      };

    return (
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <Switch>
          <Redirect exact from={navigation.root} to={navigation.login} />
          <Route exact path={[navigation.setBrand, navigation.login]} component={WrappedLogin} />
          <Route exact path={navigation.register} component={Register} />
          <Route
            exact
            path={navigation.forgottenPassword}
            component={WrappedForgottenPassword}
          />
          <Route
            exact
            path={navigation.resetPassword}
            component={WrappedResetPassword}
          />
          <PrivateRoute
            exact
            path={navigation.registrationAccept}
            redirectMsg={"Please log in to confirm"}
            component={props => {
              const service = new RegistrationService();
              service.acceptUser({ hash: props.match.params.hash });
              return <Redirect to={navigation.home} />;
            }}
          />
          <PrivateRoute
            exact
            path={navigation.registrationReject}
            redirectMsg={"Please log in to confirm"}
            component={props => {
              const service = new RegistrationService();
              service.rejectUser({ hash: props.match.params.hash });
              return <Redirect to={navigation.home} />;
            }}
          />
          <PrivateRoute exact path={navigation.home} component={Dashboard} />
          <PrivateRoute
            exact
            path={navigation.digitalResources}
            component={Dashboard}
          />
          <PrivateRoute exact path={navigation.profile} component={Dashboard} />
          <PrivateRoute
            exact
            path={navigation.registerStudent}
            component={Dashboard}
          />
          <PrivateRoute exact path={navigation.news} component={Dashboard} />
          <PrivateRoute exact path={navigation.usefulInformation} component={Dashboard} />
          <PrivateRoute
            exact
            path={navigation.calendar}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={navigation.contacts}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={navigation.browseBrands}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={navigation.needHelp}
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path={navigation.notificationSettings}
            component={Dashboard}
          />
          <PrivateRoute
            component={Dashboard}
          />
        </Switch>
      </ThemeProvider>
    );
  }
}

Router.propTypes = {
  selectedBrand: propTypes.object,
  loginSlides: propTypes.object,
};

export default Router;
