import propTypes from "prop-types";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { LoginHeader } from "../headers";
import { LoginForm } from "../forms";
// import LocalStore from "services/auth/localStore";
import navigation from "../../../../navigation";
import RoundedButton from "components/shared/button/RoundedButton";
import { THEME_KEYS } from "helpers/constants";
import isMobile from "helpers/isMobile";
import SuspendedAccountHeader from "../headers/SuspendedAccountHeader";

export function Login({ login, isAuthenticated, isSuspended, attemptLocalLogin, working, logout, loginErrorMessage }) {
  const [goToRegister, setGoToRegister] = useState(false);
  // if (!isAuthenticated && LocalStore.getRefreshToken()) {
  //   attemptLocalLogin(LocalStore.getRefreshToken());
  // }
  if (isSuspended) {
    return <SuspendedAccountHeader handleSignout={() => logout()} />;
  }
  if (goToRegister) {
    return <Redirect to={navigation.register} />;
  }
  return isAuthenticated ? (
    <Redirect to={navigation.home} />
  ) : (
    <>
      <div className="Login__left__loginHeader">
        <LoginHeader
          header={
            <>
              Oxford International <br /> Agent Portal
            </>
          }
          intro={`
              Dedicated to providing valuable agent information 
and resources from across the Oxford International Education Group portfolio.
            `}
        />
      </div>
      <div className="Login__left__loginForm">
        <LoginForm
          handleSubmit={values => login(values)}
          working={working}
          error={loginErrorMessage}
          forgotPassword={<Link to={navigation.forgottenPassword}>Forgot Password?</Link>}
          registerButton={
            <RoundedButton
              invertColors
              buttonColor={!isMobile() ? THEME_KEYS.PRIMARY : ""}
              buttonTextColor={THEME_KEYS.PRIMARY}
              title={"Register"}
              type="button"
              modifiers={["large", "white"]}
              onClick={() => setGoToRegister(true)}
            />
          }
        />
      </div>
    </>
  );
}

Login.propTypes = {
  isAuthenticated: propTypes.bool,
  isSuspended: propTypes.bool,
  attemptLocalLogin: propTypes.func,
  login: propTypes.func,
  working: propTypes.bool,
  logout: propTypes.func,
  loginErrorMessage: propTypes.string,
};

export default Login;
