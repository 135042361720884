import { connectRouter, routerMiddleware } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import newsReducer from "redux/news/reducer";
import { history } from "./history";
import authReducer from "redux/auth/reducer";
import FAQReducer from "redux/FAQs/reducer";
import eventsReducer from "redux/events/reducer";
import loginSliderReducer from "redux/loginSlider/reducer";
import contactsReducer from "redux/contacts/reducer";
import usefulInformationReducer from "redux/usefulInformation/reducer";
import systemReducer from "redux/system/reducer";
import rootSaga from "sagas/rootSaga";
import * as brands from "redux/brands/reducer";
import dashboardDataReducer from "redux/dashboard/reducer";
import notificationsReducer from "redux/notifications/reducer";
import { userDataReducer, editUserReducer } from "redux/user/reducer";
import generalReducer from "redux/general/reducer";
import forgotPasswordReducer from "redux/forgotPassword/reducer";
import jotformReducer from "redux/jotform/reducer";

/**
 * Initialises Redux store
 */
export const makeStore = () => {
  const initialState = {};
  const enhancers = [];
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [routerMiddleware(history), sagaMiddleware];

  const rootReducer = combineReducers({
    dashboard: dashboardDataReducer,
    auth: authReducer,
    loginSlides: loginSliderReducer,
    user: userDataReducer,
    editUser: editUserReducer,
    brands: brands.getAllBrands,
    events: eventsReducer,
    selectedBrand: brands.selectedBrand,
    faqs: FAQReducer,
    contacts: contactsReducer,
    news: newsReducer,
    usefulInformation: usefulInformationReducer,
    notifications: notificationsReducer,
    router: connectRouter(history),
    system: systemReducer,
    general: generalReducer,
    forgotPassword: forgotPasswordReducer,
    jotform: jotformReducer,
  });

  const persistConfig = {
    key: "OAP",
    storage,
    whitelist: ["auth", "brands", "selectedBrand"],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  const store = createStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return { store, persistor, history };
};

const store = makeStore();

export default store;
