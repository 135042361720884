import React from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";
import navigation from "../../../navigation";

function HeaderBanner({
  menuIsOpen,
  setOpenMenu,
  handleOffMenuClick,
  children,
  toggleSideBarOpen,
  selectedBrand,
  history,
}) {
  return (
    <ContextElement
      className={
        selectedBrand && history && history.location.pathname &&
          history.location.pathname === navigation.registerStudent
          ? "MainHeader MainHeader--appForm"
          : "MainHeader"
      }
      elType="div"
      config={{ backgroundColor: "primary" }}
      onClick={handleOffMenuClick}
    >
      <div
        onClick={setOpenMenu}
        className={
          menuIsOpen
            ? "MainHeader__menuBackgroundOverlay MainHeader__menuBackgroundOverlay--active"
            : "MainHeader__menuBackgroundOverlay"
        }
      />
      <button
        className="MainHeader__burgerMenu"
        onClick={toggleSideBarOpen}
      >
        <span className="MainHeader__burgerMenu__icon" />
      </button>
      {children}
    </ContextElement>
  );
}

HeaderBanner.propTypes = {
  menuIsOpen: propTypes.bool,
  setOpenMenu: propTypes.func,
  handleOffMenuClick: propTypes.func,
  children: propTypes.array,
  toggleSideBarOpen: propTypes.func,
  selectedBrand: propTypes.object,
  history: propTypes.object,
};

export default HeaderBanner;
