import API from "./../API";

let instance = null;

class AuthService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  login = async params => {
    return this.http.post("login", {
      json: params,
    }).json();
  };

  refreshToken = async params => {
    return this.http.post("refresh-token", {
      json:params,
    }).json();
  };

  requestNewPassword = async email => {
    return this.http.post("reset-password", {
      json: email,
    }).json();
  }

  changePassword = async({ token, password }) => {
    return this.http.post("change-password", {
      json: {
        token,
        password,
      },
    }).json();
  }

  logout = async() => {
    return this.http.get("logout");
  };
}

export default AuthService;
