import * as actionTypes from "./actionTypes";
import { createActionSet } from "../system/util";

export const allNewsActions = createActionSet(actionTypes.ALL_NEWS);
export const paginatedNewsActions = createActionSet(actionTypes.PAGINATED_NEWS);
export const usefullInformationActions = createActionSet(
  actionTypes.USEFULL_INFORMATION
);
export const categoriesActions = createActionSet(actionTypes.CATEGORIES);
export const tagsActions = createActionSet(actionTypes.TAGS);
export const selectedArticleActions = createActionSet(
  actionTypes.SELECTED_ARTICLE
);
export const postsCountActions = createActionSet(actionTypes.POSTS_COUNT);
export const setFiltersActions = createActionSet(actionTypes.SET_FILTERS);
