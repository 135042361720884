import React, { Component } from "react";
import CheckRadioInput from "./CheckRadioInput";
import propTypes from "prop-types";
import Label from "components/formElements/Label";

class CheckSelection extends Component {
  constructor() {
    super();
    this.state = {
      selectedArray: [],
    };
  }

  handleSelection = (selectionIndex, option) => {
    const { selectedArray } = this.state;
    if (selectionIndex > -1) {
      this.setState(
        {
          selectedArray: [
            ...selectedArray.slice(0, selectionIndex),
            ...selectedArray.slice(selectionIndex + 1, selectedArray.length),
          ],
        },
        () => this.props.onSelection && this.props.onSelection(this.state.selectedArray)
      );
    } else {
      this.setState(
        {
          selectedArray: [...selectedArray, option],
        },
        () => this.props.onSelection && this.props.onSelection(this.state.selectedArray)
      );
    }
  };

  render() {
    const { label, options } = this.props;
    return (
      <div className="CheckRadioSelection">
        {label && (
          <div className="TextInput__label FormInput__label">
            <Label>{label}</Label>
          </div>
        )}
        <div className="CheckRadioSelection__options">
          {!!options &&
            options.map((option, index) => {
              const selectionIndex = this.state.selectedArray.indexOf(option);
              return (
                <CheckRadioInput
                  key={`${option}-${index}`}
                  checkbox
                  selected={selectionIndex > -1}
                  name={option.name || option}
                  handleSelect={() => this.handleSelection(selectionIndex, option)}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

CheckSelection.propTypes = {
  label: propTypes.string,
  options: propTypes.array.isRequired,
  onSelection: propTypes.func,
};

export default CheckSelection;
