import { take, select } from "redux-saga/effects";

export default function * waitFor(selector) {
  if (yield select(selector)) return yield select(selector);

  while (true) {
    yield take("*");
    if (yield select(selector)) return yield select(selector);
  }
}
