import React from "react";
/* eslint max-len: 0 */
export const IconTableView = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
      <g fillRule="evenodd">
        <path d="M0 9.846h16v4H0zM0 0h16v4H0z" />
      </g>
    </svg>
  );
};
