import parseHTMLText from "./parseHTMLText";

/**
 * Returns the input string cut down to (`maxLength` - 1) chars to account for ellipses
 * Parses p tags to string if needed.
 * Appends ellipses to end of string
 *
 * @param {*} string Input string to limit
 * @param {*} maxLength Character limit for input string
 * @author David van Zyl
 */
export default function(string, shouldParse, maxLength = 200) {
  if (shouldParse) {
    string = parseHTMLText(string);
  }

  if (string.length <= maxLength) {
    return string;
  }

  string = string.slice(0, maxLength - 1);
  if (string[string.length - 2] === " ") {
    string = string.slice(0, string.length - 2);
  }

  if (string[string.length - 1] === " ") {
    string = string.slice(0, string.length - 1);
  }

  return string.concat("...");
}
