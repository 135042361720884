import { connect } from "react-redux";
import BrowseBrands from "../components/BrowseBrands";
import * as brandsActions from "redux/brands/actions";
import * as brandsSelector from "selectors/brands";

const mapDispatchToProps = dispatch => ({
  getBrands: () => dispatch(brandsActions.getAllBrands.request()),
  setSelectedBrand: brandId =>
    dispatch(brandsActions.setSelectedBrand.request({ selected: brandId, pushToDashboard: true })),
});

const mapStateToProps = state => ({
  brands: brandsSelector.flattenedBrandsList(state),
  working: brandsSelector.isWorking(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowseBrands);
