export default function(fileName, data, mime) {
  let url;
  try {
    url = window.URL.createObjectURL(new Blob([data]));
  } catch (error) {
    console.log(error);
  }
  if (url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    mime && link.setAttribute("type", mime);
    document.body.appendChild(link);
    link.click();
    url.revokeObjectURL && url.revokeObjectURL();
    link.remove();
  }
}
