import { createSelector } from "reselect";

export const getNews = state =>
  state && state.news ? state.news : null;

export const getAllNewsList = state =>
  state && state.news && state.news.allNews && state.news.allNews.results && state.news.allNews.results.length > 0
    ? state.news.allNews.results
    : null;

export const getPaginatedNewsList = state =>
  state && state.news && state.news.paginatedNews && state.news.paginatedNews.results &&
  state.news.paginatedNews.results.length > 0
    ? state.news.paginatedNews.results
    : null;

export const isWorking = section =>
  createSelector(
    getNews,
    newsState => newsState && newsState[section] ? newsState[section].working : false
  );

export const getNewsFilters = state =>
  state && state.news && state.news.filters && state.news.filters.results ? state.news.filters.results : null;

export const getPostsCount = state =>
  state && state.news && state.news.postsCount && state.news.postsCount.results ? state.news.postsCount.results : 0;

export const getCategories = state =>
  state && state.news && state.news.categories && state.news.categories.results ? state.news.categories.results : null;

export const getCategoryIDs =
  createSelector(
    getCategories,
    items => {
      if (items && items.length) {
        return items.map(cat => cat.id);
      }
      return null;
    }
  );

export const getTags = state =>
  state && state.news && state.news.tags && state.news.tags.results ? state.news.tags.results : [];

export const getSelectedArticle = state =>
  state.news.selectedArticle && state.news.selectedArticle.results ? state.news.selectedArticle.results : null;

export const getSelectedArticleWorking = state =>
  state.news.selectedArticle && state.news.selectedArticle.working;

export const getNextArticleId = createSelector(
  getPaginatedNewsList,
  getSelectedArticle,
  (items, selectedId) => {
    if (items && items.length) {
      if (selectedId) {
        const indexOfSelected = items.findIndex(item => item.id === selectedId.id);
        return indexOfSelected <= items.length - 2 ? items[indexOfSelected + 1].id : items[0].id;
      } else {
        return items[0].id;
      }
    }
    return null;
  }
);

export const getFirstNArticlesFiltered = (amountToGet, filterId) => {
  return createSelector(
    getAllNewsList,
    items => {
      if (items) {
        return filterId
          ? items.filter(item => item.id !== filterId).slice(0, amountToGet)
          : items.slice(0, amountToGet);
      }
      return [];
    }
  );
};
