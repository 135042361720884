import React from "react";
import { BackgroundImage } from "react-image-and-background-image-fade";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";
import propTypes from "prop-types";

function BrandSlideButton({ brandName, brandImage, handleBrandClicked }) {
  return (
    <ContextElement
      elType="button"
      key={brandName}
      config={{ borderColor: THEME_KEYS.SECONDARY }}
      className="BrandItem"
      onClick={handleBrandClicked}
    >
      <BackgroundImage
        lazyLoad
        className="BrandItem__image"
        src={brandImage}
        alt={brandName}
      />
    </ContextElement>
  );
}

BrandSlideButton.propTypes = {
  brandName: propTypes.string,
  brandImage: propTypes.string,
  handleBrandClicked: propTypes.func,
};

export default BrandSlideButton;
