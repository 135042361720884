import React from "react";
import { Field } from "formik";
import TextInput from "components/formElements/TextInput";
import ContextElement from "components/shared/elements/ContextElement";
import { PhoneCodeContainer } from "components/formElements/fieldContainers";
import { THEME_KEYS } from "helpers/constants";
import propTypes from "prop-types";

function UserPersonalDetailsFields({ formUsage }) {
  return (
    <ContextElement
      elType="div"
      className="UserInformationFormFields__section"
      config={{
        borderColor: THEME_KEYS.SECONDARY,
      }}
    >
      <ContextElement
        elType="h3"
        config={{ color: "secondary" }}
        className="UserInformationFormFields__section__heading lightHeaderSansSerif"
      >
        Personal Details
      </ContextElement>
      <Field name="firstName" label="First Name" type="text" mandatory component={TextInput} />
      <Field name="lastName" label="Last Name" type="text" mandatory component={TextInput} />
      <div className="Form__fieldRow Form__fieldRow--flexColumn">
        <div className="TextInput__label FormInput__label FormInput__label--marginBottom">
          <label className="Label">CONTACT NUMBER</label>
        </div>
        <div className="Form__fieldRow Form__fieldRow--flex Form_fieldRow--alignCenter">
          <Field
            placeholder="Area Code"
            name="areaCode"
            id="areaCode"
            mandatory
            formUsage={formUsage}
            component={PhoneCodeContainer}
            modifiers={["white", "short", "grey-border"]}
          />
          <Field modifiers={["noMargin"]} name="contactNumber" type="number" mandatory component={TextInput} />
        </div>
      </div>
    </ContextElement>
  );
}

UserPersonalDetailsFields.propTypes = {
  formUsage: propTypes.string, // Edit
};
export default UserPersonalDetailsFields;
