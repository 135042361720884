import React from "react";
/* eslint max-len: 0 */
export const IconDigitalRes = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13">
      <path
        fillRule="nonzero"
        d="M17.6 4.12h-3.26V2.68c0-.2-.16-.36-.36-.36h-6.6L6.04.3a.353.353 0 0 0-.3-.16h-3.9c-.12 0-.24.06-.3.16L.1 2.46a.34.34 0 0 0-.06.2v9.28c0 .02 0 .04.02.06v.021c0 .02.02.04.02.06v.02l.06.06.02.021.04.038s.02 0 .02.02c.02 0 .04.02.06.02H.3c.021 0 .06.021.08.021h13.6c.04 0 .06 0 .1-.02h.02c.021 0 .04-.02.06-.04h.021l.06-.06s0-.02.02-.02c.021-.02.04-.04.04-.06v-.02l3.62-7.44c.061-.121.04-.24-.02-.36a.366.366 0 0 0-.301-.14zM5.54.86l1.34 2.02c.06.1.18.16.3.16h6.44v1.08h-9.6c-.14 0-.26.08-.32.2L.76 10.34V2.78L2.04.86h3.5zm8.22 10.68H.98l3.26-6.72h12.78l-3.26 6.72z"
      />
    </svg>
  );
};
