import React from "react";
import propTypes from "prop-types";
import { Image } from "react-image-and-background-image-fade";
import Loader from "components/shared/loader/Loader";

function BrandLogo({ logoImage, brandName, className, logoFailedLoad }) {
  return (
    <Image
      src={logoImage}
      alt={`${brandName} Logo`}
      wrapperClassName={className || "MainHeader__image"}
      renderLoader={({ hasLoaded, hasFailed }) =>
        <Loader hasFailed={hasFailed} hasLoaded={hasLoaded} onHasFailed={logoFailedLoad} />
      }
    />
  );
}

BrandLogo.defaultProps = {
  logoImage: "",
};

BrandLogo.propTypes = {
  logoImage: propTypes.string,
  brandName: propTypes.string,
  className: propTypes.string,
  logoFailedLoad: propTypes.func,
};

export default React.memo(BrandLogo);
