import React from "react";
import propTypes from "prop-types";
import NeedHelpBanner from "./needHelpBanner";
import NeedHelpAccordion from "./needHelpAccordion";
import ContextElement from "components/shared/elements/ContextElement";
import QuickGuideCTA from "./quickGuide/QuickGuideCTA";
import HelpSearchBar from "./HelpSearchBar";
import Loader from "components/shared/loader/Loader";

function NeedHelpPageContent({ FAQs, handleHelpSearch, brand, handlePlayTutorial, working, getBrands }) {
  return (
    <div className="NeedHelp">
      <ContextElement elType="div" config={{ backgroundColor: "primary" }} className="NeedHelp__header">
        <ContextElement
          elType="p"
          config={{ color: "secondary" }}
          className="NeedHelp__header__title lightHeaderSansSerif"
        >
          Agent Portal Support
        </ContextElement>
        <h1>How can we help?</h1>
        <p className="NeedHelp__header__text">Do you have a question about the
        Agent Portal? Discover answers to commonly asked questions below.</p>
        <HelpSearchBar handleSearch={handleHelpSearch} />
      </ContextElement>
      <div className="NeedHelp__content animated fadeIn">
        <QuickGuideCTA handlePlayTutorial={handlePlayTutorial} />
        {!working ? (
          FAQs &&
          !!FAQs.length &&
          Array.from(FAQs).map((FAQ, index) => <NeedHelpAccordion
            className="animated fadeIn"
            key={`${index}${FAQ.question}`}
            item={FAQ}
          />
          )
        ) : (
          <div className="NeedHelp__loader">
            <Loader className="Loader--noBG" />
          </div>
        )}
        {!!brand && (
          <NeedHelpBanner faqImage={brand.faqImage}
            contactImage={brand.contactFormImage} brandId={brand.id} getBrands={getBrands} />
        )}
      </div>
    </div>
  );
}

NeedHelpPageContent.propTypes = {
  FAQs: propTypes.array,
  handleHelpSearch: propTypes.func.isRequired,
  handlePlayTutorial: propTypes.func.isRequired,
  brand: propTypes.object,
  working: propTypes.bool,
  getBrands: propTypes.func,
};

export default NeedHelpPageContent;
