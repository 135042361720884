import React, { useEffect } from "react";
import propTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import navigation from "../../../../navigation";
import { successToast } from "helpers/toastMsgs";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  redirectMsg,
  ...rest
}) => {
  useEffect(() => {
    if (!isAuthenticated && redirectMsg) {
      successToast(redirectMsg, true);
    }
  }, [isAuthenticated, redirectMsg]);

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: navigation.login, from: props.location }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: propTypes.any,
  isAuthenticated: propTypes.bool,
  location: propTypes.object,
  redirectMsg: propTypes.string,
};

export default PrivateRoute;
