/*
Provides fallback for clients without localStorage
*/
const fallback = (method, persist) => {
  return (...args) => {
    if (persist) {
      if (window.localStorage) {
        return window.localStorage[method].apply(window.localStorage, args);
      }
    } else {
      if (window.sessionStorage) {
        return window.sessionStorage[method].apply(window.sessionStorage, args);
      }
    }

    return null;
  };
};

export default shouldPersist => {
  return {
    clear: fallback("clear", shouldPersist),
    getItem: fallback("getItem", shouldPersist),
    removeItem: fallback("removeItem", shouldPersist),
    setItem: fallback("setItem", shouldPersist),
  };
};
