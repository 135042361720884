import * as brandsActionTypes from "redux/brands/actionTypes";
import * as brandsActions from "redux/brands/actions";
import { put, takeEvery, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import * as dashboardDataActions from "redux/dashboard/actions";
import handleThrow from "helpers/handleThrow";
import parseBrands from "helpers/parseBrands";
import * as newsActions from "redux/news/actions";

export const watchBrandsRequestFactory = brandsService => {
  function * watchBrandsRequest() {
    yield takeEvery(
      `${brandsActionTypes.ALL_BRANDS}_REQUEST`,
      allBrandsRequest
    );
    yield takeEvery(
      `${brandsActionTypes.SET_SELECTED}_REQUEST`,
      selectBrandRequest
    );
  }

  function * allBrandsRequest(action) {
    try {
      const response = yield call(brandsService.getAllBrands);
      yield put(brandsActions.getAllBrands.success(parseBrands(response.data)));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(brandsActions.getAllBrands.fail(parsedError));
    }
  }

  function * selectBrandRequest(action) {
    try {
      if (
        action.payload &&
        action.payload.selected &&
        action.payload.selected !== undefined
      ) {
        yield put(newsActions.paginatedNewsActions.success([]));
        yield put(newsActions.allNewsActions.success([]));
        yield put(newsActions.categoriesActions.success([]));
        yield put(newsActions.tagsActions.success([]));
        yield put(newsActions.postsCountActions.request({ totalPosts: 0 }));
        yield put(brandsActions.setSelectedBrand.success(action.payload.selected));
        if (action.payload.pushToDashboard) {
          yield put(dashboardDataActions.dashboardDataActions.request());
          yield put(brandsActions.getAllBrands.request());
          yield put(push("/dashboard"));
        }
      } else {
        throw new Error("Selected Brand is null or undefined.");
      }
    } catch (error) {
      const { message } = error;
      yield put(
        brandsActions.setSelectedBrand.fail({ error: message } || { error })
      );
    }
  }

  return {
    watchBrandsRequest,
    allBrandsRequest,
    selectBrandRequest,
  };
};
