import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure({
  position: "bottom-left",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
});

export const errorToast = (msg, customMsg = false) => {
  customMsg ? toast.error(msg) : toast.error(`${msg} failed`);
};

export const successToast = (msg, customMsg = false) => {
  customMsg ? toast.success(msg) : toast.success(`${msg} was successful`);
};
