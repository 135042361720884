import { createSelector } from "reselect";

export const FAQsSelector = state => state.faqs.results;
export const isWorking = state => state.faqs && state.faqs.working;

export const FAQsFilter = filterParam => {
  return createSelector(
    FAQsSelector,
    items => {
      if (items) {
        if (!filterParam) return items;
        return filterParam
          ? Array.from(items).filter(
            FAQ =>
              FAQ.question.toLowerCase().includes(filterParam.toLowerCase()) ||
                FAQ.answer.toLowerCase().includes(filterParam.toLowerCase())
          )
          : items;
      }
      return null;
    }
  );
};
