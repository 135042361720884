import React from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import { Button } from "./RoundedButton.css";
import PopInAnimation from "animationWrappers/PopInAnimation";
import Loader from "../../loader";

class RoundedButton extends React.PureComponent {
  render() {
    const {
      title,
      buttonClass,
      modifiers,
      Icon,
      iconRight,
      onClick,
      disabled,
      buttonColor,
      buttonTextColor,
      type,
      invertColors,
      working,
    } = this.props;
    return !working ? (
      <Button
        invertColors={invertColors}
        buttonColor={buttonColor}
        buttonTextColor={buttonTextColor}
        type={type}
        className={classnames([
          "RoundedButton",
          modifiers &&
            modifiers.map(modifier => ({
              [`RoundedButton--${modifier}`]: !!modifier,
            })),
          {
            [buttonClass]: !!buttonClass,
          },
        ])}
        onClick={onClick || null}
        disabled={disabled}
        modifiers={modifiers}
      >
        {Icon && !iconRight && <Icon className="RoundedButton__icon" />}
        <p>{title}</p>
        {Icon && iconRight && <Icon className="RoundedButton__icon" />}
      </Button>
    ) : (
      <div className="RoundedButton__loader">
        <Loader className="Loader--noBG" />
      </div>
    );
  }
}

RoundedButton.propTypes = {
  modifiers: propTypes.array,
  title: propTypes.string.isRequired,
  onClick: propTypes.func,
  buttonClass: propTypes.string,
  buttonColor: propTypes.string,
  buttonTextColor: propTypes.string,
  Icon: propTypes.any,
  iconRight: propTypes.bool,
  disabled: propTypes.bool,
  type: propTypes.string,
  invertColors: propTypes.bool,
  working: propTypes.bool,
};

export default PopInAnimation(RoundedButton);
