import API from "../API";

let instance = null;

class DashboardService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getBrandDashboard = params => {
    return this.http.get(`dashboard/brand/${params}`).json();
  };
}

export default DashboardService;
