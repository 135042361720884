import React, { Component } from "react";
import { IconBell } from "../../../assets/svg/bell";
import HoverComponent from "../../shared/util/Hover";
import RoundedButton from "components/shared/button/RoundedButton/";
import NotificationItem from "components/shared/NotificationItem";
import { NavLink } from "react-router-dom";
import navigation from "./../../../navigation";
import propTypes from "prop-types";
import { menuStates } from "../headerMenuStates";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";

class HeaderNotifications extends Component {
  constructor() {
    super();
    this.state = {
      isMenuOpen: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openMenu !== menuStates.NOTIFICATIONS && prevState.isMenuOpen) {
      document.body.style.position = "relative";
      return { isMenuOpen: false };
    } else if (nextProps.openMenu === menuStates.NOTIFICATIONS && !prevState.isMenuOpen) {
      document.body.style.position = "fixed";
      return { isMenuOpen: true };
    }
    return prevState;
  }

  handleBellClicked = () => {
    this.setState(prevState => {
      !prevState.isMenuOpen ? this.props.onMenuOpen(menuStates.NOTIFICATIONS) : this.props.onMenuOpen(null);
    });
  };

  handleClearClicked = () => {
    this.props.setAllNotificationsRead();
  }

  viewAllNotifications = () => {
    this.props.onMenuOpen(null);
  }

  render() {
    return (
      <div
        className={this.state.isMenuOpen ? "HeaderNotifications HeaderNotifications--active" : "HeaderNotifications"}
      >
        <ContextElement
          elType="button"
          config={{ borderColor: THEME_KEYS.PRIMARY }}
          className={
            this.props.isUnread
              ? "HeaderNotifications__icon HeaderNotifications__icon--active"
              : "HeaderNotifications__icon"
          }
          onClick={this.handleBellClicked}
        >
          <HoverComponent
            defaultFill={this.props.isUnread ? "#FFDB3E" : "#FFF"}
            hoverFill={this.props.isUnread ? "#FFDB3E" : THEME_KEYS.SECONDARY}
          >
            <IconBell />
          </HoverComponent>
        </ContextElement>
        <div className="HeaderNotifications__menu">
          <div className="HeaderNotifications__menu__items">
            {this.props.notifications && !!this.props.notifications.length ? (
              this.props.notifications.map((el, i) => (
                <NotificationItem
                  setSelectedBrand={this.props.setSelectedBrand}
                  brandById={this.props.brandById}
                  setRead={this.props.setRead} key={`notification${i}`} notification={el} />
              ))
            ) : (
              <p className="HeaderNotifications__menu__items__noItems">No notifications.</p>
            )}
          </div>
          <div className="HeaderNotifications__menu__buttons">
            <NavLink to={navigation.notifications} onClick={this.viewAllNotifications} exact>
              <RoundedButton
                buttonColor={THEME_KEYS.PRIMARY}
                buttonClass="HeaderNotifications__menu__buttons__view"
                title="View All Notifications"
              />
            </NavLink>
            <RoundedButton
              onClick={this.handleClearClicked}
              buttonColor={THEME_KEYS.PRIMARY}
              buttonTextColor={THEME_KEYS.PRIMARY}
              invertColors
              buttonClass="HeaderNotifications__menu__buttons__clear"
              title="Clear"
              modifiers={["white"]}
            />
          </div>
        </div>
      </div>
    );
  }
}

HeaderNotifications.propTypes = {
  openMenu: propTypes.string,
  onMenuOpen: propTypes.func,
  isUnread: propTypes.bool,
  notifications: propTypes.array,
  brandById: propTypes.func,
  setRead: propTypes.func,
  setAllNotificationsRead: propTypes.func,
  setSelectedBrand: propTypes.func,
};

export default HeaderNotifications;
