import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withTheme } from "styled-components";
import { IconCross } from "assets/svg/cross";

class ActiveFilterList extends PureComponent {
  computeActive = () => {
    const _self = this;
    const computed = this.props.options
      .filter(element => this.props.activeOptions.includes(element.id))
      .map((element, index) => (
        <div
          key={`${element.id}${index}`}
          className="ActiveFilterList__element"
          onClick={_self.handleClick(element.id)}
          style={{
            backgroundColor: this.props.theme.secondary,
            borderColor: this.props.theme.secondary,
          }}
        >
          {element.name}
          <span className="ActiveFilterList__delete">
            <IconCross className="ActiveFilterList__delete__icon" />
          </span>
        </div>
      ));
    return computed;
  };

  handleClick = id => () => {
    this.props.onClick(id);
  };

  render() {
    return <div className="ActiveFilterList">{this.computeActive()}</div>;
  }
}

ActiveFilterList.propTypes = {
  options: propTypes.array,
  activeOptions: propTypes.array,
  onClick: propTypes.func,
  theme: propTypes.object,
};

export default withTheme(ActiveFilterList);
