import ky from "ky/umd";
import config from "config";

class JotformService {
  constructor() {
    this.http = ky.create({
      prefixUrl: config.JotFormURL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.jotform = ky.create({
      prefixUrl: config.JotFormURL,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    this.config = {
      formID: config.JotformFormID,
      apiKey: config.JotformAPIKey,
    };
  }

  getFormQuestions = () => {
    return this.http.get(`form/${this.config.formID}/questions?apiKey=${this.config.apiKey}`).json();
  };

  sendFormAnswers = data => {
    try {
      return this.jotform.post(`form/${this.config.formID}/submissions?apiKey=${this.config.apiKey}`, { body: data });
    } catch (error) {
      return { error };
    }
  };
}

export default JotformService;
