import React, { Component } from "react";

import NewsSlider from "./NewsSlider";
import PageSelector from "./PageSelector";
import FilterMenu from "./filterMenu";
import Pagination from "./Pagination";
import propTypes from "prop-types";
import ActiveFilterList from "./ActiveFilterList";
import isMobile from "helpers/isMobile";
import NewsPage from "./NewsPage/NewsPage";
import Loader from "components/shared/loader/Loader";
import { NEWS_VIEW_TYPES, THEME_KEYS } from "helpers/constants";
import ContextElement from "components/shared/elements/ContextElement";
import navigation from "../../../../../navigation";

class NewsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postsOffset: 0,
      currentPage: 1,
      categories: [],
      tags: [],
      order: "desc",
      orderby: "date",
      sortOptions: [
        {
          name: "Latest",
          id: 1,
        },
        {
          name: "Oldest",
          id: 2,
        },
        {
          name: "A-Z",
          id: 3,
        },
        {
          name: "Z-A",
          id: 4,
        },
      ],
      currentSortOption: {
        name: "Latest",
        id: 1,
      },
      showSort: false,
      showFilters: false,
      paginatedNewsList: [],
      usefulInformationList: [],
      newsViewType: NEWS_VIEW_TYPES.NEWS,
    };
  }

  BACK_ACTION = "article";

  componentDidMount() {
    const {
      getAllNews,
      getCategories,
      getTags,
      getUsefulInformation,
      selectedBrand,
      filters,
      workingPaginated,
      history,
    } = this.props;

    const { postsOffset, categories, tags, order, orderby, currentPage } = this.state;
    getUsefulInformation();
    if (selectedBrand && selectedBrand.blogUrl) {
      getCategories();
      getTags();
      if (filters && history.location.state && history.location.state.from === this.BACK_ACTION) {
        this.setState({
          categories: filters.categories,
          tags: filters.tags,
          order: filters.order,
          orderby: filters.orderby,
        });
        this.setCurrentSortOption([filters.order, filters.orderby]);
        getAllNews({ postsOffset, categories: filters.categories, tags: filters.tags });
        this.getPaginatedNewsList({
          postsOffset,
          categories: filters.categories,
          tags: filters.tags,
          orderby: filters.orderby,
          order: filters.order,
        });
      } else {
        getAllNews({ postsOffset, categories, tags });
        this.getPaginatedNewsList({ postsOffset, categories, tags, orderby, order });
        this.props.setNewsFilters({
          categories: [],
          tags: [],
          order: "desc",
          orderby: "date",
        });
      }
    } else {
      const articles = this.props.usefulInformation(currentPage, orderby, order);
      if (articles && articles.length && !workingPaginated) {
        this.props.history.push(navigation.usefulInformation);
      }
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.paginatedNewsList !== null &&
      nextProps.paginatedNewsList !== prevState.paginatedNewsList) ||
      nextProps.usefulInformation(prevState.currentPage, prevState.orderby, prevState.order) !==
      prevState.usefulInformationList
    ) {
      return {
        newsViewType: nextProps.history.location.pathname.includes(navigation.news)
          ? NEWS_VIEW_TYPES.NEWS : NEWS_VIEW_TYPES.USEFULINFO,
        paginatedNewsList: nextProps.paginatedNewsList,
        usefulInformationList: nextProps.usefulInformation(prevState.currentPage, prevState.orderby, prevState.order),
      };
    }
    return null;
  }

  getPaginatedNewsList = ({ postsOffset, categories, tags, orderby, order }) => {
    if (this.props.selectedBrand && this.props.selectedBrand.blogUrl) {
      this.props.getPaginatedNews({ postsOffset, categories, tags, orderby, order });
    }
  }

  setNewsViewType = event => {
    this.setState({ newsViewType: event.currentTarget.value });
    this.props.history.push({
      pathname: event.currentTarget.value === NEWS_VIEW_TYPES.NEWS
        ? navigation.news
        : navigation.usefulInformation,
      state: { from: event.currentTarget.value === NEWS_VIEW_TYPES.NEWS
        ? navigation.usefulInformation
        : navigation.news,
      },
    }
    );
  };

  computePageAmount = () => {
    if (this.state.newsViewType === NEWS_VIEW_TYPES.NEWS) {
      const characterArray = (this.props.postsCount / 9).toString().split(".");
      const pageAmount =
      characterArray.length === 1
        ? parseInt(characterArray[0], 10)
        : parseInt(characterArray[0], 10) + 1;
      return pageAmount;
    } else if (this.state.newsViewType === NEWS_VIEW_TYPES.USEFULINFO) {
      return this.props.usefulInformationPages;
    }
  };

  handleNext = () => {
    const { currentPage } = this.state;

    this.setState(
      {
        currentPage:
          currentPage >= this.computePageAmount() ? 1 : currentPage + 1,
      },
      () => {
        const { categories, orderby, order, tags } = this.state;
        this.getPaginatedNewsList({
          postsOffset: (this.state.currentPage - 1) * 9,
          categories,
          tags,
          orderby,
          order,
        });
      }
    );
  };

  handleBack = () => {
    const { currentPage } = this.state;

    this.setState(
      {
        currentPage:
          currentPage > 1 ? currentPage - 1 : this.computePageAmount(),
      },
      () => {
        const { categories, orderby, order, tags } = this.state;
        this.getPaginatedNewsList({
          postsOffset: (this.state.currentPage - 1) * 10,
          categories,
          tags,
          orderby,
          order,
        });
      }
    );
  };

  handlePageChange = id => {
    const { categories, tags } = this.state;

    this.setState(
      {
        currentPage: id + 1,
      },
      () => {
        this.getPaginatedNewsList({
          postsOffset: (this.state.currentPage - 1) * 9,
          categories,
          tags,
        });
      }
    );
  };

  setCurrentSortOption = orderData => {
    switch (true) {
      case orderData.includes("date") && orderData.includes("desc"):
        this.setState({
          currentSortOption: this.state.sortOptions.filter(option =>
            option.name === "Latest"
          )[0],
        });
        break;
      case orderData.includes("date") && orderData.includes("asc"):
        this.setState({
          currentSortOption: this.state.sortOptions.filter(option =>
            option.name === "Oldest"
          )[0],
        });
        break;
      case orderData.includes("title") && orderData.includes("asc"):
        this.setState({
          currentSortOption: this.state.sortOptions.filter(option =>
            option.name === "A-Z"
          )[0],
        });
        break;
      case orderData.includes("title") && orderData.includes("desc"):
        this.setState({
          currentSortOption: this.state.sortOptions.filter(option =>
            option.name === "Z-A"
          )[0],
        });
        break;
      default:
        this.setState({
          currentSortOption: this.state.sortOptions[0],
        });
        break;
    }
  };

  handleSelectedOption = id => {
    this.setState(
      {
        currentSortOption: this.state.sortOptions.filter(element => {
          if (element.id === id) {
            return element;
          }
          return null;
        })[0],
      },
      () => {
        switch (this.state.currentSortOption.name) {
          case "Latest":
            this.setOrder("desc");
            this.setOrderBy("date");
            break;
          case "Oldest":
            this.setOrder("asc");
            this.setOrderBy("date");
            break;
          case "A-Z":
            this.setOrder("asc");
            this.setOrderBy("title");
            break;
          case "Z-A":
            this.setOrder("desc");
            this.setOrderBy("title");
            break;
          default:
            this.setOrder("desc");
            this.setOrderBy("date");
            break;
        }
      }
    );
  };

  setOrder = order => {
    this.setState({
      order,
    },
    () => {
      this.props.setNewsFilters({ order });
    });
  };

  setOrderBy = orderby => {
    this.setState(
      {
        orderby,
      },
      () => {
        const { categories, orderby, order, tags, currentPage } = this.state;
        this.setState({
          showSort: false,
        });
        this.getPaginatedNewsList({
          postsOffset: (currentPage - 1) * 9,
          categories,
          tags,
          orderby,
          order,
        });
        this.props.setNewsFilters({ orderby });
      }
    );
  };

  handleCategoryChange = data => {
    this.setState(
      {
        categories: data,
      },
      () => {
        const { categories, orderby, order, tags } = this.state;
        this.setState({
          showFilters: false,
        });
        this.getPaginatedNewsList({
          postsOffset: (this.state.currentPage - 1) * 9,
          categories,
          tags,
          orderby,
          order,
        });
        this.props.setNewsFilters({ categories });
      }
    );
  };

  handleTagsChange = data => {
    this.setState(
      {
        tags: data,
      },
      () => {
        const { categories, orderby, order, tags } = this.state;
        this.setState({
          showFilters: false,
        });
        this.getPaginatedNewsList({
          postsOffset: (this.state.currentPage - 1) * 9,
          categories,
          tags,
          orderby,
          order,
        });
        this.props.setNewsFilters({ tags });
      }
    );
  };

  resetActiveCategories = callback => {
    this.setState({
      categories: [],
    });
  };

  resetActiveTags = () => {
    this.setState({
      tags: [],
    });
  };

  handleCategoriesActiveFilterList = id => {
    this.setState(
      {
        categories: this.state.categories.filter(element => !(element === id)),
      },
      () => {
        const { categories, orderby, order, tags } = this.state;
        this.getPaginatedNewsList({
          postsOffset: (this.state.currentPage - 1) * 9,
          categories,
          tags,
          orderby,
          order,
        });
        this.props.setNewsFilters({ categories });
      }
    );
  };

  handleTagsActiveFilterList = id => {
    this.setState(
      {
        tags: this.state.tags.filter(element => !(element === id)),
      },
      () => {
        const { categories, orderby, order, tags } = this.state;
        this.getPaginatedNewsList({
          postsOffset: (this.state.currentPage - 1) * 9,
          categories,
          tags,
          orderby,
          order,
        });
        this.props.setNewsFilters({ tags });
      }
    );
  };

  handleShowSort = () => {
    this.setState({
      showSort: !this.state.showSort,
    });
  };

  handleShowFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  isNewsWorking = () => {
    const { newsViewType } = this.state;
    const { workingPaginated, workingUsefulInfo } = this.props;
    return (newsViewType === NEWS_VIEW_TYPES.NEWS && !workingPaginated) ||
         (newsViewType === NEWS_VIEW_TYPES.USEFULINFO && !workingUsefulInfo);
  }

  render() {
    const {
      history,
      latestNArticlesFilteredNews,
      categories,
      tags,
      postsCount,
      selectedBrand,
      workingAll,
    } = this.props;
    const {
      paginatedNewsList,
      currentPage,
      sortOptions,
      currentSortOption,
      showSort,
      showFilters,
      newsViewType,
      usefulInformationList,
    } = this.state;

    const latestNews = latestNArticlesFilteredNews(4);

    return (
      <div className="News animated fadeIn">
        {!workingAll ? (
          latestNews && !!latestNews.length &&
          <NewsSlider
            history={history}
            latestNewsArray={latestNews}
          />)
          : (
            <div className="NewsSlider__loader">
              <Loader className="Loader--noBG" />
            </div>
          )
        }
        <ContextElement config={{ borderColor: THEME_KEYS.SECONDARY }} elType="div" className="News__typeSelector">
          <input className="News__typeSelector__input" defaultChecked={newsViewType === NEWS_VIEW_TYPES.NEWS}
            onClick={this.setNewsViewType}
            value={NEWS_VIEW_TYPES.NEWS} type="radio" name="view" id="news" />
          <ContextElement
            config={{
              borderColor: newsViewType === NEWS_VIEW_TYPES.NEWS
                ? THEME_KEYS.SECONDARY
                : "transparent",
              opacity: newsViewType === NEWS_VIEW_TYPES.NEWS
                ? 1
                : 0.5,
              color: THEME_KEYS.PRIMARY }
            }
            elType="label"
            className="News__typeSelector__label News__typeSelector__label--news"
            htmlFor="news">
            News
          </ContextElement>
          <input className="News__typeSelector__input" defaultChecked={newsViewType === NEWS_VIEW_TYPES.USEFULINFO}
            onClick={this.setNewsViewType}
            value={NEWS_VIEW_TYPES.USEFULINFO} type="radio" name="view" id="usefulInfo" />
          <ContextElement
            config={{
              borderColor: newsViewType === NEWS_VIEW_TYPES.USEFULINFO
                ? THEME_KEYS.SECONDARY
                : "transparent",
              opacity: newsViewType === NEWS_VIEW_TYPES.USEFULINFO
                ? 1
                : 0.5,
              color: THEME_KEYS.PRIMARY }
            }
            elType="label"
            className="News__typeSelector__label News__typeSelector__label--usefulInfo"
            htmlFor="usefulInfo">
            Useful Information
          </ContextElement>
        </ContextElement>
        { ((newsViewType === NEWS_VIEW_TYPES.USEFULINFO && usefulInformationList && !!usefulInformationList.length) ||
        (newsViewType === NEWS_VIEW_TYPES.NEWS && paginatedNewsList && paginatedNewsList.length)) &&
          <FilterMenu
            sortOptions={sortOptions}
            currentSortOption={currentSortOption}
            showSort={showSort}
            showFilters={showFilters}
            categories={categories}
            tags={tags}
            currentPage={currentPage}
            handleShowSort={this.handleShowSort}
            handleShowFilters={this.handleShowFilters}
            handleSelectedOption={this.handleSelectedOption}
            handleCategoryChange={this.handleCategoryChange}
            state={this.state}
            resetActiveCategories={this.resetActiveCategories}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            handleTagsChange={this.handleTagsChange}
            resetActiveTags={this.resetActiveTags}
            computePageAmount={this.computePageAmount}
            postsCount={postsCount}
            newsViewType={newsViewType}
          />
        }
        <div className="News__activeFilters">
          <ActiveFilterList
            options={this.props.categories || []}
            activeOptions={this.state.categories}
            type="categories"
            onClick={this.handleCategoriesActiveFilterList}
          />
          <ActiveFilterList
            options={this.props.tags}
            activeOptions={this.state.tags}
            type="tags"
            onClick={this.handleTagsActiveFilterList}
          />
        </div>
        {this.isNewsWorking()
          ? (
            <NewsPage history={history}
              articles={paginatedNewsList}
              newsViewType={newsViewType}
              selectedBrand={selectedBrand}
              usefulInformation={usefulInformationList} />
          ) : (
            <div className="News__loader">
              <Loader className="Loader--noBG" />
            </div>
          )}
        <div className="News__footer">
          {!isMobile() && (
            <Pagination
              allPagesCount={this.computePageAmount()}
              onPageChange={this.handlePageChange}
              currentPage={currentPage}
            />
          )}
          {!!this.computePageAmount() &&
          <PageSelector
            allPagesCount={this.computePageAmount()}
            onNext={this.handleNext}
            onBack={this.handleBack}
            currentPage={currentPage}
          />
          }
        </div>
      </div>
    );
  }
}

NewsMain.propTypes = {
  history: propTypes.object,
  filters: propTypes.object,
  paginatedNewsList: propTypes.array,
  categories: propTypes.array,
  tags: propTypes.array,
  getAllNews: propTypes.func,
  getPaginatedNews: propTypes.func,
  getUsefulInformation: propTypes.func,
  usefulInformation: propTypes.func,
  getCategories: propTypes.func,
  getTags: propTypes.func,
  latestNArticlesFilteredNews: propTypes.func,
  postsCount: propTypes.number,
  workingPaginated: propTypes.bool,
  workingAll: propTypes.bool,
  usefulInformationPages: propTypes.number,
  workingUsefulInfo: propTypes.bool,
  selectedBrand: propTypes.object,
  setNewsFilters: propTypes.func,
};

export default NewsMain;
