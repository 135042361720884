import * as loginSliderActionTypes from "redux/loginSlider/actionTypes";
import { loginSliderActions } from "redux/loginSlider/actions";
import { put, takeEvery, call } from "redux-saga/effects";
import * as brandsActions from "redux/brands/actions";
import handleThrow from "helpers/handleThrow";
import parseBrands from "../helpers/parseBrands";

export const watchLoginSliderRequestFactory = loginSliderService => {
  function * watchLoginSlider() {
    yield takeEvery(`${loginSliderActionTypes.GET_LOGIN_SLIDES}_REQUEST`, allLoginSlides);
  }

  function * allLoginSlides(action) {
    if (action.payload) {
      const { brandId, urlPhrase } = action.payload;
      try {
        const response = yield call(loginSliderService.getloginSlidesByBrandOrPhrase, { brandId, urlPhrase });
        yield put(loginSliderActions.success(...parseBrands([response.data])));
        yield put(brandsActions.setSelectedBrand.success(response.data.id));
      } catch (error) {
        const parsedError = yield handleThrow(error);
        yield put(loginSliderActions.fail(parsedError));
      }
    }
  }

  return {
    watchLoginSlider,
    allLoginSlides,
  };
};
