import React from "react";
import { Field } from "formik";
import TextInput from "components/formElements/TextInput";
import ContextElement from "components/shared/elements/ContextElement";
import { CountriesContainer } from "components/formElements/fieldContainers";
import { THEME_KEYS } from "helpers/constants";
import propTypes from "prop-types";

function UserAddressFields({ formUsage }) {
  return (
    <ContextElement
      elType="div"
      className="UserInformationFormFields__section"
      config={{
        borderColor: THEME_KEYS.SECONDARY,
      }}
    >
      <ContextElement
        elType="h3"
        config={{ color: "secondary" }}
        className="UserInformationFormFields__section__heading lightHeaderSansSerif"
      >
        Address
      </ContextElement>
      <Field name="address1" label="Address Line 1" type="text" mandatory component={TextInput} />
      <Field name="address2" label="Address Line 2" type="text" mandatory component={TextInput} />
      <Field name="townCity" label="Town / City" type="text" mandatory component={TextInput} />
      <Field name="postcode" label="Zip / postal code" type="text" mandatory component={TextInput} />
      <Field
        label="Country"
        placeholder="Select country"
        name="country"
        id="country"
        mandatory
        formUsage={formUsage}
        component={CountriesContainer}
        modifiers={["white", "grey-border"]}
      />
    </ContextElement>
  );
}
UserAddressFields.propTypes = {
  formUsage: propTypes.string, // Edit
};
export default UserAddressFields;
