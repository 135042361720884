import localStorage from "./localStoragePolyfil";

class LocalStore {
  static store = localStorage(true);

  static setStorageType(shouldPersist) {
    this.store = localStorage(shouldPersist);
  }

  /**
   * Authenticate a user. Save a token in Local Storage
   *
   * @param {string} refresh_token
   */
  /*eslint-disable */
  static setUserToken({ refresh_token, access_token, expires_in }) {
    this.store.setItem("refresh_token", refresh_token);
    this.store.setItem("access_token", access_token);
    this.store.setItem("expires_in", expires_in);
  }
  /* eslint-enable */

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static removeUserToken() {
    this.store.removeItem("refresh_token");
    this.store.removeItem("access_token");
    this.store.removeItem("expires_in");
  }

  /**
   * Get the refresh_token value.
   *
   * @returns {string}
   */
  static getRefreshToken() {
    return this.store.getItem("refresh_token");
  }

  /**
   * Get the access_token value.
   *
   * @returns {string}
   */
  static getAccessToken() {
    return this.store.getItem("access_token");
  }

  /**
   * Get the expires_in value.
   *
   * @returns {string}
   */
  static getExpiryDuration() {
    return this.store.getItem("expires_in");
  }
}

export default LocalStore;
