import { SELECTED_USEFUL_INFORMATION, GET_USEFUL_INFORMATION } from "./actionTypes";
import { createReducerSet, initialState } from "./../system/util";
import { combineReducers } from "redux-starter-kit";

const getUsefullInformationReducer = (state = initialState, action) => {
  if (action.type.includes(GET_USEFUL_INFORMATION)) {
    switch (true) {
      case action.type.includes("_REQUEST"):
        return {
          ...state,
          working: true,
          failed: {
            hasFailed: false,
          },
        };
      case action.type.includes("_SUCCESS"):
        const results = action.payload;
        return {
          working: false,
          results: results,
          failed: {
            hasFailed: false,
          },
        };
      case action.type.includes("_FAIL"):
        const { message, response } = action.payload;
        return {
          ...state,
          working: false,
          failed: {
            error: {
              message,
              status: response ? response.status : "",
              statusText: response ? response.statusText : "",
            },
            hasFailed: true,
          },
          hasFailed: true,
        };
      default:
        return state;
    }
  }
  return state;
};

const getSelectedArticleReducer = createReducerSet(SELECTED_USEFUL_INFORMATION);

const reducer = combineReducers({
  allArticles: getUsefullInformationReducer,
  selectedArticle: getSelectedArticleReducer,
});

export default reducer;
