import React from "react";
import PropTypes from "prop-types";

export const HelperText = ({ text }) => {
  return (
    <div className="HelperText">
      <p className="HelperText__text">{text}</p>
    </div>
  );
};

HelperText.propTypes = {
  text: PropTypes.string,
};

export default HelperText;
