import React from "react";
import PropTypes from "prop-types";
/* eslint max-len: 0 */
export const IconTick = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
      <path d="M6.144 8.148C5.31 7.377 3.96 5.965 3.062 5.066 2.163 4.23 1.585 4.937.493 6.093c-1.22 1.22 0 1.862 1.734 3.596l3.147 3.146c1.156 1.028 2.119-.513 3.66-1.99l6.293-6.357c1.99-1.926 2.44-1.926.834-3.467-1.412-1.477-1.348-1.477-3.595.834-1.028.963-6.1 6.1-6.422 6.293z" />
    </svg>
  );
};

IconTick.propTypes = {
  className: PropTypes.string,
};
