import { connect } from "react-redux";
import DigitalResources from "../components/DigitalResources";
import * as brandsSelector from "selectors/brands";
import * as brandsActions from "redux/brands/actions";

const mapDispatchToProps = dispatch => ({
  setSelectedBrand: brandId => dispatch(brandsActions.setSelectedBrand.request({ selected: brandId })),
});

const mapStateToProps = state => ({
  selectedBrand: brandsSelector.selectedBrand(state),
  brandByName: name => brandsSelector.brandByName(name)(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DigitalResources);
