import React from "react";
import pt from "prop-types";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { withTheme } from "styled-components";

function SidebarNavItem({ link, Icon, title, theme, working, ...props }) {
  return (
    <NavLink
      to={!working && link}
      className={cn(
        "SidebarNavItem animated fadeIn",
        { "SidebarNavItem--working": working },
      )}
      activeClassName="SidebarNavItem--isActive"
      activeStyle={{
        color: theme.primary,
        fill: theme.primary,
        stroke: theme.primary,
      }}
      {...props}
    >
      <Icon />
      <span className="SidebarNavItem__span">{title}</span>
    </NavLink>
  );
}

SidebarNavItem.propTypes = {
  link: pt.string.isRequired,
  Icon: pt.func.isRequired,
  title: pt.string.isRequired,
  theme: pt.object.isRequired,
  working: pt.bool,
};

export default withTheme(SidebarNavItem);
