import styled from "styled-components";
import * as MODIFIERS from "styles/styledModifiers";

export const Button = styled.button`
  background-color: ${props =>
    !props.buttonColor
      ? "transparent"
      : props.invertColors
        ? "transparent"
        : props.buttonColor.includes("#")
          ? props.buttonColor
          : props.theme[props.buttonColor] || props.theme["primary"]};
  fill: ${props =>
    !!props.buttonTextColor && !props.buttonColor
      ? props.theme[props.buttonTextColor]
      : props.invertColors
        ? props.buttonColor && props.buttonColor.includes("#")
          ? props.buttonColor
          : props.theme[props.buttonColor || "primary"]
        : MODIFIERS.WHITE};
  color: ${props =>
    !props.buttonTextColor
      ? props.invertColors
        ? "black"
        : "white"
      : props.invertColors
        ? props.buttonTextColor.includes("#")
          ? props.buttonTextColor
          : props.theme[props.buttonTextColor] || props.theme["primary"]
        : "transparent"};
  border-color: ${props =>
    !props.buttonColor
      ? "transparent"
      : props.invertColors
        ? props.buttonColor.includes("#")
          ? props.buttonColor
          : props.theme[props.buttonColor] || props.theme["primary"]
        : "transparent"};
  &:hover {
    background-color: ${props =>
    !!props.buttonTextColor && !props.buttonColor
      ? "transparent"
      : props.invertColors
        ? props.buttonColor && props.buttonColor.includes("#")
          ? props.buttonColor
          : props.theme[props.buttonColor || "primary"]
        : "transparent"};
    fill: ${props =>
    !!props.buttonTextColor && !props.buttonColor
      ? props.theme[props.buttonTextColor]
      : props.invertColors
        ? props.modifiers && props.modifiers.includes(MODIFIERS.WHITE)
          ? MODIFIERS.WHITE
          : props.theme["primary"]
        : props.buttonColor && props.buttonColor.includes("#")
          ? props.buttonColor
          : props.theme[props.buttonColor || "primary"]};
    color: ${props =>
    !!props.buttonTextColor && !props.buttonColor
      ? props.theme[props.buttonTextColor]
      : props.invertColors
        ? props.modifiers && props.modifiers.includes(MODIFIERS.WHITE)
          ? MODIFIERS.WHITE
          : props.theme["primary"]
        : props.buttonColor && props.buttonColor.includes("#")
          ? props.buttonColor
          : props.theme[props.buttonColor || "primary"]};
    border-color:   ${props =>
    !!props.buttonTextColor && !props.buttonColor
      ? "transparent"
      : props.invertColors
        ? MODIFIERS.WHITE
        : props.buttonColor && props.buttonColor.includes("#")
          ? props.buttonColor
          : props.theme[props.buttonColor || "primary"]}
`;
