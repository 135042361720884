/**
 * Parses an html element to string, for titles and excerpts from WP for example.
 *
 * @param {*} string Input string to limit
 * @param {*} maxLength Character limit for input string
 * @author David van Zyl
 */
export default function(string, elType) {
  if (string[0] === "<" && string[string.length - 1] === ">") {
    const parsed = new DOMParser().parseFromString(string, "text/html");
    return parsed.getElementsByTagName(elType)[0].innerText;
  } else {
    const temp = document.createElement("p");
    temp.innerHTML = string;
    string = temp.textContent || temp.innerText;
  }
  return string;
}
