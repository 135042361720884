// import LocalStore from "../services/auth/localStore";

import LocalStore from "../services/auth/localStore";
import { createSelector } from "reselect";

export const getIsAuthenticated = state => {
  if (state && state.auth && state.auth.results) {
    LocalStore.setStorageType(!!state.auth.results.rememberMe);
  }
  const isAuthenticated = LocalStore.getAccessToken();
  if (!isAuthenticated) {
    LocalStore.removeUserToken();
    return false;
  }
  return true;
};

export const authWorkingSelector = ({ auth }) => auth.working;

export const loginErrorMessage = ({ auth }) => {
  try {
    return auth.failed.hasFailed ? auth.failed.error.message : null;
  } catch {
    return null;
  }
};

export const routerSelector = state => state.router && state.router.location ? state.router.location : null;

export const redirectPath = createSelector(routerSelector, locationData => {
  if (locationData) {
    return locationData.from ? locationData.from.pathname : null;
  }
  return null;
});
