import React, { Component } from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";

class SectionHeader extends Component {
  render() {
    const { descriptor, title, className } = this.props;

    return (
      <div className={`SectionHeader ${className}`}>
        <ContextElement className="lightHeaderSansSerif" config={{ color: "secondary" }} elType="p">
          {descriptor}
        </ContextElement>
        <h1 className="headerSerif">{title}</h1>
      </div>
    );
  }
}

SectionHeader.propTypes = {
  descriptor: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  className: propTypes.string,
};

export default SectionHeader;
