import React, { Component } from "react";
import Swiper from "react-id-swiper";
import propTypes from "prop-types";
import classnames from "classnames";
import HoverComponent from "components/shared/util/Hover";
import { IconChevronDown } from "assets/svg/chevronDown";
import { IconChevronLeft } from "assets/svg/chevronLeft";
import { IconChevronRight } from "assets/svg/chevronRight";
import { IconMessage } from "assets/svg/message";
import { IconWhatsApp } from "assets/svg/whatsapp";
import { IconWeChat } from "assets/svg/wechat";
import { Image } from "react-image-and-background-image-fade";
import isMobile from "helpers/isMobile";
import { withTheme } from "styled-components";
import { THEME_KEYS } from "helpers/constants";
import ContextElement from "components/shared/elements/ContextElement";
import SingleIconButton from "components/shared/button/SingleIconButton/";
import { successToast, errorToast } from "helpers/toastMsgs";

class ContactSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swiper: null,
      currentRegion: null,
      viewRegions: false,
      showNav: false,
    };
  }

  slideContainerRef = React.createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.currentRegion && nextProps.regionList) {
      return {
        currentRegion: nextProps.regionList[0],
        contactList: nextProps.contactListByRegion(nextProps.regionList[0]),
      };
    }
    return null;
  }

  swiperParams = {
    slidesPerView: "auto",
    spaceBetween: 50,
    loop: false,
    preloadImages: true,
    observer: true,
  };

  handleRegionClicked = region => {
    this.setState({
      currentRegion: region,
      contactList: this.props.contactListByRegion(region),
    }, () => {
      if (this.state.swiper && this.state.swiper.slidesGrid) {
        this.state.swiper.update();
        this.setState({
          showNav: this.state.swiper.width <
          (this.state.swiper.slidesGrid.length * (this.state.swiper.slidesSizesGrid[0] + 100)),
        });
      }
    });
  };

  handleNextClicked = () => {
    if (this.state.swiper !== null) {
      this.state.swiper.slideNext();
    }
  };

  handleBackClicked = () => {
    if (this.state.swiper !== null) {
      this.state.swiper.slidePrev();
    }
  };

  swiperRef = ref => {
    if (ref) {
      this.setState({
        swiper: ref,
        showNav: ref.width < ((ref.slidesGrid.length * ref.slidesSizesGrid[0]) + 100),
      });
    }
  };

  toggleViewRegion = () => {
    this.setState(prevState => {
      return { viewRegions: !prevState.viewRegions };
    });
  };

  userInitials = (firstName, lastName) => {
    let initials = "";
    initials = (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "");
    return initials.toUpperCase();
  };

  createContactButtons = ({ email, whatsapp, wechat }) => {
    const buttons = [
      {
        Icon: IconMessage,
        onClick: () => this.handleEmailSelected(email),
      },
    ];
    whatsapp &&
      buttons.push({
        Icon: IconWhatsApp,
        onClick: () => this.handleWhatsAppSelected(whatsapp),
      });
    wechat &&
      buttons.push({
        Icon: IconWeChat,
        onClick: () => this.handleWeChatSelected(wechat),
      });
    return buttons;
  };

  handleEmailSelected = contactInfo => {
    window.open(`mailto:${contactInfo}`);
  };

  handleWhatsAppSelected = contactInfo => {
    window.open(
      `${
        isMobile() ? "whatsapp:/" : "https://web.whatsapp.com"
      }/send?phone=${contactInfo}`
    );
  };

  handleWeChatSelected = contactInfo => {
    navigator.clipboard.writeText(contactInfo).then(
      () => {
        // Promise resolved successfully.
        successToast("WeChat user ID copied to the clipboard.", true);
      },
      () => {
        // Promise rejected.
        errorToast("WeChat user ID failed to copy to the clipboard.", true);
      }
    );
  };

  render() {
    const { regionList, theme } = this.props;
    const { currentRegion, contactList, showNav } = this.state;
    /* eslint-disable */
    const GradientBackground = {
      background: `${theme.primary}`,
      background: ` -moz-linear-gradient(
        top,
        ${theme.primary} 0%,
        ${theme.primary} ${isMobile() ? "250px" : "55%"},
        #f5f5f5 ${isMobile() ? "250px" : "55%"}
      )`,
      background: `-webkit-linear-gradient(
        top,
        ${theme.primary} 0%,
        ${theme.primary} ${isMobile() ? "250px" : "55%"},
        #f5f5f5 ${isMobile() ? "250px" : "55%"}
      )`,
      background: `linear-gradient(
        to bottom,
        ${theme.primary} 0%,
        ${theme.primary} ${isMobile() ? "250px" : "55%"},
        #f5f5f5 ${isMobile() ? "250px" : "55%"}
      )`,
      filter: `progid:dximagetransform.microsoft.gradient(
        startColorstr=${theme.primary},
        endColorstr="#f5f5f5",
        GradientType=0
      )`
    };
    /* eslint-enable */

    let contactListComponents;
    if (contactList) {
      contactListComponents = contactList.map((contact, index) => (
        <div key={`${contact.name}${index}`} className="ContactItem">
          {contact && contact.image ? (
            <Image
              src={contact.image}
              alt={`${contact.first_name} ${contact.last_name} Profile Image`}
              wrapperClassName="ContactItem__image"
            />
          ) : (
            <ContextElement
              elType="div"
              config={{ borderColor: THEME_KEYS.SECONDARY }}
              className="ContactItem__image ContactItem__image--initials"
            >
              <ContextElement
                elType="h1"
                config={{ color: THEME_KEYS.SECONDARY }}
              >
                {this.userInitials(contact.first_name, contact.last_name)}
              </ContextElement>
            </ContextElement>
          )}
          <div className="ContactItem__details">
            <p
              style={{ color: theme.secondary }}
              className="lightHeaderSansSerif ContactItem__details__firstname"
            >
              {contact.first_name}
            </p>
            <p
              style={{ color: theme.primary }}
              className="ContactItem__details__lastname"
            >
              {contact.last_name}
            </p>
            <p className="ContactItem__details__job">{contact.job_title}</p>
            <p className="ContactItem__details__phone">{`${contact.phone_code ||
              ""} ${contact.phone_number}`}</p>
          </div>
          <div className="ContactItem__sendMessage">
            {this.createContactButtons(contact).map((item, index) => {
              return (
                <SingleIconButton
                  key={`${contact.name}Icon${index}`}
                  className="ContactItem__sendMessage__button"
                  invertColors={false}
                  Icon={item.Icon}
                  onClick={item.onClick}
                  modifiers={["white"]}
                />
              );
            })}
          </div>
        </div>
      ));
    }

    return (
      <div className="ContactSlider" style={GradientBackground}>
        {contactList && !!contactList.length ? (
          <div className="ContactSlider__toolbar">
            <div className="ContactSlider__toolbar__regionSelect">
              <p
                style={{ color: theme.secondary }}
                className="lightHeaderSansSerif"
              >
                REGION
              </p>
              <div className="ContactSlider__toolbar__regionSelect__dropdown">
                <h1 className="ContactSlider__toolbar__regionSelect__dropdown__title">
                  {this.state.currentRegion}
                </h1>
                <button
                  className={classnames(
                    "ContactSlider__toolbar__regionSelect__dropdown__button",
                    {
                      "ContactSlider__toolbar__regionSelect__dropdown__button--active": this
                        .state.viewRegions,
                    }
                  )}
                  onClick={this.toggleViewRegion}
                >
                  <HoverComponent hoverFill={THEME_KEYS.SECONDARY}>
                    <IconChevronDown />
                  </HoverComponent>
                </button>
              </div>
            </div>
            {showNav &&
            <div className="ContactSlider__toolbar__selector">
              <button
                className="ContactSlider__toolbar__selector__button"
                style={{ border: `1px solid ${theme.secondary}` }}
                onClick={this.handleBackClicked}
              >
                <HoverComponent
                  hoverFill={THEME_KEYS.SECONDARY}
                  defaultFill={THEME_KEYS.SECONDARY}
                >
                  <IconChevronLeft />
                </HoverComponent>
              </button>
              <span
                style={{ borderColor: `${theme.secondary}` }}
                className="ContactSlider__toolbar__selector__middle" />
              <button
                className="ContactSlider__toolbar__selector__button"
                style={{ border: `1px solid ${theme.secondary}` }}
                onClick={this.handleNextClicked}
              >
                <HoverComponent
                  hoverFill={THEME_KEYS.SECONDARY}
                  defaultFill={THEME_KEYS.SECONDARY}
                >
                  <IconChevronRight />
                </HoverComponent>
              </button>
            </div>
            }

            <div
              className={classnames(
                "ContactSlider__toolbar__regionSelect__regionList",
                {
                  "ContactSlider__toolbar__regionSelect__regionList--active": this
                    .state.viewRegions,
                }
              )}
            >
              {regionList &&
                regionList
                  .filter(region => region !== this.state.currentRegion)
                  .map(region => (
                    <button
                      className="ContactSlider__toolbar__regionSelect__regionList__button"
                      onClick={() => this.handleRegionClicked(region)}
                      key={region}
                    >
                      <h2>{region}</h2>
                    </button>
                  ))}
            </div>
          </div>
        ) : (
          <p className="ContactSlider__noContacts">
            No Contacts listed for this brand.
          </p>
        )}
        {!isMobile() && currentRegion && (
          <Swiper
            noSwiping={!isMobile()}
            getSwiper={this.swiperRef}
            {...this.swiperParams}
          >
            {contactListComponents}
          </Swiper>
        )}
        {isMobile() && (
          <div className="ContactSlider__slides">{contactListComponents}</div>
        )}
      </div>
    );
  }
}

ContactSlider.propTypes = {
  contactListByRegion: propTypes.func,
  regionList: propTypes.array,
  theme: propTypes.object,
};

export default withTheme(ContactSlider);
