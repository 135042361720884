import { connect } from "react-redux";
import EditProfile from "../components";
import * as UserActions from "redux/user/actions";
import * as UserSelector from "selectors/user";

const mapDispatchToProps = dispatch => ({
  patchUserRequest: formData =>
    dispatch(UserActions.editUserActions.request(formData)),
  getUser: () => dispatch(UserActions.getUserActions.request()),
  changePasswordRequest: formData =>
    dispatch(UserActions.changePasswordActions.request(formData)),
});

const mapStateToProps = state => ({
  user: UserSelector.userProfileInfoSelector(state),
  working: UserSelector.userWorkingSelector(state),
  workingEdit: UserSelector.editUserWorkingSelector(state),
  editUserFailedMessage: UserSelector.editUserFailedMessageSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
