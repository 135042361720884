import { createSelector } from "reselect";

export const dashboardSelector = state =>
  state.dashboard && state.dashboard.results;

export const isWorking = state => state.dashboard && state.dashboard.working;

export const newsLoadingSelector = state => !!state.dashboard.working;

export const dashboardBrandId = createSelector(dashboardSelector, dashboard => {
  if (dashboard && dashboard.brand) {
    return dashboard.brand.id || null;
  }
});

export const brandWelcomeMsg = createSelector(dashboardSelector,
  dashboard => {
    return dashboard && dashboard.brand && dashboard.brand.welcome_message;
  });

export const dashboardBrandImage = createSelector(
  dashboardSelector,
  dashboard => {
    if (dashboard && dashboard.brand) {
      return dashboard.brand.dashboard_image || "";
    }
  }
);

export const dashboardContact = createSelector(
  dashboardSelector,
  dashboard => {
    if (dashboard && dashboard.brand.default_contact) {
      return dashboard.brand.default_contact;
    }
    return null;
  }
);

export const dashboardDocuments = createSelector(
  dashboardSelector,
  dashboard => {
    if (dashboard && dashboard.documents && dashboard.documents.length) {
      const docs = {
        brochure: [],
        priceList: [],
        factSheet: [],
        keyDocument: [],
      };
      dashboard.documents.forEach(document => {
        if (docs[document.document_type]) {
          docs[document.document_type].push(document);
        }
      });
      return docs;
    }
    return null;
  }
);
