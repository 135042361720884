import React from "react";
import { Link } from "react-router-dom";
import RoundedButton from "components/shared/button/RoundedButton/";
import navigation from "../../../../navigation";
import { THEME_KEYS } from "helpers/constants";

const NotFound = () => (
  <div className="NotFound animated fadeIn">
    <h2>Page not found.</h2>
    <p>You've landed on an empty page. Click below to head back to the dashboard.</p>
    <Link to={navigation.home} className="NotFound__link">
      <RoundedButton
        title="Dashboard"
        buttonColor={THEME_KEYS.SECONDARY}
        buttonClass="NotFound__link__button"
        modifiers={[]}
      />
    </Link>
  </div>
);

export default NotFound;
