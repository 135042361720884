import React, { Component } from "react";
import propTypes from "prop-types";
import { withTheme } from "styled-components";
import { IconChevronDown } from "../../../assets/svg/chevronDown";
import HoverComponent from "../../shared/util/Hover";
import { menuStates } from "../headerMenuStates";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { THEME_KEYS } from "helpers/constants";
import navigation from "./../../../navigation";
import { NavLink } from "react-router-dom";
import ContextElement from "components/shared/elements/ContextElement";
import Loader from "../../shared/loader";

export class HeaderProfile extends Component {
  constructor() {
    super();
    this.state = {
      isMenuOpen: false,
    };
  }

  componentDidMount() {
    if (!this.props.user) {
      this.props.getUser();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openMenu !== menuStates.PROFILE && prevState.isMenuOpen) {
      return { isMenuOpen: false };
    } else if (
      nextProps.openMenu === menuStates.PROFILE &&
      !prevState.isMenuOpen
    ) {
      return { isMenuOpen: true };
    }
    return prevState;
  }

  handleProfileMenuClicked = () => {
    this.setState(prevState => {
      !prevState.isMenuOpen
        ? this.props.onMenuOpen(menuStates.PROFILE)
        : this.props.onMenuOpen(null);
    });
  };

  userInitials = (firstName, lastName) => {
    const initials =
      (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "");
    return initials.toUpperCase();
  };

  render() {
    const { theme, user } = this.props;
    const { image, first_name: firstName, last_name: lastName } = user || {};

    let profileButtons = [
      <NavLink
        to={navigation.profile}
        className="HeaderProfile__menu__button"
        onClick={this.handleProfileMenuClicked}
        exact
        activeStyle={{ color: theme.secondary }}
      >
        Profile
      </NavLink>,
      <NavLink
        to={navigation.notificationSettings}
        onClick={this.handleProfileMenuClicked}
        exact
        className="HeaderProfile__menu__button"
        activeStyle={{ color: theme.secondary }}
      >
        Settings
      </NavLink>,
      <button
        className="HeaderProfile__menu__button"
        onClick={this.props.logout}
      >
        Logout
      </button>,
    ];

    return (
      <div
        className={
          this.state.isMenuOpen
            ? "HeaderProfile HeaderProfile--active"
            : "HeaderProfile"
        }
      >
        <button
          className="HeaderProfile__button"
          onClick={this.handleProfileMenuClicked}
        >
          <ContextElement
            elType="div"
            config={{ backgroundColor: THEME_KEYS.SECONDARY }}
            className={`HeaderProfile__image ${!image ? "HeaderProfile__image--initials" : ""}`}
          >
            {!user ? (
              <Loader className="Loader--noBG" />
            ) : image ? (
              <BackgroundImage
                lazyLoad
                className="HeaderProfile__image"
                src={image}
                alt="Profile"
              />
            ) : (
              <h1>{this.userInitials(firstName, lastName)}</h1>
            )}
          </ContextElement>
          <HoverComponent
            wrapperClassName="HeaderProfile__arrow"
            hoverFill={THEME_KEYS.SECONDARY}
          >
            <IconChevronDown />
          </HoverComponent>
        </button>
        <div className="HeaderProfile__menu">
          {profileButtons.map((button, index) => {
            return (
              <HoverComponent
                key={`${button.title}${index}`}
                defaultFill={THEME_KEYS.PRIMARY}
                hoverFill={THEME_KEYS.SECONDARY}
              >
                {button}
              </HoverComponent>
            );
          })}
        </div>
      </div>
    );
  }
}

HeaderProfile.propTypes = {
  user: propTypes.object,
  openMenu: propTypes.string,
  onMenuOpen: propTypes.func,
  logout: propTypes.func,
  theme: propTypes.object,
  getUser: propTypes.func,
};

export default withTheme(HeaderProfile);
