import { all } from "redux-saga/effects";
import { watchNewsRequestFactory } from "./newsSaga";
import { watchAuthRequestFactory } from "./authSaga";
import { watchBrandsRequestFactory } from "./brandsSaga";
import { watchFAQsRequestFactory } from "./FAQsSaga";
import { watchEventsRequestFactory } from "./eventsSaga";
import { watchContactsRequestFactory } from "./contactsSaga";
import { watchLoginSliderRequestFactory } from "./loginSliderSaga";
import { watchGeneralRequestFactory } from "./generalSaga";
import { watchQuestionsRequestFactory } from "./jotformSaga";
import NewsService from "services/wordpress/newsService";
import AuthService from "services/auth/authService";
import BrandsService from "services/brands/brandsService";
import FAQsService from "services/FAQs/FAQsService";
import EventsService from "services/events/eventsService";
import NotificationsService from "services/notification/notificationsService";
import ContactsService from "services/contacts/contactsService";
import LoginSliderService from "services/loginSlider/loginSliderService";
import { watchDashboardRequestFactory } from "./dashboardDataSaga";
import { watchNotificationsRequestFactory } from "./notificationSaga";
import { watchUsefulInformationRequestFactory } from "./usefulInformationSaga";
import UsefulInformationService from "services/usefulInformation/usefulInformationService";
import DashboardService from "services/dashboard/dashboardService";
import { watchUserRequestFactory } from "./userSaga";
import UserService from "services/user/userService";
import GeneralService from "services/general/generalService";
import JotformService from "services/jotform/jotformService";

const newsService = new NewsService();
const authService = new AuthService();
const brandsService = new BrandsService();
const faqsService = new FAQsService();
const eventsService = new EventsService();
const contactsService = new ContactsService();
const loginSliderService = new LoginSliderService();
const dashboardService = new DashboardService();
const userService = new UserService();
const generalService = new GeneralService();
const notificationsService = new NotificationsService();
const usefulInformationService = new UsefulInformationService();
const jotformService = new JotformService();

const news = watchNewsRequestFactory(newsService);
const auth = watchAuthRequestFactory(authService);
const brands = watchBrandsRequestFactory(brandsService);
const FAQs = watchFAQsRequestFactory(faqsService);
const events = watchEventsRequestFactory(eventsService);
const contacts = watchContactsRequestFactory(contactsService);
const loginSlider = watchLoginSliderRequestFactory(loginSliderService);
const dashboard = watchDashboardRequestFactory(dashboardService);
const user = watchUserRequestFactory(userService);
const general = watchGeneralRequestFactory(generalService);
const notifications = watchNotificationsRequestFactory(notificationsService);
const jotform = watchQuestionsRequestFactory(jotformService);
const usefulInformation = watchUsefulInformationRequestFactory(usefulInformationService);

export default function * rootSaga() {
  yield all([
    news.watchNewsRequest(),
    auth.watchAuthRequest(),
    brands.watchBrandsRequest(),
    events.watchEventsRequest(),
    loginSlider.watchLoginSlider(),
    contacts.watchContactsRequest(),
    FAQs.watchFAQsRequest(),
    dashboard.watchDashboardRequest(),
    user.watchUserRequest(),
    general.watchForGeneralRequests(),
    notifications.watchNotificationsRequest(),
    jotform.watchJotformRequests(),
    usefulInformation.watchUsefulInformationRequests(),
  ]);
}
