import React from "react";
import { connect } from "react-redux";
import LoginSlider from "./components/LoginSlider";
import propTypes from "prop-types";
import { loginSliderActions } from "redux/loginSlider/actions";
import LoginLayoutComponent from "./LoginLayoutComponent";
import * as loginSlideSelectors from "selectors/loginSlider";
import * as brandsSelector from "selectors/brands";
import navigation from "../../navigation";

function LoginHOC(WrappedComponent) {
  class SharedLoginLogic extends React.Component {
    DEFAULT_BRAND = 4;
    componentDidMount() {
      if (this.props.match && this.props.match.params && this.props.match.params.brandId) {
        this.props.getLoginSlides({ urlPhrase: this.props.match.params.brandId });
        this.props.history.push(navigation.login);
      } else if (!this.props.loginSlides) {
        this.props
          .getLoginSlides({ brandId: this.props.selectedBrand
            ? this.props.selectedBrand.id : this.DEFAULT_BRAND });
      }
    }

    render() {
      const { loginSlides } = this.props;
      return (
        <LoginLayoutComponent
          logo={loginSlides && loginSlides.logoImage}
          leftSide={<WrappedComponent {...this.props} />}
          rightSide={loginSlides && loginSlides.loginSlides && <LoginSlider slides={loginSlides.loginSlides} />}
        />
      );
    }
  }

  SharedLoginLogic.propTypes = {
    getLoginSlides: propTypes.func,
    loginSlides: propTypes.object,
    selectedBrand: propTypes.object,
    match: propTypes.object,
    history: propTypes.object,
  };

  /* eslint-disable */
  const mapDispatchToProps = dispatch => ({
    getLoginSlides: brand => dispatch(loginSliderActions.request(brand)),
  });
  /* eslint-enable */

  const mapStateToProps = state => ({
    loginSlides: loginSlideSelectors.loginSlidesSelector(state),
    selectedBrand: brandsSelector.selectedBrand(state),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(SharedLoginLogic);
}

export default LoginHOC;
