import React from "react";
import propTypes from "prop-types";

/* eslint max-len: 0 */
export const IconSearch = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15.2" height="15.2"><rect width="100%" height="100%" fill="none" /><defs><filter id="a" width="130.8%" height="356.7%" x="-15.4%" y="-128.3%" filterUnits="objectBoundingBox"><feOffset dy="10" in="SourceAlpha" result="shadowOffsetOuter1" /><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="15" /><feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" /><feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge></filter></defs><g className="currentLayer"><path fill="none" stroke="#2B2C2F" d="M13.98 13.34l-2.64-2.63a5.82 5.82 0 0 0-.31-7.9 5.72 5.72 0 0 0-4.1-1.71 5.8 5.8 0 0 0-4.12 1.7 5.82 5.82 0 0 0 0 8.25 5.83 5.83 0 0 0 4.12 1.7c1.35 0 2.71-.47 3.79-1.4l2.64 2.64c.07.06.18.1.29.1a.44.44 0 0 0 .31-.13c.18-.15.18-.44.02-.62zM3.3 10.44a4.95 4.95 0 0 1-1.47-3.56c0-1.34.53-2.62 1.47-3.59a5 5 0 0 1 3.59-1.47 5.1 5.1 0 0 1 3.58 1.47 5.1 5.1 0 0 1 0 7.17 5.14 5.14 0 0 1-3.58 1.47 5.07 5.07 0 0 1-3.59-1.5z" filter="url(#a)" /></g></svg>
  );
};

IconSearch.propTypes = {
  className: propTypes.string,
};
