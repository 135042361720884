import styled from "styled-components";
import * as MODIFIERS from "styles/styledModifiers";

export const DropdownWrapper = styled.div`
  border-radius: 30px;
  cursor: pointer;
  background-color: ${props =>
    (props.modifiers && props.modifiers.includes(MODIFIERS.WHITE)) || props.selected ? MODIFIERS.WHITE : "transparent"};
`;

export const Dropdown = styled.div(props => {
  let style = {
    color: props.theme.primary,
  };

  if (props.modifiers) {
    switch (true) {
      case props.modifiers.includes(MODIFIERS.SELECTED_TRANSPARENT):
        style.border = "#e2e2e2c0";
        style.color = "#fff";
        break;
      case props.selected && props.modifiers.includes(MODIFIERS.WHITE_BORDER):
        style.border = MODIFIERS.WHITE;
        break;
      case props.modifiers.includes(MODIFIERS.GREY_BORDER):
        style.border = "#e2e2e2";
        break;
      case props.modifiers.includes(MODIFIERS.NO_BORDER):
        style.border = "transparent";
        break;
      default:
        style.border = props.theme.primary;
        break;
    }
  }

  if (props.config && (props.config["border-color"] || props.config["borderColor"])) {
    style.border = props.config["border-color"] || props.config["borderColor"];
  }
  return `
      font-family: ${props.modifiers && props.modifiers.includes(MODIFIERS.BOLD) ? "LatoBold" : "Lato"};
      border: 0.05rem solid ${style.border};
      cursor: pointer;
      color: ${style.color};
      & input::placeholder {
        color: ${style.color};
    }
    `;
});

export const Option = styled.li`
  color: ${props => (props.selected ? props.theme.secondary : props.theme.primary)};
  font-weight: ${props => (props.selected ? "bold" : "inherit")};
  &:hover {
    background: ${props => props.theme.secondary};
  }
`;

export const ArrowContainer = styled.button`
  fill: ${props => {
    if (props.modifiers) {
      if (props.modifiers.includes(MODIFIERS.DARK)) {
        return props.selected ? props.theme.secondary : props.theme.primary;
      } else if (props.modifiers.includes(MODIFIERS.WHITE)) {
        return props.selected ? props.theme.secondary : props.theme.primary;
      } else if (props.modifiers.includes(MODIFIERS.SELECTED_TRANSPARENT)) {
        return props.selected ? props.theme.primary : "#fff";
      }
    }

    if (props.config && props.config["arrow-fill"]) {
      return props.selected ? props.theme.secondary : props.config["arrow-fill"];
    }
    return props.selected ? props.theme.secondary : props.theme.primary;
  }};
  border: ${props => {
    if (props.modifiers && props.modifiers.includes(MODIFIERS.NO_BORDER)) {
      return "none";
    }
  }};
  & > svg {
    transform: rotate(${props => (props.selected ? "180" : "0")}deg);
    margin-left: 1px;
    width: 100%;
  }
`;
