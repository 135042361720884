import LoginHOC from "routes/login/LoginHOC";
import LoginContainer from "../containers/LoginContainer";
import ForgottenPassword from "./WrappedComponents/ForgottenPassword";
import ResetPasswordContainer from "../containers/ResetPasswordContainer";

const WrappedLogin = LoginHOC(LoginContainer);
const WrappedForgottenPassword = LoginHOC(ForgottenPassword);
const WrappedResetPassword = LoginHOC(ResetPasswordContainer);

export { WrappedLogin, WrappedForgottenPassword, WrappedResetPassword };
