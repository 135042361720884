import React from "react";
import PropTypes from "prop-types";
/* eslint max-len: 0 */
export const IconCalendarAdd = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12 12">
      <path
        fillRule="evenodd"
        d="M2.87 0a.787.787 0 0 0-.783.783v.521H.522A.527.527 0 0 0 0 1.826v8.609c0 .284.237.522.522.522h7.585A2.088 2.088 0 0 0 9.913 12C11.063 12 12 11.062 12 9.913c0-.77-.42-1.444-1.043-1.806v-6.28a.527.527 0 0 0-.522-.523H8.87V.783A.787.787 0 0 0 8.087 0a.787.787 0 0 0-.783.783v.521H3.652V.783A.787.787 0 0 0 2.87 0zm0 .522c.149 0 .26.111.26.26v1.566c0 .15-.111.26-.26.26a.254.254 0 0 1-.261-.26V.783c0-.15.111-.261.26-.261zm5.217 0c.15 0 .26.111.26.26v1.566c0 .15-.11.26-.26.26a.254.254 0 0 1-.26-.26V.783c0-.15.11-.261.26-.261zM.522 1.826h1.565v.522c0 .429.354.782.783.782a.787.787 0 0 0 .782-.782v-.522h3.652v.522c0 .429.354.782.783.782a.787.787 0 0 0 .783-.782v-.522h1.565v2.087H.522V1.826zm0 2.609h9.913v3.46a2.075 2.075 0 0 0-.522-.069c-1.15 0-2.087.938-2.087 2.087 0 .18.026.355.07.522H.521v-6zm9.391 4.304a.26.26 0 0 0-.26.261v.652H9a.26.26 0 1 0 0 .522h.652v.652a.26.26 0 1 0 .522 0v-.652h.652a.26.26 0 1 0 0-.522h-.652V9a.26.26 0 0 0-.261-.26z"
      />
    </svg>
  );
};

IconCalendarAdd.propTypes = {
  className: PropTypes.string,
};
