import React from "react";
import propTypes from "prop-types";
import HoverComponent from "components/shared/util/Hover";
import { IconChevronDown } from "assets/svg/chevronDown";
import ContextElement from "components/shared/elements/ContextElement";

function FileAccordionComponent({ open, title, hoverFill, defaultFill, children, handleToggleButtonClick }) {
  return (
    <div className={open ? "FileAccordion FileAccordion--open" : "FileAccordion"}>
      <div className="FileAccordion__header">
        <p className="FileAccordion__header__title">{title}</p>
        <ContextElement
          elType="span"
          config={{ "backgroundColor": "secondary" }}
          className="FileAccordion__header__seperator"
        />
        <button onClick={handleToggleButtonClick} className="FileAccordion__header__button">
          <HoverComponent hoverFill={hoverFill} defaultFill={defaultFill}>
            <IconChevronDown />
          </HoverComponent>
        </button>
      </div>
      <div className="FileAccordion__children">{children}</div>
    </div>
  );
};

FileAccordionComponent.propTypes = {
  open: propTypes.bool,
  title: propTypes.string,
  hoverFill: propTypes.string,
  defaultFill: propTypes.string,
  children: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  handleToggleButtonClick: propTypes.func,
};

export default FileAccordionComponent;
