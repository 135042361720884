import React from "react";
/* eslint max-len: 0 */
export const IconPhone = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M10.735 14c-.07 0-.141-.003-.213-.007-.862-.059-1.725-.312-2.8-.823a14.994 14.994 0 0 1-4.924-3.855C1.703 8.005.902 6.667.418 5.337.178 4.674-.06 3.877.014 3c.024-.554.26-1.064.682-1.458l1.07-1.07C2.39-.155 3.25-.158 3.901.465c.2.186.396.387.586.582l.288.292.846.847c.649.676.649 1.508.007 2.178-.188.188-.369.363-.55.539-.153.149-.307.298-.46.45.183.414.453.828.862 1.328.856 1.04 1.684 1.792 2.604 2.363l.02.012c.115.08.257.152.407.227l.122.061 1.002-1.002c.31-.309.68-.473 1.072-.473.391 0 .762.163 1.072.473l1.734 1.735c.648.647.648 1.517 0 2.164-.113.114-.228.226-.34.336-.226.221-.439.43-.645.648-.447.512-1.052.775-1.794.775zM3.447 8.771a14.138 14.138 0 0 0 4.643 3.637c.97.46 1.739.69 2.488.74.578.038.999-.118 1.325-.49.227-.243.456-.468.678-.685l.336-.33c.32-.322.32-.647 0-.968L11.18 8.941c-.295-.295-.651-.295-.946 0l-1.08 1.079c-.087.088-.356.356-.784.142-.087-.047-.163-.084-.238-.122-.167-.084-.34-.17-.504-.283-.997-.62-1.888-1.427-2.803-2.54-.502-.613-.822-1.123-1.038-1.653a.625.625 0 0 1 .17-.746c.177-.177.354-.35.532-.522.178-.173.356-.346.534-.523.325-.34.325-.649-.007-.995l-.84-.84c-.1-.1-.198-.2-.295-.3-.191-.196-.372-.38-.561-.557-.229-.218-.575-.393-.957-.01l-1.08 1.078c-.27.254-.41.556-.426.905-.06.729.135 1.388.356 1.994.45 1.238 1.2 2.49 2.233 3.723z"
      />
    </svg>
  );
};
