import React, { Component } from "react";
import { withTheme } from "styled-components";
import ContextElement from "components/shared/elements/ContextElement";
import propTypes from "prop-types";

class Pagination extends Component {
  handleClicked = id => {
    return () => {
      const { onPageChange } = this.props;

      onPageChange(id);
    };
  };

  paginationElements = () => {
    let elements = [];

    for (let index = 1; index <= this.props.allPagesCount; index++) {
      elements.push(index);
    }

    return elements.map((element, index) => (
      <ContextElement
        key={`Pagination__button${index}`}
        elType="button"
        className={`Pagination__button ${this.props.currentPage === element ? "active" : ""}`}
        config={{ borderColor: "secondary" }}
        onClick={this.handleClicked(index)}
      >
        {element}
      </ContextElement>
    ));
  };

  render() {
    return <div className="Pagination">{this.paginationElements()}</div>;
  }
}

Pagination.propTypes = {
  allPagesCount: propTypes.number,
  currentPage: propTypes.number,
  onPageChange: propTypes.func,
};

export default withTheme(Pagination);
