import React, { PureComponent, Fragment } from "react";
import propTypes from "prop-types";
import isMobile from "helpers/isMobile";
import SortDropDown from "./../SortDropDown";
import PageSelector from "./../PageSelector";
import Filter from "./../Filter";
import { IconArrow } from "assets/svg/arrow";
import { NEWS_VIEW_TYPES, FILTER_TYPES } from "helpers/constants";

class FilterMenu extends PureComponent {
  state = {
    active: null,
  };

  handleOptionSelection = selection => {
    if (selection.constructor === Number) {
      this.props.setSelectedOption(selection);
    }
  };

  handleSubmit = data => {
    this.props.onChange(data[this.props.name]);
    this.setState({
      active: null,
    });
  };

  handleClick = filterType => {
    this.setState({
      active: typeof filterType === "string" ? filterType : null,
    });
  };

  render() {
    const {
      sortOptions,
      currentSortOption,
      showSort,
      showFilters,
      categories,
      tags,
      currentPage,
      handleShowSort,
      handleShowFilters,
      handleSelectedOption,
      handleCategoryChange,
      state,
      resetActiveCategories,
      handleNext,
      handleBack,
      handleTagsChange,
      resetActiveTags,
      computePageAmount,
      newsViewType,
    } = this.props;

    const { active } = this.state;

    return (
      <div className="News__topBar">
        {isMobile() ? (
          <Fragment>
            {categories && !!categories.length && tags && !!tags.length && (
              <div className="News__topBar__menu">
                <span className="News__topBar__menu__option" onClick={handleShowSort}>
                  Sort by
                  <IconArrow />
                </span>
                {newsViewType === NEWS_VIEW_TYPES.NEWS &&
                <span className="News__topBar__menu__option" onClick={handleShowFilters}>
                  Filter
                </span>
                }
              </div>
            )}
            {showSort && (
              <div className="News__topBar__sort News__topBar__sort--mobile">
                <SortDropDown
                  selectedOption={currentSortOption}
                  options={sortOptions}
                  setSelectedOption={handleSelectedOption}
                />
              </div>
            )}
            {showFilters && categories && !!categories.length && (
              <div className="News__topBar__filter News__topBar__filter--mobile">
                <div
                  className={`News__topBar__filter__buttons__background ${active
                    ? "News__topBar__filter__buttons__background--active"
                    : ""}`}
                  onClick={this.handleClick} />
                <Filter
                  handleClick={this.handleClick}
                  active={active === FILTER_TYPES.CATEGORIES}
                  filterType={FILTER_TYPES.CATEGORIES}
                  selectedOption={currentSortOption}
                  options={categories}
                  name="categories"
                  setSelectedOption={handleSelectedOption}
                  onChange={handleCategoryChange}
                  activeOptions={state.categories}
                  resetActiveOptions={resetActiveCategories}
                />
                {showFilters && tags && !!tags.length && (
                  <Filter
                    handleClick={this.handleClick}
                    active={active === FILTER_TYPES.TAGS}
                    filterType={FILTER_TYPES.TAGS}
                    selectedOption={currentSortOption}
                    options={tags}
                    name="tags"
                    setSelectedOption={handleSelectedOption}
                    onChange={handleTagsChange}
                    activeOptions={state.tags}
                    resetActiveOptions={resetActiveTags}
                  />
                )}
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <div className="News__topBar__sort">
              <span className="News__topBar__label">Sort by</span>
              <SortDropDown
                selectedOption={currentSortOption}
                options={sortOptions}
                setSelectedOption={handleSelectedOption}
              />
            </div>
            {newsViewType !== NEWS_VIEW_TYPES.USEFULINFO && tags && !!tags.length && (
              <div className="News__topBar__filter">
                <span className="News__topBar__label">Filter by</span>
                <div className="News__topBar__filter__buttons">
                  <div
                    className={`News__topBar__filter__buttons__background ${active
                      ? "News__topBar__filter__buttons__background--active"
                      : ""}`}
                    onClick={this.handleClick} />
                  <Filter
                    handleClick={this.handleClick}
                    active={active === FILTER_TYPES.CATEGORIES}
                    filterType={FILTER_TYPES.CATEGORIES}
                    selectedOption={currentSortOption}
                    options={categories}
                    name="categories"
                    setSelectedOption={handleSelectedOption}
                    onChange={handleCategoryChange}
                    activeOptions={state.categories}
                    resetActiveOptions={resetActiveCategories}
                  />
                  <Filter
                    handleClick={this.handleClick}
                    active={active === FILTER_TYPES.TAGS}
                    filterType={FILTER_TYPES.TAGS}
                    selectedOption={currentSortOption}
                    options={tags}
                    name="tags"
                    setSelectedOption={handleSelectedOption}
                    onChange={handleTagsChange}
                    activeOptions={state.tags}
                    resetActiveOptions={resetActiveTags}
                  />
                </div>
              </div>
            )}
            {!!computePageAmount() &&
            <PageSelector
              allPagesCount={computePageAmount()}
              onNext={handleNext}
              onBack={handleBack}
              currentPage={currentPage}
            />
            }
          </Fragment>
        )}
      </div>
    );
  }
}

FilterMenu.propTypes = {
  sortOptions: propTypes.array,
  currentSortOption: propTypes.object,
  showSort: propTypes.bool,
  showFilters: propTypes.bool,
  categories: propTypes.array,
  tags: propTypes.array,
  currentPage: propTypes.number,
  handleShowSort: propTypes.func,
  handleShowFilters: propTypes.func,
  handleSelectedOption: propTypes.func,
  handleCategoryChange: propTypes.func,
  state: propTypes.object,
  resetActiveCategories: propTypes.func,
  handleNext: propTypes.func,
  handleBack: propTypes.func,
  handleTagsChange: propTypes.func,
  resetActiveTags: propTypes.func,
  computePageAmount: propTypes.func,
  setSelectedOption: propTypes.func,
  onChange: propTypes.func,
  name: propTypes.string,
  newsViewType: propTypes.string,
};

export default FilterMenu;
