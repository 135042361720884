import { connect } from "react-redux";
import * as JotformActions from "redux/jotform/actions";
import * as dashboardDataActions from "redux/dashboard/actions";
import * as JotformSelectors from "selectors/jotform";
import * as brandsSelector from "selectors/brands";

import RegisterStudentIframe from "../components/RegisterStudentIframe";

const mapDispatchToProps = dispatch => ({
  getDashboardData: () =>
    dispatch(dashboardDataActions.dashboardDataActions.request()),
  getQuestions: () => dispatch(JotformActions.getFormQuestions.request()),
  submitJotform: formData =>
    dispatch(JotformActions.sendFormAnswers.request(formData)),
});

const mapStateToProps = state => ({
  formQuestions: JotformSelectors.questionsSelector(state), // TODO: Review jotform use and components relevance
  displayAppForm: brandsSelector.displayAppForm(state), // used
  selectedBrand: brandsSelector.selectedBrand(state), // used
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStudentIframe);
