import React from "react";
import propTypes from "prop-types";
import RoundedButton from "components/shared/button/RoundedButton";
import { THEME_KEYS } from "helpers/constants";

function SuspendedAccountHeader({
  header = "Account suspended",
  intro = "You need to wait for someone to approve you.",
  handleSignout,
}) {
  return (
    <header className="LoginHeader">
      <h1 className="LoginHeader__h1">
        {header}
      </h1>
      <div className="LoginHeader__intro">
        {intro}
      </div>
      <div>
        <RoundedButton
          invertColors
          buttonColor={THEME_KEYS.PRIMARY}
          title={"Login with another account"}
          type="button"
          modifiers={["large"]}
          onClick={handleSignout}
        />
      </div>
    </header>
  );
};

SuspendedAccountHeader.propTypes = {
  header: propTypes.string,
  intro: propTypes.string,
  handleSignout: propTypes.func.isRequired,
};

export default SuspendedAccountHeader;
