import React, { PureComponent } from "react";
import propTypes from "prop-types";
import RoundedButton from "components/shared/button/RoundedButton/";
import { IconCalendarAdd } from "assets/svg/calendarAdd";
import ContextElement from "components/shared/elements/ContextElement";
import { IconPlus } from "assets/svg/plus";
import { THEME_KEYS } from "helpers/constants";
import monthNames from "helpers/monthNames";
import { sanitize } from "dompurify";
import SingleIconButton from "components/shared/button/SingleIconButton/";

class SelectedEventComponent extends PureComponent {
  getDateTime(dateTime) {
    const date = new Date(dateTime);
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      " " +
      date.getFullYear() +
      ", " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2)
    );
  }

  render() {
    const {
      title,
      type,
      location,
      selectEvent,
      startDate,
      endDate,
      details,
      handleAddToCalendar,
    } = this.props;
    return (
      <div className="Calendar__event">
        <div className="Calendar__event__head">
          <div className="Calendar__event__head__info">
            <p className="Calendar__event__head__info__title">{title}</p>
            <ContextElement
              elType="p"
              className="Calendar__event__head__info__brandName"
              config={{ backgroundColor: THEME_KEYS.SECONDARY }}
            >
              {type}
            </ContextElement>
            <p>{location}</p>
          </div>
          <SingleIconButton
            Icon={IconPlus}
            onClick={selectEvent}
            className="Calendar__event__head__close"
          />
        </div>
        <div className="Calendar__event__dates">
          <p>{this.getDateTime(startDate)}</p>
          <p>-</p>
          <p>{this.getDateTime(endDate)}</p>
        </div>
        <div className="Calendar__event__details">
          <p dangerouslySetInnerHTML={{ __html: sanitize(details) }} />
          <RoundedButton
            Icon={IconCalendarAdd}
            onClick={handleAddToCalendar}
            title="Add to Calendar"
            buttonColor={THEME_KEYS.SECONDARY}
            buttonClass="Calendar__event__details__button"
          />
        </div>
      </div>
    );
  }
}

SelectedEventComponent.propTypes = {
  title: propTypes.string,
  type: propTypes.string,
  location: propTypes.string,
  selectEvent: propTypes.func,
  startDate: propTypes.any,
  endDate: propTypes.any,
  details: propTypes.string,
  handleAddToCalendar: propTypes.func,
};

export default SelectedEventComponent;
