import React from "react";
/* eslint max-len: 0 */
export const IconExpand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 28 28">
      <defs>
        <filter id="a" width="204.3%" height="115.3%" x="-52.2%" y="-7.6%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fillRule="evenodd" filter="url(#a)" transform="translate(4 2)">
        <path
          d="M8.594 6.898L2.85 1.155C2.588.89 2.326.623 2.058.36L2.047.35A1.23 1.23 0 0 0 1.199
            0C.904 0 .555.13.35.35.143.578-.013.877 0 1.198c.014.318.122.618.35.848l.65.65 1.566
            1.567L4.454 6.15 6.093 7.79c.263.263.525.532.793.793l.012.012c.218.218.538.35.848.35.295
            0 .644-.13.848-.35.208-.228.364-.527.35-.849a1.26 1.26 0 0 0-.35-.848zM22.648
            20.948l-.651-.652-1.557-1.557-1.893-1.894-1.63-1.63c-.267-.266-.528-.536-.797-.797l-.012-.012c-.219-.219-.54-.35-.85-.35-.296
            0-.646.13-.85.35-.21.23-.366.53-.352.851.015.32.123.62.351.85l.652.653 1.557 1.557 1.893 1.893 1.629
            1.63c.267.267.529.536.798.798l.011.011c.22.22.54.351.85.351.297 0 .647-.13.851-.35.209-.23.365-.53.351-.852a1.26
            1.26 0 0 0-.35-.85zM6.898 14.406a7879260.868 7879260.868 0 0 1-2.216 2.216L2.794 18.51l-1.64
            1.639c-.263.263-.531.525-.793.793l-.011.011a1.23 1.23 0 0 0-.35.848c0
            .295.13.644.35.848.228.208.527.364.848.35.318-.014.618-.122.848-.35l.65-.65 1.567-1.566 1.888-1.887
            1.639-1.639c.263-.263.532-.525.793-.793l.012-.012a1.23 1.23 0 0 0
            .35-.848c0-.295-.13-.644-.35-.848-.228-.208-.527-.364-.849-.35-.319.015-.618.122-.848.35zM20.948.352l-.652.651-1.557 1.557-1.894
            1.893-1.63 1.63c-.266.267-.536.528-.797.797l-.012.012c-.219.219-.35.54-.35.85 0
            .296.13.646.35.85.23.21.53.366.851.352.32-.015.62-.123.85-.351l.653-.652 1.557-1.557 1.893-1.893
            1.63-1.629c.267-.267.536-.529.798-.798l.011-.011c.22-.22.351-.54.351-.85
            0-.297-.13-.647-.35-.851-.23-.209-.53-.365-.852-.351-.319.014-.622.12-.85.35z"
        />
        <path
          d="M2.522 7.683V1.26L1.26 2.52h6.422c.322 0 .663-.14.892-.368.22-.218.384-.577.368-.892-.015-.325-.12-.664-.368-.893A1.308
        1.308 0 0 0 7.683 0H1.26C.58 0 0 .577 0 1.26v6.423c0
        .322.141.663.368.892.219.22.577.384.893.368.325-.015.663-.12.892-.368.225-.247.369-.551.369-.892zM15.317
        23h6.422C22.42 23 23 22.423 23 21.74v-6.423c0-.322-.141-.663-.368-.892-.219-.22-.577-.384-.893-.368-.325.015-.663.12-.892.368-.227.247-.369.55-.369.892v6.422l1.261-1.26h-6.422c-.322 0-.663.14-.892.368-.22.218-.384.577-.368.892.015.325.12.664.368.893.25.227.552.368.892.368zM7.683 20.478H1.26l1.26 1.261v-6.422c0-.322-.14-.663-.368-.892-.218-.22-.577-.384-.892-.368-.325.015-.664.12-.893.368-.227.247-.368.55-.368.892v6.422C0 22.42.577 23 1.26 23h6.423c.322 0 .663-.141.892-.368.22-.219.384-.577.368-.893-.015-.325-.12-.663-.368-.892a1.317 1.317 0 0 0-.892-.369zM23 7.683V1.26C23 .58 22.423 0 21.74 0h-6.423c-.322 0-.663.141-.892.368-.22.219-.384.577-.368.893.015.325.12.663.368.892.247.227.55.369.892.369h6.422L20.48 1.26v6.422c0 .322.14.663.368.892.218.22.577.384.892.368.325-.015.664-.12.893-.368.225-.247.368-.551.368-.892z"
        />
      </g>
    </svg>
  );
};
