import React from "react";
import propTypes from "prop-types";
/* eslint max-len: 0 */
export const IconWhatsApp = ({ fill = "#fff" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16">
      <path fillRule="evenodd" d="M10.933 9.029c-.187-.093-1.104-.545-1.275-.607-.17-.062-.295-.093-.42.093-.124.187-.482.607-.59.732-.11.124-.218.14-.405.047-.186-.094-.787-.29-1.5-.926a5.621 5.621 0 0 1-1.038-1.293c-.108-.186-.011-.287.082-.38.084-.084.187-.218.28-.327.093-.11.124-.187.187-.311.062-.125.03-.234-.016-.327-.047-.094-.42-1.012-.575-1.385-.152-.364-.305-.315-.42-.32a7.482 7.482 0 0 0-.357-.007.685.685 0 0 0-.498.233c-.17.187-.653.638-.653 1.557 0 .918.669 1.805.762 1.93.093.124 1.315 2.008 3.187 2.816.445.192.792.307 1.063.393.447.142.854.122 1.175.074.359-.053 1.104-.451 1.26-.887.155-.436.155-.81.108-.887-.046-.078-.17-.125-.357-.218m-3.404 4.648h-.003c-1.113 0-2.205-.3-3.158-.865l-.227-.135-2.348.616.627-2.29-.148-.234a6.19 6.19 0 0 1-.949-3.303 6.215 6.215 0 0 1 6.208-6.205c1.658 0 3.216.647 4.388 1.82a6.168 6.168 0 0 1 1.815 4.39 6.214 6.214 0 0 1-6.205 6.206M12.81 2.19A7.418 7.418 0 0 0 7.53 0C3.414 0 .064 3.35.063 7.466c-.001 1.316.343 2.6.996 3.732L0 15.068l3.958-1.04a7.46 7.46 0 0 0 3.568.91h.003c4.115 0 7.464-3.35 7.466-7.466 0-1.995-.775-3.871-2.185-5.282" />
    </svg>
  );
};

IconWhatsApp.propTypes = {
  fill: propTypes.string,
};
