import React from "react";
import { Formik, Field } from "formik";
import propTypes from "prop-types";
import TextInput from "components/formElements/TextInput";
import RoundedButton from "components/shared/button/RoundedButton";
import FormError from "components/formElements/FormError";
import Loader from "components/shared/loader";
import * as Yup from "yup";
import * as commonValidators from "helpers/commonValidators";
import { THEME_KEYS } from "../../../../../helpers/constants";

function ChangePasswordForm({ error, handleSubmit, working }) {
  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {subformik => (
        <div className="Form__inner">
          <Field name="password" label="Current Password" type="password" mandatory component={TextInput} />
          <Field name="newPassword" label="New Password" type="password" mandatory component={TextInput} />
          <Field name="confirmPassword" label="Confirm Password" type="password" mandatory component={TextInput} />
          <div>
            <div className="flex">
              <RoundedButton
                title="Update Password"
                type="button"
                onClick={() => subformik.submitForm()}
                modifiers={[]}
                buttonColor={THEME_KEYS.SECONDARY}

              />
              {working && <Loader className="Loader--buttonLoader alignSelfCenter" />}
            </div>
            {!!error && <FormError message={error} />}
          </div>
        </div>
      )}
    </Formik>
  );
}

const validationSchema = Yup.object().shape({
  password: commonValidators.isRequired("Current Password"),
  newPassword: commonValidators.isPassword(),
  confirmPassword: commonValidators.hasMatchingField("newPassword"),
});

ChangePasswordForm.propTypes = {
  error: propTypes.object,
  handleSubmit: propTypes.func.isRequired,
  working: propTypes.bool,
};

export default ChangePasswordForm;
