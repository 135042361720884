import React, { PureComponent } from "react";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";
import FormError from "components/formElements/FormError";
import RoundedButton from "components/shared/button/RoundedButton/";
import * as Yup from "yup";
import * as commonValidators from "helpers/commonValidators";
import UserPersonalDetailsFields from "components/formParts/UserPersonalDetailsFields";
import UserAddressFields from "components/formParts/UserAddressFields";
import TeamFields from "components/formParts/TeamFields";
import RegionSection from "components/formParts/RegionSection";
import ContextElement from "components/shared/elements/ContextElement";
import TextInput from "components/formElements/TextInput";
import { THEME_KEYS } from "helpers/constants";
import InterestSection from "components/formParts/InterestSection";
import ScrollToError from "../../../components/shared/util/ScrollToError";
import RegistrationType from "components/formParts/RegistrationType";

class RegisterForm extends PureComponent {
  render() {
    const { onSubmit, error, working, backButton } = this.props;

    return (
      <div className="RegisterForm">
        <Formik
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          render={({ handleSubmit, setFieldValue, isSubmitting, isValidating, errors }) => {
            return (
              <form className="Form RegisterForm" onSubmit={handleSubmit} noValidate>
                <ScrollToError isSubmitting={isSubmitting} isValidating={isValidating} errors={errors} />
                <div className="Form__inner">
                  <div className="UserInformationFormFields Form__fieldRow--lineDivide">
                    <ContextElement
                      elType="div"
                      className="UserInformationFormFields__section"
                      config={{
                        borderColor: THEME_KEYS.SECONDARY,
                      }}
                    >
                      <ContextElement
                        elType="h3"
                        config={{ color: "secondary" }}
                        className="UserInformationFormFields__section__heading lightHeaderSansSerif"
                      >
                      Account Details
                      </ContextElement>
                      <Field name="emailAddress" label="Email Address" type="email" mandatory component={TextInput} />
                      <Field name="password" label="Password" type="password" mandatory component={TextInput} />
                      <Field
                        name="passwordConfirmation"
                        label="Verify Password"
                        type="password"
                        mandatory
                        component={TextInput}
                      />
                    </ContextElement>
                    <UserPersonalDetailsFields />
                    <ContextElement
                      elType="div"
                      className="UserInformationFormFields__section"
                      config={{
                        borderColor: THEME_KEYS.SECONDARY,
                      }}
                    >
                      <ContextElement
                        elType="h3"
                        config={{ color: "secondary" }}
                        className="UserInformationFormFields__section__heading lightHeaderSansSerif"
                      >
                      Organisation Details
                      </ContextElement>
                      <Field name="company" label="Company Name" type="text" mandatory component={TextInput} />
                      <TeamFields />
                    </ContextElement>
                    <UserAddressFields />
                    <ContextElement
                      elType="div"
                      className="UserInformationFormFields__section"
                      config={{
                        borderColor: THEME_KEYS.SECONDARY,
                      }}
                    >
                      <RegionSection setFieldValue={setFieldValue} />
                    </ContextElement>
                    <ContextElement
                      elType="div"
                      className="UserInformationFormFields__section"
                      config={{
                        borderColor: THEME_KEYS.SECONDARY,
                      }}
                    >
                      <InterestSection setFieldValue={setFieldValue} />
                    </ContextElement>
                    <RegistrationType />
                  </div>
                  <div
                    className="Form__fieldRow--flex Form__fieldRow--spaceBetween
                  Form__fieldRow--wrap Form__fieldRow--spacing120"
                  >
                    <div className="Form__fieldRow__item Form__fieldRow__item--fullWidth">
                      <RoundedButton
                        buttonColor={THEME_KEYS.PRIMARY}
                        title="Register"
                        type="submit"
                        disabled={working}
                        modifiers={["medium", "white"]}
                      />
                    </div>
                    <div className="Form__fieldRow__item Form__fieldRow__item--fullWidth">
                      {backButton && backButton}
                    </div>
                  </div>
                  {!!error && <FormError message={error} />}
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

const validationSchema = Yup.object().shape({
  emailAddress: commonValidators.isValidEmail(),
  password: commonValidators.isPassword(),
  passwordConfirmation: commonValidators.hasMatchingField("password"),
  firstName: commonValidators.isValidGenericTextField("First Name"),
  lastName: commonValidators.isValidGenericTextField("Last Name"),
  areaCode: commonValidators.isRequired("Area Code"),
  contactNumber: commonValidators.isPositiveNumber("Contact Number"),
  company: commonValidators.isValidGenericTextField("Company Name"),
  team: commonValidators.isRequiredMixed("Team"),
  address1: commonValidators.isValidGenericTextField("Address Line 1"),
  address2: commonValidators.isValidGenericTextField("Address Line 2"),
  townCity: commonValidators.isValidGenericTextField("Town / City"),
  postcode: commonValidators.isRequired("ZIP / Postal Code"),
  country: commonValidators.isRequiredMixed("Country"),
  registrationType: commonValidators.isNumber("Registration Type"),
});

RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  working: PropTypes.bool,
  backButton: PropTypes.element,
};

export default RegisterForm;
