import React from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";
import RoundedButton from "components/shared/button/RoundedButton";
import { THEME_KEYS } from "helpers/constants";

function QuickGuideCTA({
  handlePlayTutorial,
  heading = "Guided Tour",
  text = `
  Take a quick tour around the site and find out how to use the Agent Portal. The tour will only take a few moments.
  `,
  buttonTitle = "Take the tour",
}) {
  return (
    <div className="QuickGuideCTA">
      <div className="QuickGuideCTA__left">
        <ContextElement
          elType="h2"
          className="QuickGuideCTA__heading headerSerif"
          config={{
            color: THEME_KEYS.PRIMARY,
          }}
        >
          {heading}
        </ContextElement>
        <p className="QuickGuideCTA__text">{text}</p>
      </div>
      <div className="QuickGuideCTA__right">
        <RoundedButton
          modifiers={["large"]}
          className="QuickGuideCTA__button"
          title={buttonTitle}
          onClick={handlePlayTutorial}
          buttonColor={THEME_KEYS.SECONDARY}
          type="button"
        />
      </div>
    </div>
  );
};

QuickGuideCTA.propTypes = {
  handlePlayTutorial: propTypes.func.isRequired,
  heading: propTypes.string,
  text: propTypes.string,
  buttonTitle: propTypes.string,
};

export default QuickGuideCTA;
