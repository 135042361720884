export function mapWordpressResponse(response) {
  return response.map(item => {
    return {
      id: item.id ? item.id : "",
      title: item.title && item.title.rendered ? item.title.rendered : "",
      content:
        item.content && item.content.rendered
          ? item.content.rendered
          : item.acf &&
            item.acf.page_builder &&
            item.acf.page_builder[0] &&
            item.acf.page_builder[0].post_content &&
            item.acf.page_builder[0].post_content[0] &&
            item.acf.page_builder[0].post_content[0].content
            ? item.acf.page_builder[0].post_content[0].content
            : "",
      excerpt:
        item.excerpt && item.excerpt.rendered
          ? item.excerpt.rendered
          : item.acf &&
            item.acf.page_builder &&
            item.acf.page_builder[0] &&
            item.acf.page_builder[0].post_content &&
            item.acf.page_builder[0].post_content[0] &&
            item.acf.page_builder[0].post_content[0].content
            ? item.acf.page_builder[0].post_content[0].content
              .replace(/<[^>]*>?/gm, "")
              .slice(0, 150) + "..."
            : "",
      link: item.link ? item.link : "",
      date: item.date ? item.date : "",
      image:
        item._embedded &&
        item._embedded["wp:featuredmedia"] &&
        item._embedded["wp:featuredmedia"][0].media_details
          ? item._embedded["wp:featuredmedia"][0].media_details.sizes
            .medium_large.source_url
          : "",
    };
  });
}
