import React from "react";
/* eslint max-len: 0 */
export const IconContacts = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13">
      <path
        fillRule="nonzero"
        d="M2.989 3.234c0 1.085.54 2.05 1.366 2.637-.489.157-.95.375-1.378.643 0 0-.318.168-.716.527a6.097 6.097 0 0 0-2.147 4.645c-.002.12.127.319.127.319s.115.13.315.132c1.81.021 10.93 0 11.33 0 .444 0-.443-.647-.443-.647H.979c0-1.71.844-3.03 2.112-3.982a5.199 5.199 0 0 1 3.131-1.042 5.2 5.2 0 0 1 3.046.977c.03.03.064.055.101.075 1.26.952 2.074 2.268 2.074 3.972.083.085.324.647.443.647a.447.447 0 0 0 .445-.45c0-1.857-.964-3.665-2.277-4.787.508-.62 1.41-1.03 2.277-1.03 1.542 0 2.982 1.49 2.982 2.672-.001.065-3.897-.06-3.87 0 .021.047.239.677.275.715.085.084 3.719 0 3.838 0a.446.446 0 0 0 .444-.45c0-1.482-.884-2.761-2.15-3.34a2.386 2.386 0 0 0 .872-1.843 2.397 2.397 0 0 0-2.391-2.389 2.395 2.395 0 0 0-2.39 2.39c0 .739.341 1.402.872 1.841-.526.24-.987.6-1.345 1.048a6.094 6.094 0 0 0-1.379-.644 3.234 3.234 0 0 0 1.367-2.636A3.24 3.24 0 0 0 6.222 0 3.24 3.24 0 0 0 2.99 3.234zM6.222.889c1.3 0 2.345 1.045 2.345 2.345 0 1.299-1.045 2.344-2.345 2.344a2.339 2.339 0 0 1-2.344-2.344c0-1.3 1.045-2.345 2.344-2.345zm6.11 1.236c.835 0 1.502.668 1.502 1.5 0 .836-.666 1.5-1.502 1.5-.834 0-1.5-.664-1.5-1.5 0-.832.667-1.5 1.5-1.5z"
      />
    </svg>
  );
};
