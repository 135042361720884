import isMobile from "helpers/isMobile";

const TOP = "top";
const CENTER = "center";
const LEFT = "left";
const RIGHT = "right";

class PopupConfiguration {
  constructor(
    x,
    y,
    heading,
    content,
    sideOfThePopup,
    positionOfTheArrow,
    xAxisScreen,
    yAxisScreen
  ) {
    this.x = x;
    this.y = y;
    this.heading = heading;
    this.content = content;
    this.arrowPointing = {
      sideOfThePopup,
      positionOfTheArrow,
    };
    this.popupAnchor = {
      xAxisScreen,
      yAxisScreen,
    };
  }
}

let popupConfigurationArray = [
  new PopupConfiguration(
    70,
    50,
    "Switching Brands",
    `The brand drop-down feature allows you to navigate to a different brand hub easily.
    By switching to a different brand, you will be able to get access to its information and resources.`,
    TOP,
    RIGHT,
    TOP,
    LEFT
  ),
  new PopupConfiguration(
    70,
    30,
    "Profile Settings",
    `The profile settings is the place for you to keep all of your information up to date. 
You can also update what brands you’re interested in getting notifications about.`,
    TOP,
    RIGHT,
    TOP,
    RIGHT
  ),
  new PopupConfiguration(
    70,
    100,
    "Notifications",
    `Notifications highlight the new updates that have been added to the portal. 
You will only receive notifications for brands you’re opted in to follow. 
You can easily opt in or out of notifications in your profile settings.`,
    TOP,
    RIGHT,
    TOP,
    RIGHT
  ),
];

export function popupConfigurations(
  displayAppForm = false
) {
  let navigationTutorials = [
    {
      title: "Dashboard",
      description: `The Dashboard is the homepage of a brand hub. 
Each brand has its own homepage and is a space for us to highlight key information and resources to you.`,
    },
    {
      title: "Digital Resources",
      description: `This section contains all of the key collateral that we have available for a brand. 
Each brand has its own Digital Resources area. To access and utilise the content, 
you can search, share and download files from within this section. 
You can also pin documents to the Quick Access area so that you can easily locate it again.`,
    },
    {
      title: "News",
      description: `This section hosts the latest brand information 
and is made up of the News tab and the Useful Information tab. 
The News tab contains curated blog posts that are pulled in directly from that brand’s website. 
The Useful Information tab contains key agent notices that our teams have prepared for you.`,
    },
    {
      title: "Calendar",
      description: `The Calendar section highlights key events that are happening for each brand. 
When you click on an event, you will have the option to see more details and to add it to your own calendar.`,
    },
    {
      title: "Contacts",
      description: `The Contacts section showcases key team members from across our different regions. 
Feel free to reach out to your regional contact, as they will be happy to help.`,
    },
    {
      title: "Browse Brands",
      description: `The Browse Brands section is the place for 
you to go to discover more information about each of our brands.`,
    },
    {
      title: "Need Help",
      description: `This section contains frequently asked questions and provides 
a way for you to get in touch for support. 
There is an instructional tour on how to use the site hosted here.`,
    },
  ];

  const navMap = navigationTutorials.map(
    (el, index) => {
      if (!displayAppForm) {
        if (index <= 3) {
          return new PopupConfiguration(
            (index * 55) + 75,
            275,
            el.title,
            el.description,
            LEFT,
            TOP,
            TOP,
            LEFT
          );
        } else {
          return new PopupConfiguration(
            (index * 55) - 50,
            275,
            el.title,
            el.description,
            LEFT,
            CENTER,
            TOP,
            LEFT
          );
        }
      } else {
        return new PopupConfiguration(
          (index * 55) + 20,
          275,
          el.title,
          el.description,
          LEFT,
          CENTER,
          TOP,
          LEFT
        );
      }
    }
  );
  const arraySet = [
    ...popupConfigurationArray.slice(0, 1),
    ...navMap,
    ...popupConfigurationArray.slice(1),
  ];
  return isMobile()
    ? arraySet.map(item => {
      item.x = 0;
      item.y = 0;
      return item;
    })
    : arraySet;
}
