import React, { useCallback, useState } from "react";
import { Formik } from "formik";
import propTypes from "prop-types";
import RoundedButton from "components/shared/button/RoundedButton";
import UserPersonalDetailsFields from "components/formParts/UserPersonalDetailsFields";
import UserAddressFields from "components/formParts/UserAddressFields";
import RegionSection from "components/formParts/RegionSection";
import AccountDetailsFields from "components/formParts/AccountDetailsFields";
import * as Yup from "yup";
import * as commonValidators from "helpers/commonValidators";
import ChangePasswordForm from "./ChangePasswordForm";
import { THEME_KEYS } from "helpers/constants";
import ContextElement from "components/shared/elements/ContextElement";
import InterestSection from "../../../../../components/formParts/InterestSection";
import { errorToast } from "../../../../../helpers/toastMsgs";

function EditProfileForm({
  onSubmit,
  prepopulatedFields,
  prepopulatedFields: { additionalInterests, regions },
  working,
  changePasswordRequest,
  editUserFailedMessage,
}) {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const onToastAlert = useCallback(() => {
    errorToast("Please fill in all required fields.", true);
  }, []);

  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  const preventSubmitOnEnter = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <Formik
      initialValues={{
        ...prepopulatedFields,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({ handleSubmit, setFieldValue, errors }) => (
        <form
          onKeyDown={preventSubmitOnEnter}
          className="Form EditProfileForm"
          onSubmit={handleSubmit}
          noValidate
        >
          <div className="Form__inner">
            <div className="UserInformationFormFields">
              <UserPersonalDetailsFields formUsage="Edit" />
              <UserAddressFields formUsage="Edit" />
              <AccountDetailsFields
                changePasswordForm={
                  showChangePassword ? (
                    <ChangePasswordForm
                      handleSubmit={formData =>
                        changePasswordRequest(formData)
                      }
                      working={working}
                      editUserFailedMessage={editUserFailedMessage}
                    />
                  ) : (
                    <></>
                  )
                }
                changePasswordButton={
                  <RoundedButton
                    invertColors
                    buttonClass="EditProfileForm__change-password"
                    title="Change password"
                    type="button"
                    buttonColor={THEME_KEYS.PRIMARY}
                    buttonTextColor={THEME_KEYS.PRIMARY}
                    modifiers={["white"]}
                    onClick={() => setShowChangePassword(!showChangePassword)}
                  />
                }
              />
              <ContextElement
                elType="div"
                className="EditProfileForm__section"
                config={{
                  borderColor: THEME_KEYS.SECONDARY,
                }}
              >
                <RegionSection
                  patchedRegions={regions}
                  setFieldValue={setFieldValue}
                  formUsage="Edit"
                />
              </ContextElement>
              <ContextElement
                elType="div"
                className="EditProfileForm__section"
                config={{
                  borderColor: THEME_KEYS.SECONDARY,
                }}
              >
                <InterestSection
                  patchedAdditionalInterests={additionalInterests}
                  setFieldValue={setFieldValue}
                  formUsage="Edit"
                />
              </ContextElement>
            </div>
            <div className="EditProfileForm__submitholder">
              <RoundedButton
                title="Save changes"
                type="submit"
                onClick={
                  Object.keys(errors).length ? onToastAlert : handleSubmit
                }
                disabled={working}
                modifiers={["medium"]}
                buttonColor={THEME_KEYS.SECONDARY}
                working={working}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
}

const validationSchema = Yup.object().shape({
  emailAddress: commonValidators.isValidEmail(),
  firstName: commonValidators.isRequired("First Name"),
  postcode: commonValidators.isRequired("Post Code"),
  contactNumber: commonValidators.isValidGenericTextField("Contact Number"),
  address1: commonValidators.isValidGenericTextField("Address line 1"),
  address2: commonValidators.isValidGenericTextField("Address line 2"),
  townCity: commonValidators.isValidGenericTextField("Town / City"),
  company: commonValidators.isValidGenericTextField("Company Name"),
  country: commonValidators.isRequiredMixed("Country"),
  areaCode: commonValidators.isRequired("Area Code"),
});

EditProfileForm.propTypes = {
  onSubmit: propTypes.func,
  changePasswordRequest: propTypes.func,
  prepopulatedFields: propTypes.object,
  editUserFailedMessage: propTypes.string,
  working: propTypes.bool,
};

export default React.memo(EditProfileForm);
