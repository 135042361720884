import React from "react";
import propTypes from "prop-types";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import Loader from "components/shared/loader";
import "react-awesome-slider/dist/styles.css";

const LoginSlider = props => {
  let slideComponents = [];

  if (props.slides && props.slides.length) {
    slideComponents = props.slides.map(slide => {
      return (
        <div data-src={slide.image} key={slide.name} className="LoginSlider__slider__image" >
          <div className="LoginSlider__info">
            <h1>{slide.name}</h1>
            <p>{slide.description}</p>
          </div>
        </div>
      );
    });
  }

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  return (
    <div className="LoginSlider">
      {AutoplaySlider
        ? <AutoplaySlider
          interval={3000}
          fillParent
          play
          organicArrows={false}
          cancelOnInteraction={false}
          className="LoginSlider__slider"
        >
          {slideComponents && slideComponents.length ? slideComponents : <Loader />}
        </AutoplaySlider>
        : <Loader />
      }
    </div>
  );
};

LoginSlider.propTypes = {
  slides: propTypes.array,
};

export default LoginSlider;
