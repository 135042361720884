import React from "react";
import propTypes from "prop-types";
import { useSpring } from "react-spring";

function PopInAnimation(props, WrappedComponent) {
  const animation = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
  });
  return (
    <WrappedComponent style={{
      ...props.style,
      ...animation,
    }} {...props} />
  );
}

PopInAnimation.propTypes = {
  style: propTypes.object,
};

function PopInAnimationWrapper(WrappedComponent) {
  return props => PopInAnimation(props, WrappedComponent);
};

export default PopInAnimationWrapper;
