import API from "../API";

let instance = null;

class RegistrationService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  acceptUser = ({ hash }) => {
    this.http.patch(`registration-accept/${hash}`);
  };

  rejectUser = ({ hash }) => {
    this.http.patch(`registration-reject/${hash}`);
  };
}

export default RegistrationService;
