import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();
  const ignoreList = {
    news: "useful",
    useful: "news",
  };

  useEffect(() => {
    let ignore = false;
    if (location.state) {
      const ignoreKeys = Object.keys(ignoreList);
      const index = ignoreKeys.findIndex(item => location.pathname.includes(item));
      if (index > -1) {
        ignore = !!location.state.from.includes(ignoreList[ignoreKeys[index]]);
      }
    }

    const content = document.querySelector(".DashboardLayout__content");
    if (content && !ignore && content.scrollTo) {
      content.scrollTo(0, 0);
    }
  }, [ignoreList, location]);

  return null;
}
