import { connect } from "react-redux";
import ResetPassword from "../components/WrappedComponents/ResetPassword";
import { changePasswordActions } from "redux/forgotPassword/actions";
import * as forgotPasswordSelectors from "selectors/forgotPassword";

const mapDispatchToProps = dispatch => ({
  submitResetPassword: (password, token) => dispatch(changePasswordActions.request({ password, token })),
});

const mapStateToProps = state => ({
  success: forgotPasswordSelectors.forgotPasswordMessage(state).success,
  fail: forgotPasswordSelectors.forgotPasswordMessage(state).fail,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
