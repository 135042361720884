import React from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";
import InterestsContainer from "components/formElements/fieldContainers/InterestsContainer";
const InterestSection = ({ setFieldValue, formUsage, patchedAdditionalInterests }) => {
  return (
    <>
      <ContextElement
        elType="h3"
        config={{ color: "secondary" }}
        className="UserInformationFormFields__section__heading lightHeaderSansSerif"
      >
        Interests
      </ContextElement>
      <div className="Form__fieldRow Form__fieldRow--flexColumn">
        <InterestsContainer
          patchedAdditionalInterests={patchedAdditionalInterests}
          setFieldValue={setFieldValue}
          formUsage={formUsage}
        />
      </div>
    </>
  );
};

InterestSection.propTypes = {
  formUsage: propTypes.string,
  setFieldValue: propTypes.func.isRequired,
  patchedAdditionalInterests: propTypes.array,
};

export default InterestSection;
