import React, { Component } from "react";
import propTypes from "prop-types";
import FileAccordion from "../FileAccordion";
import FileTableView from "../FileTableView/FileTableView";
import FileView from "../FileView/FileView";
import { viewType } from "./../viewTypes";
import Loader from "components/shared/loader/Loader";

class FileSection extends Component {
  constructor() {
    super();
    this.state = {
      open: true,
    };
  }

  render() {
    const {
      title,
      files,
      selectedViewType,
      folderClicked,
      downloadFolder,
      handleFileDoubleClick,
      sectionType,
      working,
    } = this.props;

    if (selectedViewType === viewType.TABLE) {
      return (
        <FileAccordion title={title}>
          {files.length ? (
            <FileTableView folderClicked={folderClicked} files={files} />
          ) : (
            working && (
              <div className="FileAccordion__loader">
                <Loader className="Loader--noBG" />
              </div>
            )
          )}
        </FileAccordion>
      );
    }

    return (
      <FileAccordion title={title}>
        {files.length ? (
          <FileView
            folderClicked={folderClicked}
            downloadFolder={downloadFolder}
            files={files}
            handleFileDoubleClick={handleFileDoubleClick}
            sectionType={sectionType}
          />
        ) : (
          working && (
            <div className="FileAccordion__loader">
              <Loader className="Loader--noBG" />
            </div>
          )
        )}
      </FileAccordion>
    );
  }
}

FileSection.propTypes = {
  title: propTypes.string.isRequired,
  files: propTypes.array.isRequired,
  selectedViewType: propTypes.string,
  folderClicked: propTypes.func,
  downloadFolder: propTypes.func,
  handleFileDoubleClick: propTypes.func,
  sectionType: propTypes.string,
  working: propTypes.bool,
};

export default FileSection;
