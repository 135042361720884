import { connect } from "react-redux";
import * as newsActions from "redux/news/actions";
import * as newsSelectors from "selectors/news";
import NewsItem from "./../../components/NewsItem";
import * as usefulInformationSelectors from "selectors/usefulInformation";
import usefulInformationActions from "redux/usefulInformation/actions";

const mapDispatchToProps = dispatch => ({
  getNewsArticle: id => dispatch(newsActions.selectedArticleActions.request({ id })),
  getUsefulInformationArticle: id => dispatch(usefulInformationActions.getSelectedArticleActions.request({ id })),
});

const mapStateToProps = state => ({
  selectedNewsArticle: newsSelectors.getSelectedArticle(state),
  selectedUsefulInfoArticle: usefulInformationSelectors.getSelectedArticle(state),
  nextArticleIdNews: newsSelectors.getNextArticleId(state),
  nextArticleIdUsefulInfo: usefulInformationSelectors.getNextArticleId(state),
  latestNArticlesFilteredNews: (amountToGet, filterId) =>
    newsSelectors.getFirstNArticlesFiltered(amountToGet, filterId)(state),
  latestNArticlesFilteredUsefulInfo: (amountToGet, filterId) =>
    usefulInformationSelectors.getFirstNArticlesFiltered(amountToGet, filterId)(state),
  getUsefulInfoArticle: id => usefulInformationSelectors.getSelectedArticle(id)(state),
  selectedArticleWorking: newsSelectors.getSelectedArticleWorking(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsItem);
