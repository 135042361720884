import React from "react";
import propTypes from "prop-types";
/* eslint max-len: 0 */
export const IconDownload = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "13"}
      height={props.height || "16"}
      viewBox="0 0 13 16"
    >
      <path
        fillRule="evenodd"
        d="M5.554 12.054a.91.91 0 0 0 1.2-.003l4.536-4.13c.804-.72-.402-1.8-1.206-1.08L7.396 9.246c-.224.2-.404.135-.404-.145V.763C6.992.342 6.61 0 6.139 0s-.853.342-.853.763v8.339c0 .28-.18.346-.404.145L2.193 6.84C1.39 6.12.183 7.201.987 7.92l4.567 4.134zm6.598 2.39H.848c-.468 0-.848.324-.848.723 0 .398.38.722.848.722h11.304c.468 0 .848-.324.848-.722 0-.399-.38-.723-.848-.723z"
      />
    </svg>
  );
};

IconDownload.propTypes = {
  height: propTypes.string,
  width: propTypes.string,
};
