import { createSelector } from "reselect";

export const isWorking = state => {
  return state && state.notifications && state.notifications.working;
};

export const allNotifications = createSelector(
  state => state.notifications.results,
  notifications =>
    notifications
      ? notifications.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      })
      : null
);

export const isUnread = createSelector(
  state => state.notifications.results,
  notifications =>
    notifications ? notifications.filter(item => !item.read).length > 0 : false
);
