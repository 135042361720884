import React from "react";
import propTypes from "prop-types";
import { Image } from "react-image-and-background-image-fade";
import moment from "moment";
import stringcharlimiter from "helpers/stringCharLimiter";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";
import navigation from "./../../../../navigation";
import { withRouter } from "react-router-dom";
import { sanitize } from "dompurify";

function ArticleCard({
  article: { title = "", date = "", excerpt = "", image = null, imgalt = "", id = 0 },
  history,
  articleType = navigation.news,
}) {
  const handleArticleClicked = () => {
    history.push(articleType + "/" + id);
  };

  return (
    <ContextElement
      elType="div"
      onClick={handleArticleClicked}
      className="ArticleCard"
      config={{ borderBottomColor: THEME_KEYS.SECONDARY }}
    >
      {!!image && (
        <Image lazyLoad wrapperClassName="ArticleCard__image" src={image} alt={imgalt} />
      )}
      <div className="ArticleCard__body">
        <ContextElement
          elType="p"
          config={{ color: THEME_KEYS.PRIMARY }}
          className="lightHeaderSansSerif ArticleCard__body__date"
        >
          {moment(date).format("DD MMMM YYYY")}
        </ContextElement>
        <ContextElement
          elType="h2"
          config={{ color: THEME_KEYS.PRIMARY }}
          className="headerSerif ArticleCard__body__title"
          dangerouslySetInnerHTML={{ __html: stringcharlimiter(sanitize(title), true, 20) }}
        />
        <div
          className="ArticleCard__body__info"
          dangerouslySetInnerHTML={{ __html: stringcharlimiter(sanitize(excerpt), true) }}
        />
      </div>
    </ContextElement>
  );
}

ArticleCard.propTypes = {
  article: propTypes.shape({
    title: propTypes.string.isRequired,
    date: propTypes.string.isRequired,
    image: propTypes.string,
    imgalt: propTypes.string,
    excerpt: propTypes.string.isRequired,
    id: propTypes.number,
  }),
  history: propTypes.object,
  articleType: propTypes.string,
};

export default withRouter(ArticleCard);
