import React from "react";
/* eslint max-len: 0 */

export const IconStar = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
      <path fillRule="evenodd" d="M15.08 17.999a.454.454 0 0 0 .455-.549l-1.24-6.003 4.554-4.13a.454.454 0 0 0-.257-.791l-6.129-.682L9.916.264a.459.459 0 0 0-.832 0l-2.547 5.58-6.128.682a.456.456 0 0 0-.258.791l4.554 4.13-1.241 6.004a.455.455 0 0 0 .18.462c.146.106.34.116.497.027l5.36-3.029 5.358 3.028v.001a.465.465 0 0 0 .222.059zM13.915 15.8l-4.21-2.365v-.001a.415.415 0 0 0-.408 0L5.086 15.8l.974-4.686a.403.403 0 0 0-.124-.382L2.358 7.507l4.813-.534a.41.41 0 0 0 .327-.233L9.5 2.378l2.002 4.363V6.74c.06.129.184.218.328.233l4.813.534-3.578 3.225h-.001a.404.404 0 0 0-.124.382l.974 4.686z" />
    </svg>
  );
};
