import * as constants from "helpers/constants";
import { push } from "react-router-redux";
import { call, put, takeEvery, select } from "redux-saga/effects";
import * as authActions from "redux/auth/actions";
import * as forgotPasswordActions from "redux/forgotPassword/actions";
import * as authActionTypes from "redux/auth/actionTypes";
import * as forgotPasswordActionTypes from "redux/forgotPassword/actionTypes";
import * as userActions from "redux/user/actions";
import * as brandsActions from "redux/brands/actions";
import * as brandsSelector from "selectors/brands";
import * as authSelector from "selectors/auth";

import * as userSelector from "selectors/user";
import handleThrow from "helpers/handleThrow";

import LocalStore from "../services/auth/localStore";
import { successToast, errorToast } from "../helpers/toastMsgs";

export const watchAuthRequestFactory = authService => {
  function * watchAuthRequest() {
    yield takeEvery(`${authActionTypes.LOGIN}_REQUEST`, loginRequest);
    yield takeEvery(`${authActionTypes.REFRESH_TOKEN}_REQUEST`, refreshTokenRequest);
    yield takeEvery(`${authActionTypes.LOGOUT}_REQUEST`, logoutRequest);
    yield takeEvery(`${forgotPasswordActionTypes.GET_NEW_PASSWORD}_REQUEST`, requestNewPasswordRequest);
    yield takeEvery(`${forgotPasswordActionTypes.CHANGE_PASSWORD}_REQUEST`, changePasswordRequest);
  }

  function * loginRequest(action) {
    try {
      const { email, password, rememberMe } = action.payload;
      const response = yield call(authService.login, { email, password });
      // eslint-disable-next-line camelcase

      try {
        const pathname = yield select(authSelector.redirectPath);
        LocalStore.setStorageType(rememberMe);
        LocalStore.setUserToken(response.data);
        yield put(userActions.getUserActions.request());
        yield put(brandsActions.getAllBrands.request());
        yield put(authActions.loginActions.success({ rememberMe: rememberMe }));
        if (pathname) {
          yield put(push(pathname));
        }
      } catch (error) {
        throw error;
      }
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(authActions.loginActions.fail(parsedError));
    }
  }

  function * refreshTokenRequest(action) {
    try {
      if (action.payload && action.payload.refresh_token) {
        const response = yield call(authService.refreshToken, action.payload.refresh_token);
        const user = yield select(userSelector.userSelector);
        if (!user) {
          yield put(userActions.getUserActions.request());
        }

        LocalStore.setUserToken(response.data);
        yield put(authActions.refreshTokenActions.success());
        const brands = yield select(brandsSelector.flattenedBrandsList);
        if (!brands || !brands.length) {
          yield put(brandsActions.getAllBrands.request());
        }
      } else {
        yield put(
          authActions.refreshTokenActions.fail({
            message: constants.UNDEFINED_REFRESH,
          })
        );
        LocalStore.removeUserToken();
        yield put(push("login"));
      }
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(authActions.refreshTokenActions.fail(parsedError));
    }
  }

  function * logoutRequest(action) {
    try {
      yield call(authService.logout);
      yield put(authActions.logoutActions.success());
      yield put(userActions.getUserActions.success({}));
    } catch {
      yield put(authActions.logoutActions.fail());
    }
    yield put(push("/login"));
    LocalStore.removeUserToken();
  }

  function * requestNewPasswordRequest(action) {
    try {
      const response = yield call(authService.requestNewPassword, action.payload);
      yield put(forgotPasswordActions.requestNewPasswordActions.success({ ...response.data, failed: false }));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(forgotPasswordActions.requestNewPasswordActions.fail(parsedError));
    }
  }

  function * changePasswordRequest(action) {
    try {
      const response = yield call(authService.changePassword, action.payload.password);
      yield put(forgotPasswordActions.changePasswordActions.success(response.data));
      successToast("Update");
      yield put(push("/login"));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(forgotPasswordActions.changePasswordActions.fail(parsedError));
      errorToast("Update");
    }
  }

  return {
    watchAuthRequest,
    loginRequest,
    refreshTokenRequest,
    logoutRequest,
    requestNewPasswordRequest,
  };
};
