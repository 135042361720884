import React from "react";
/* eslint max-len: 0 */
export const IconNews = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
      <g fillRule="nonzero">
        <path d="M17.274 4.346h-2.301V.419a.287.287 0 0 0-.289-.288H.472a.29.29 0 0 0-.288.288v12.546a.288.288 0 0 0 .288.289h15.713c.9-.001 1.63-.731 1.631-1.63V4.886c0-.3-.242-.541-.542-.541zM.762.708h13.635l-.001 11.969H.76V.707zm16.476 10.915c0 .582-.471 1.053-1.054 1.054h-1.211V4.923h2.265v6.7z" />
        <path d="M12.7 2.679H2.456a.289.289 0 0 0 0 .577H12.7a.288.288 0 1 0 0-.577zM12.7 4.697H2.456a.289.289 0 0 0 0 .577H12.7a.288.288 0 1 0 0-.577zM6.937 6.273h-4.48a.288.288 0 0 0-.288.289v4.48a.288.288 0 0 0 .288.288h4.48a.29.29 0 0 0 .289-.288v-4.48a.287.287 0 0 0-.289-.289zm-.288 4.48H2.746V6.85h3.903v3.903zM12.7 6.717H8.738a.288.288 0 1 0 0 .576h3.96a.289.289 0 0 0 0-.576zM12.7 8.735H8.738a.288.288 0 1 0 0 .577h3.96a.289.289 0 0 0 0-.577zM12.7 10.753H8.738a.288.288 0 1 0 0 .577h3.96a.288.288 0 1 0 0-.577z" />
      </g>
    </svg>
  );
};
