import React from "react";
import propTypes from "prop-types";

import useEventListener from "hooks/useEventListener";

import FilePreviewComponent from "./FilePreviewComponent";

const FilePreview = ({
  file,
  handleClosePreview,
  handlePreviewNext,
  handlePreviewPrevious,
  className,
  showControls,
}) => {
  const handleKeyPress = ({ key }) => {
    switch (key) {
      case "ArrowRight":
        handlePreviewNext();
        break;
      case "ArrowLeft":
        handlePreviewPrevious();
        break;
      case "Escape":
        handleClosePreview();
        break;
      default:
        return null;
    }
  };
  useEventListener("keydown", handleKeyPress);
  return (
    <div className={`FilePreview ${className}`}>
      <div className="FilePreview__header">
        <p className="FilePreview__header__filename">{file.name}</p>
        <p onClick={handleClosePreview} className="FilePreview__icons">
          x
        </p>
      </div>
      <div className="FilePreview__body">
        {handlePreviewPrevious && showControls && (
          <div className="FilePreview__body__nav">
            <p onClick={handlePreviewPrevious} className="FilePreview__icons">
              {"<"}
            </p>
          </div>
        )}
        <div className="FilePreview__body__content">
          <FilePreviewComponent file={file} className="FilePreview__body__content__file" />
        </div>
        {handlePreviewNext && showControls && (
          <div className="FilePreview__body__nav">
            <p onClick={handlePreviewNext} className="FilePreview__icons">
              {">"}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

FilePreview.propTypes = {
  file: propTypes.object,
  handleClosePreview: propTypes.func,
  handlePreviewPrevious: propTypes.func,
  handlePreviewNext: propTypes.func,
  className: propTypes.string,
  showControls: propTypes.bool,
};

export default FilePreview;
