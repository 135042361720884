import React, { Component } from "react";
import propTypes from "prop-types";
import { withTheme } from "styled-components";
import isMobile from "helpers/isMobile";

class HoverComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  toggleHoverState = () => {
    this.setState(prevState => ({ hover: !prevState.hover }));
  };

  eventProps = isMobile()
    ? {
      onTouchStart: this.toggleHoverState,
      onTouchEnd: this.toggleHoverState,
    } : {
      onMouseEnter: this.toggleHoverState,
      onMouseLeave: this.toggleHoverState,
    };

  render() {
    const { hoverFill, defaultFill, theme, wrapperClassName, children } = this.props;
    const style = this.state.hover
      ? {
        fill: hoverFill ? (hoverFill.includes("#") ? hoverFill : theme[hoverFill]) : theme["secondary"],
        color: hoverFill ? (hoverFill.includes("#") ? hoverFill : theme[hoverFill]) : theme["secondary"],
      }
      : {
        fill: defaultFill ? (defaultFill.includes("#") ? defaultFill : theme[defaultFill]) : "#fff",
        color: defaultFill ? (defaultFill.includes("#") ? defaultFill : theme[defaultFill]) : "#fff",
      };

    return (
      <div
        className={wrapperClassName || ""}
        {...this.eventProps}
        style={style}
      >
        {children}
      </div>
    );
  }
}

HoverComponent.propTypes = {
  defaultFill: propTypes.string,
  hoverFill: propTypes.string,
  children: propTypes.object.isRequired,
  wrapperClassName: propTypes.string,
  theme: propTypes.object,
};

export default withTheme(HoverComponent);
