export const DARK = "dark";
export const WHITE = "white";
export const WHITE_BORDER = "white-border";
export const GREY_BORDER = "grey-border";
export const NO_BORDER = "noborder";
export const SELECTED_TRANSPARENT = "selected-transparent";
export const LONG = "long";
export const SMALL = "small";
export const NO_MARGIN = "no-margin";
export const ARROW_DROPDOWN = "arrow-dropdown";
export const BOLD = "bold";
