import * as commonValidators from "helpers/commonValidators";

export default function({
  payload: {
    image,
    emailAddress,
    firstName,
    lastName,
    areaCode,
    contactNumber,
    address1,
    address2,
    townCity,
    postcode,
    company,
    country,
    regions,
    mainRegion,
    mainInterest,
    additionalInterests,
  },
}) {
  let toSend = {
    email: emailAddress,
    firstName,
    lastName,
    phoneCode: areaCode,
    phoneNumber: parseInt(contactNumber, 10) || null,
    address1,
    address2,
    town: townCity,
    postCode: postcode ? postcode.toUpperCase() : null,
    companyName: company,
    country,
    regions,
    mainRegion,
    mainInterest,
    additionalInterests,
  };
  if (commonValidators.isBase64Image(image)) {
    toSend.image = image;
  }

  return toSend;
}
