import { connect } from "react-redux";
import MainHeader from "./../MainHeader/";
import { logoutActions } from "redux/auth/actions";
import * as brandsActions from "redux/brands/actions";
import * as brandsSelector from "selectors/brands";
import * as userSelector from "selectors/user";
import {
  getNotificationsActions,
  readNotificationActions,
  readAllNotificationActions,
} from "redux/notifications/actions";
import * as notificationSelector from "selectors/notifications";
import * as userActions from "redux/user/actions";
import { withRouter } from "react-router-dom";

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutActions.request()),
  getBrands: () => dispatch(brandsActions.getAllBrands.request()),
  setSelectedBrand: (brandId, pushToDashboard = true) =>
    dispatch(
      brandsActions.setSelectedBrand.request({
        selected: brandId,
        pushToDashboard,
      })
    ),
  getNotifications: () => dispatch(getNotificationsActions.request()),
  setNotificationRead: id => dispatch(readNotificationActions.request({ id })),
  setAllNotificationsRead: id =>
    dispatch(readAllNotificationActions.request(id)),
  getUser: () => dispatch(userActions.getUserActions.request()),
});

const mapStateToProps = state => ({
  brands: brandsSelector.brandsList(state),
  selectedBrand: brandsSelector.selectedBrand(state),
  user: userSelector.userSelector(state),
  notifications: notificationSelector.allNotifications(state),
  isUnread: notificationSelector.isUnread(state),
  brandById: Id => brandsSelector.brandById(Id)(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainHeader));
