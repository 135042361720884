import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { IconPlus } from "assets/svg/plus";
import ContextElement from "components/shared/elements/ContextElement";
import HoverComponent from "components/shared/util/Hover";
import { THEME_KEYS } from "helpers/constants";

class SeeMoreComponent extends PureComponent {
  render() {
    const { handleClose, brandById, events, onSelectEvent } = this.props;
    return (
      <div className="Calendar__seeMore">
        <ContextElement
          elType="button"
          className="Calendar__seeMore__closeButton"
          onClick={handleClose}
          config={{ borderColor: THEME_KEYS.SECONDARY }}
        >
          <HoverComponent defaultFill={THEME_KEYS.SECONDARY} hoverFill={THEME_KEYS.SECONDARY}>
            <IconPlus />
          </HoverComponent>
        </ContextElement>
        <div className="Calendar__seeMore__eventWrap">
          {events &&
            Array.isArray(events) &&
            events.map((event, index) => {
              const brand = brandById(event.brand);
              return (
                <button
                  key={`${event.title}${index}`}
                  onClick={() => onSelectEvent(event)}
                  className="Calendar__seeMore__event"
                >
                  <p>{event.title}</p>
                  <ContextElement
                    elType="p"
                    className="Calendar__seeMore__event__brandName"
                    config={{ backgroundColor: event.backgroundColor }}
                  >
                    {brand.name}
                  </ContextElement>
                </button>
              );
            })}
        </div>
      </div>
    );
  }
}

SeeMoreComponent.propTypes = {
  handleClose: propTypes.func,
  brandById: propTypes.func,
  events: propTypes.array,
  onSelectEvent: propTypes.func,
};

export default SeeMoreComponent;
