import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import SelectInput from "../SelectInput";
import * as GeneralActions from "redux/general/actions";
import * as GeneralSelectors from "selectors/general";

function withRegistrationTypes(WrappedComponent) {
  class RegistrationType extends React.Component {
    componentDidMount() {
      this.props.getchRegistrationTypes();
    }

    render() {
      return <WrappedComponent
        {...this.props}
        options={!!this.props.registrationTypes && this.props.registrationTypes}
      />;
    }
  }

  RegistrationType.propTypes = {
    getchRegistrationTypes: propTypes.func.isRequired,
    registrationTypes: propTypes.array.isRequired,
    form: propTypes.object,
  };

  return RegistrationType;
}

const mapDispatchToProps = dispatch => ({
  getchRegistrationTypes: () => dispatch(GeneralActions.getRegistrationTypesActions.request()),
});

const mapStateToProps = (state) => {
  return {
    registrationTypes: GeneralSelectors.registrationTypes(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRegistrationTypes(SelectInput));
