import React from "react";
import propTypes from "prop-types";
import { Formik, Field } from "formik";
import TextInput from "components/formElements/TextInput";
import { IconSend } from "assets/svg/send";
import RoundedButton from "components/shared/button/RoundedButton/";
import HoverComponent from "components/shared/util/Hover";
import { THEME_KEYS } from "helpers/constants";

function NeedHelpContactForm({ handleSubmitForm, initialValues }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {formik => (
        <>
          <Field
            hideLabelOnType
            modifiers={["needHelp"]}
            name="fullName"
            label="Full
            Name"
            type="text"
            mandatory
            component={TextInput}
          />
          <Field
            hideLabelOnType
            modifiers={["needHelp"]}
            name="subject"
            label="Subject"
            type="text"
            mandatory
            component={TextInput}
          />
          <Field
            hideLabelOnType
            modifiers={["needHelp"]}
            name="message"
            label="Message"
            type="text"
            mandatory
            component={TextInput}
            elementType="textarea"
          />
          <HoverComponent
            defaultFill={"#fff"}
            hoverFill={THEME_KEYS.SECONDARY}
          >
            <RoundedButton
              onClick={formik.submitForm}
              buttonClass="NeedHelpBanner__details__button"
              modifiers={["medium", "is-form-open"]}
              buttonColor={THEME_KEYS.SECONDARY}
              title={"Submit"}
              type={"submit"}
              Icon={IconSend}
              iconRight
            />
          </HoverComponent>
        </>
      )}
    </Formik>
  );
};

NeedHelpContactForm.propTypes = {
  handleSubmitForm: propTypes.func.isRequired,
  initialValues: propTypes.object,
};

export default NeedHelpContactForm;
