import styled from "styled-components";

export const NEXT = "NEXT";
export const PREV = "PREV";
export const GOTO = "GOTO";

export const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  transition: ${props => (props.sliding ? "none" : "transform 1s ease")};
  transform: ${props => {
    if (!props.sliding) return "translateX(calc(-230px))";
    if (props.dir === PREV) return "translateX(calc(2 * (-230px)))";
    return "translateX(0%)";
  }};
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: #fff;
`;

export const CarouselSlot = styled.div`
  order: ${props => props.order};
  width: 230px;
  min-width: 230px;
`;
