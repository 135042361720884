import React, { Component } from "react";
import propTypes from "prop-types";
import { Image } from "react-image-and-background-image-fade";
import RoundedButton from "components/shared/button/RoundedButton/";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";

class BrandsBanner extends Component {
  handleBrandSelect = () => {
    this.props.selectBrand(this.props.brand.id);
  };

  handleGoToWebsite = () => {
    window.open(this.props.brand.websiteLink);
  };

  render() {
    const { brand } = this.props;
    return (
      <div className="BrandBanner">
        <Image lazyLoad wrapperClassName="BrandBanner__image" src={brand.pageImage} alt={""} />
        <div className="BrandBanner__details">
          <div>
            <ContextElement
              elType="p"
              className="BrandBanner__details__brands lightHeaderSansSerif"
              config={{ color: "secondary" }}
            >
              Our Brands
            </ContextElement>
            <h2 className="BrandBanner__details__title">{brand.name}</h2>
            <div className="BrandBanner__details__scrollContainer">
              <p className="BrandBanner__details__scrollContainer__desc">{brand.description}</p>
            </div>
          </div>
          <div className="BrandBanner__details__buttons">
            <RoundedButton
              buttonClass="BrandBanner__details__buttons__button"
              onClick={this.handleBrandSelect}
              title="Switch to this brand"
              buttonColor={THEME_KEYS.SECONDARY}
              modifiers={["medium"]}
            />
            <RoundedButton
              buttonClass="BrandBanner__details__buttons__button"
              onClick={this.handleGoToWebsite}
              title="Go to Website"
              buttonColor="#2b2c2f"
              modifiers={["medium", "white"]}
              invertColors
            />
          </div>
        </div>
      </div>
    );
  }
}

BrandsBanner.propTypes = {
  brand: propTypes.object,
  selectBrand: propTypes.func,
};

export default BrandsBanner;
