import API from "../API";

let instance = null;

class EventsService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getEventsByDate = params => {
    return this.http.get(`event/${params.brandId}/${params.year}/${params.month}`).json();
  };
}

export default EventsService;
