export const phoneCodes = state => {
  try {
    return state.general.phoneCodes.results.map(code => ({
      name: code["phone-code"],
      value: code.id,
    }));
  } catch {
    return [];
  }
};

export const countries = state => {
  try {
    return state.general.countries.results.map(country => ({
      name: country.nice_name,
      value: country.id,
    }));
  } catch {
    return [];
  }
};

export const regions = state => {
  try {
    return state.general.regions.results.map(region => ({
      name: region.name,
      value: region.id,
    }));
  } catch {
    return [];
  }
};

export const interests = state => {
  try {
    return state.general.interests.results.map(interest => ({
      name: interest.name,
      value: interest.id,
    }));
  } catch {
    return [];
  }
};

export const teams = state => {
  try {
    return state.general.teams.results.map(team => ({
      name: team.name,
      value: team.id,
    }));
  } catch {
    return [];
  }
};

export const registrationTypes = state => {
  try {
    return state.general.registrationTypes.results.map(({ id, description }) => ({
      name: description,
      value: id,
    }));
  } catch {
    return [];
  }
};
