import { connect } from "react-redux";
import Register from "../components";
import * as userActions from "redux/user/actions";
import * as userSelectors from "selectors/user";

const mapDispatchToProps = dispatch => ({
  registerUser: formData => dispatch(userActions.registerUserActions.request(formData)),
  resetUser: () => dispatch(userActions.resetUserActions.success()),
});

const mapStateToProps = state => ({
  working: userSelectors.userWorkingSelector(state),
  registrationSuccess: userSelectors.registrationSuccess(state),
  registrationErrorMessage: userSelectors.registrationErrorMessage(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
