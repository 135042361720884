import * as React from "react";
import PropTypes from "prop-types";
//
export class FormError extends React.PureComponent {
  render() {
    const { message } = this.props;
    if (!message) return null;
    switch (message) {
      // case API_RESPONSES.ERROR_USER_NOT_FOUND:
      //   return (
      //     <div className="FormError">
      //       not found
      //     </div>
      //   );
      // case API_RESPONSES.ERROR_USER_BLOCKED:
      //   return (
      //     <div className="FormError">
      //       blocked
      //     </div>
      //   );

      default:
        return <div className="Form__error">{message}</div>;
    }
  }
}

FormError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default FormError;
