import React, { Component } from "react";
import FileViewItem from "../FileViewItem/FileViewItem";
import propTypes from "prop-types";

class FileView extends Component {
  render() {
    const { files, folderClicked, downloadFolder, handleFileDoubleClick, sectionType } = this.props;
    const fileComponents = files && Array.isArray(files) ? files.map((file, index) => (
      <FileViewItem
        downloadFolder={downloadFolder}
        folderClicked={folderClicked}
        key={`${file.name}-${index}`}
        file={file}
        handleFileDoubleClick={handleFileDoubleClick}
        fileIndex={index}
        sectionType={sectionType} />
    )) : <span>No Files</span>;
    return <div className="FileView">{fileComponents}</div>;
  }
}

FileView.propTypes = {
  files: propTypes.array.isRequired,
  folderClicked: propTypes.func,
  downloadFolder: propTypes.func,
  handleFileDoubleClick: propTypes.func,
  sectionType: propTypes.string,
};

export default FileView;
