import * as userActionTypes from "redux/user/actionTypes";
import * as userActions from "redux/user/actions";
import { put, takeEvery, call } from "redux-saga/effects";
import isUnauthorized from "helpers/isUnauthorized";
import patchUserSanitizer from "sanitizers/patchUser";
import * as contactActions from "redux/contacts/actions";
import registerUserSanitizer from "sanitizers/registerUser";
import handleThrow from "helpers/handleThrow";
import { successToast, errorToast } from "../helpers/toastMsgs";
import { EDIT_PROFILE_MSG, CHANGE_PWD_MSG, HTTP_CODES } from "../helpers/constants";
import { navigation } from "./../navigation";
import { push } from "connected-react-router";

export const watchUserRequestFactory = userService => {
  function * watchUserRequest() {
    yield takeEvery(`${userActionTypes.GET_USER}_REQUEST`, getUserRequest);
    yield takeEvery(`${userActionTypes.EDIT_USER}_REQUEST`, editUserRequest);
    yield takeEvery(
      `${userActionTypes.REGISTER_USER}_REQUEST`,
      registerUserRequest
    );
    yield takeEvery(
      `${userActionTypes.CHANGE_PASSWORD}_REQUEST`,
      changePasswordRequest
    );
  }

  function * getUserRequest(action) {
    try {
      const response = yield call(userService.getUser);
      yield put(userActions.getUserActions.success(response.data));
      yield put(contactActions.getMainContactActions.request());
      if (response.data.first_login) {
        yield put(push(navigation.needHelp));
      }
    } catch (error) {
      if (isUnauthorized(error.response)) {
        yield put(
          userActions.getUserActions.fail({
            error,
            unauthorized: true,
          })
        );
      } else {
        const parsedError = yield handleThrow(error);
        yield put(userActions.getUserActions.fail(parsedError));
      }
    }
  }

  function * editUserRequest(action) {
    try {
      const payload = patchUserSanitizer(action);
      yield call(userService.patchUser, payload);
      yield put(userActions.editUserActions.success());
      yield put(userActions.getUserActions.request());
      successToast(EDIT_PROFILE_MSG);
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(userActions.editUserActions.fail(parsedError));
      errorToast(EDIT_PROFILE_MSG);
    }
  }

  function * registerUserRequest(action) {
    try {
      const payload = registerUserSanitizer(action);
      const response = yield call(userService.registerUser, payload);
      yield put(
        userActions.registerUserActions.success({
          ...response.data,
          registerSuccess: true,
        })
      );
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(userActions.registerUserActions.fail(parsedError));
    }
  }

  function * changePasswordRequest({ payload: { newPassword, password } }) {
    try {
      const response = yield call(userService.changePassword, {
        password,
        newPassword,
      });
      yield put(userActions.changePasswordActions.success(response.data));
      successToast(CHANGE_PWD_MSG);
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(userActions.changePasswordActions.fail(parsedError));
      error.response.status === HTTP_CODES.BAD_REQUEST
        ? errorToast("Current password is invalid", true) : errorToast(CHANGE_PWD_MSG);
    }
  }
  return {
    watchUserRequest,
    getUserRequest,
    editUserRequest,
    registerUserRequest,
  };
};
