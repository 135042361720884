import React from "react";
import PropTypes from "prop-types";
/* eslint max-len: 0 */
export const IconSearch = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
      className={className}
    >
      <path
        fillRule="nonzero"
        d="M18.841 17.945l-3.658-3.632c2.728-3.165 2.573-7.945-.434-10.955C13.2 1.776 11.122 1 9.075 1a8.042 8.042 0 0 0-5.704 2.36 8.061 8.061 0 0 0 0 11.42 8.076 8.076 0 0 0 5.704 2.358c1.86 0 3.752-.652 5.24-1.956l3.658 3.663a.589.589 0 0 0 .403.155c.155 0 .31-.062.434-.187.248-.217.248-.62.031-.868zM4.036 13.932C2.7 12.627 2 10.846 2 9c0-1.846.732-3.627 2.036-4.964C5.373 2.7 7.123 2 9 2c1.877 0 3.627.732 4.964 2.036A7.065 7.065 0 0 1 16 9a7.066 7.066 0 0 1-2.036 4.964A7.12 7.12 0 0 1 9 16a7.015 7.015 0 0 1-4.964-2.068z"
      />
    </svg>
  );
};

IconSearch.propTypes = {
  className: PropTypes.string,
};
