import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import * as authSelector from "selectors/auth";

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  isAuthenticated: authSelector.getIsAuthenticated(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
