import { useState, useEffect } from "react";

export default authService => {
  const [forgotResponse, setForgotResponse] = useState(null);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);

  useEffect(() => {
    setSuccessfulSubmit(false);
  }, []);

  useEffect(() => {
    const makeforgotRequest = async() => {
      try {
        const response = await authService.requestNewPassword({ email: userEmail });
        setForgotResponse(response);
        setSuccessfulSubmit(true);
      } catch (e) {
        if (e.response) {
          e.response.json()
            .then(error => {
              setForgotResponse({ error });
            });
        } else {
          setForgotResponse({ error: e });
        }
      }
    };

    if (isSubmitClicked) {
      makeforgotRequest();
      setIsSubmitClicked(false);
    }
  }, [authService, isSubmitClicked, userEmail]);

  return {
    successfulSubmit,
    forgotResponse,
    setIsSubmitClicked,
    setUserEmail,
  };
};
