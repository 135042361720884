import React, { Component } from "react";
import propTypes from "prop-types";
import DocumentToolBarComponent from "./DocumentToolBarComponent";
import { THEME_KEYS } from "helpers/constants";

class DocumentsToolBar extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      x: 0,
      y: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.x !== prevState.x || nextProps.y !== prevState.y) {
      return {
        x: nextProps.x,
        y: nextProps.y,
      };
    }
    return prevState;
  }

  render() {
    const { fileViewType, handleInputChange, handleSearchSubmit, handleViewChangeClicked } = this.props;
    return (
      <DocumentToolBarComponent
        handleInputChange={handleInputChange}
        handleSearchSubmit={handleSearchSubmit}
        fileViewType={fileViewType}
        hoverFill={THEME_KEYS.SECONDARY}
        defaultFill={THEME_KEYS.PRIMARY}
        handleViewChangeClicked={handleViewChangeClicked}
      />
    );
  }
}

DocumentsToolBar.propTypes = {
  fileViewType: propTypes.any,
  handleInputChange: propTypes.func,
  handleSearchSubmit: propTypes.func,
  handleViewChangeClicked: propTypes.func,
  x: propTypes.number,
  y: propTypes.number,
};

export default DocumentsToolBar;
