import React from "react";
import ContextElement from "components/shared/elements/ContextElement";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import navigation from "navigation";

function RegisterStudentHeading({ title, image }) {
  return (
    <ContextElement elType="div" className="RegisterStudent__header" config={{ backgroundColor: "primary" }}>
      <div className="RegisterStudent__header__left">
        <ContextElement
          elType="h1"
          className="RegisterStudent__header__subheading lightHeaderSansSerif"
          config={{ color: "secondary" }}
        >
          Application Form
        </ContextElement>
        <h1 className="RegisterStudent__header__heading headerSerif">{title}</h1>
        <p className="RegisterStudent__header__text">
          If you have any queries about completing this form, please contact one our
          <Link to={navigation.studentAdvisors}> student advisors. </Link>
        </p>
      </div>
      {image && <div className="RegisterStudent__header__right" style={{ backgroundImage: `url(${image})` }} />}
    </ContextElement>
  );
}

RegisterStudentHeading.propTypes = {
  title: propTypes.string,
  image: propTypes.oneOfType([propTypes.string, propTypes.bool]),
};

export default RegisterStudentHeading;
