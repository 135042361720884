import React from "react";
import propTypes from "prop-types";
import { Button } from "../RoundedButton/RoundedButton.css";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";

class SingleIconButton extends React.PureComponent {
  render() {
    const { onClick, className, modifiers, Icon, invertColors = true, buttonType = Button, ...rest } = this.props;
    const propObj = buttonType === Button
      ? {
        elType: buttonType,
        invertColors: invertColors,
        className: className,
        buttonColor: THEME_KEYS.SECONDARY,
        onClick: onClick,
        modifiers: modifiers,
        config: { borderColor: THEME_KEYS.SECONDARY },
      } : {
        elType: buttonType,
        className: className,
        onClick: onClick,
      };

    return (
      <ContextElement
        {...propObj}
        {...rest}
      >
        <Icon />
      </ContextElement>
    );
  }
}

SingleIconButton.propTypes = {
  onClick: propTypes.func,
  className: propTypes.string,
  modifiers: propTypes.array,
  Icon: propTypes.func,
  invertColors: propTypes.bool,
  buttonType: propTypes.any,
};

export default SingleIconButton;
