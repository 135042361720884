import React, { Component } from "react";
import propTypes from "prop-types";
import FileTableRowItem from "../FileTableRowItem";
import FileTableHeader from "../FileTableHeader/FileTableHeader";

class FileTableView extends Component {
  render() {
    const { files, folderClicked } = this.props;
    const fileComponents = files && Array.isArray(files) ? files.map((file, index) => (
      <FileTableRowItem
        folderClicked={folderClicked}
        key={`${file.name}-${index}`}
        file={file}
      />
    )) : <span>No files found.</span>;
    return (
      <React.Fragment>
        <FileTableHeader />
        <div className="FileTableView">{fileComponents}</div>
      </React.Fragment>
    );
  }
}

FileTableView.propTypes = {
  files: propTypes.array,
  folderClicked: propTypes.func,
};

export default FileTableView;
