import React, { Component } from "react";
import { Image } from "react-image-and-background-image-fade";
import RoundedButton from "components/shared/button/RoundedButton/";
import Swiper from "react-id-swiper";
import { withTheme } from "styled-components";
import ContextElement from "components/shared/elements/ContextElement";
import propTypes from "prop-types";
import { THEME_KEYS } from "helpers/constants";
import isMobile from "helpers/isMobile";
import stringcharlimiter from "helpers/stringCharLimiter";
import navigation from "./../../../../../../navigation";

class NewsSlider extends Component {
  constructor() {
    super();
    this.state = {
      swiper: null,
    };
  }

  slideContainerRef = React.createRef();

  swiperParams = {
    slidesPerView: 1,
    loop: true,
    preloadImages: true,
    speed: 2,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    on: {
      slideChange: () => {
        if (this.state.swiper) {
          this.state.swiper.update();
          this.styleSliderBullets(this.state.swiper);
        }
      },
    },
  };

  handleReadMore = (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.history.push(navigation.news + "/" + id);
  };

  swiperRef = ref => {
    if (ref) {
      this.setState({ swiper: ref });
      this.styleSliderBullets(ref);
    }
  };

  styleSliderBullets(swiperRef) {
    if (swiperRef.pagination) {
      Array.from(swiperRef.pagination.bullets).forEach((bullet, index) => {
        bullet.style.cssText =
          index === swiperRef.realIndex
            ? `background: ${this.props.theme.secondary}`
            : `border-color: ${this.props.theme.secondary};
                border-width: 1px;
                border-style: solid;
                background: white;
                opacity: 1;`;
      });
    }
  }

  render() {
    const { latestNewsArray, theme } = this.props;

    return (
      <div
        ref={this.slideContainerRef}
        className="NewsSlider animated fadeInRight"
        style={
          isMobile()
            ? {
              borderBottom: `5px solid ${theme.secondary}`,
            }
            : {
              borderLeft: `5px solid ${theme.secondary}`,
            }
        }
      >
        {latestNewsArray && !!latestNewsArray.length && (
          <Swiper
            noSwiping
            getSwiper={this.swiperRef}
            className="NewsSlider__carousel"
            {...this.swiperParams}
          >
            {latestNewsArray.map(article => (
              <div key={article.title} className="NewsSlider__content">
                <div className="NewsSlider__content__details">
                  <div>
                    <ContextElement
                      elType="p"
                      className="NewsSlider__content__latest lightHeaderSansSerif"
                      config={{ color: "secondary" }}
                    >
                        Latest News
                    </ContextElement>
                    <p className="NewsSlider__content__title headerSerif">
                      {stringcharlimiter(article.title, true, 70)}
                    </p>
                    <p className="NewsSlider__content__excerpt">
                      {stringcharlimiter(article.excerpt, true, 125)}
                    </p>
                  </div>
                  <RoundedButton
                    buttonColor={THEME_KEYS.PRIMARY}
                    invertColors
                    title="Read more"
                    onClick={e => this.handleReadMore(article.id, e)}
                    modifiers={["white"]}
                  />
                </div>
                {!!article.image && (
                  <div className="NewsSlider__content__image">
                    <Image
                      wrapperClassName="NewsSlider__content__image__wrap"
                      src={article.image}
                    />
                  </div>
                )}
              </div>
            ))}
          </Swiper>
        )}
      </div>
    );
  }
}

NewsSlider.propTypes = {
  latestNewsArray: propTypes.array,
  history: propTypes.object,
  theme: propTypes.object,
};

export default withTheme(NewsSlider);
