import React from "react";
import propTypes from "prop-types";
import cn from "classnames";

function CheckRadioInput({ selected, name, checkbox, modifiers, handleSelect }) {
  return (
    <div className={cn("CheckRadioInput", modifiers && modifiers.map(mod => `CheckRadioInput--${mod}`))}>
      <div
        onClick={() => handleSelect()}
        className={cn(
          "CheckRadioInput__checker",
          { "CheckRadioInput__checker--checkbox": checkbox },
          { "CheckRadioInput__checker--radio": !checkbox },
          { "CheckRadioInput__checker--checked": selected }
        )}
      />
      <span className="CheckRadioInput__title">{name}</span>
    </div>
  );
}

CheckRadioInput.propTypes = {
  selected: propTypes.bool.isRequired,
  checkbox: propTypes.bool,
  name: propTypes.string,
  modifiers: propTypes.array,
  handleSelect: propTypes.func,
};

export default CheckRadioInput;
