import React from "react";
import propTypes from "prop-types";
/* eslint max-len: 0 */

export const IconShare = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "13"}
      height={props.height || "14"}
      viewBox="0 0 13 14"
    >
      <path
        fillRule="evenodd"
        d="M10.558 9.747c-.446 0-.852.162-1.218.406L4.02 7.107c0-.081.04-.162.04-.203 0-.04 0-.162-.04-.204l5.32-3.044c.325.243.772.405 1.218.405 1.137 0 2.031-.893 2.031-2.03C12.59.894 11.696 0 10.56 0 9.421 0 8.527.893 8.527 2.03c0 .082 0 .123.04.204L3.25 5.28a1.995 1.995 0 0 0-1.218-.405C.894 4.875 0 5.768 0 6.905c0 1.137.893 2.031 2.03 2.031.448 0 .853-.162 1.219-.406l5.32 3.046c0 .081-.04.122-.04.203 0 1.137.893 2.03 2.03 2.03 1.137 0 2.031-.892 2.031-2.03a2.013 2.013 0 0 0-2.032-2.032z"
      />
    </svg>
  );
};

IconShare.propTypes = {
  height: propTypes.string,
  width: propTypes.string,
};
