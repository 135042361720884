/**
 * Class containing email helper methods.
 */
export class EmailHelper {
  /**
   * Is the passed in email address valid?
   * @author Nick Porter (nick.porter@mobile-5.com)
   */
  static isValidEmailAddress(emailAddress) {
    let retVal = false;
    if (emailAddress != null) {
      if (emailAddress.length <= 254) {
        // See https://www.rfc-editor.org/errata_search.php?eid=1690
        /* eslint-disable */
        retVal = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          emailAddress
        );
        /* eslint-enable */
      }
    }
    return retVal;
  }
}

export default EmailHelper;
