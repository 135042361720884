import React from "react";
/* eslint max-len: 0 */
export const IconCalendar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fillRule="nonzero">
        <path d="M14.25 1.438h-2.5a.312.312 0 0 0-.313.312v.625a.313.313 0 0 1-.312.313h.625a.313.313 0 0 0 .313-.313v-.312h2.187c.518 0 .938.42.938.937v11.25c0 .518-.42.938-.938.938H1.75a.937.937 0 0 1-.937-.938V3c0-.518.42-.937.937-.937h1.563v-.625H1.75C.887 1.438.188 2.137.188 3v11.25a1.563 1.563 0 0 0 1.562 1.563h12.5a1.563 1.563 0 0 0 1.563-1.563V3a1.563 1.563 0 0 0-1.563-1.562zm-8.438.937v-.312h3.75v-.625H5.5a.312.312 0 0 0-.313.312v.625a.313.313 0 0 1-.312.313H5.5a.313.313 0 0 0 .313-.313z" />
        <path d="M5.5 2.688H4.25a.312.312 0 0 1-.313-.313V.5c0-.173.14-.312.313-.312H5.5A.313.313 0 0 1 5.813.5v1.875a.313.313 0 0 1-.313.313zm-.938-.625h.625V.813h-.625v1.25zM11.75 2.688H10.5a.312.312 0 0 1-.313-.313V.5c0-.173.14-.312.313-.312h1.25a.313.313 0 0 1 .313.312v1.875a.313.313 0 0 1-.313.313zm-.938-.625h.626V.813h-.626v1.25zM15.5 5.188H.5a.312.312 0 1 1 0-.625h15a.312.312 0 1 1 0 .625zM12.188 9.5h-1.876A.312.312 0 0 1 10 9.187V7.313c0-.173.14-.313.313-.313h1.874a.313.313 0 0 1 .313.313v1.875a.313.313 0 0 1-.313.312zm-1.563-.625h1.25v-1.25h-1.25v1.25z" />
      </g>
    </svg>
  );
};
