import React from "react";
import propTypes from "prop-types";
/* eslint max-len: 0 */
export const IconThreeDots = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || "3"} height={props.height || "14"} viewBox="0 0 3 14">
      <g fillRule="evenodd">
        <circle cx="1.5" cy="1.5" r="1.5" />
        <circle cx="1.5" cy="6.75" r="1.5" />
        <circle cx="1.5" cy="12" r="1.5" />
      </g>
    </svg>
  );
};

IconThreeDots.propTypes = {
  height: propTypes.string,
  width: propTypes.string,
};
