import React from "react";

function RepliesWithin() {
  return (
    <div className="RepliesWithin">
      <p className="lightHeaderSansSerif">
        Typically<br />
        replies within
      </p>
      <div className="RepliesWithin__box">
        <h1 className="RepliesWithin__box__number">24</h1>
        <h2 className="RepliesWithin__box__hours">hours</h2>
      </div>
    </div>
  );
};

export default RepliesWithin;
