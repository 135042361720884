import { connect } from "react-redux";
import NeedHelp from "../components/NeedHelp";
import * as FAQsActions from "redux/FAQs/actions";
import * as FAQsSelector from "selectors/FAQs";
import * as brandsSelector from "selectors/brands";
import * as userSelector from "selectors/user";
import * as brandsActions from "redux/brands/actions";
import * as userActions from "redux/user/actions";

const mapDispatchToProps = dispatch => ({
  getFAQs: () => dispatch(FAQsActions.getFAQs.request()),
  getBrands: () => dispatch(brandsActions.getAllBrands.request()),
  setFirstLoginFalse: () => dispatch(userActions.firstLoginDoneActions.request()),
});

const mapStateToProps = state => ({
  FAQs: param => FAQsSelector.FAQsFilter(param)(state),
  selectedBrand: brandsSelector.selectedBrand(state),
  displayAppForm: brandsSelector.displayAppForm(state),
  firstLogin: userSelector.firstLogin(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NeedHelp);
