import React from "react";
import propTypes from "prop-types";
import { withTheme } from "styled-components";
import PopInAnimation from "animationWrappers/PopInAnimation";
import { useSpring, animated } from "react-spring";
import isHex from "helpers/isHex";

const ContextElement = ({
  elType = "div",
  children,
  theme,
  config = {},
  style,
  spring,
  fillOption,
  ...otherProps
}) => {
  const useSpringOnElement = useSpring(spring || {});
  const styleWithTheme = Object.keys(config).reduce(
    (prev, key) => ({
      ...prev,
      [key]: isHex(config[key]) ||
       !isNaN(config[key]) ? config[key] : theme[config[key]],
    }),
    {}
  );

  let elementType, combinedStyles;

  if (spring) {
    elementType = animated[elType];
    combinedStyles = { ...style, ...styleWithTheme, ...useSpringOnElement };
  } else {
    elementType = elType;
    combinedStyles = { ...style, ...styleWithTheme };
  }

  let fill;

  if (fillOption) {
    fill = { fill: theme[fillOption] };
  }

  return React.createElement(elementType, { style: combinedStyles, ...fill, ...otherProps }, children);
};

ContextElement.propTypes = {
  elType: propTypes.any,
  className: propTypes.string,
  children: propTypes.any,
  theme: propTypes.object,
  config: propTypes.object,
  fillOption: propTypes.oneOfType([propTypes.object, propTypes.string]),
  style: propTypes.object,
  spring: propTypes.object,
};

export default PopInAnimation(withTheme(ContextElement));
