export const RESPONSE_STATUS_SUCCESS = "success";
export const RESPONSE_STATUS_UNAUTHORIZED = "Unauthorized";
export const INVALID_TOKEN = "The access token provided is invalid.";
export const UNDEFINED_REFRESH = "The refresh token is undefined.";
export const ACCOUNT_SUSPENDED = "Your account set for approval.";
export const DOWNLOAD_PREFIX_TITLE = "Oxford.International.";

export const HTTP_CODES = {
  UNAUTH: 401,
  SUCCESS: 200,
  BAD_REQUEST:400,
};

export const EVENT_KEYS = {
  ENTER: "Enter",
};

export const THEME_KEYS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SECONDARY_EIGHTY: "secondaryEighty",
};

export const NEWS_VIEW_TYPES = {
  NEWS: "NEWS",
  USEFULINFO: "USEFULINFO",
};

export const NOTIFICATION_TYPE = {
  EVENT: "event",
  DOCUMENT: "document",
};

export const FILTER_TYPES = {
  CATEGORIES: "cat",
  TAGS: "tags",
};

export const LOGIN_FAILED_MESSAGE =
  "Please check your login details and try again";

export const GENERAL_TOAST_MSG = {
  FAILED: "Something wrong. Please, try it again.",
  SUCCESS: "Your action is successful.",
};
export const EDIT_PROFILE_MSG = "Editing your profile";
export const CHANGE_PWD_MSG = "Changing your password";

export const SHAREABLE_LINK_MSG = "Copying shareable link";
export const NOTIFICATION_MSG = "Updating notification settings";
