import React from "react";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import TextInput from "components/formElements/TextInput";
import FormError from "components/formElements/FormError";
import * as commonValidators from "helpers/commonValidators";
import RoundedButton from "components/shared/button/RoundedButton";
import { THEME_KEYS, LOGIN_FAILED_MESSAGE } from "helpers/constants";
import Loader from "components/shared/loader/Loader";
import isMobile from "helpers/isMobile";
import CheckRadioInput from "components/formElements/RadioCheck/CheckRadioInput";
import cn from "classnames";

export class LoginForm extends React.PureComponent {
  render() {
    const { handleSubmit, prepopulatedFields, error, forgotPassword, registerButton, working } = this.props;
    return (
      <div className="FormWrapper LoginForm">
        {!working ? (
          <Formik
            initialValues={{
              email: "",
              password: "",
              rememberMe: false,
              ...prepopulatedFields,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            render={({ values, handleSubmit, setFieldValue }) => (
              <form className="Form LoginForm__form" onSubmit={handleSubmit} noValidate>
                <div className="Form__inner alignCenterMobile">
                  <Field
                    placeholder={isMobile() ? "Email Address" : ""}
                    name="email"
                    label="Email Address"
                    type="text"
                    mandatory
                    component={TextInput}
                  />
                  <Field
                    placeholder={isMobile() ? "Password" : ""}
                    name="password"
                    label="Password"
                    type="password"
                    mandatory
                    component={TextInput}
                  />
                  <div
                    className={cn(
                      "Form__fieldRow",
                      "Form__fieldRow--spaceBetween",
                      { "Form__fieldRow--lineDivide": !isMobile() },
                      { "Form__fieldRow--marginSpacing": isMobile() },
                      "Form__fieldRow--flex"
                    )}
                  >
                    <div className="LoginForm__form__rememberMe">
                      <CheckRadioInput
                        checkbox
                        handleSelect={() => setFieldValue("rememberMe", !values.rememberMe)}
                        name="Remember Me"
                        selected={values.rememberMe}
                        modifiers={["left-margin-title"]}
                      />
                    </div>
                    <div className="LoginForm__form__forgotPassword alignRight">{forgotPassword}</div>
                  </div>
                  <div className="Form__fieldRow Form__fieldRow--spaceBetween">
                    <div className="Form__fieldRow__item Form__fieldRow__item--fullWidth">
                      <RoundedButton
                        buttonColor={THEME_KEYS.PRIMARY}
                        title={"Login"}
                        type={"submit"}
                        modifiers={["large"]}
                      />
                    </div>
                    <div className="Form__fieldRow__item Form__fieldRow__item--fullWidth">{registerButton}</div>
                  </div>
                </div>
              </form>
            )}
          />
        ) : (
          <div className="LoginForm__container">
            <Loader className="LoginForm__loader" />
          </div>
        )}
        {!!error && <FormError message={LOGIN_FAILED_MESSAGE} />}
      </div>
    );
  }
}

const validationSchema = Yup.object().shape({
  email: commonValidators.isValidEmail(),
});

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  forgotPassword: PropTypes.element.isRequired,
  registerButton: PropTypes.element.isRequired,
  error: PropTypes.string,
  prepopulatedFields: PropTypes.object,
  working: PropTypes.bool,
};

export default LoginForm;
