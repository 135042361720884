import React from "react";
import propTypes from "prop-types";
import RegionsContainer from "components/formElements/fieldContainers/RegionsContainer";
import ContextElement from "components/shared/elements/ContextElement";

function RegionSection({ setFieldValue, formUsage, patchedRegions }) {
  return (
    <>
      <ContextElement
        elType="h3"
        config={{ color: "secondary" }}
        className="UserInformationFormFields__section__heading lightHeaderSansSerif"
      >
        Regions
      </ContextElement>
      <div className="Form__fieldRow Form__fieldRow--flexColumn">
        <RegionsContainer patchedRegions={patchedRegions} formUsage={formUsage} setFieldValue={setFieldValue} />
      </div>
    </>
  );
}

RegionSection.propTypes = {
  patchedRegions: propTypes.array,
  formUsage: propTypes.string,
  setFieldValue: propTypes.func.isRequired,
};

export default RegionSection;
