/* eslint-disable */
import React, { useEffect } from "react";
import propTypes from "prop-types";
import RegisterStudentHeading from "./RegisterStudentHeading";

function RegisterStudentIframe({ selectedBrand, displayAppForm }) {
  const pStyle = {
    minWidth: "100%",
    height: "539px",
    border: "none"
  };
  const { jot_form_id } = displayAppForm || {};
  const onLoad = () => window.parent.scrollTo(0, 0);
  let iframe = "";
  useEffect(() => {
    const ifr = document.getElementById(`JotFormIFrame-${jot_form_id}`);
    if (window.location.href && window.location.href.indexOf("?") > -1) {
      const get = window.location.href.substr(
        window.location.href.indexOf("?") + 1
      );
      if (ifr && get.length > 0) {
        let src = ifr.src;
        src = src.indexOf("?") > -1 ? src + "&" + get : src + "?" + get;
        ifr.src = src;
      }
    }
    window.handleIFrameMessage = function(e) {
      if (typeof e.data === "object") {
        return;
      }
      const args = e.data.split(":");
      if (args.length > 2) {
        iframe = document.getElementById(
          "JotFormIFrame-" + args[args.length - 1]
        );
      } else {
        iframe = document.getElementById("JotFormIFrame");
      }
      if (!iframe) {
        return;
      }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "reloadPage":
          window.location.reload();
          break;
        case "loadScript":
          let src = args[1];
          if (args.length > 3) {
            src = args[1] + ":" + args[2];
          }
          const script = document.createElement("script");
          script.src = src;
          script.type = "text/javascript";
          document.body.appendChild(script);
          break;
        case "exitFullscreen":
          if (window.document.exitFullscreen) window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)
            window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)
            window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)
            window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)
            window.document.msExitFullscreen();
          break;
      }
      const isJotForm = e.origin.indexOf("jotform") > -1;
      if (
        isJotForm &&
        "contentWindow" in iframe &&
        "postMessage" in iframe.contentWindow
      ) {
        const urls = {
          docurl: encodeURIComponent(document.URL),
          referrer: encodeURIComponent(document.referrer)
        };
        iframe.contentWindow.postMessage(
          JSON.stringify({ type: "urls", value: urls }),
          "*"
        );
      }
    };
    if (window.addEventListener) {
      window.addEventListener("message", handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", handleIFrameMessage);
    }
  });
  return (
    <div className="RegisterStudent">
      <RegisterStudentHeading
        image={selectedBrand && selectedBrand.applicationFormImage}
      />
      <iframe
        id={`JotFormIFrame-${jot_form_id}`}
        onLoad={onLoad}
        allowFullScreen={true}
        allow="geolocation; microphone; camera"
        src={`https://form.jotformeu.com/${jot_form_id}`}
        frameBorder="0"
        style={pStyle}
        scrolling="no"
      />
    </div>
  );
}

RegisterStudentIframe.propTypes = {
  dashboardData: propTypes.object,
  displayAppForm: propTypes.oneOfType([propTypes.object, propTypes.bool])
};

export default RegisterStudentIframe;
