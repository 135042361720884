import * as notificationsActionTypes from "redux/notifications/actionTypes";
import * as notificationsActions from "redux/notifications/actions";
import { put, takeEvery, call } from "redux-saga/effects";
import handleThrow from "helpers/handleThrow";

export const watchNotificationsRequestFactory = notificationsService => {
  function * watchNotificationsRequest() {
    yield takeEvery(`${notificationsActionTypes.GET_NOTIFICATIONS}_REQUEST`, getNotificationsRequest);
    yield takeEvery(`${notificationsActionTypes.READ_NOTIFICATION}_REQUEST`, readNotificationRequest);
    yield takeEvery(`${notificationsActionTypes.READ_ALL_NOTIFICATION}_REQUEST`, readAllNotificationRequest);
  }

  function * getNotificationsRequest(action) {
    try {
      const response = yield call(notificationsService.getNotifications);
      const { data } = response;
      yield put(notificationsActions.getNotificationsActions.success(data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(notificationsActions.getNotificationsActions.fail(parsedError));
    }
  }

  function * readNotificationRequest(action) {
    try {
      yield call(notificationsService.readNotifications, action.payload.id);
      yield put(notificationsActions.readNotificationActions.success({ id: action.payload.id }));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(notificationsActions.readNotificationActions.fail(parsedError));
    }
  }

  function * readAllNotificationRequest(action) {
    try {
      yield call(notificationsService.readAllNotifications);
      yield put(notificationsActions.readAllNotificationActions.success());
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(notificationsActions.readAllNotificationActions.fail(parsedError));
    }
  }

  return {
    watchNotificationsRequest,
    getNotificationsRequest,
    readNotificationRequest,
    readAllNotificationRequest,
  };
};
