import React from "react";
import propTypes from "prop-types";
// as a req, Hide HeaderSearch
// import HeaderSearch from "../HeaderSearch";
import HeaderNotifications from "../HeaderNotifications/HeaderNotifications";
import HeaderProfile from "../HeaderProfile";

function ActionButtons({
  user,
  brandById,
  isUnread,
  menuIsOpen,
  setOpenMenu,
  setNotificationRead,
  notifications,
  handleLogout,
  setAllNotificationsRead,
  getUser,
  setSelectedBrand,
}) {
  return (
    <>
      {/* <HeaderSearch location={searchLocation} openMenu={menuIsOpen} onMenuOpen={setOpenMenu} /> */}
      <HeaderNotifications
        setAllNotificationsRead={setAllNotificationsRead}
        setSelectedBrand={setSelectedBrand}
        brandById={brandById}
        isUnread={isUnread}
        setRead={setNotificationRead}
        notifications={notifications}
        openMenu={menuIsOpen}
        onMenuOpen={setOpenMenu}
      />
      <HeaderProfile
        getUser={getUser}
        openMenu={menuIsOpen}
        onMenuOpen={setOpenMenu}
        user={user}
        logout={handleLogout}
      />
    </>
  );
}

ActionButtons.propTypes = {
  user: propTypes.object,
  brandById: propTypes.func,
  isUnread: propTypes.bool,
  menuIsOpen: propTypes.string,
  setOpenMenu: propTypes.func,
  setNotificationRead: propTypes.func,
  notifications: propTypes.array,
  handleLogout: propTypes.func,
  setAllNotificationsRead: propTypes.func,
  getUser: propTypes.func,
  setSelectedBrand: propTypes.func,
};

export default ActionButtons;
