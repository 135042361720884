import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import NeedHelpPageContent from "./NeedHelpPageContent";
import QuickGuideOverlay from "./quickGuide/QuickGuideOverlay";
import { popupConfigurations } from "./quickGuide/QuickGuideOverlay/config";

const NeedHelp = ({ FAQs, selectedBrand, displayAppForm, firstLogin, getBrands, getFAQs, setFirstLoginFalse }) => {
  const [questions, setQuestions] = useState(null);
  const [popups, setPopups] = useState(null);
  const [tutorial, setTutorial] = useState(false);

  useEffect(() => {
    if (!questions) {
      getFAQs();
    }
  }, [getFAQs, questions]);

  useEffect(() => {
    if (FAQs && FAQs.length && !questions) {
      setQuestions(FAQs());
    }
  }, [FAQs, questions]);

  useEffect(() => {
    if (!popups) {
      setPopups(
        popupConfigurations(displayAppForm)
      );
    }
  }, [displayAppForm, popups]);

  useEffect(() => {
    if (firstLogin) {
      setTutorial(true);
      setFirstLoginFalse();
    }
  }, [firstLogin, setFirstLoginFalse]);

  const handleHelpSearchSubmit = searchTerm => {
    setQuestions(FAQs(searchTerm));
  };

  const handlePlayTutorial = () => setTutorial(true);

  const handleFinishedCallback = () => setTutorial(false);

  return (
    <>
      <NeedHelpPageContent
        FAQs={questions}
        handleHelpSearch={handleHelpSearchSubmit}
        handlePlayTutorial={handlePlayTutorial}
        brand={selectedBrand}
        getBrands={getBrands}
      />
      {tutorial && popups && (
        <QuickGuideOverlay
          finishedCallback={handleFinishedCallback}
          popups={popups}
        />
      )}
    </>
  );
};

NeedHelp.propTypes = {
  getFAQs: propTypes.func,
  FAQs: propTypes.func,
  setFirstLoginFalse: propTypes.func,
  selectedBrand: propTypes.object,
  getBrands: propTypes.func,
  displayAppForm: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  firstLogin: propTypes.bool,
};

export default NeedHelp;
