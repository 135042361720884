import { connect } from "react-redux";
import { loginActions, refreshTokenActions, logoutActions } from "redux/auth/actions";
import * as authSelectors from "selectors/auth";
import * as brandsSelector from "selectors/brands";
import * as userSelectors from "selectors/user";

import Login from "../components/WrappedComponents/Login";

/* eslint-disable */
const mapDispatchToProps = dispatch => ({
  attemptLocalLogin: refresh_token => dispatch(refreshTokenActions.request({ refresh_token })),
  login: params => dispatch(loginActions.request(params)),
  logout: () => dispatch(logoutActions.request()),
});
/* eslint-enable */

const mapStateToProps = state => ({
  isAuthenticated: authSelectors.getIsAuthenticated(state),
  selectedBrand: brandsSelector.selectedBrand(state),
  isSuspended: userSelectors.isSuspended(state),
  working: authSelectors.authWorkingSelector(state),
  loginErrorMessage: authSelectors.loginErrorMessage(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
