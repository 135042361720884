import React, { Component } from "react";

class FileTableHeader extends Component {
  render() {
    return (
      <div className="FileTableRow FileTableRow--header">
        <p className="FileTableRow__name">Name</p>
        <p className="FileTableRow__items">Items</p>
        <p className="FileTableRow__lastModified">Last Modified</p>
        <p className="FileTableRow__fileSize">File Size</p>
      </div>
    );
  }
}

export default FileTableHeader;
