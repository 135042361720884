import React, { Component } from "react";
import propTypes from "prop-types";
import { THEME_KEYS } from "helpers/constants";
import FileViewDocumentComponent from "./FileViewDocumentComponent";

class FileViewDocument extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: true,
    };
  }

  handleDownloadClicked = () => {
    this.props.downloadFolder(this.props.file);
  };

  handleDoubleClicked = () => {
    this.props.handleFileDoubleClick(this.props.file, this.props.fileIndex, this.props.sectionType);
  };

  render() {
    const { file_extension: fileExt, thumbnail, name, shareable } = this.props.file;

    return (
      <FileViewDocumentComponent
        thumbnail={thumbnail}
        name={name}
        defaultFill={THEME_KEYS.PRIMARY}
        hoverFill={THEME_KEYS.SECONDARY}
        fileExt={fileExt}
        shareable={shareable}
        color={this.props.color}
        handleShareClicked={this.props.handleShareClicked}
        handleDownloadClicked={this.handleDownloadClicked}
        handleMoreOptionsClicked={this.props.handleMoreOptionsClicked}
        handleFileDoubleClick={this.handleDoubleClicked}
      />
    );
  }
}

FileViewDocument.propTypes = {
  file: propTypes.object.isRequired,
  handleMoreOptionsClicked: propTypes.func,
  downloadFolder: propTypes.func,
  handleFileDoubleClick: propTypes.func,
  fileIndex: propTypes.number,
  sectionType: propTypes.string,
  color: propTypes.string,
  handleShareClicked: propTypes.func,
};

export default FileViewDocument;
