import React from "react";
import propTypes from "prop-types";
import { Image } from "react-image-and-background-image-fade";
import { IconFolder } from "assets/svg/folder";
import ContextElement from "components/shared/elements/ContextElement";
import HoverComponent from "components/shared/util/Hover";
import { IconThreeDots } from "assets/svg/threeDots";
import SingleIconButton from "components/shared/button/SingleIconButton/";
import { THEME_KEYS } from "helpers/constants";
import moment from "moment";
import { bytesToSize } from "helpers/sizeFormatter";

const FileTableRowItem = ({ file, folderClicked }) => {
  const {
    children_count: childrenCount,
    documents_count: documentsCount,
    filesize,
    thumbnail,
    updated_at: lastModified,
    name,
  } = file;

  const handleFolderLeftClicked = event => {
    if (event.nativeEvent.which !== 3) {
      if (file.document_type === undefined) {
        folderClicked(event, file);
      }
    }
  };

  const handleFolderRightClicked = event => {
    event.preventDefault();
    folderClicked(event, file);
  };

  const handleMoreOptionsClicked = event => {
    Object.assign(event, { nativeEvent: { which: 3 } });
    folderClicked(event, file);
  };

  const formatUTC = (UTCDate) => {
    return moment(UTCDate).format("DD MMM YYYY");
  };

  return (
    <div onContextMenu={handleFolderRightClicked} className="FileTableRow">
      <button className="FileTableRow__name" onMouseDown={handleFolderLeftClicked}>
        {file.document_type === undefined ? (
          <IconFolder className="FileTableRow__preview" />
        ) : (
          <Image height="25px" width="30px"
            wrapperClassName="FileTableRow__preview" lazyLoad src={thumbnail || ""} alt="File Preview" />
        )}
        <ContextElement elType="p" className="FileTableRow__name__text" config={{ color: THEME_KEYS.PRIMARY }}>
          {name}
        </ContextElement>
      </button>
      <p className="FileTableRow__items">{childrenCount + documentsCount || "-"}</p>
      <p className="FileTableRow__lastModified">{formatUTC(lastModified)}</p>
      <p className="FileTableRow__fileSize">{bytesToSize(filesize)}</p>
      <HoverComponent defaultFill={THEME_KEYS.PRIMARY} hoverFill={THEME_KEYS.SECONDARY}
        wrapperClassName="FileTableRow__dots">
        <SingleIconButton
          buttonType="button"
          className="FileViewDocument__card__menu__buttons__icon"
          Icon={IconThreeDots} onClick={handleMoreOptionsClicked} />
      </HoverComponent>
    </div>
  );
};

FileTableRowItem.propTypes = {
  file: propTypes.shape({
    thumbnail: propTypes.string,
    name: propTypes.string,
    items: propTypes.string,
    updated_at: propTypes.string,
    filesize: propTypes.string,
    document_type: propTypes.string,
    children_count: propTypes.number,
    documents_count: propTypes.number,
  }),
  folderClicked: propTypes.func,
};

export default FileTableRowItem;
