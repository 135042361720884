import { useState, useEffect } from "react";

export default documentsService => {
  const [currentBrandSearch, setCurrentBrandSearch] = useState(null);
  const [filterParam, setFilterParam] = useState(null);
  const [searchResult, setSearchResult] = useState([]);

  const handleError = error => {
    // TODO: something useful with this
    error = null;
  };

  useEffect(() => {
    const searchDocuments = async() => {
      try {
        const response = await documentsService.searchDocuments({ brand: currentBrandSearch.id, filterParam });
        const { directories, documents } = response.data;
        setSearchResult([...directories, ...documents]);
      } catch (error) {
        handleError(error);
      }
    };
    if (currentBrandSearch && filterParam && filterParam.length) {
      searchDocuments();
      setFilterParam(null);
    } else {
      setSearchResult(null);
    }
  }, [currentBrandSearch, documentsService, filterParam]);

  return {
    setFilterParam,
    searchResult,
    setCurrentBrandSearch,
  };
};
