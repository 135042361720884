import { createSelector } from "reselect";

export const usefulInformationSelector = state =>
  state.usefulInformation.allArticles && state.usefulInformation.allArticles.results;

export const isWorking = state => state.usefulInformation.allArticles && state.usefulInformation.allArticles.working;

export const usefulInformationByPage = (page, orderby, order) =>
  createSelector(usefulInformationSelector,
    usefulInformation => {
      if (usefulInformation) {
        const startIndex = (page - 1) * 9;
        const endIndex = usefulInformation.length >= (startIndex + 9)
          ? startIndex + 9
          : usefulInformation.length;
        let usefulInfoPageContent = null;
        if (orderby) {
          if (orderby === "title") {
            usefulInfoPageContent = order === "desc"
              ? usefulInformation.sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1)
              : usefulInformation.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);
          } else if (orderby === "date") {
            usefulInfoPageContent = order === "desc"
              ? usefulInformation.sort((a, b) => new Date(b.date) - new Date(a.date))
              : usefulInformation.sort((a, b) => new Date(a.date) - new Date(b.date));
          }
        }
        return usefulInfoPageContent
          ? usefulInfoPageContent.slice(startIndex, endIndex)
          : usefulInformation.slice(startIndex, endIndex);
      }
      return null;
    });

export const usefulInformationPages =
  createSelector(usefulInformationSelector,
    usefulInformation => {
      if (usefulInformation) {
        const characterArray = (usefulInformation.length / 9).toString().split(".");
        return characterArray.length === 1
          ? parseInt(characterArray[0], 10)
          : parseInt(characterArray[0], 10) + 1;
      }
      return 0;
    });

export const showUsefulInformation =
    createSelector(usefulInformationSelector,
      usefulInformation => {
        return usefulInformation && !!usefulInformation.length;
      });

export const getSelectedArticle = state =>
  state.usefulInformation.selectedArticle &&
  state.usefulInformation.selectedArticle.results ? state.usefulInformation.selectedArticle.results : null;

export const getFirstNArticlesFiltered = (amountToGet, filterId = null) => {
  return createSelector(
    usefulInformationSelector,
    items => {
      if (items) {
        return filterId
          ? Array.from(items).filter(item => item.id !== filterId).slice(0, amountToGet)
          : Array.from(items).slice(0, amountToGet);
      }
      return [];
    }
  );
};

export const getNextArticleId = createSelector(
  usefulInformationSelector,
  getSelectedArticle,
  (items, selectedId) => {
    if (items && items.length) {
      if (selectedId) {
        const indexOfSelected = items.findIndex(item => item.id === selectedId.id);
        return indexOfSelected <= items.length - 2 ? items[indexOfSelected + 1].id : items[0].id;
      } else {
        return items[0].id;
      }
    }
    return null;
  }
);
