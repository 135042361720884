import React from "react";
/* eslint max-len: 0 */
export const IconFolderView = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fillRule="evenodd">
        <path d="M0 0h6.154v6.154H0zM9.846 0H16v6.154H9.846zM9.846 9.846H16V16H9.846zM0 9.846h6.154V16H0z" />
      </g>
    </svg>
  );
};
