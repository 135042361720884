import ky from "ky/umd";
import config from "../../config";

class WPAPI {
  constructor(apiUrl) {
    this.baseUrl = apiUrl;
  }
  baseUrl = this.baseUrl;
  newsEndPoint = config.WPNewsEndPoint;
  categoriesEndPoint = config.WPCategoriesEndPoint;
  tagsEndPoint = config.WPTagsEndPoint;
  singlePostEndPoint = config.WPSinglePostEndPoint;

  http = () => {
    return ky.create({
      prefixUrl: this.baseUrl,
      headers: { "Content-Type": "application/json" },
      retry: {
        limit: 2,
        methods: ["get"],
      },
      hooks: {
        afterResponse: [
          async(_input, _options, response) => {
            const articles = await response.json();
            if (response.headers.get("x-wp-total") && _input.includes("posts")) {
              return new Response(
                JSON.stringify(
                  Object.assign({}, { articles: articles }, { totalPosts: response.headers.get("x-wp-total") })
                )
              );
            }
          },
        ],
      },
    });
  };
}

export default WPAPI;
