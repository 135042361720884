import API from "../API";

let instance = null;

class LoginSliderService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getloginSlidesByBrandOrPhrase = ({ brandId, urlPhrase }) => {
    if (brandId !== undefined && brandId !== null) {
      return this.http.get(`brand/${brandId}/login-page`).json();
    } else if (urlPhrase) {
      return this.http.get(`brand/url-phrase/${urlPhrase}/login-page`).json();
    }
  }
}

export default LoginSliderService;
