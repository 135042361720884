import React, { Component } from "react";
import propTypes from "prop-types";
import { Image } from "react-image-and-background-image-fade";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";
import stringcharlimiter from "helpers/stringCharLimiter";

class SocialFeedItem extends Component {
  render() {
    const { item } = this.props;

    const handleSocialClicked = () => {
      window.open(this.props.item.link);
    };

    return (
      <ContextElement elType="div" className="SocialFeedItem" config={{ "backgroundColor": THEME_KEYS.PRIMARY }}>
        <Image wrapperClassName="SocialFeedItem__image" src={item.image} alt={item.imgalt} />
        <button onClick={handleSocialClicked} className="SocialFeedItem__details">
          <p>{stringcharlimiter(item.caption, false, 90)}</p>
        </button>
      </ContextElement>
    );
  }
}

SocialFeedItem.propTypes = {
  item: propTypes.object.isRequired,
};

export default SocialFeedItem;
