import * as actionTypes from "./actionTypes";
import { createReducerSet } from "../system/util";
import { combineReducers } from "redux";

const regionContactsReducer = createReducerSet(actionTypes.GET_REGION_CONTACTS);
const mainContactReducer = createReducerSet(actionTypes.GET_MAIN_CONTACT);

const contactsReducer = combineReducers({
  regionContacts: regionContactsReducer,
  mainContact: mainContactReducer,
});

export default contactsReducer;
