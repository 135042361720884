import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NewsMain from "./container/newsMain";
import NewsItem from "./container/newsItem";
import navigation from "../../../../navigation";

class News extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={navigation.news + "/:id"} component={NewsItem} />
        <Route exact path={navigation.usefulInformation + "/:id"} component={NewsItem} />
        <Route path={[navigation.news, navigation.usefulInformation]} component={NewsMain} />
      </Switch>
    );
  }
}

export default News;
