import { HTTPError } from "ky/umd";

export default async function(error) {
  if (error instanceof HTTPError) {
    const { response } = error;
    return response.json().then(data => {
      return Object.assign({}, error, { message: data.message || response.statusText });
    });
  }
  return Object.assign({}, error, { message: error && error.message ? error.message : error.name });
}
