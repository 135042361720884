import React, { useReducer, useEffect } from "react";
import propTypes from "prop-types";
import BrandBanner from "../BrandBanner";
import BrandsSliderTitle from "./BrandsSliderTitle";
import BrandSwiperComponent from "./BrandSwiperComponent";
import BrandSwiperPaginationButtons from "./BrandSwiperPaginationButtons";
import { selectedBrandId } from "selectors/brands";
import Loader from "components/shared/loader/Loader";
import { useSwipeable } from "react-swipeable";
import {
  PREV,
  NEXT,
  GOTO,
} from "./Components";
import { useSelector } from "react-redux";

const initialState = { pos: 1, sliding: false, dir: NEXT };

const BrandsSlider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const slide = dir => {
    dispatch({ type: dir, numItems: (props.brandList ? props.brandList.length : 0) });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 50);
  };

  const brand = useSelector(selectedBrandId);
  useEffect(() => {
    dispatch({ type: GOTO,
      pos: props.brandList.findIndex(_brand => _brand.id === brand),
      numItems: (props.brandList ? props.brandList.length : 0) });
  }, [brand, brand.id, props.brandList]);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleBrandClicked = index => {
    dispatch({ type: GOTO, pos: index, numItems: (props.brandList ? props.brandList.length : 0) });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 50);
  };

  const handleNext = () => slide(NEXT);
  const handlePrev = () => slide(PREV);

  const { brandList, selectBrand, working } = props;
  return (
    <div className="BrandsSlider">
      {!working ? (
        <>
          <div className="BrandsSlider__toolbar">
            <BrandsSliderTitle />
            <BrandSwiperPaginationButtons
              currentSlide={state.pos}
              slideTotal={(props.brandList ? props.brandList.length : 0)}
              handleNext={handleNext}
              handlePrevious={handlePrev}
            />
          </div>
          <BrandSwiperComponent
            state={state}
            handlers={handlers}
            numItems={(props.brandList ? props.brandList.length : 0)}
            handleBrandClicked={handleBrandClicked}
            brands={props.brandList}
            slide={slide}
            currentBrand={state.pos}
          />
          {brandList && <BrandBanner selectBrand={selectBrand}
            brand={brandList[state.pos === (props.brandList ? props.brandList.length : 0) ? 0 : state.pos]} />}
        </>
      ) : (
        <div className="BrandsSlider__loader">
          <Loader className="Loader--noBG" />
        </div>
      )}
    </div>
  );
};

function reducer(state, { type, numItems, pos }) {
  switch (type) {
    case "reset":
      return initialState;
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos <= 1 ? numItems : state.pos - 1,
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === numItems ? 1 : state.pos + 1,
      };
    case GOTO:
      return {
        ...state,
        dir: GOTO,
        sliding: false,
        pos: pos === 0 ? numItems : pos,
      };
    case "stopSliding":
      return { ...state, sliding: false };
    default:
      return state;
  }
}

BrandsSlider.propTypes = {
  brandList: propTypes.array,
  selectBrand: propTypes.func,
  working: propTypes.bool,
};

export default BrandsSlider;
