import React, { PureComponent } from "react";
import propTypes from "prop-types";
import HoverComponent from "components/shared/util/Hover";
import SearchComponent from "components/shared/Search";
import { IconFolderView } from "assets/svg/folderView";
import { IconTableView } from "assets/svg/tableView";
import { IconSearch } from "assets/svg/search";
import { viewType } from "./../viewTypes";

class DocumentToolBarComponent extends PureComponent {
  render() {
    const {
      handleInputChange,
      handleSearchSubmit,
      hoverFill,
      defaultFill,
      handleViewChangeClicked,
      fileViewType,
    } = this.props;
    return (
      <div className="DigitalResources__menu__controls">
        <SearchComponent
          inputClassName="DigitalResources__menu__controls__search"
          name="fileSearch"
          id="fileSearch"
          placeholder="Search Files"
          onChange={handleInputChange}
          onKeyDown={handleSearchSubmit}
          IconComponent={IconSearch}
          IconClassName="DigitalResources__menu__controls__search__icon"
        />
        <div className="DigitalResources__menu__controls__viewToggle">
          <input defaultChecked={fileViewType === viewType.TABLE}
            onClick={handleViewChangeClicked} value={viewType.TABLE} type="radio" name="view" id="table" />
          <label htmlFor="table">
            <HoverComponent defaultFill={defaultFill} hoverFill={hoverFill}>
              <IconTableView />
            </HoverComponent>
          </label>
          <input defaultChecked={fileViewType === viewType.FILE}
            onClick={handleViewChangeClicked} value={viewType.FILE} type="radio" name="view" id="file" />
          <label htmlFor="file">
            <HoverComponent defaultFill={defaultFill} hoverFill={hoverFill}>
              <IconFolderView />
            </HoverComponent>
          </label>
        </div>
      </div>
    );
  }
}

DocumentToolBarComponent.propTypes = {
  handleInputChange: propTypes.func,
  handleSearchSubmit: propTypes.func,
  hoverFill: propTypes.string,
  defaultFill: propTypes.string,
  handleViewChangeClicked: propTypes.func,
  fileViewType: propTypes.string,
};

export default DocumentToolBarComponent;
