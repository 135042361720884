import React, { useState } from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";
import { IconSearch } from "assets/svg/searchBold";
import { EVENT_KEYS } from "helpers/constants";

function HelpSearchBar({ handleSearch }) {
  const [hasValue, setHasValue] = useState(null);

  const handleSearchSubmit = event => {
    if (event.key === EVENT_KEYS.ENTER) {
      handleSearch(event.currentTarget.value.trim());
    }
  };

  const handleInputChange = event => {
    if (event.currentTarget.value.length === 0) {
      handleSearch("");
      setHasValue(false);
    } else {
      if (!hasValue) {
        setHasValue(true);
      }
    }
  };

  return (
    <div className="HelpSearchBar">
      <ContextElement
        elType="input"
        config={{ borderColor: "secondary", color: "secondary" }}
        type="search"
        name="help"
        id="help"
        placeholder="What can we help with?"
        className="HelpSearchBar__input"
        onChange={handleInputChange}
        onKeyDown={handleSearchSubmit}
      />
      {!hasValue && <IconSearch className="HelpSearchBar__input__icon" />}
    </div>
  );
}

HelpSearchBar.propTypes = {
  handleSearch: propTypes.func.isRequired,
};

export default HelpSearchBar;
