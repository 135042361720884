import { connect } from "react-redux";
import Dashboard from "../components/Dashboard";
import * as dashboardDataActions from "redux/dashboard/actions";
import * as contactActions from "redux/contacts/actions";
import * as dashboardSelector from "selectors/dashboard";
import * as contactsSelector from "selectors/contacts";
import * as newsSelectors from "selectors/news";
import * as brandsSelector from "selectors/brands";
import * as usefulInformationSelectors from "selectors/usefulInformation";
import { userSelector } from "selectors/user";

const mapDispatchToProps = dispatch => ({
  getDashboardData: () =>
    dispatch(dashboardDataActions.dashboardDataActions.request()),
  getMainContact: () => dispatch(contactActions.getMainContactActions.request()),
});

const mapStateToProps = state => ({
  mainContact: contactsSelector.mainContactSelector(state),
  dashboardData: dashboardSelector.dashboardSelector(state),
  dashboardDocuments: dashboardSelector.dashboardDocuments(state),
  dashboardContact: dashboardSelector.dashboardContact(state),
  dashboardImage: dashboardSelector.dashboardBrandImage(state),
  user: userSelector(state),
  newsList: newsSelectors.getFirstNArticlesFiltered(3)(state),
  usefulInformationList: usefulInformationSelectors.getFirstNArticlesFiltered(3)(state),
  selectedBrand: brandsSelector.selectedBrand(state),
  workingBrand: brandsSelector.isWorking(state),
  workingDashboard: dashboardSelector.isWorking(state),
  workingMainContact: contactsSelector.isWorking(state, "mainContact"),
  workingNews: newsSelectors.isWorking("allNews")(state),
  workingUsefulInfo: usefulInformationSelectors.isWorking(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
