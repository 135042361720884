import React, { useCallback, useEffect } from "react";
import propTypes from "prop-types";
import CheckSelection from "components/formElements/RadioCheck/CheckSelection";
import EditRegionsCheckSelection from "../formElements/RadioCheck/EditRegionsCheckSelection";
import { checkSelectionHelper } from "../formElements/RadioCheck/EditProfileHelper";

export const Regions = ({ setFieldValue, regions, fetchRegions, formUsage, patchedRegions }) => {
  useEffect(() => {
    fetchRegions();
  }, [fetchRegions, formUsage]);

  useEffect(() => {
    formUsage && setFieldValue("regions", checkSelectionHelper(patchedRegions));
  }, [formUsage, patchedRegions, setFieldValue]);

  const handleSelect = useCallback(
    key => res => {
      setFieldValue(key, res);
    },
    [setFieldValue]
  );
  return (
    <>
      {formUsage ? (
        <EditRegionsCheckSelection
          patchedRegions={patchedRegions}
          label="I recruit students from"
          onSelection={handleSelect("regions")}
          options={!!regions && regions}
        />
      ) : (
        <CheckSelection
          label="I recruit students from"
          onSelection={res => setFieldValue("regions", res.map(({ value }) => value))}
          options={!!regions && regions}
        />
      )}
    </>
  );
};

Regions.propTypes = {
  patchedRegions: propTypes.array,
  formUsage: propTypes.string,
  setFieldValue: propTypes.func,
  fetchRegions: propTypes.func.isRequired,
  regions: propTypes.array.isRequired,
};
