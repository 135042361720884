import { connect } from "react-redux";
import Router from "./../";
import * as authSelector from "selectors/auth";
import * as loginSliderActions from "redux/loginSlider/actions";
import * as brandsSelector from "selectors/brands";
import * as loginSlideSelectors from "selectors/loginSlider";

const mapDispatchToProps = dispatch => ({
  getLoginPage: urlPhrase =>
    dispatch(loginSliderActions.loginSliderActions.request({ urlPhrase })),
});

const mapStateToProps = state => ({
  isAuthenticated: authSelector.getIsAuthenticated(state),
  selectedBrand: brandsSelector.selectedBrand(state),
  loginSlides: loginSlideSelectors.loginSlidesSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Router);
