import * as actionTypes from "./actionTypes";
import { createReducer } from "redux-starter-kit";
import { createReducerSet, initialState } from "./../system/util";
import { combineReducers } from "redux";

const getAllNewsReducer = createReducerSet(actionTypes.ALL_NEWS);
const getPaginatedNewsReducer = createReducerSet(actionTypes.PAGINATED_NEWS);
const getUsefullInformationReducer = createReducerSet(actionTypes.USEFULL_INFORMATION);
const getCategoriesReducer = createReducerSet(actionTypes.CATEGORIES);
const getTagsReducer = createReducerSet(actionTypes.TAGS);
const selectedArticleReducer = createReducerSet(actionTypes.SELECTED_ARTICLE);

const setFilterReducer = createReducer(initialState, {
  NEWS__SET_FILTERS_REQUEST: (state, action) => {
    state.results = {
      categories: action.payload.categories || (state.results ? state.results.categories : []),
      tags: action.payload.tags || (state.results ? state.results.tags : []),
      order: action.payload.order || (state.results ? state.results.order : "desc"),
      orderby: action.payload.orderby || (state.results ? state.results.orderby : "date"),
    };
  },
});

const countAllNewsReducer = createReducer(initialState, {
  NEWS__POSTS_COUNT_REQUEST: (state, action) => {
    state.results = parseInt(action.payload.totalPosts, 10);
  },
});

const reducer = combineReducers({
  allNews: getAllNewsReducer,
  paginatedNews: getPaginatedNewsReducer,
  selectedArticle: selectedArticleReducer,
  postsCount: countAllNewsReducer,
  categories: getCategoriesReducer,
  tags: getTagsReducer,
  usefullInformation: getUsefullInformationReducer,
  filters: setFilterReducer,
});

export default reducer;
