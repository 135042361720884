import React, { Component } from "react";
import classnames from "classnames";
import propTypes from "prop-types";
import Swiper, { Pagination } from "react-id-swiper";
import isMobile from "helpers/isMobile";
import { withTheme } from "styled-components";
import RoundedButton from "components/shared/button/RoundedButton/";
import ArticleCard from "components/shared/articles/ArticleCard";
import ArticleSwiperBullet from "./AricleSwiperBullet";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";
import navigation from "../../../../navigation";

class ArticleSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swiper: null,
      article: null,
    };
  }

  params = {
    modules: [Pagination],
    on: {
      slideChange: () => {
        if (this.state.swiper) {
          this.setState({
            article: this.props.articles[this.state.swiper.realIndex],
          });
        }
      },
    },
  };

  handleBulletClicked = index => {
    if (this.state.swiper) {
      this.state.swiper.slideTo(index);
    }
  };

  swiperRef = ref => {
    if (ref) {
      this.setState({
        swiper: ref,
        article: this.props.articles[ref.realIndex],
      });
    }
  };

  goBackNewsMain = () => this.props.history.push(this.props.articleType || navigation.news);

  render() {
    const {
      articleSectionTitle,
      articleSectionClass,
      buttonTitle,
      buttonColor,
      articles,
      hasBackground,
      brand,
      theme,
      articleType,
    } = this.props;
    const { swiper } = this.state;

    const FALLBACK_FONT_STYLE = { color: "#fff" };
    /* eslint-enable */

    return (
      <div
        style={
          /* eslint-disable */
          hasBackground
            ? {
                background: theme.primary,
                background: `-moz-linear-gradient(top, ${theme.primary} 0%, ${theme.primary} 50%, #f5f5f5 50%)`,
                background: `-webkit-linear-gradient(top, ${theme.primary} 0%, ${theme.primary} 50%, #f5f5f5 50%)`,
                background: `linear-gradient(to bottom, ${theme.primary} 0%, ${theme.primary} 50%, #f5f5f5 50%)`,
                filter: `progid:dximagetransform.microsoft.gradient(
                    startColorstr=${theme.primary},
                    endColorstr="#f5f5f5",
                    GradientType=0
                  )`
              }
            : {}
          /* eslint-enable */
        }
        className={classnames([
          "ArticleSection",
          { [`${articleSectionClass}`]: !!articleSectionClass },
        ])}
      >
        <div className="ArticleSection__title">
          {hasBackground && brand && (
            <ContextElement
              className="ArticleSection__title__brand"
              elType="p"
              config={{ color: THEME_KEYS.SECONDARY }}
            >
              {brand}
            </ContextElement>
          )}
          <h1 style={hasBackground && FALLBACK_FONT_STYLE}>
            {articleSectionTitle}
          </h1>
        </div>
        <RoundedButton
          title={buttonTitle}
          buttonClass="ArticleSection__button"
          buttonColor={buttonColor}
          onClick={this.goBackNewsMain}
        />
        {isMobile() ? (
          <div className="ArticleSection__swiper">
            <Swiper {...this.params} getSwiper={this.swiperRef}>
              {!!articles &&
                articles.map((article, index) => (
                  <div
                    key={`${article.title}${index}`}
                    className="ArticleSection__slide"
                  >
                    <ArticleCard articleType={articleType} article={article} />
                  </div>
                ))}
            </Swiper>
            <div className="ArticleSection__swiper__bullets">
              {!!swiper &&
                !!articles &&
                articles.map((article, index) => {
                  return (
                    <ArticleSwiperBullet
                      key={`${article.title}${index}-bullet`}
                      title={article.title}
                      index={index}
                      stateIndex={this.state.swiper.realIndex || 0}
                      handleBulletClicked={() =>
                        this.handleBulletClicked(index)
                      }
                    />
                  );
                })}
            </div>
          </div>
        ) : (
          <div className="ArticleSection__items__container">
            <div className="ArticleSection__items">
              {articles.map((article, index) => (
                <div
                  key={`${article.title}${index}`}
                  className="ArticleSection__items__item"
                >
                  <ArticleCard
                    articleType={articleType}
                    article={article}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ArticleSection.propTypes = {
  history: propTypes.object,
  articleSectionTitle: propTypes.string.isRequired,
  articleSectionClass: propTypes.string,
  buttonTitle: propTypes.string.isRequired,
  buttonColor: propTypes.string,
  articles: propTypes.array.isRequired,
  hasBackground: propTypes.bool,
  theme: propTypes.object,
  setAsHTML: propTypes.bool,
  brand: propTypes.string,
  articleType: propTypes.string,
};

export default withTheme(ArticleSection);
