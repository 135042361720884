import { connect } from "react-redux";
import * as newsActions from "redux/news/actions";
import * as newsSelectors from "selectors/news";
import * as usefulInformationSelectors from "selectors/usefulInformation";
import usefulInformationActions from "redux/usefulInformation/actions";
import NewsMain from "./../../components/NewsMain";
import * as brandsSelector from "selectors/brands";

const mapDispatchToProps = dispatch => ({
  getAllNews: data => dispatch(newsActions.allNewsActions.request(data)),
  getUsefulInformation: () => dispatch(usefulInformationActions.getUsefulInformationActions.request()),
  getCategories: data => dispatch(newsActions.categoriesActions.request(data)),
  getTags: data => dispatch(newsActions.tagsActions.request(data)),
  getPaginatedNews: data => dispatch(newsActions.paginatedNewsActions.request(data)),
  setNewsFilters: filters => dispatch(newsActions.setFiltersActions.request(filters)),
});

const mapStateToProps = state => ({
  selectedBrand: brandsSelector.selectedBrand(state),
  newsList: newsSelectors.getAllNewsList(state),
  usefulInformation: (page, orderby, order) =>
    usefulInformationSelectors.usefulInformationByPage(page, orderby, order)(state),
  workingPaginated: newsSelectors.isWorking("paginatedNews")(state),
  workingUsefulInfo: usefulInformationSelectors.isWorking(state),
  usefulInformationPages: usefulInformationSelectors.usefulInformationPages(state),
  workingAll: newsSelectors.isWorking("allNews")(state),
  paginatedNewsList: newsSelectors.getPaginatedNewsList(state),
  postsCount: newsSelectors.getPostsCount(state),
  categories: newsSelectors.getCategories(state),
  tags: newsSelectors.getTags(state),
  latestNArticlesFilteredNews: amountToGet => newsSelectors.getFirstNArticlesFiltered(amountToGet)(state),
  filters: newsSelectors.getNewsFilters(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsMain);
