import styled from "styled-components";

export const Controls = styled.div`
  background-color: ${props => (props.isLoading ? props.theme.primary : props.theme.secondary)};
  pointer-events: ${props => (props.isLoading ? "none" : "all")};

  &:hover {
    .Avatar__controls__icon {
      fill: ${props => props.theme.secondary};
      opacity: ${props => (props.isLoading ? 0 : 1)};
    }
  }
`;
