import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import SelectInput from "../SelectInput";
import * as GeneralActions from "redux/general/actions";
import * as GeneralSelectors from "selectors/general";
import ProfileSelect from "../ProfileSelect/ProfileSelect";

function withPhoneCode(WrappedComponent) {
  class PhoneCode extends React.Component {
    componentDidMount() {
      this.props.fetchPhoneCodes();
    }

    render() {
      // eslint-disable-next-line max-len
      return this.props.formUsage ? <ProfileSelect {...this.props} options={this.props.phoneCodes} /> : <WrappedComponent {...this.props} options={this.props.phoneCodes} />;
    }
  }

  PhoneCode.propTypes = {
    formUsage: propTypes.string, // Edit
    fetchPhoneCodes: propTypes.func.isRequired,
    phoneCodes: propTypes.array.isRequired,
  };

  return PhoneCode;
}

const mapDispatchToProps = dispatch => ({
  fetchPhoneCodes: () => dispatch(GeneralActions.getPhoneCodeActions.request()),
});

const mapStateToProps = (state, ownProps) => ({
  formUsage:ownProps.formUsage,
  phoneCodes: GeneralSelectors.phoneCodes(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPhoneCode(SelectInput));
