import React from "react";
import propTypes from "prop-types";
import { Image } from "react-image-and-background-image-fade";
import HoverComponent from "components/shared/util/Hover";
import { IconShare } from "assets/svg/share";
import { IconDownload } from "assets/svg/download";
import { IconThreeDots } from "assets/svg/threeDots";
import ContextElement from "components/shared/elements/ContextElement";
import isMobile from "helpers/isMobile";
import SingleIconButton from "components/shared/button/SingleIconButton/";
import { THEME_KEYS } from "helpers/constants";

function FileViewDocumentComponent({
  thumbnail,
  name,
  defaultFill,
  hoverFill,
  fileExt,
  shareable,
  handleShareClicked,
  handleDownloadClicked,
  handleMoreOptionsClicked,
  handleFileDoubleClick,
  color,
}) {
  return (
    <div className="FileViewDocument">
      <div className="FileViewDocument__card" >
        <div className="FileViewDocument__card__previewWrap" onClick={isMobile() ? undefined : handleFileDoubleClick}>
          {fileExt && (
            <div
              style={{ border: `2px solid ${defaultFill}`, color: defaultFill }}
              className="FileViewDocument__card__previewWrap__fileExt"
            >
              <p>.{fileExt}</p>
            </div>
          )}
          <Image wrapperClassName="FileViewDocument__card__previewWrap__preview"
            lazyLoad src={thumbnail} alt="File Preview" />
        </div>
        <div className="FileViewDocument__card__menu">
          {fileExt && (
            <div
              style={{ border: `2px solid ${defaultFill}`, color: defaultFill }}
              className="FileViewDocument__card__menu__fileExt"
            >
              <p>.{fileExt}</p>
            </div>
          )}
          <div className="FileViewDocument__card__menu__buttons">
            {shareable &&
              <HoverComponent defaultFill={defaultFill} hoverFill={hoverFill}
                wrapperClassName="FileViewDocument__card__menu__buttons--share">
                <SingleIconButton
                  buttonType="button"
                  className="FileViewDocument__card__menu__buttons__icon"
                  Icon={IconShare} onClick={handleShareClicked} />
              </HoverComponent>
            }
            <HoverComponent defaultFill={defaultFill} hoverFill={hoverFill}
              wrapperClassName="FileViewDocument__card__menu__buttons--download">
              <SingleIconButton
                buttonType="button"
                className="FileViewDocument__card__menu__buttons__icon"
                Icon={IconDownload} onClick={handleDownloadClicked} />
            </HoverComponent>
            <HoverComponent defaultFill={defaultFill} hoverFill={hoverFill}
              wrapperClassName="FileViewDocument__card__menu__buttons--dots">
              <SingleIconButton
                buttonType="button"
                className="FileViewDocument__card__menu__buttons__icon"
                Icon={IconThreeDots} onClick={handleMoreOptionsClicked} />
            </HoverComponent>
          </div>
        </div>
      </div>
      <div className="FileViewDocument__nameWrap">
        <ContextElement elType={"p"} config={{ color: color }}
          className="FileViewDocument__nameWrap__name">{name}</ContextElement>
        <HoverComponent defaultFill={THEME_KEYS.PRIMARY} hoverFill={THEME_KEYS.SECONDARY}
          wrapperClassName="FileViewDocument__nameWrap__dots">
          <SingleIconButton
            buttonType="button"
            className="FileViewDocument__card__menu__buttons__icon"
            Icon={IconThreeDots} onClick={handleMoreOptionsClicked} />
        </HoverComponent>
      </div>
    </div>
  );
};

FileViewDocumentComponent.defaultProps = {
  thumbnail: "",
};
FileViewDocumentComponent.propTypes = {
  thumbnail: propTypes.string,
  name: propTypes.string,
  defaultFill: propTypes.string,
  hoverFill: propTypes.string,
  fileExt: propTypes.string,
  shareable: propTypes.bool,
  handleShareClicked: propTypes.func,
  handleDownloadClicked: propTypes.func,
  handleMoreOptionsClicked: propTypes.func,
  handleFileDoubleClick: propTypes.func,
  color: propTypes.string,
};

export default FileViewDocumentComponent;
