import React from "react";
import propTypes from "prop-types";

export const LoginHeader = ({ header, intro }) => {
  return (
    <header className="LoginHeader">
      <h1 className="LoginHeader__h1">
        {header}
      </h1>
      <div className="LoginHeader__intro">
        {intro}
      </div>
    </header>
  );
};

LoginHeader.propTypes = {
  header: propTypes.oneOfType([propTypes.string, propTypes.element, propTypes.object]),
  intro: propTypes.string,
};

export default LoginHeader;
