import * as Yup from "yup";
import EmailHelper from "./EmailHelper";
import isBase64 from "is-base64";

/* Standard string required */
export const isRequired = fieldName => Yup.string().required(`${fieldName} is required`);
export const isRequiredMixed = fieldName => Yup.mixed().required(`${fieldName} is required`);

/* Generic text field validator (min 1 char, max 1000 char) */
export const isValidGenericTextField = (fieldName, required = true) => {
  return required
    ? isRequired(fieldName)
      .min(1, `${fieldName}_must_be_more_than_1_char`)
      .max(1000, `${fieldName}_must_be_less_than_1000_char`)
    : Yup.string()
      .min(1, `${fieldName}_must_be_more_than_1_char`)
      .max(1000, `${fieldName}_must_be_less_than_1000_char`);
};

/* Does email meet the restrictions */
export const isValidEmail = (fieldName = "Email Address") => {
  return isRequired(fieldName).test("isValidEmailAddress", "Please enter a valid email address", emailAddress =>
    EmailHelper.isValidEmailAddress(emailAddress)
  );
};

/* eslint max-len: 0 */
export const isPassword = () =>
  Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password - minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    );

export const isBase64Image = string => isBase64(string, { mime: true });

export const hasMatchingField = fieldRef =>
  Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref(fieldRef)], "Password should be matched");

export const isPositiveNumber = fieldName =>
  Yup.number()
    .positive("Must be greater then 0")
    .required(`${fieldName} is required`);

export const isNumber = fieldName =>
  Yup.number()
    .required(`${fieldName} is required`);
