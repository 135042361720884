export default function(brandArray) {
  return brandArray.map(brand => {
    return {
      id: brand.id || "",
      name: brand.name || "",
      description: brand.description || "",
      websiteLink: brand.website_link || "",
      navigationColourHex: brand.navigation_colour_hex || "",
      primaryColourHex: brand.primary_colour_hex || "",
      secondaryColourHex: brand.secondary_colour_hex || "",
      image: brand.image || "",
      contactFormImage: brand.contact_form_image || "",
      pageImage: brand.page_image || "",
      logoImage: brand.logo_image || "",
      dashboardImage: brand.dashboard_image || "",
      faqImage: brand.faq_image || "",
      notificationImage: brand.notification_image || "",
      blogUrl: brand.blog_url || "",
      applicationForm: brand.application_form,
      default: brand.default || "",
      loginSlides: brand.login_sliders || null,
      applicationFormImage: brand.application_form_image || "",
      newsCategories: brand.news_categories ? brand.news_categories.split(",") : null,
      children: !brand.children
        ? null
        : brand.children.map(brand => {
          return {
            id: brand.id || "",
            name: brand.name || "",
            description: brand.description || "",
            websiteLink: brand.website_link || "",
            navigationColourHex: brand.navigation_colour_hex || "",
            primaryColourHex: brand.primary_colour_hex || "",
            secondaryColourHex: brand.secondary_colour_hex || "",
            image: brand.image || "",
            contactFormImage: brand.contact_form_image || "",
            pageImage: brand.page_image || "",
            logoImage: brand.logo_image || "",
            dashboardImage: brand.dashboard_image || "",
            faqImage: brand.faq_image || "",
            notificationImage: brand.notification_image || "",
            blogUrl: brand.blog_url || "",
            applicationForm: brand.application_form,
            applicationFormImage: brand.application_form_image || "",
            newsCategories: brand.news_categories ? brand.news_categories.split(",") : null,
            default: brand.default || "",
          };
        }),
    };
  });
}
