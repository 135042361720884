import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import * as dashboardSelector from "selectors/dashboard";
import * as brandsSelector from "selectors/brands";

const mapStateToProps = state => ({
  newsLoading: dashboardSelector.newsLoadingSelector(state),
  displayAppForm: brandsSelector.displayAppForm(state),
  selectedBrand: brandsSelector.selectedBrand(state),
});

export default connect(mapStateToProps)(Sidebar);
