import React, { Component } from "react";
import ContactSlider from "./contactsSlider/ContactSlider";
import { Image } from "react-image-and-background-image-fade";
import contactsImage from "assets/contactsMap.png";
import propTypes from "prop-types";
import Loader from "components/shared/loader/Loader";

class Contacts extends Component {
  constructor(props) {
    super(props);
    props.getBrandContacts();
  }

  render() {
    const { contactListByRegion, regionList, workingRegion } = this.props;
    return (
      <div className="Contact animated fadeIn">
        <div className="Contact__banner">
          <Image
            wrapperClassName="Contact__banner__image animated fadeIn"
            lazyLoad
            src={contactsImage}
            alt="World Map Outline"
          />
          <div className="Contact__banner__info">
            <h1>Contact Us</h1>
            <p>
            Looking to get in touch with one of our team? Navigate through the different
            regions below and find a regional contact to answer your enquiry.
            </p>
          </div>
        </div>
        {!workingRegion ? (
          regionList && <ContactSlider regionList={regionList} contactListByRegion={contactListByRegion} />
        ) : (
          <div className="News__loader">
            <Loader className="Loader--noBG" />
          </div>
        )}
      </div>
    );
  }
}

Contacts.propTypes = {
  contactListByRegion: propTypes.func,
  regionList: propTypes.array,
  getBrandContacts: propTypes.func,
  workingRegion: propTypes.bool,
};

export default Contacts;
