import React from "react";
import { Formik } from "formik";
import RoundedButton from "components/shared/button/RoundedButton/";
import CheckboxInput from "components/formElements/CheckboxInput";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";
import NotificationsService from "services/notification/notificationsService";
import useNotificationSettings from "hooks/useNotificationSettings";
import Loader from "components/shared/loader";

const NotificationsSettings = props => {
  const {
    brandSettings,
    setSubmitForm,
    setFormResults,
    working,
    workingPatch,
  } = useNotificationSettings(new NotificationsService());

  const handleSubmitForm = values => {
    setFormResults(values);
    setSubmitForm(true);
  };
  return (
    <div className="NotificationsSettings animated fadeIn">
      <ContextElement
        elType="h1"
        className="NotificationsSettings__title"
        config={{ color: THEME_KEYS.PRIMARY }}
      >
        Notification Settings
      </ContextElement>
      <p className="NotificationsSettings__info">
      To receive and opt out of notifications,
      simply tick or untick the boxes associated with each brand below.
      Notifications highlight any new updates that have been added to the portal from across our brand portfolio.
      You will only receive notifications for brands that display a tick next to their name.
      </p>
      <p className="NotificationsSettings__ticklistHeader">
        Show Notifications For
      </p>
      {!working ? (
        brandSettings &&
        !!brandSettings.length && (
          <Formik
            initialValues={{
              brands: brandSettings
                .filter(brand => brand.active)
                .map(brand => brand.id),
            }}
            onSubmit={handleSubmitForm}
          >
            {formik => (
              <React.Fragment>
                <div className="NotificationsSettings__ticklist">
                  {brandSettings.map(setting => (
                    <CheckboxInput
                      whiteTick
                      key={setting.id}
                      id={setting.name}
                      name="brands"
                      value={setting.id}
                      label={setting.name}
                    />
                  ))}
                </div>
                <ContextElement
                  elType="span"
                  config={{ backgroundColor: "secondary" }}
                  className="NotificationsSettings__ticklist__seperator"
                />
                <RoundedButton
                  buttonClass="NotificationsSettings__ticklist__submit"
                  buttonColor={"secondary"}
                  onClick={formik.submitForm}
                  title="Save Changes"
                  type="submit"
                  working={workingPatch}
                />
              </React.Fragment>
            )}
          </Formik>
        )
      ) : (
        <div className="NotificationsSettings__loader">
          <Loader className="Loader--noBG" />
        </div>
      )}
    </div>
  );
};

NotificationsSettings.propTypes = {};

export default NotificationsSettings;
