import React, { useCallback, useEffect } from "react";
import propTypes from "prop-types";
import CheckSelection from "components/formElements/RadioCheck/CheckSelection";
import EditInterestsCheckSelection from "../formElements/RadioCheck/EditInterestsCheckSelection";
import { checkSelectionHelper } from "../formElements/RadioCheck/EditProfileHelper";

export const Interests = ({ setFieldValue, interests, fetchInterests, formUsage, patchedAdditionalInterests }) => {
  useEffect(() => {
    fetchInterests();
  }, [fetchInterests]);

  useEffect(() => {
    formUsage && setFieldValue("additionalInterests", checkSelectionHelper(patchedAdditionalInterests));
  }, [formUsage, patchedAdditionalInterests, setFieldValue]);

  const handleSelect = useCallback(
    key => res => {
      setFieldValue(key, res);
    },
    [setFieldValue]
  );

  return (
    <>
      {formUsage ? (
        <EditInterestsCheckSelection
          patchedAdditionalInterests={patchedAdditionalInterests}
          label="I am interested in"
          onSelection={handleSelect("additionalInterests")}
          options={!!interests && interests}
        />
      ) : (
        <CheckSelection
          label="I am interested in"
          onSelection={res => setFieldValue("additionalInterests", res.map(({ value }) => value))}
          options={!!interests && interests}
        />
      )}
    </>
  );
};

Interests.propTypes = {
  patchedAdditionalInterests: propTypes.array,
  formUsage: propTypes.string, // Edit
  setFieldValue: propTypes.func,
  fetchInterests: propTypes.func.isRequired,
  interests: propTypes.array.isRequired,
};
