import React from "react";
/* eslint max-len: 0 */
export const IconDashboard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15">
      <path
        fillRule="nonzero"
        d="M15.783 6.234L8.68 1.071a.378.378 0 0 0-.44 0L1.134 6.234a.365.365 0 0 0-.063.502.377.377 0 0 0 .504.092l.9-.668v7.745c0 .204.168.37.375.37h11.216a.371.371 0 0 0 .374-.37V6.16l.901.657c.063.05.14.078.22.081.119 0 .23-.057.3-.151a.366.366 0 0 0-.079-.513zm-8.82 7.302V9.111h2.991v4.425h-2.99zm6.73 0h-2.99V8.742a.371.371 0 0 0-.375-.369H6.59a.371.371 0 0 0-.373.37v4.793H3.225V5.63l5.234-3.806 5.234 3.806v7.907z"
      />
    </svg>
  );
};
