import "./styles/main.scss";

import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import moment from "moment";
import { Helmet } from "react-helmet";

import Router from "./routes/container/";
import reduxStore from "./store/";

moment.locale("gb", {
  week: {
    dow: 1,
  },
});

function App() {
  const { store, persistor, history } = reduxStore;

  return (
    <Provider store={store}>
      {process.env.REACT_APP_UA_ID &&
        (<Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_UA_ID}`} />
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_UA_ID}');
          `}</script>
        </Helmet>)}
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
