import React from "react";
import propTypes from "prop-types";

function RegisterHeader({
  intro = "Please fill in the following information to register with Agents Portal.",
  title = "Register",
}) {
  return (
    <div className="LoginHeader">
      <h1 className="LoginHeader__h1">{title}</h1>
      <p className="LoginHeader__intro">
        {intro}
      </p>
    </div>
  );
}

RegisterHeader.propTypes = {
  intro: propTypes.string,
  title: propTypes.string,
};

export default RegisterHeader;
