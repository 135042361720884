import { useState, useEffect } from "react";
import downloadFile from "helpers/downloadFile";
import { DOWNLOAD_PREFIX_TITLE } from "../helpers/constants";
import ky from "ky/umd";

export default documentsService => {
  // docs
  const [documents, setDocuments] = useState([]);
  // quick access
  const [quickAccess, setQuickAccess] = useState([]);
  const [quickAccessFile, setQuickAccessFile] = useState(null);
  // clicked file id
  const [newDirectoryId, setNewDirectoryId] = useState(null);
  const [currentDirectoryId, setCurrentDirectoryId] = useState(null);
  // file path
  const [filePath, setFilePath] = useState([]);
  const [currentBrand, setCurrentBrand] = useState(null);
  // search param
  const [filter, setFilter] = useState(null);

  // download
  const [downloadFolder, setDownloadFolder] = useState(null);

  // preview
  const [showPreview, setShowPreview] = useState(false);
  const [currentPreviewFile, setCurrentPreviewFile] = useState({});

  // working
  const [filesWorking, setFilesWorking] = useState(false);
  const [quickAccessWorking, setQuickAccessWorking] = useState(false);
  const [downloadWorking, setDownloadWorking] = useState(false);

  const handleError = error => {
    // TODO: something useful with this
    error = null;
  };

  // Get Brand root directory on load
  useEffect(() => {
    const getRoot = async() => {
      try {
        if (currentBrand && !currentDirectoryId && !newDirectoryId) {
          setFilesWorking(true);
          setFilePath([
            { id: "", name: currentBrand.name || "Digital Resources" },
          ]);
          const response = await documentsService.getDocumentRoot(
            currentBrand.id
          );
          const { data } = response;
          setDocuments([...(data.children || []), ...(data.documents || [])]);
          if (data.file_path && !Array.isArray(data.file_path[0])) {
            setFilePath([
              { id: "", name: currentBrand.name || "Digital Resources" },
              ...data.file_path,
            ]);
          } else {
            setFilePath([
              { id: "", name: currentBrand.name || "Digital Resources" },
            ]);
          }
        } else {
          throw new Error("Brand is null or undefined");
        }
      } catch (error) {
        handleError(error);
      }
      setFilesWorking(false);
    };

    getRoot();
  }, [currentBrand, newDirectoryId, documentsService, currentDirectoryId]);

  // Get directory when new directory id is set
  useEffect(() => {
    const getDirectory = async() => {
      try {
        let response = null;
        setFilesWorking(true);
        if (newDirectoryId && currentBrand && Number(currentDirectoryId) !== Number(newDirectoryId)) {
          setCurrentDirectoryId(newDirectoryId);
          response = await documentsService.getDocumentDirectory(
            newDirectoryId
          );
        } else if (
          currentBrand &&
          filePath.length > 1 &&
          (newDirectoryId === "" || newDirectoryId === undefined) &&
          currentDirectoryId !== ""
        ) {
          setCurrentDirectoryId("");
          response = await documentsService.getDocumentRoot(currentBrand.id);
        } else {
          throw new Error("No brand or directory");
        }
        const { data } = response;
        setDocuments([...(data.children || []), ...(data.documents || [])]);
        if (data.file_path && !Array.isArray(data.file_path[0])) {
          setFilePath([
            { id: "", name: currentBrand.name || "Digital Resources" },
            ...data.file_path,
          ]);
        } else {
          setFilePath([
            { id: "", name: currentBrand.name || "Digital Resources" },
          ]);
        }
        setFilesWorking(false);
      } catch (error) {
        setFilesWorking(false);
        handleError(error);
      }
    };

    getDirectory();
  }, [
    currentBrand,
    currentDirectoryId,
    documentsService,
    filePath.length,
    newDirectoryId,
  ]);

  // Get quick access files on load and when file is added or removed from the list
  useEffect(() => {
    const getQuickAccess = async() => {
      try {
        if (currentBrand && quickAccessFile === null) {
          setQuickAccessWorking(true);
          const response = await documentsService.getQuickAccess(
            currentBrand.id
          );
          const { data } = response;
          const files = data.documents.map(file =>
            Object.assign(file, { isQuickAccess: true })
          );
          const docs = data.directories.map(file =>
            Object.assign(file, { isQuickAccess: true })
          );
          setQuickAccess([...files, ...docs]);
        } else {
          throw new Error("Brand is null or undefined");
        }
      } catch (error) {
        handleError(error);
      }
      setQuickAccessWorking(false);
    };

    getQuickAccess();
  }, [currentBrand, documentsService, quickAccessFile]);

  // Set or remove from quick access
  useEffect(() => {
    const setQuickAccessStatus = async() => {
      try {
        if (quickAccessFile.isToSet) {
          await documentsService.setQuickAccess({
            fileId: quickAccessFile.id,
            type: quickAccessFile.type,
          });
        } else {
          await documentsService.removeQuickAccess({
            fileId: quickAccessFile.id,
            type: quickAccessFile.type,
          });
        }
        setQuickAccessFile(null);
      } catch (error) {
        handleError(error);
      }
    };

    if (quickAccessFile) {
      setQuickAccessStatus();
    }
  }, [documentsService, quickAccess, quickAccessFile]);

  // Download folder
  useEffect(() => {
    const downloadSelectedFolder = async() => {
      const id = downloadFolder.id;
      const isFolder = downloadFolder.isFolder !== undefined
        ? downloadFolder.isFolder
        : !downloadFolder.file_extension;
      let name = isFolder
        ? `${downloadFolder.name}.zip`
        : downloadFolder.original_name;
      try {
        setDownloadWorking(true);
        const response = await documentsService.download(id, isFolder);
        let fileData;
        if (!isFolder && response.data) {
          fileData = await ky
            .get(response.data.document, {
              responseType: "arrayBuffer",
            })
            .arrayBuffer();
          name = response.data.original_filename;
          if (downloadFolder.isFromLink) {
            setCurrentPreviewFile(response.data);
            setShowPreview(true);
          }
        }
        const fileName = `${DOWNLOAD_PREFIX_TITLE}${name}`;
        downloadFile(fileName, isFolder ? response : fileData);
      } catch (error) {
        handleError(error);
      }
      setDownloadWorking(false);
    };

    if (downloadFolder) {
      downloadSelectedFolder();
      setDownloadFolder(null);
    }
  }, [documentsService, downloadFolder]);

  return {
    documents,
    setDocuments,
    newDirectoryId,
    currentDirectoryId,
    setNewDirectoryId,
    currentBrand,
    setCurrentBrand,
    quickAccess,
    setQuickAccess,
    quickAccessFile,
    setQuickAccessFile,
    filter,
    setFilter,
    filePath,
    setFilePath,
    setDownloadFolder,
    filesWorking,
    quickAccessWorking,
    downloadWorking,
    setCurrentPreviewFile,
    currentPreviewFile,
    setShowPreview,
    showPreview,
  };
};
