import React from "react";
import SidebarNavItem from "./SidebarNavItem";
import SidebarCallToAction from "./SidebarCallToAction";
import { navigation } from "../../navigation";
import { IconDashboard } from "../../assets/svg/dashboard";
import { IconCalendar } from "../../assets/svg/calendar";
import { IconBrowseBrands } from "../../assets/svg/browseBrands";
import { IconNeedHelp } from "../../assets/svg/needHelp";
import { IconDigitalRes } from "../../assets/svg/digitalResources";
import { IconContacts } from "../../assets/svg/contacts";
import { IconNews } from "../../assets/svg/news";
import Clock from "./Clock/Clock";
import classnames from "classnames";
import propTypes from "prop-types";
import isMobile from "helpers/isMobile";

function SidebarComponent({ newsWorking,
  isOpen,
  toggleSideBarOpen,
  displayAppForm,
  selectedBrand,
}) {
  return (
    <>
      <div
        onClick={toggleSideBarOpen}
        className={classnames("SidebarBackground", {
          "SidebarBackground--show": isOpen,
        })}
      />
      <div
        className={classnames("Sidebar", {
          "Sidebar--open": isOpen,
        })}
      >
        <nav onClick={isMobile() ? toggleSideBarOpen : undefined} className="Sidebar__nav">
          {displayAppForm && <SidebarCallToAction />}
          <SidebarNavItem Icon={IconDashboard} link={navigation.home} title="Dashboard" />
          <SidebarNavItem Icon={IconDigitalRes}
            link={selectedBrand ? `${navigation.digitalResources}/${selectedBrand.name.replace(/ /g, "+")}`
              : navigation.digitalResources} title="Digital Resources" />
          <SidebarNavItem Icon={IconNews} link={navigation.news} title="News" working={newsWorking}
            isActive={(_, location) => {
              return location.pathname.includes(navigation.news) ||
              location.pathname.includes(navigation.usefulInformation);
            }}
          />
          <SidebarNavItem Icon={IconCalendar} link={navigation.calendar} title="Calendar" />
          <SidebarNavItem Icon={IconContacts} link={navigation.contacts} title="Contacts" />
          <SidebarNavItem Icon={IconBrowseBrands} link={navigation.browseBrands} title="Browse Brands" />
          <SidebarNavItem Icon={IconNeedHelp} link={navigation.needHelp} title="Need Help?" />
        </nav>
        <Clock />
      </div>
    </>
  );
}
SidebarComponent.defaultProps = {
  displayAppForm: false,
};
SidebarComponent.propTypes = {
  toggleSideBarOpen: propTypes.func.isRequired,
  newsWorking: propTypes.bool,
  isOpen: propTypes.bool.isRequired,
  displayAppForm: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  selectedBrand: propTypes.object,
};

export default React.memo(SidebarComponent);
