import React, { Component } from "react";
import propTypes from "prop-types";
import FileViewDocument from "../FileViewDocument";
import { THEME_KEYS } from "helpers/constants";
import FileViewItemComponent from "./FileViewItemComponent";

class FileViewItem extends Component {
  handleFolderClicked = event => {
    this.props.folderClicked(event, this.props.file);
  };

  handleShareClicked = event => {
    Object.assign(event, { nativeEvent: { which: 3 } });
    this.props.folderClicked(event, this.props.file, true);
  };

  handleFolderRightClicked = event => {
    event.preventDefault();
    this.props.folderClicked(event, this.props.file);
  };

  handleMoreOptionsClicked = event => {
    Object.assign(event, { nativeEvent: { which: 3 } });
    this.props.folderClicked(event, this.props.file);
  }

  render() {
    const {
      name,
      children_count: childCount,
      documents_count: docCount,
      document_type: docType,
      thumbnail,
    } = this.props.file;
    const { handleFileDoubleClick, fileIndex, sectionType } = this.props;
    return thumbnail === undefined && docType === undefined ? (
      <FileViewItemComponent
        handleMoreOptionsClicked={this.handleMoreOptionsClicked}
        handleFolderClicked={this.handleFolderClicked}
        handleFolderRightClicked={this.handleFolderRightClicked}
        name={name}
        color={THEME_KEYS.PRIMARY}
        amountOfItems={Number(childCount) + Number(docCount) > 0 ? Number(childCount) + Number(docCount) : 0}
      />
    ) : (
      <FileViewDocument
        handleMoreOptionsClicked={this.handleMoreOptionsClicked}
        downloadFolder={this.props.downloadFolder}
        handleShareClicked={this.handleShareClicked}
        file={this.props.file}
        handleFileDoubleClick={handleFileDoubleClick}
        fileIndex={fileIndex}
        color={THEME_KEYS.PRIMARY}
        sectionType={sectionType} />
    );
  }
}

FileViewItem.propTypes = {
  file: propTypes.shape({
    thumbnail: propTypes.string,
    name: propTypes.string,
    updated_at: propTypes.string,
    fileSize: propTypes.string,
    document_type: propTypes.string,
    children_count: propTypes.number,
    documents_count: propTypes.number,
    items: propTypes.string,
  }),
  folderClicked: propTypes.func,
  downloadFolder: propTypes.func,
  handleFileDoubleClick: propTypes.func,
  fileIndex: propTypes.number,
  sectionType: propTypes.string,
};

export default FileViewItem;
