import { createReducer } from "redux-starter-kit";
import { isEmptyObject } from "../../helpers/isEmptyObject";

export const initialState = {
  working: null,
  results: null,
  failed: null,
};

export const createReducerSet = actionName => {
  return createReducer(initialState, {
    [`${actionName}_REQUEST`]: (state, action) => {
      state.working = true;
      state.results = null;
      state.failed = {};
      state.failed.hasFailed = false;
    },
    [`${actionName}_SUCCESS`]: (state, action) => {
      const results = isEmptyObject(action.payload) ? null : action.payload;
      state.working = false;
      state.results = results;
      state.failed = {};
      state.failed.hasFailed = false;
    },
    [`${actionName}_FAIL`]: (state, action) => {
      const { message, response } = action.payload;
      state.working = false;
      state.results = null;
      state.failed = {
        error: {
          message,
          status: response ? response.status : "",
          statusText: response ? response.statusText : "",
        },
        hasFailed: true,
      };
    },
  });
};

export const createActionSet = actionName => {
  const request = params => {
    return {
      type: `${actionName}_REQUEST`,
      payload: {
        ...params,
      },
    };
  };
  const success = response => {
    return {
      type: `${actionName}_SUCCESS`,
      payload: response,
    };
  };
  const fail = error => {
    return {
      type: `${actionName}_FAIL`,
      payload: {
        ...error,
      },
    };
  };

  return {
    request,
    success,
    fail,
  };
};
