const WPBaseApiUrl = process.env.REACT_APP_WPAPI_URI;
const WPNewsEndPoint = "posts";
const WPSinglePostEndPoint = "posts/";
const WPCategoriesEndPoint = "categories";
const WPTagsEndPoint = "tags";
const APIUrl = process.env.REACT_APP_API_URI;
const JotformAPIKey = process.env.REACT_APP_JOTFORM_API_KEY;
const JotformFormID = process.env.REACT_APP_JOTFORM_FORM_ID;
const JotFormURL = "https://api.jotform.com/";

export default {
  WPBaseApiUrl,
  WPNewsEndPoint,
  WPSinglePostEndPoint,
  WPCategoriesEndPoint,
  WPTagsEndPoint,
  APIUrl,
  JotformAPIKey,
  JotformFormID,
  JotFormURL,
};
