import propTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

/**
 * ScrollToError - scroll the view to the first error in a formik form
 * NOTE: Ensure that the YUP validation object lists the form elements in the order they appear on the web page
 *
 * @author David van Zyl
 */
class ScrollToError extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps;
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (ReactDOM.findDOMNode(errorElement)) {
        ReactDOM.findDOMNode(errorElement).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center" });
      }
    }
  }

  render() {
    return null;
  }
}

export default ScrollToError;

ScrollToError.propTypes = {
  isSubmitting: propTypes.bool,
  isValidating: propTypes.bool,
  errors: propTypes.object,
};
