import React from "react";
import PropTypes from "prop-types";
/* eslint max-len: 0 */
export const IconLink = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="9" viewBox="0 0 19 9">
      <g fillRule="evenodd">
        <path d="M14.586.04h-2.651a.563.563 0 0 0-.555.574c0 .306.258.572.555.572h2.651c1.761 0 3.206 1.494 3.206 3.314s-1.445 3.313-3.206 3.313h-2.651a.563.563 0 0 0-.555.573c0 .328.258.573.555.573h2.651c2.375 0 4.315-2.005 4.315-4.46 0-2.454-1.94-4.458-4.315-4.458zM7.065 7.813H4.414c-1.761 0-3.206-1.493-3.206-3.313s1.445-3.313 3.206-3.313h2.651A.563.563 0 0 0 7.62.614c0-.307-.258-.573-.555-.573H4.414C2.039.04.099 2.045.099 4.5c0 2.454 1.94 4.458 4.315 4.458h2.651a.563.563 0 0 0 .555-.573c0-.327-.238-.572-.555-.572z" />
        <path d="M4.69 4.5c0 .328.258.573.555.573h8.51a.563.563 0 0 0 .554-.573.563.563 0 0 0-.554-.573h-8.51c-.317 0-.555.245-.555.573z" />
      </g>
    </svg>

  );
};

IconLink.propTypes = {
  className: PropTypes.string,
};
