function computerize(string) {
  if (string === "Yes") {
    return true;
  }
  if (string === "No") {
    return false;
  }
  if (typeof string === "string" && string.indexOf("|") > -1) {
    return string.split("|");
  }
  return string;
}

function loopThrough(object) {
  return Object.keys(object).reduce((prev, current) => {
    return {
      ...prev,
      [current]: computerize(object[current]),
    };
  }, {});
}

function currentSwitcher(current) {
  const convertedHumanStrings = loopThrough(current);
  const { type, sublabels, middle: hasMiddleName, prefix: hasPrefix, suffix: hasSuffix } = convertedHumanStrings;

  switch (type) {
    case "control_head":
      return {
        ...convertedHumanStrings,
      };
    case "control_fullname":
      const { first, last, middle, prefix, suffix } = sublabels;
      let filteredSublabels = {};
      if (hasPrefix) filteredSublabels.prefix = prefix;
      if (first) filteredSublabels.first = first;
      if (hasMiddleName) filteredSublabels.middle = middle;
      if (last) filteredSublabels.last = last;
      if (hasSuffix) filteredSublabels.suffix = suffix;
      return {
        ...convertedHumanStrings,
        fields: createNameValuePairs(filteredSublabels),
      };
    default:
      return convertedHumanStrings;
  }
}

function createNameValuePairs(obj) {
  return Object.keys(obj).reduce(
    (prev, current) => [
      ...prev,
      {
        value: current,
        name: obj[current],
      },
    ],
    []
  );
}

export default function(response) {
  const data = Object.values(response.content).reduce((prev, current) => [...prev, currentSwitcher(current)], []);
  return data.sort((a, b) => (Number(a.order) > Number(b.order) ? 1 : -1));
}
