import * as actionTypes from "./actionTypes";
import { createActionSet } from "../system/util";

export const getUserActions = createActionSet(actionTypes.GET_USER);
export const patchUserActions = createActionSet(actionTypes.PATCH_USER);
export const editUserActions = createActionSet(actionTypes.EDIT_USER);
export const registerUserActions = createActionSet(actionTypes.REGISTER_USER);
export const changePasswordActions = createActionSet(
  actionTypes.CHANGE_PASSWORD
);
export const resetUserActions = createActionSet(actionTypes.RESET_USER);
export const firstLoginDoneActions = createActionSet(actionTypes.FIRST_LOGIN);
