import React, { PureComponent } from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";
import CheckboxInput from "components/formElements/CheckboxInput";
import { Formik } from "formik";
import { withTheme } from "styled-components";
import { THEME_KEYS } from "helpers/constants";

class Filter extends PureComponent {
  handleOptionSelection = selection => {
    if (selection.constructor === Number) {
      this.props.setSelectedOption(selection);
    }
  };

  handleSubmit = data => {
    this.props.onChange(data[this.props.name]);
    this.props.handleClick();
  };

  handleFilterClicked = () => {
    this.props.handleClick(this.props.active ? null : this.props.filterType);
  }

  render() {
    const { options, name, theme, activeOptions, active } = this.props;

    const checkOptions = !!options && !!options.length;

    return (
      <div className="Filter">
        {checkOptions && (
          <div
            className="Filter__button"
            onClick={this.handleFilterClicked}
            style={{
              borderColor: theme.secondary,
              backgroundColor: activeOptions.length ? theme.secondary : false,
              color: activeOptions.length ? "#FFF" : theme.primary,
            }}
          >
            {name}
          </div>
        )}
        {active && (
          <Formik
            initialValues={{
              [name]: activeOptions,
            }}
            onSubmit={this.handleSubmit}
            enableReinitialize
            className="Filter__dropdown"
          >
            {({ resetForm, handleSubmit, submitForm }) => (
              <ContextElement elType="div" config={{ fill: THEME_KEYS.PRIMARY }} className="Filter__dropdown">
                <form onSubmit={handleSubmit}>
                  {checkOptions &&
                    options.map(element => {
                      return (
                        <CheckboxInput
                          key={name + element.id}
                          id={name + element.name}
                          name={name}
                          value={element.id}
                          label={element.name}
                        />
                      );
                    })}
                  <div className="Filter__cta">
                    <button
                      type="reset"
                      style={{
                        color: theme.primary,
                      }}
                      onClick={() => {
                        if (activeOptions.length) {
                          this.props.resetActiveOptions();
                          submitForm();
                        } else {
                          resetForm({
                            [name]: [],
                          });
                        }
                      }}
                    >
                      Clear
                    </button>
                    <button
                      type="submit"
                      style={{
                        color: theme.secondary,
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </form>
              </ContextElement>
            )}
          </Formik>
        )}
      </div>
    );
  }
}

Filter.propTypes = {
  options: propTypes.array,
  activeOptions: propTypes.array,
  resetActiveOptions: propTypes.func,
  name: propTypes.string,
  onChange: propTypes.func,
  setSelectedOption: propTypes.func,
  theme: propTypes.object,
  active: propTypes.bool,
  handleClick: propTypes.func,
  filterType: propTypes.string,
};

export default withTheme(Filter);
