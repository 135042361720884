import React from "react";
import PropTypes from "prop-types";
/* eslint max-len: 0 */
export const IconArrow = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18">
      <path
        fillRule="nonzero"
        d="M.507 9.123a.885.885 0 0 0 .27.5l7.966 7.556c.178.19.433.307.707.32.273.014.542-.076.74-.247a.868.868 0 0 0 .31-.661.865.865 0 0 0-.315-.659L2.872 9l7.313-6.932a.865.865 0 0 0 .315-.659.868.868 0 0 0-.31-.66A1.054 1.054 0 0 0 9.45.5a1.03 1.03 0 0 0-.707.32L.778 8.377a.857.857 0 0 0-.27.746H.507z"
      />
    </svg>
  );
};

IconArrow.propTypes = {
  className: PropTypes.string,
};
