export default function(response, brand) {
  if (brand && brand.newsCategories && brand.newsCategories.length) {
    const filtered = response
      .filter(element => brand.newsCategories.includes(String(element.id)));
    if (filtered && filtered.length) {
      return filtered.map(element => {
        return {
          id: element.id,
          name: element.name,
          count: element.count,
        };
      });
    }
  }
  return response.map(element => {
    return {
      id: element.id,
      name: element.name,
      count: element.count,
    };
  });
};
