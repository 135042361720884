import { createSelector } from "reselect";

export const regionContactsSelector = state => state.contacts.regionContacts.results;
export const mainContactSelector = state => {
  return state.contacts.mainContact.results;
};

export const isWorking = (state, section) => state.contacts[section].working;

export const regionList = createSelector(
  regionContactsSelector,
  items => {
    if (items) {
      let regionList = [];
      let regionSet = new Set();
      Array.from(items).forEach(contact => {
        if (contact && contact.regions) {
          regionList = [...regionList, ...contact.regions];
        }
      });
      regionList.forEach(region => regionSet.add(region));
      return Array.from(regionSet).sort();
    }
    return null;
  }
);

export const contactListByRegion = param => {
  return createSelector(
    regionContactsSelector,
    items => {
      if (items) {
        return param ? items.filter(contact => contact.regions.includes(param)) : items;
      }
      return null;
    }
  );
};
