import React from "react";
import { Formik, Field } from "formik";
import propTypes from "prop-types";
import * as Yup from "yup";
import TextInput from "components/formElements/TextInput";
import FormError from "components/formElements/FormError";
import * as commonValidators from "helpers/commonValidators";
import RoundedButton from "components/shared/button/RoundedButton";
import isMobile from "helpers/isMobile";
import { THEME_KEYS } from "helpers/constants";

export class ForgottenPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      failMessage: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.failMessage === undefined) {
      return {
        failMessage: null,
      };
    } else if (prevState.fail !== nextProps.failMessage) {
      return {
        failMessage: nextProps.failMessage,
      };
    }
    return prevState;
  }

  render() {
    const { handleSubmit, error, backLink, successMessage, failMessage } = this.props;
    return (
      <div className="FormWrapper LoginForm">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form className="Form LoginForm__form" onSubmit={handleSubmit} noValidate>
              <div className="Form__inner">
                <div className="Form__section">
                  <Field
                    placeholder={isMobile() ? "Email Address" : ""}
                    name="email"
                    label="Email Address"
                    type="text"
                    mandatory
                    component={TextInput}
                  />
                  {successMessage && <span className="Form__message Form__message--success">{successMessage}</span>}
                  {failMessage && (
                    <span className="Form__message Form__message--fail animated shake">{failMessage}</span>
                  )}
                </div>
                <div
                  className="Form__fieldRow Form__fieldRow--flex Form__fieldRow--spaceBetween
                Form__fieldRow--wrap Form__fieldRow--spacing120"
                >
                  <RoundedButton
                    buttonColor={THEME_KEYS.PRIMARY}
                    title={"Reset"}
                    type={"submit"}
                    modifiers={["large"]}
                  />
                  <RoundedButton
                    buttonColor={THEME_KEYS.PRIMARY}
                    buttonTextColor={THEME_KEYS.PRIMARY}
                    title={"Cancel"}
                    type={"button"}
                    invertColors
                    onClick={() => backLink()}
                    modifiers={["large", "white"]}
                  />
                </div>
              </div>
            </form>
          )}
        />
        {!!error && <FormError message={error} />}
      </div>
    );
  }
}

const validationSchema = Yup.object().shape({
  email: commonValidators.isValidEmail(),
});

ForgottenPassword.propTypes = {
  handleSubmit: propTypes.func.isRequired,
  error: propTypes.string,
  backLink: propTypes.func.isRequired,
  successMessage: propTypes.string,
  failMessage: propTypes.string,
};

export default ForgottenPassword;
