import React from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";

function ArticleSwiperBullet({ title, index, stateIndex, handleBulletClicked }) {
  return (
    <ContextElement
      elType="button"
      key={`${title}bullet${index}`}
      onClick={handleBulletClicked}
      className="ArticleSection__swiper__bullets__bullet"
      config={
        stateIndex === index
          ? {
            backgroundColor: THEME_KEYS.SECONDARY,
          }
          : {
            borderColor: THEME_KEYS.SECONDARY,
          }
      }
      style={
        stateIndex === index
          ? {
            borderColor: "transparent",
          }
          : {
            backgroundColor: "transparent",
          }
      }
    />
  );
}

ArticleSwiperBullet.propTypes = {
  title: propTypes.string,
  index: propTypes.number,
  stateIndex: propTypes.number,
  handleBulletClicked: propTypes.func,
};

export default ArticleSwiperBullet;
