import { createSelector } from "reselect";

export const userWorkingSelector = ({ user }) => user.working;
export const editUserWorkingSelector = ({ editUser }) => editUser.working;

export const isSuspended = ({ user }) => {
  try {
    return user.failed.error.unauthorized;
  } catch {
    return false;
  }
};

export const registrationSuccess = ({ user }) => {
  try {
    return !user.working && !user.hasFailed && user.results.registerSuccess;
  } catch {
    return false;
  }
};

export const registrationErrorMessage = ({ user }) => {
  try {
    return user.failed.hasFailed && user.failed.error.message;
  } catch {
    return null;
  }
};

export const editUserFailedMessageSelector = ({ editUser }) =>
  editUser.failed && editUser.failed.error && editUser.failed.error.message;

export const userSelector = ({ user }) => user.results;

export const userRegion = createSelector(
  userSelector,
  user => {
    if (user) return user.main_region;
    return null;
  }
);

export const userFirstLogin = createSelector(
  userSelector,
  user => {
    if (user) return user.first_login;
    return null;
  }
);

export const userType = createSelector(
  userSelector,
  user => {
    if (user) return user.user_role || null;
    return null;
  }
);

export const firstLogin = createSelector(
  userSelector,
  user => {
    if (user) return user.first_login || null;
    return null;
  }
);

export const userProfileInfoSelector = createSelector(
  userSelector,
  user => {
    if (user) {
      const {
        email,
        phone_code: phoneCode,
        first_name: firstName,
        last_name: lastName,
        phone_number: contactNumber,
        address1,
        address2,
        town,
        post_code: postCode,
        country,
        company_name: companyName,
        regions,
        main_region: mainRegion,
        main_interest: mainInterest,
        additional_interests: additionalInterests,
        image,
      } = user;
      return {
        email,
        phoneCode,
        firstName,
        lastName,
        contactNumber,
        address1,
        address2,
        town,
        postCode,
        country,
        companyName,
        regions,
        mainRegion,
        mainInterest,
        additionalInterests,
        image,
      };
    } else {
      return {};
    }
  }
);
