import { connect } from "react-redux";
import BrandDropDown from "./BrandDropDown";
import * as brandsActions from "redux/brands/actions";
import * as brandsSelector from "selectors/brands";

const mapDispatchToProps = dispatch => ({
  setSelectedBrand: brandId =>
    dispatch(brandsActions.setSelectedBrand.request({ selected: brandId, pushToDashboard: true })),
});

const mapStateToProps = state => ({
  brands: brandsSelector.brandsList(state),
  selectedBrand: brandsSelector.selectedBrand(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandDropDown);
