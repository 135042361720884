import React from "react";
import PropTypes from "prop-types";
/* eslint max-len: 0 */
export const IconCross = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M.286 9.714a.667.667 0 0 1 0-.943L4.057 5 .286 1.229a.667.667 0 0 1 .943-.943L5 4.057 8.771.286a.667.667 0 0 1 .943.943L5.943 5l3.771 3.771a.667.667 0 0 1-.943.943L5 5.943 1.229 9.714a.667.667 0 0 1-.943 0z"
      />
    </svg>
  );
};

IconCross.propTypes = {
  className: PropTypes.string,
};
