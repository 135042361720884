import * as FAQsActionTypes from "redux/FAQs/actionTypes";
import * as FAQsActions from "redux/FAQs/actions";
import { put, takeEvery, call } from "redux-saga/effects";
import handleThrow from "helpers/handleThrow";

export const watchFAQsRequestFactory = FAQsService => {
  function * watchFAQsRequest() {
    yield takeEvery(`${FAQsActionTypes.GET_FAQS}_REQUEST`, allFAQsRequest);
  }

  function * allFAQsRequest(action) {
    try {
      const response = yield call(FAQsService.getFAQs);
      yield put(FAQsActions.getFAQs.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(FAQsActions.getFAQs.fail(parsedError));
    }
  }

  return {
    watchFAQsRequest,
    allFAQsRequest,
  };
};
