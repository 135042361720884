import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Label from "../Label";
import HelperText from "../HelperText";
import {
  Dropdown,
  Option,
  ArrowContainer,
  DropdownWrapper,
} from "./SelectInput.css";
import { IconArrow } from "assets/svg/arrow";
import isMobile from "helpers/isMobile";

const SelectInput = props => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedName, setSelectedName] = useState(props.placeholder || "");
  const [filterInput, setFilterInput] = useState("");
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(null);

  let nameInput;

  useEffect(() => {
    if (props.options !== options) {
      const matchedProp = props.field.value
        ? options
          ? options.filter(
            option =>
              option.value === props.field.value.id ||
                option.name === props.field.value.name
          )[0]
          : false
        : false;
      if (matchedProp) {
        props.form.setFieldValue(props.field.name, matchedProp.value);
        setSelectedIndex(matchedProp.value);
        setSelectedName(matchedProp.name);
      }
      setOptions(props.options);
    }

    if (props.form.initialValues.id && props.form.initialValues.id !== selectedIndex) {
      setSelectedIndex(props.form.initialValues.id);
      setSelectedName(props.form.initialValues.name);
    }
  }, [options, props.options, props.form, props.field, selectedIndex]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!open) {
      if (filterInput !== null) {
        setFilterInput(null);
      }
      if (options !== props.options) {
        setOptions(props.options);
      }
    } else if (open && !isMobile()) {
      nameInput.focus();
    }
  }, [filterInput, nameInput, open, options, props.options]);

  const filter = e => {
    setFilterInput(
      e.target.value ? e.target.value.toLowerCase() : e.target.value
    );
  };

  useEffect(() => {
    const filterResults = () => {
      const filtered = options.filter(option => {
        if (!option || !option.name) {
          return false;
        }
        return (
          (option.children &&
            option.children.find(child =>
              child.name.toLowerCase().includes(filterInput)
            )) ||
          option.name.toLowerCase().includes(filterInput)
        );
      });

      if (filterInput && filterInput.length > 0) {
        setFilteredOptions(filtered);
      } else {
        setFilteredOptions(null);
      }
    };

    filterResults();
  }, [filterInput, options]);

  const handleBlur = () => {
    setFilterInput(null);
    setOpen(false);
  };

  const handleInputFocus = e => {
    e.stopPropagation();
    e.preventDefault();
    nameInput.focus();
  };

  const {
    field,
    form: { setFieldValue, touched, errors, submitCount },
    label,
    id = "",
    modifiers,
    onSelect,
    className,
    minWidth,
  } = props;

  const error =
    touched[field.name] && errors[field.name]
      ? errors[field.name]
      : null || (submitCount > 0 && errors[field.name]);
  const helperText = error ? errors[field.name] : null;
  return (
    <>
      <div
        className={cn(
          "FormElement SelectInput",
          modifiers && modifiers.map(mod => `SelectInput--${mod} `),
          {
            "SelectInput--open": open,
            [className]: !!className,
          }
        )}
      >
        {label && (
          <div className="SelectInput__label FormInput__label">
            <Label htmlFor={id || field.name}>{label}</Label>
          </div>
        )}
        <DropdownWrapper
          selected={open}
          modifiers={modifiers}
          className="SelectInput__wrapper"
        >
          <Dropdown
            className="SelectInput__dropdown"
            data-testid="click-dropdown"
            onClick={toggleOpen}
            tabIndex={selectedIndex}
            modifiers={modifiers}
            selected={open}
            config={props.config}
          >
            <input
              ref={input => {
                nameInput = input;
              }}
              name={field.name}
              className="SelectInput__selected--input FormElement--dropdown"
              id={`dropdown-search dropdown-search${id || field.name} ${
                field.name
              }`}
              type="text"
              value={filterInput || ""}
              disabled={!open}
              onChange={filter}
              placeholder={selectedName}
              data-testid="selectedName"
              style={minWidth ? { minWidth: minWidth } : undefined}
              onClick={handleInputFocus}
            />
            {open && (
              <button
                type="button"
                onClick={handleBlur}
                className="SelectInput__background"
              />
            )}
            <ul
              className={cn("SelectInput__dropdown__body", {
                "animated fadeIn faster": open,
              })}
            >
              {!!open && options &&
              (filteredOptions || options).map((option, index) => (
                <React.Fragment key={`${option.value}-${index}`}>
                  <Option
                    selected={selectedIndex === option.value}
                    className="SelectInput__dropdown__body__item"
                    key={`${option.value}parent-${index}`}
                    data-testid={option.value || option}
                    onClick={() => {
                      setFieldValue(field.name, option.value || option);
                      setSelectedIndex(option.value);
                      setSelectedName(option.name || option);
                      !!onSelect && onSelect(option.value || option);
                      setOpen(false);
                    }}
                    modifiers={modifiers}
                  >
                    {option.name || option}
                  </Option>
                  {option &&
                      option.children &&
                      option.children.map((option, index) => (
                        <Option
                          selected={selectedIndex === option.value}
                          className="SelectInput__dropdown__body__item SelectInput__dropdown__body__item--child"
                          key={`${option.value}child-${index}`}
                          data-testid={option.value || option}
                          onClick={() => {
                            setFieldValue(field.name, option.value || option);
                            setSelectedIndex(option.value);
                            setSelectedName(option.name || option);
                            !!onSelect && onSelect(option.value || option);
                          }}
                          modifiers={modifiers}
                        >
                          {option.name || option}
                        </Option>
                      ))}
                </React.Fragment>
              ))
              }
            </ul>
            <ArrowContainer
              selected={open}
              className="SelectInput__arrow"
              onClick={toggleOpen}
              config={props.config}
              modifiers={modifiers}
            >
              <IconArrow modifiers={modifiers} />
            </ArrowContainer>
          </Dropdown>
        </DropdownWrapper>
        {helperText && <HelperText text={helperText} />}
      </div>
    </>
  );
};

SelectInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  modifiers: PropTypes.array,
  onSelect: PropTypes.func,
  config: PropTypes.object,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default React.memo(SelectInput);
