import React from "react";
import propTypes from "prop-types";
import { LoginHeader } from "../headers";
import { ResetPasswordForm } from "../forms";

function ResetPassword({ submitResetPassword, success, fail, match }) {
  const handleSubmit = ({ password }) => submitResetPassword({ password, token: match.params.hash });
  return (
    <>
      <LoginHeader
        header="Set your new password"
        intro="Please enter your new password twice so we can verify you typed it in correctly."
      />
      <ResetPasswordForm
        handleSubmit={handleSubmit}
        successMessage={success}
        failMessage={fail}
      />
    </>
  );
};

ResetPassword.propTypes = {
  submitResetPassword: propTypes.func.isRequired,
  match: propTypes.string.isRequired,
  success: propTypes.string,
  fail: propTypes.string,
};

export default ResetPassword;
