import React from "react";
import propTypes from "prop-types";
import HoverComponent from "components/shared/util/Hover";
import { IconChevronLeft } from "assets/svg/chevronLeft";
import { IconChevronRight } from "assets/svg/chevronRight";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";

function BrandSwiperPaginationButtons({ currentSlide = 0, slideTotal = 0, handleNext, handlePrevious }) {
  return (
    <div className="BrandsSlider__toolbar__selector">
      <ContextElement
        elType="button"
        className="BrandsSlider__toolbar__selector__button"
        config={{ borderColor: THEME_KEYS.SECONDARY }}
        onClick={handlePrevious}
      >
        <HoverComponent hoverFill={THEME_KEYS.SECONDARY} defaultFill={THEME_KEYS.SECONDARY}>
          <IconChevronLeft />
        </HoverComponent>
      </ContextElement>
      <p className="BrandsSlider__toolbar__selector__count">
        {slideTotal > 0 ? currentSlide : 0 } of {slideTotal}
      </p>
      <ContextElement
        elType="button"
        className="BrandsSlider__toolbar__selector__button"
        config={{ borderColor: THEME_KEYS.SECONDARY }}
        onClick={handleNext}
      >
        <HoverComponent hoverFill={THEME_KEYS.SECONDARY} defaultFill={THEME_KEYS.SECONDARY}>
          <IconChevronRight />
        </HoverComponent>
      </ContextElement>
    </div>
  );
}

BrandSwiperPaginationButtons.propTypes = {
  currentSlide: propTypes.number,
  slideTotal: propTypes.number,
  handleNext: propTypes.func,
  handlePrevious: propTypes.func,
};

export default BrandSwiperPaginationButtons;
