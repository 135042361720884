import React from "react";
/* eslint max-len: 0 */
export const IconNeedHelp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="8" cy="8" r="8" stroke="#595959" strokeWidth=".5" />
        <path
          stroke="#525257"
          fillRule="nonzero"
          d="M6.154 5.036c.118-.101.245-.197.381-.285a2.692 2.692 0 0 1 .96-.387c.186-.037.385-.056.599-.056.295 0 .562.04.802.12.24.08.445.196.615.345.171.15.303.329.397.539.093.21.14.445.14.704 0 .25-.035.465-.106.647-.07.183-.159.34-.266.475a2.025 2.025 0 0 1-.352.35l-.364.276c-.115.085-.214.17-.298.253a.534.534 0 0 0-.155.278L8.384 9h-.93l-.094-.797a.652.652 0 0 1 .06-.411c.061-.117.145-.223.25-.32a3.3 3.3 0 0 1 .352-.28c.13-.091.251-.188.364-.291.114-.104.209-.22.286-.35a.884.884 0 0 0 .116-.46.646.646 0 0 0-.224-.517c-.15-.128-.347-.192-.594-.192a1.511 1.511 0 0 0-.772.19 3.775 3.775 0 0 0-.227.13.335.335 0 0 1-.177.059.304.304 0 0 1-.28-.162l-.36-.562zm.92 5.78a.78.78 0 0 1 .485-.726.806.806 0 0 1 .318-.062.81.81 0 0 1 .571.23.78.78 0 0 1 .231.559.775.775 0 0 1-.231.558.772.772 0 0 1-.256.165.84.84 0 0 1-.315.059.835.835 0 0 1-.318-.06.778.778 0 0 1-.423-.413.775.775 0 0 1-.062-.31z"
        />
      </g>
    </svg>
  );
};
