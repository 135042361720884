import API from "../API";

let instance = null;

class NotificationsService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getNotificationSettings = params => {
    return this.http.get("notification/settings", {
      json: params,
    }).json();
  };

  patchNotificationSettings = params => {
    return this.http.patch("notification/settings", {
      json: params,
    }).json();
  };

  getNotifications = () => {
    return this.http.get("notification/brand").json();
  };

  readNotifications = (id) => {
    return this.http.patch(`notification/${id}/read`).json();
  };

  readAllNotifications = () => {
    return this.http.post("notification/all-read").json();
  };
}

export default NotificationsService;
