import React from "react";
/* eslint max-len: 0 */
export const IconMessage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        d="M9.784 5.296a.329.329 0 0 0-.03-.437.32.32 0 0 0-.434-.013L3.533 9.828.477 8.63a.75.75 0 0 1-.475-.654.752.752 0 0 1 .39-.71L13.077.076a.62.62 0 0 1 .642.029c.193.13.299.355.277.588L12.884 12.43a.78.78 0 0 1-.364.59.76.76 0 0 1-.686.058l-3.72-1.457-2.335 2.213a.598.598 0 0 1-.654.114.612.612 0 0 1-.36-.564v-1.738l5.019-6.35z"
      />
    </svg>
  );
};
