import React, { Component } from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";
import moment from "moment";
import HoverComponent from "components/shared/util/Hover";
import { IconArrow } from "assets/svg/arrow";
import navigation from "./../../../../../navigation";
import ArticleSection from "components/shared/articles/ArticleSection";
import { THEME_KEYS } from "helpers/constants";
import { sanitize } from "dompurify";
import { Image } from "react-image-and-background-image-fade";
import Loader from "components/shared/loader";

class NewsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      selectedArticle: null,
      isNews: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.id) {
      if (nextProps.match.path.includes(navigation.news)) {
        nextProps.getNewsArticle(nextProps.match.params.id);
        return {
          id: nextProps.match.params.id,
          isNews: true,
        };
      } else if (nextProps.match.path.includes(navigation.usefulInformation)) {
        nextProps.getUsefulInformationArticle((nextProps.match.params.id));
        return {
          id: nextProps.match.params.id,
          isNews: false,
        };
      }
      return {
        id: nextProps.match.params.id,
      };
    }
    if ((nextProps.selectedNewsArticle || nextProps.selectedUsefulInfoArticle) &&
          (nextProps.selectedNewsArticle !== prevState.selectedArticle ||
            nextProps.selectedUsefulInfoArticle !== prevState.selectedArticle)) {
      return {
        id: nextProps.match.params.id,
        selectedArticle: nextProps.match.path.includes(navigation.news)
          ? nextProps.selectedNewsArticle
          : nextProps.selectedUsefulInfoArticle,
      };
    }
    return prevState;
  }

  handleBackClicked = () => {
    this.props.history.push(this.state.isNews
      ? navigation.news
      : navigation.usefulInformation,
    { from: "article" }
    );
  };

  handleNextClicked = () => {
    if (this.state.isNews) {
      this.props.history.push(navigation.news + "/" + this.props.nextArticleIdNews);
    } else {
      this.props.history.push(navigation.usefulInformation + "/" + this.props.nextArticleIdUsefulInfo);
    }
  };

  render() {
    const { latestNArticlesFilteredNews, latestNArticlesFilteredUsefulInfo, selectedArticleWorking } = this.props;
    const { selectedArticle, isNews } = this.state;
    return (
      <div className="NewsArticle animated fadeIn delay-1s">
        <div className="NewsArticle__main">
          <div className="NewsArticle__main__toolbar">
            <button
              onClick={this.handleBackClicked}
              className="NewsArticle__main__toolbar__back"
            >
              <HoverComponent
                wrapperClassName="NewsArticle__main__toolbar__back__icon"
                defaultFill={THEME_KEYS.PRIMARY}
              >
                <IconArrow />
              </HoverComponent>
              <p>{isNews
                ? "Back to News"
                : "Back to Useful Information"
              }</p>
            </button>
            {!!this.props.nextArticleIdNews &&
              <button
                onClick={this.handleNextClicked}
                className="NewsArticle__main__toolbar__next"
              >
                <p>Next Post</p>
                <HoverComponent
                  wrapperClassName="NewsArticle__main__toolbar__next__icon"
                  defaultFill={THEME_KEYS.PRIMARY}
                >
                  <IconArrow />
                </HoverComponent>
              </button>
            }
          </div>
          {selectedArticleWorking
            ? (
              <div className="NewsArticle__loader">
                <Loader className="Loader--noBG" />
              </div>
            )
            : (selectedArticle ? (
              <>
                <ContextElement
                  elType="p"
                  config={{ color: "primary" }}
                  className="NewsArticle__main__date"
                >
                  {moment(selectedArticle.date).format("DD MMMM YYYY")}
                </ContextElement>
                <ContextElement
                  elType="h1"
                  config={{ color: "primary" }}
                  className="NewsArticle__main__title"
                  dangerouslySetInnerHTML={{ __html: sanitize(selectedArticle.title) }}
                />
                {!!selectedArticle.image &&
                <Image src={selectedArticle.image} wrapperClassName="NewsArticle__main__image" />
                }
                <ContextElement
                  elType="div"
                  className="NewsArticle__main__article"
                  config={{ borderColor: THEME_KEYS.SECONDARY }}
                  dangerouslySetInnerHTML={{ __html: sanitize(selectedArticle.content) }}
                />
              </>
            )
              : (<p>Failed to load article.</p>)
            )
          }
        </div>
        {!!selectedArticle && (
          <ArticleSection
            articleSectionTitle={isNews
              ? "Latest News & Blogs"
              : "Useful Information"
            }
            buttonTitle={isNews
              ? "Read All News" : "Read All Useful Information"}
            buttonColor={THEME_KEYS.SECONDARY}
            hasBackground
            setAsHTML
            articles={isNews
              ? latestNArticlesFilteredNews(3, selectedArticle.id)
              : latestNArticlesFilteredUsefulInfo(3, selectedArticle.id)
            }
            articleType={isNews
              ? navigation.news
              : navigation.usefulInformation
            }
            history={this.props.history}
          />
        )}
      </div>
    );
  }
}

NewsItem.propTypes = {
  match: propTypes.object,
  getNewsArticle: propTypes.func,
  getUsefulInformationArticle: propTypes.func,
  selectedNewsArticle: propTypes.object,
  selectedUsefulInfoArticle: propTypes.object,
  history: propTypes.object,
  nextArticleIdNews: propTypes.number,
  nextArticleIdUsefulInfo: propTypes.number,
  latestNArticlesFilteredNews: propTypes.func,
  latestNArticlesFilteredUsefulInfo: propTypes.func,
  selectedArticleWorking: propTypes.bool,
};

export default NewsItem;
