import { connect } from "react-redux";
import * as GeneralActions from "redux/general/actions";
import * as GeneralSelectors from "selectors/general";
import { Regions } from "./../../formParts/Regions";

const mapDispatchToProps = dispatch => ({
  fetchRegions: () => dispatch(GeneralActions.getRegionsActions.request()),
});

const mapStateToProps = (state, ownProps) => ({
  regions: GeneralSelectors.regions(state),
  formUsage: ownProps.formUsage,
  patchedRegions:ownProps.patchedRegions,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Regions);
