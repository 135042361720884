import React from "react";
import {
  Wrapper,
  CarouselContainer,
  CarouselSlot,
} from "./Components";
import propTypes from "prop-types";

const getOrder = ({ index, pos, numItems }) => {
  return (index + 1) - pos < 0 ? numItems - Math.abs((index + 1) - pos) : (index + 1) - pos;
};

const Carousel = props => {
  const {
    state,
    handlers,
    numItems,
  } = props;
  return (
    <div {...handlers}>
      <Wrapper>
        <CarouselContainer dir={state.dir} sliding={state.sliding}>
          {React.Children.map(props.children, (child, index) => (
            <CarouselSlot
              key={index}
              order={getOrder({ index: index, pos: state.pos, numItems })}
            >
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
    </div>
  );
};

Carousel.propTypes = {
  state: propTypes.object,
  children: propTypes.oneOfType([propTypes.object, propTypes.array]),
  handlers: propTypes.object,
  numItems: propTypes.number,
};

export default Carousel;
