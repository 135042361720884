import React, { useEffect } from "react";
import propTypes from "prop-types";
import cn from "classnames";

export default function Loader({ className, hasLoaded, hasFailed, onHasFailed }) {
  useEffect(() => {
    if (hasFailed && onHasFailed) {
      onHasFailed();
    }
  }, [hasFailed, onHasFailed]);

  return (
    <div
      className={cn("Loader", {
        "Loader--loaded": hasLoaded,
        "Loader--hasFailed": hasFailed,
        [className]: !!className,
      })}
    />
  );
}

Loader.propTypes = {
  hasFailed: propTypes.bool,
  hasLoaded: propTypes.bool,
  className: propTypes.string,
  onHasFailed: propTypes.func,
};
