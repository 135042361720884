import React from "react";
import propTypes from "prop-types";
import { Field } from "formik";
import TextInput from "components/formElements/TextInput";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";

function AccountDetailsFields({ changePasswordButton, changePasswordForm }) {
  return (
    <ContextElement
      elType="div"
      className="UserInformationFormFields__section"
      config={{
        borderColor: THEME_KEYS.SECONDARY,
      }}
    >
      <ContextElement
        elType="h3"
        config={{ color: "secondary" }}
        className="UserInformationFormFields__section__heading lightHeaderSansSerif"
      >
        Account Details
      </ContextElement>
      <div className="flex flex--space-between flex--center">
        <div className="flex--60">
          <Field name="emailAddress" label="Email Address" type="text" mandatory component={TextInput} />
        </div>
        {!!changePasswordButton && <div className="flex--40">{changePasswordButton}</div>}
      </div>
      {!!changePasswordForm && <div className="UserInformationFormFields__subSection">{changePasswordForm}</div>}
      <div className="UserInformationFormFields__subSection">
        <Field name="company" label="Company Name" type="text" mandatory component={TextInput} />
      </div>
    </ContextElement>
  );
}

AccountDetailsFields.propTypes = {
  changePasswordButton: propTypes.element,
  changePasswordForm: propTypes.element,
};

export default AccountDetailsFields;
