import * as actionTypes from "./actionTypes";
import { createReducerSet } from "../system/util";
import { combineReducers } from "redux";

const phoneCodeReducer = createReducerSet(actionTypes.GET_PHONE_CODES);
const countriesReducer = createReducerSet(actionTypes.GET_COUNTRIES);
const interestsReducer = createReducerSet(actionTypes.GET_INTERESTS);
const regionsReducer = createReducerSet(actionTypes.GET_REGIONS);
const teamsReducer = createReducerSet(actionTypes.GET_TEAMS);
const registrationTypesReducer = createReducerSet(actionTypes.GET_REGISTRATION_TYPES);

const reducer = combineReducers({
  phoneCodes: phoneCodeReducer,
  countries: countriesReducer,
  interests: interestsReducer,
  regions: regionsReducer,
  teams: teamsReducer,
  registrationTypes: registrationTypesReducer,
});

export default reducer;
