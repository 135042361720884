import React, { Component } from "react";
import LoginHOC from "routes/login/LoginHOC";
import propTypes from "prop-types";
import RegisterForm from "./RegisterForm";
import RegisterHeader from "./RegisterHeader";
import RoundedButton from "components/shared/button/RoundedButton/";
import { history } from "store/history";
import navigation from "../../../navigation";
import { THEME_KEYS } from "helpers/constants";
import isMobile from "helpers/isMobile";

class Register extends Component {
  componentDidMount() {
    this.props.resetUser();
  }

  handleSubmit = formData => {
    this.props.registerUser(formData);
  }

  render() {
    const { registrationSuccess, registrationErrorMessage } = this.props;
    return (
      <div className="Register">
        {registrationSuccess ? (
          <RegisterHeader intro={`Someone will be in touch within the next 48 hours to confirm your application. 
Once approved, you will be able to get access to the portal.`} title="Registration complete" />
        ) : (
          <RegisterHeader />
        )}
        {!registrationSuccess ? (
          <RegisterForm
            onSubmit={this.handleSubmit}
            working={this.props.working}
            error={registrationErrorMessage}
            backButton={
              <RoundedButton
                title="Go back"
                type="button"
                buttonColor={!isMobile() ? THEME_KEYS.PRIMARY : ""}
                buttonTextColor={isMobile() ? THEME_KEYS.PRIMARY : ""}
                onClick={() => history.push(navigation.login)}
                modifiers={["medium", "white"]}
                invertColors
              />
            }
          />
        ) : (
          <RoundedButton
            invertColors
            buttonColor={!isMobile() ? THEME_KEYS.PRIMARY : ""}
            buttonTextColor={isMobile() ? THEME_KEYS.PRIMARY : ""}
            title={"Go back"}
            type="button"
            modifiers={["medium", "white"]}
            onClick={() => history.push(navigation.login)}
          />
        )}
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: propTypes.func.isRequired,
  working: propTypes.bool,
  registrationSuccess: propTypes.bool.isRequired,
  resetUser: propTypes.func.isRequired,
  registrationErrorMessage: propTypes.oneOfType([propTypes.string, propTypes.bool]),
};

export default LoginHOC(Register);
