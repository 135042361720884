import React from "react";
import PropTypes from "prop-types";

export const Label = ({ htmlFor, children }) => {
  return (
    <div className="Label">
      <label htmlFor={htmlFor || undefined} className="Label__label">
        {children}
      </label>
    </div>
  );
};

Label.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Label;
