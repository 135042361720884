import * as jotformActionTypes from "redux/jotform/actionTypes";
import * as jotformActions from "redux/jotform/actions";
import { put, takeEvery, call } from "redux-saga/effects";
import { mapJotFormQuestions, mapJotFormAnswers } from "helpers/Jotform";
import handleThrow from "helpers/handleThrow";

export const watchQuestionsRequestFactory = jotformService => {
  function * watchJotformRequests() {
    yield takeEvery(`${jotformActionTypes.GET_FORM_QUESTIONS}_REQUEST`, getFormQuestions);
    yield takeEvery(`${jotformActionTypes.SEND_FORM_ANSWERS}_REQUEST`, sendFormAnswers);
  }

  function * getFormQuestions(action) {
    try {
      const response = yield call(jotformService.getFormQuestions);
      const mapped = mapJotFormQuestions(response);
      yield put(jotformActions.getFormQuestions.success(mapped));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(jotformActions.getFormQuestions.fail(parsedError));
    }
  }

  function * sendFormAnswers(action) {
    try {
      const mapped = mapJotFormAnswers(action.payload);
      yield call(jotformService.sendFormAnswers, mapped);
      yield put(jotformActions.sendFormAnswers.success());
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(jotformActions.sendFormAnswers.fail({ ...parsedError }));
    }
  }

  return {
    watchJotformRequests,
    getFormQuestions,
  };
};
