import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import SelectInput from "../SelectInput";
import * as GeneralActions from "redux/general/actions";
import * as GeneralSelectors from "selectors/general";

function withTeams(WrappedComponent) {
  class Teams extends React.Component {
    componentDidMount() {
      this.props.fetchTeams();
    }

    render() {
      return <WrappedComponent {...this.props} options={!!this.props.teams && this.props.teams} />;
    }
  }

  Teams.propTypes = {
    fetchTeams: propTypes.func.isRequired,
    teams: propTypes.array.isRequired,
  };

  return Teams;
}

const mapDispatchToProps = dispatch => ({
  fetchTeams: () => dispatch(GeneralActions.getTeamsActions.request()),
});

const mapStateToProps = state => ({
  teams: GeneralSelectors.teams(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTeams(SelectInput));
