import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import ContextElement from "components/shared/elements/ContextElement";
import { IconTick } from "assets/svg/tick";
import { THEME_KEYS } from "helpers/constants";

export class CheckboxInput extends React.PureComponent {
  render() {
    const {
      name,
      label,
      id,
      type,
      mandatory,
      placeholder,
      white,
      whiteTick,
      ...props
    } = this.props;
    const derivedId = id || name;
    let values = [];
    return (
      <Field name={name}>
        {({ field: { value }, form: { setFieldValue } }) => {
          values = Array.isArray(value) ? value : Object.values(value);
          return (
            <div className="FormElement CheckboxInput">
              <input
                type="checkbox"
                name={derivedId}
                id={derivedId}
                {...props}
                checked={values.includes(props.value)}
                onChange={() => {
                  if (values.includes(props.value)) {
                    const nextValue = values.filter(
                      value => value !== props.value
                    );
                    setFieldValue(name, nextValue);
                  } else {
                    const nextValue = values.concat(props.value);
                    setFieldValue(name, nextValue);
                  }
                }}
              />
              <ContextElement
                elType="label"
                config={{
                  borderColor: THEME_KEYS.PRIMARY,
                  fill: whiteTick ? "#fff" : THEME_KEYS.PRIMARY,
                  backgroundColor: white ? "#FFF" : THEME_KEYS.PRIMARY,
                }}
                className="CheckboxInput__tickbox"
                htmlFor={derivedId}
              >
                <IconTick className="CheckboxInput__tickbox__tick" />
              </ContextElement>
              <p className="CheckboxInput__label">{label}</p>
            </div>
          );
        }}
      </Field>
    );
  }
}

CheckboxInput.defaultProps = {
  type: "checkbox",
};

CheckboxInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  mandatory: PropTypes.bool,
  placeholder: PropTypes.string,
  white: PropTypes.bool,
  whiteTick: PropTypes.bool,
};

export default CheckboxInput;
