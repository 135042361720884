export const navigation = {
  root: "/",
  login: "/login",
  register: "/register",
  registrationAccept: "/registration-accept/:hash",
  registrationReject: "/registration-reject/:hash",
  forgottenPassword: "/password-reset",
  resetPassword: "/password-reset/:hash",
  home: "/dashboard",
  profile: "/profile",
  registerStudent: "/register-student",
  digitalResources: "/digital-resources",
  news: "/news",
  usefulInformation: "/useful-information",
  calendar: "/calendar",
  contacts: "/contacts",
  browseBrands: "/browse-brands",
  needHelp: "/need-help",
  notifications: "/notifications",
  notificationSettings: "/notifications-settings",
  setBrand: "/setBrand/:brandId",
  studentAdvisors: "/student-advisors",
  notFound: "/404",
};

export default navigation;
