import React, { Component } from "react";
import monthNames from "helpers/monthNames";
import HoverComponent from "components/shared/util/Hover";
import { IconChevronRight } from "assets/svg/chevronRight";
import { IconChevronLeft } from "assets/svg/chevronLeft";
import { IconTableView } from "assets/svg/tableView";
import { IconFolderView } from "assets/svg/folderView";
import { IconSearch } from "assets/svg/search";
import propTypes from "prop-types";
import { calendarType } from "../../digitalResources/components/viewTypes";
import { Navigate } from "react-big-calendar";
import { THEME_KEYS } from "helpers/constants";
import ContextElement from "../../../../../components/shared/elements/ContextElement";

class ToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      calendarType: calendarType.MONTH,
      paramDate: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.paramDate &&
      nextProps.paramDate.getTime() !==
        (prevState.paramDate ? prevState.paramDate.getTime() : null)
    ) {
      nextProps.toolbar.onNavigate("DATE", nextProps.paramDate);
    }
    return {
      ...prevState,
      paramDate: nextProps.paramDate || null,
    };
  }

  handleSearchSubmit = event => {
    if (event.key === "Enter") {
      this.props.filterEvents(event.target.value);
    }
  };

  handleInputChange = event => {
    const value = event.target.value;
    if (!value.length) this.props.filterEvents();
  };

  getCurrentDate = () => {
    let mDate = this.props.toolbar.date;
    if (this.state.calendarType === calendarType.MONTH) {
      return monthNames[mDate.getMonth()] + ", " + mDate.getFullYear();
    } else if (this.state.calendarType === calendarType.WEEK) {
      return this.props.toolbar.label;
    }
  };

  handleNavigate = action => {
    this.props.toolbar.onNavigate(action, this.props.toolbar.date);
  };

  getCurrentYear = () => {
    let mDate = this.props.toolbar.date;
    return mDate.getFullYear();
  };

  handleViewChangeClicked = event => {
    this.setState({ calendarType: event.target.value });
    this.props.toolbar.onView(event.target.value);
  };

  render() {
    return (
      <div className="Calendar__toolbar">
        <div className="Calendar__toolbar__top">
          <p className="Calendar__toolbar__top__title">
            {"Calendar " + this.getCurrentYear()}
          </p>
          <span className="Calendar__toolbar__top__input__container">
            <IconSearch className="Calendar__toolbar__top__input__icon" />
            <ContextElement
              elType="input"
              config={{ borderColor: THEME_KEYS.PRIMARY }}
              className={
                this.state.search
                  ? "Calendar__toolbar__top__input Calendar__toolbar__top__input--active"
                  : "Calendar__toolbar__top__input"
              }
              type="search"
              placeholder="Search Events"
              onChange={this.handleInputChange}
              onKeyDown={this.handleSearchSubmit}
            />
          </span>
          <input
            className="Calendar__toolbar__top__radio"
            checked={this.state.calendarType === calendarType.WEEK}
            onChange={this.handleViewChangeClicked}
            value={calendarType.WEEK}
            type="radio"
            name="view"
            id="table"
          />
          <label
            className="Calendar__toolbar__top__radio--table"
            htmlFor="table"
          >
            <HoverComponent
              defaultFill={THEME_KEYS.PRIMARY}
              hoverFill={"secondary"}
            >
              <IconTableView />
            </HoverComponent>
          </label>
          <input
            className="Calendar__toolbar__top__radio"
            checked={this.state.calendarType === calendarType.MONTH}
            onChange={this.handleViewChangeClicked}
            value={calendarType.MONTH}
            type="radio"
            name="view"
            id="file"
          />
          <label
            className="Calendar__toolbar__top__radio--folder"
            htmlFor="file"
          >
            <HoverComponent
              defaultFill={THEME_KEYS.PRIMARY}
              hoverFill={"secondary"}
            >
              <IconFolderView />
            </HoverComponent>
          </label>
        </div>
        <div className="Calendar__toolbar__bottom">
          <div className="Calendar__toolbar__bottom__monthSelector">
            <ContextElement
              elType="button"
              config={{ borderColor: THEME_KEYS.PRIMARY }}
              onClick={() => this.handleNavigate(Navigate.PREVIOUS)}
            >
              <HoverComponent
                hoverFill={"secondary"}
                defaultFill={THEME_KEYS.PRIMARY}
              >
                <IconChevronLeft />
              </HoverComponent>
            </ContextElement>
            <p>{this.getCurrentDate()}</p>
            <ContextElement
              elType="button"
              config={{ borderColor: THEME_KEYS.PRIMARY }}
              onClick={() => this.handleNavigate(Navigate.NEXT)}
            >
              <HoverComponent
                hoverFill={"secondary"}
                defaultFill={THEME_KEYS.PRIMARY}
              >
                <IconChevronRight />
              </HoverComponent>
            </ContextElement>
          </div>
          {/* <div className="Calendar__toolbar__bottom__tagFilter"> */}
          {/*  <p>Tags</p> */}
          {/*  <Formik */}
          {/*    render={_ => ( */}
          {/*      <Field */}
          {/*        placeholder="Filter by..." */}
          {/*        name="filter" */}
          {/*        component={SelectInput} */}
          {/*        modifiers={["white"]} */}
          {/*        className="Calendar__toolbar__dropdown" */}
          {/*      /> */}
          {/*    )} */}
          {/*  /> */}
          {/* </div>} */}
        </div>
      </div>
    );
  }
}

ToolBar.propTypes = {
  toolbar: propTypes.object,
  filterEvents: propTypes.func,
  paramDate: propTypes.object,
};

export default ToolBar;
