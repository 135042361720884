import React, { useState } from "react";
import propTypes from "prop-types";
import QuickGuidePopup from "../QuickGuidePopup";
import { animated, useTransition } from "react-spring";
import imageNeedHelpBrands from "assets/needHelp_brands.png";
import imageNeedHelpCal from "assets/needHelp_calendar.png";
import imageNeedHelpContacts from "assets/needHelp_contacts.png";
import imageNeedHelpDash from "assets/needHelp_dashboard.png";
import imageNeedHelpHelp from "assets/needHelp_help.png";
import imageNeedHelpNews from "assets/needHelp_news.png";
import imageNeedHelpNotif from "assets/needHelp_notifications.png";
import imageNeedHelpProfile from "assets/needHelp_profile.png";
import imageNeedHelpRes from "assets/needHelp_resources.png";
import imageNeedHelpSel from "assets/needHelp_selectbrand.png";

function QuickGuideOverlay({ popups, finishedCallback }) {
  const [index, setIndex] = useState(0);
  if (index === popups.length) {
    finishedCallback && finishedCallback();
  } // If there are no more popups to show, let the parent know.

  const positionBasedOnCurrentPopup = XY => ({
    [popups[index] && popups[index].popupAnchor[`${XY}AxisScreen`]]:
      `${popups[index] ? popups[index][XY] : 0}px`,
  }); // example output of this function : { left: 80px } |OR| { top: 100px } |ETC|

  const providePosiitons = {
    ...positionBasedOnCurrentPopup("x"), // Plug the "X" position, starting poiint either at left or right.
    ...positionBasedOnCurrentPopup("y"), // Plug the "Y" posiiton, starting poiint either top or bottom.
  };

  const imageArray = [
    imageNeedHelpSel,
    imageNeedHelpDash,
    imageNeedHelpRes,
    imageNeedHelpNews,
    imageNeedHelpCal,
    imageNeedHelpContacts,
    imageNeedHelpBrands,
    imageNeedHelpHelp,
    imageNeedHelpProfile,
    imageNeedHelpNotif,
  ];

  const transitions = useTransition(index, null, {
    from: {
      opacity: 0,
      ...providePosiitons, // Previous popup posiiton
    },
    enter: {
      opacity: 1,
      ...providePosiitons, // Current popup's posiiton
    },
    leave: {
      opacity: 0,
      ...providePosiitons, // The next popup's posiiton
    },
  });
  return (
    <div className="QuickGuideOverlay">
      {transitions.map(({ item, props, key }) =>
        <animated.div
          key={key}
          style={props}
          className="QuickGuidePopup"
        >
          {popups && popups[item] && (
            <QuickGuidePopup
              arrowPointing={popups[item].arrowPointing}
              heading={popups[item].heading}
              content={popups[item].content}
              image={imageArray[index]}
              goToNext={() => setIndex(index + 1)}
              skipToEnd={() => setIndex(popups.length)}
              index={index}
              total={popups.length}
            />
          )}
        </animated.div>
      )}
    </div>
  );
};

QuickGuideOverlay.propTypes = {
  popups: propTypes.array.isRequired,
  finishedCallback: propTypes.func,
};

export default QuickGuideOverlay;
