import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import SingleIconButton from "components/shared/button/SingleIconButton/";
import ContextElement from "components/shared/elements/ContextElement";
import RoundedButton from "components/shared/button/RoundedButton/";
import NeedHelpContactForm from "./NeedHelpContactForm";
import RepliesWithin from "./RepliesWithin";
import { Image } from "react-image-and-background-image-fade";
import { IconSend } from "assets/svg/send";
import { THEME_KEYS } from "helpers/constants";
import ContactService from "services/contact/contactService";
import { IconPlus } from "assets/svg/plus";
import handleThrow from "helpers/handleThrow";
import Loader from "components/shared/loader";

const NeedHelpBanner = props => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const toggleFormOpen = () => setIsFormOpen(!isFormOpen);

  useEffect(() => {
    const handleFormSubmit = data => {
      const contactService = new ContactService();
      const { brandId } = props;
      try {
        const response = contactService.submitContactForm({ data, brandId });
        response.then(res => {
          if (res.data) {
            setIsFormOpen(false);
            setSubmitSuccess(true);
            setFormData(null);
          } else {
            throw res;
          }
        });
      } catch (error) {
        handleThrow(error);
      }
    };

    if (formData) {
      handleFormSubmit(formData);
    }
  }, [props.brandId, formData, props]);

  return (
    <div className={classnames(["NeedHelpBanner", { "NeedHelpBanner--formOpen": isFormOpen }])}>
      <ContextElement
        elType="div"
        config={{ backgroundColor: THEME_KEYS.PRIMARY }}
        className="NeedHelpBanner__imageWrap"
      >
        <Image width="100%" wrapperClassName="NeedHelpBanner__imageWrap__image" src={props.faqImage} alt={""}
          renderLoader={({ hasLoaded, hasFailed }) =>
            <Loader hasFailed={hasFailed} hasLoaded={hasLoaded} onHasFailed={props.getBrands} />
          }
        />
        {isFormOpen && <RepliesWithin />}
      </ContextElement>
      <div className="NeedHelpBanner__details">
        <SingleIconButton
          Icon={IconPlus}
          className="NeedHelpBanner__details__closeButton"
          modifiers={["white"]}
          onClick={toggleFormOpen}
        />
        <ContextElement
          elType="p"
          className="NeedHelpBanner__details__contactText lightHeaderSansSerif"
          config={{ color: THEME_KEYS.SECONDARY }}
        >
          {submitSuccess ? "SUPPORT TEAM" : "Need More Help?"}
        </ContextElement>
        <h2 className="NeedHelpBanner__details__heading">
          {submitSuccess ? "Your message has been sent successfully" : "Contact our Support Team"}
        </h2>
        {isFormOpen && !submitSuccess && <NeedHelpContactForm handleSubmitForm={setFormData} />}
        {!isFormOpen && !submitSuccess && (
          <RoundedButton
            onClick={toggleFormOpen}
            buttonClass="NeedHelpBanner__details__button"
            modifiers={["medium", "is-form-closed", "white"]}
            invertColors
            buttonColor={"#9b9b9b"}
            buttonTextColor={"#9b9b9b"}
            title={"Get In Touch"}
            type={"button"}
            Icon={IconSend}
            iconRight={false}
          />
        )}
      </div>
    </div>
  );
};

NeedHelpBanner.propTypes = {
  faqImage: propTypes.string,
  brandId: propTypes.number,
  getBrands: propTypes.func,
};

export default NeedHelpBanner;
