import React from "react";

function ForgottenPasswordHeader() {
  return (
    <div className="LoginHeader">
      <h1 className="LoginHeader__h1">Confirm email address</h1>
      <p className="LoginHeader__intro">
        Forgotten your password? Enter your email address below, and we'll email instructions for setting a new one.
      </p>
    </div>
  );
}

export default ForgottenPasswordHeader;
