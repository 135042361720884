import { GET_USEFUL_INFORMATION, SELECTED_USEFUL_INFORMATION } from "redux/usefulInformation/actionTypes";
import usefulInformationActions from "redux/usefulInformation/actions";
import { put, takeEvery, call, select } from "redux-saga/effects";
import * as brandsSelectors from "selectors/brands";
import * as usefulInformationSelectors from "selectors/usefulInformation";
import handleThrow from "helpers/handleThrow";
import waitFor from "./waitFor";

export const watchUsefulInformationRequestFactory = usefulInformationService => {
  function * watchUsefulInformationRequests() {
    yield takeEvery(`${GET_USEFUL_INFORMATION}_REQUEST`, usefulInformationRequest);
    yield takeEvery(`${SELECTED_USEFUL_INFORMATION}_REQUEST`, selectedArticleRequest);
  }

  function * usefulInformationRequest(action) {
    try {
      const brandId = yield select(brandsSelectors.selectedBrandId);
      const response = yield call(usefulInformationService.getUsefulInformation, { brandId });
      yield put(usefulInformationActions.getUsefulInformationActions.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(usefulInformationActions.getUsefulInformationActions.fail(parsedError));
    }
  }

  function * selectedArticleRequest(action) {
    try {
      let usefulInformation = yield select(usefulInformationSelectors.usefulInformationSelector);
      if (!usefulInformation || !usefulInformation.length) {
        yield put(usefulInformationActions.getUsefulInformationActions.request());
        usefulInformation = yield call(waitFor, usefulInformationSelectors.usefulInformationSelector);
      }
      const selectedArticle = usefulInformation
        .filter(article => Number(article.id) === Number(action.payload.id))[0];
      yield put(usefulInformationActions.getSelectedArticleActions.success(selectedArticle));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(usefulInformationActions.getSelectedArticleActions.fail(parsedError));
    }
  }

  return {
    watchUsefulInformationRequests,
    usefulInformationRequest,
    selectedArticleRequest,
  };
};
