import { connect } from "react-redux";
import {
  getNotificationsActions,
  readNotificationActions,
} from "redux/notifications/actions";
import * as notificationSelector from "selectors/notifications";
import * as brandsSelector from "selectors/brands";

import Notifications from "./../components/";

/* eslint-disable */
const mapDispatchToProps = dispatch => ({
  getNotifications: () => dispatch(getNotificationsActions.request()),
  setNotificationRead: id => dispatch(readNotificationActions.request(id))
});
/* eslint-enable */

const mapStateToProps = state => ({
  notifications: notificationSelector.allNotifications(state),
  brandById: Id => brandsSelector.brandById(Id)(state),
  working: notificationSelector.isWorking(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
