import React, { useState, useRef } from "react";
import propTypes from "prop-types";
import cn from "classnames";

import useEventListener from "hooks/useEventListener";

const Search = ({
  containerClassName,
  inputClassName,
  name,
  id,
  placeholder,
  onChange,
  onKeyDown,
  IconComponent,
  IconClassName,
}) => {
  const [searchState, setSearchState] = useState(false);
  let searchBoxRef = useRef();
  const handleMouseDown = (event) => {
    if (event.target.id === id) {
      setSearchState(true);
    } else {
      setSearchState(false);
    }
  };

  const handleIconClick = () => {
    setSearchState(true);
    searchBoxRef.current.focus();
  };

  useEventListener("mousedown", handleMouseDown);
  return (
    <div className={cn("Search__container", containerClassName,
      { "Search__container--active": searchState },
      { "Search__container--not-active": !searchState }
    )}>
      <button onClick={handleIconClick}>
        <IconComponent className={IconClassName} />
      </button>
      <input
        ref={searchBoxRef}
        className={inputClassName}
        type="search"
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

Search.propTypes = {
  onChange: propTypes.func,
  onKeyDown: propTypes.func,
  inputClassName: propTypes.string,
  name: propTypes.string,
  id: propTypes.string.isRequired,
  placeholder: propTypes.string,
  IconComponent: propTypes.func,
  IconClassName: propTypes.string.isRequired,
  containerClassName: propTypes.string,
};

export default Search;
