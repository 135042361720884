import React, { Component } from "react";
import BrandsSlider from "./BrandsSlider/BrandsSlider";
import propTypes from "prop-types";
import Loader from "components/shared/loader";

class BrowseBrands extends Component {
  componentDidMount() {
    this.props.getBrands();
  }

  render() {
    const { brands, setSelectedBrand, working } = this.props;
    return (
      <div className="BrowseBrands animated fadeIn">
        <div className="BrowseBrands__header">
          <h1>Looking for a different brand?</h1>
          <p>
          There are many different brands that make up our group.
          To find out more about them, simply click on the brand logos below.
          </p>
        </div>
        {!working ? (
          brands &&
          !!brands.length && (
            <BrandsSlider
              working={working}
              selectBrand={setSelectedBrand}
              brandList={brands}
            />
          )
        ) : (
          <div className="BrowseBrands__loader">
            <Loader className="Loader--noBG" />
          </div>
        )}
      </div>
    );
  }
}

BrowseBrands.propTypes = {
  setSelectedBrand: propTypes.func,
  brands: propTypes.array,
  getBrands: propTypes.func,
  working: propTypes.bool,
};

export default BrowseBrands;
