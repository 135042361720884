import React from "react";
import propTypes from "prop-types";
/* eslint max-len: 0 */
export const IconWeChat = ({ fill = "#fff" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
      <defs>
        <path id="a" d="M0 .007h13.893v13.517H0z" />
        <path id="c" d="M.004.006h11.915v10.886H.004z" />
      </defs>
      <g fillRule="evenodd">
        <g fill="inherit" transform="translate(0 .002)">
          <mask id="a" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill="inherit"
            d="M9.807 4.065c-.458.009-.845.399-.837.844a.836.836 0 0 0 .86.816.82.82 0 0 0 .825-.841.826.826 0 0 0-.848-.819m-4.457.857c.017-.444-.367-.843-.823-.856a.831.831 0 0 0-.866.803.821.821 0 0 0 .814.853.845.845 0 0 0 .875-.8m8.543.755c-1.856.097-3.47.66-4.78 1.93-1.325 1.285-1.93 2.86-1.764 4.81-.726-.09-1.386-.188-2.051-.244-.23-.02-.502.008-.696.117-.646.365-1.264.776-1.998 1.234.135-.608.222-1.141.376-1.654.113-.377.061-.586-.286-.832C.466 9.465-.474 7.111.229 4.687.88 2.444 2.477 1.084 4.647.376c2.962-.968 6.29.019 8.091 2.37.65.85 1.05 1.803 1.155 2.93"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(8.077 6.36)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            fill="inherit"
            d="M7.609 3.033a.692.692 0 0 0-.687.661.68.68 0 0 0 .678.716.674.674 0 0 0 .685-.657.687.687 0 0 0-.676-.72M3.295 4.414a.693.693 0 0 0 .697-.662.696.696 0 0 0-.692-.72.704.704 0 0 0-.7.716.698.698 0 0 0 .695.666m6.691 6.478c-.588-.261-1.127-.654-1.7-.714-.573-.06-1.174.27-1.772.331-1.822.187-3.455-.321-4.801-1.566-2.56-2.368-2.195-6 .767-7.94 2.633-1.725 6.494-1.15 8.35 1.243 1.62 2.089 1.43 4.86-.548 6.615-.572.508-.778.926-.41 1.595.067.124.075.28.114.436"
            mask="url(#d)"
          />
        </g>
      </g>
    </svg>
  );
};

IconWeChat.propTypes = {
  fill: propTypes.string,
};
