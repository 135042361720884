import React from "react";
import { Formik, Field } from "formik";
import propTypes from "prop-types";
import * as Yup from "yup";
import TextInput from "components/formElements/TextInput";
import FormError from "components/formElements/FormError";
import RoundedButton from "components/shared/button/RoundedButton";
import * as commonValidators from "helpers/commonValidators";
import isMobile from "helpers/isMobile";
import { THEME_KEYS } from "helpers/constants";

export class ResetPasswordForm extends React.Component {
  render() {
    const { handleSubmit, error, successMessage, failMessage } = this.props;
    return (
      <div className="FormWrapper LoginForm">
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form className="Form LoginForm__form" onSubmit={handleSubmit} noValidate>
              <div className="Form__inner">
                <div className="Form__section">
                  <Field
                    name="password"
                    label="New Password"
                    placeholder={isMobile() ? "New Password" : ""}
                    type="password"
                    mandatory
                    component={TextInput}
                  />
                  <Field
                    name="passwordConfirmation"
                    label="Verify Password"
                    placeholder={isMobile() ? "Verify Password" : ""}
                    type="password"
                    mandatory
                    component={TextInput}
                  />
                  {successMessage && <span className="Form__message Form__message--success">{successMessage}</span>}
                  {failMessage && (
                    <span className="Form__message Form__message--fail animated shake">{failMessage}</span>
                  )}
                </div>
                <RoundedButton
                  buttonColor={THEME_KEYS.PRIMARY}
                  title={"Change my password"}
                  type={"submit"}
                  modifiers={["large", "white"]}
                />
              </div>
            </form>
          )}
        />
        {!!error && <FormError message={error} />}
      </div>
    );
  }
}

const validationSchema = Yup.object().shape({
  password: commonValidators.isRequired("Password"),
  passwordConfirmation: commonValidators.hasMatchingField("password"),
});

ResetPasswordForm.propTypes = {
  handleSubmit: propTypes.func.isRequired,
  error: propTypes.string,
  successMessage: propTypes.string,
  failMessage: propTypes.string,
};

export default ResetPasswordForm;
