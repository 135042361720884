import React from "react";
import { Field } from "formik";
import { TeamsContainer } from "components/formElements/fieldContainers";

function TeamFields() {
  return (
    <Field
      placeholder="Team"
      name="team"
      id="team"
      mandatory
      component={TeamsContainer}
      label="Company Profile"
      modifiers={["white", "grey-border"]}
    />
  );
}

TeamFields.propTypes = {
};

export default TeamFields;
