import React from "react";
import SidebarComponent from "./SidebarComponent";
import propTypes from "prop-types";

export class Sidebar extends React.PureComponent {
  render() {
    return (
      <SidebarComponent
        selectedBrand={this.props.selectedBrand}
        isOpen={this.props.isOpen}
        toggleSideBarOpen={this.props.toggleSideBarOpen}
        newsLoading={this.props.newsLoading}
        displayAppForm={this.props.displayAppForm}
        showNews={this.props.showNews}
        showUsefulInformation={this.props.showUsefulInformation}
      />
    );
  }
}
Sidebar.defaultProps = {
  displayAppForm: false,
};
Sidebar.propTypes = {
  isOpen: propTypes.bool,
  toggleSideBarOpen: propTypes.func,
  newsLoading: propTypes.bool,
  displayAppForm: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  selectedBrand: propTypes.object,
  showNews: propTypes.bool,
  showUsefulInformation: propTypes.bool,
};

export default Sidebar;
