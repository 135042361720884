import { createSelector } from "reselect";
import { selectedBrand } from "./brands";

export const eventsSelector = state => state.events.results || [];

export const eventsList = searchParam => {
  return createSelector(eventsSelector, selectedBrand, (items, brand) => {
    let eventList = [];
    if (items && items.length) {
      eventList = Array.from(items).map(item => {
        return {
          title: item.name || null,
          brand: brand ? brand.id : null,
          location: item.location || null,
          details: item.description || null,
          backgroundColor:
            item.colour || (brand ? brand.primaryColourHex : "#000000"),
          start: item.start_datetime
            ? new Date(item.start_datetime.replace(/\+00:00/, ""))
            : null,
          end: item.end_datetime
            ? new Date(item.end_datetime.replace(/\+00:00/, ""))
            : null,
          allDay: item.all_day || null,
          id: item.id || null,
          type: item.type || null,
        };
      });
    }
    return searchParam
      ? eventList.filter(
        event =>
          event.title.toLowerCase().includes(searchParam.toLowerCase()) ||
            event.details.toLowerCase().includes(searchParam.toLowerCase())
      )
      : eventList;
  });
};
