import React, { Component } from "react";
import propTypes from "prop-types";
import BrandDropdown from "components/shared/brandDropdown";
import HeaderBanner from "./HeaderBanner";
import ActionButtons from "./ActionButtons";
import BrandLogo from "./BrandLogo";
import * as MODIFIERS from "styles/styledModifiers";
import navigation from "../../../navigation";

export class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: null,
    };
  }

  componentDidMount() {
    this.props.getNotifications();
    this.props.getBrands();
  }

  setOpenMenu = (value = null) => {
    this.setState({ openMenu: value });
  };

  /**
   * Handle closing any open header menus if the user clicks on another or outside the menu
   *  -- ignore the event if the user clicks on an header menu
   */
  handleOffMenuClick = event => {
    if (
      event.target.classList &&
      event.target.classList[0] &&
      !event.target.classList[0].includes("HeaderProfile") &&
      !event.target.classList[0].includes("HeaderNotification") &&
      !event.target.classList[0].includes("HeaderSearch")
    ) {
      this.setOpenMenu();
    }
  };

  render() {
    const {
      selectedBrand,
      toggleSideBarOpen,
      user,
      history,
      isUnread,
      setAllNotificationsRead,
      setNotificationRead,
      notifications,
      logout,
      getUser,
      getBrands,
    } = this.props;
    return (
      <HeaderBanner
        selectedBrand={selectedBrand}
        history={history}
        setOpenMenu={this.setOpenMenu}
        handleOffMenuClick={this.handleOffMenuClick}
        toggleSideBarOpen={toggleSideBarOpen}
      >
        {selectedBrand && selectedBrand.image && (
          <BrandLogo
            logoImage={selectedBrand.image}
            brandName={selectedBrand.name}
            logoFailedLoad={getBrands}
          />
        )}
        <BrandDropdown
          modifiers={[
            MODIFIERS.LONG,
            MODIFIERS.SMALL,
            MODIFIERS.NO_MARGIN,
            MODIFIERS.ARROW_DROPDOWN,
            MODIFIERS.SELECTED_TRANSPARENT,
            MODIFIERS.BOLD,
          ]}
        />
        {
          selectedBrand && history && history.location.pathname &&
          history.location.pathname === navigation.registerStudent
            ? (
              <BrandLogo className="MainHeader__image MainHeader__image--appForm"
                logoImage={selectedBrand.image} brandName={selectedBrand.name} />
            ) : (
              <div />
            )
        }
        <ActionButtons
          getUser={getUser}
          user={user}
          searchLocation={
            history && history.location && history.location.pathname
          }
          brandById={this.props.brandById}
          setSelectedBrand={this.props.setSelectedBrand}
          isUnread={isUnread}
          menuIsOpen={this.state.openMenu}
          setOpenMenu={this.setOpenMenu}
          setNotificationRead={setNotificationRead}
          notifications={notifications}
          handleLogout={logout}
          setAllNotificationsRead={setAllNotificationsRead}
        />
      </HeaderBanner>
    );
  }
}

MainHeader.propTypes = {
  logout: propTypes.func,
  toggleSideBarOpen: propTypes.func,
  selectedBrand: propTypes.object,
  user: propTypes.object,
  history: propTypes.object,
  isUnread: propTypes.bool,
  setNotificationRead: propTypes.func,
  getNotifications: propTypes.func,
  brandById: propTypes.func,
  notifications: propTypes.array,
  setAllNotificationsRead: propTypes.func,
  getUser: propTypes.func,
  getBrands: propTypes.func,
  setSelectedBrand: propTypes.func,
};

export default MainHeader;
