import React, { PureComponent } from "react";
import propTypes from "prop-types";
import SelectInput from "components/formElements/SelectInput";
import { Formik, Field } from "formik";
import { withTheme } from "styled-components";

class SortDropDown extends PureComponent {
  handleOptionSelection = selection => {
    if (selection.constructor === Number) {
      this.props.setSelectedOption(selection);
    }
  };

  render() {
    const { options, selectedOption, modifiers, theme } = this.props;
    return (
      <Formik
        initialValues={{
          id: selectedOption ? selectedOption.id : null,
          name: selectedOption ? selectedOption.name : null,
        }}
        enableReinitialize
        render={_ => (
          <div className="SortDropDown">
            <Field
              options={
                options &&
                options.map(({ name, id }) => ({
                  name,
                  value: id,
                }))
              }
              placeholder="Select option"
              name="sort"
              component={SelectInput}
              onSelect={this.handleOptionSelection}
              modifiers={modifiers}
              config={{
                borderColor: theme.secondary,
                "arrow-fill": theme.secondary,
              }}
            />
          </div>
        )}
      />
    );
  }
}

SortDropDown.propTypes = {
  selectedOption: propTypes.object,
  options: propTypes.array,
  modifiers: propTypes.array,
  setSelectedOption: propTypes.func,
  theme: propTypes.object,
};

export default withTheme(SortDropDown);
