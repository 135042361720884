import React from "react";
import propTypes from "prop-types";
import BrandSlideButton from "./BrandSlideButton";
import Carousel from "./Carousel";

function BrandSwiperComponent({
  handleBrandClicked,
  currentBrand,
  brands,
  state,
  handlers,
  numItems,
}) {
  return (
    <div className="BrandSwiperComponent">
      {brands && Array.isArray(brands) && !!brands.length ? (
        <Carousel
          state={state}
          handlers={handlers}
          numItems={numItems}
        >
          { brands.map((brand, index) => (
            <BrandSlideButton
              key={brand.name}
              brandName={brand.name}
              brandImage={brand.logoImage}
              handleBrandClicked={() => handleBrandClicked(index)}
              isSelected={currentBrand === index}
            />
          ))}
        </Carousel>
      ) : (
        <span className="BrandSwiperComponent__noBrands lightHeaderSansSerif">There are no brands.</span>
      )}
    </div>
  );
}

BrandSwiperComponent.propTypes = {
  handleBrandClicked: propTypes.func,
  currentBrand: propTypes.number,
  brands: propTypes.array,
  state: propTypes.object,
  handlers: propTypes.object,
  numItems: propTypes.number,
};

export default BrandSwiperComponent;
