import React from "react";
import RoundedButton from "components/shared/button/RoundedButton/";
import { withRouter, Link } from "react-router-dom";
import { THEME_KEYS } from "helpers/constants";
import navigation from "../../navigation";

const SidebarCallToAction = () =>
  <Link to={navigation.registerStudent} className="SidebarCallToAction">
    <RoundedButton
      title="Application Form"
      buttonColor={THEME_KEYS.SECONDARY}
      buttonClass="SidebarCallToAction__button"
      modifiers={["small"]}
    />
  </Link>;

export default withRouter(SidebarCallToAction);
