import API from "../API";

let instance = null;

class BrandsService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  getAllBrands = async() => this.http.get("brand").json();
}

export default BrandsService;
