export default function({
  payload: {
    emailAddress,
    password,
    firstName,
    lastName,
    areaCode,
    contactNumber,
    address1,
    address2,
    townCity,
    postcode,
    company,
    country,
    regions,
    mainRegion,
    mainInterest,
    additionalInterests,
    team,
    registrationType,
  },
}) {
  return {
    email: emailAddress,
    password,
    firstName,
    lastName,
    phoneCode: parseInt(areaCode, 10) || null,
    phoneNumber: parseInt(contactNumber, 10) || null,
    address1,
    address2,
    town: townCity,
    postCode: postcode.toUpperCase(),
    companyName: company,
    country: parseInt(country),
    regions,
    mainRegion,
    mainInterest,
    additionalInterests,
    team,
    registrationType: registrationType,
  };
}
