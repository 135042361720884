/*eslint-disable */

import ky from "ky/umd";
import * as constants from "helpers/constants";
import navigation from "./../navigation";
import config from "./../config";
import LocalStore from "./auth/localStore";
import { history } from "./../store/history";

export default class API {
  http;
  static requestStack = [];
  static isRefreshing = false;

  constructor() {
    this.http = ky.create({
      prefixUrl: config.APIUrl,
      headers: {
        "Content-Type": "application/json"
      },
      hooks: {
        afterResponse: [this.handleAfterResponse],
        beforeRequest: [this.handleBeforeRequest]
      }
    });
  }

  handleAfterResponse = (url, options, response) => {
    if (
      response.status === constants.HTTP_CODES.UNAUTH &&
      url.includes("login")
    )
      return;
    if (
      response.status === constants.HTTP_CODES.UNAUTH &&
      !url.includes("refresh-token") &&
      !url.includes("logout")
    ) {
      if (!API.isRefreshing) {
        API.isRefreshing = true;
        this._refreshToken();
      }
      return new Promise(resolve => {
        this._pushRequestToStack(token => {
          if (token) {
            options.headers.set("Authorization", `Bearer ${token}`);
            options.retry.methods = [...options.retry.methods];
            options.retry.statusCodes = [...options.retry.statusCodes];
            options.prefixUrl = "";
            resolve(ky(url, options));
          }
        });
      });
    }
  };

  handleBeforeRequest = (url, options, error, retryCount) => {
    if (
      !url.includes("login") &&
      !url.includes("refresh-token") &&
      !url.includes("register")
    ) {
      if (API.isRefreshing) {
        return new Promise(resolve => {
          this._pushRequestToStack(token => {
            if (token) {
              options.headers.set("Authorization", `Bearer ${token}`);
              options.retry.methods = [...options.retry.methods];
              options.retry.statusCodes = [...options.retry.statusCodes];
              options.prefixUrl = "";
              resolve(ky(url, options));
            }
          });
        });
      } else {
        if (LocalStore.getAccessToken()) {
          options.headers.set(
            "Authorization",
            `Bearer ${LocalStore.getAccessToken()}`
          );
        }
      }
    }
  };

  /**
   * Call to refresh the users access token and then set it in localstorage and update
   * defaults with new token.
   *
   * @author David van Zyl
   * @author Oscar Hilton
   */
  async _refreshToken() {
    await this.refreshAccessToken(LocalStore.getRefreshToken())
      .then(response => {
        this._onTokenRefreshed(response);

        API.requestStack = [];
      })
      .catch(err => {
        API.isRefreshing = false;
        LocalStore.removeUserToken();
        history.push(navigation.login);
      });
  }

  _pushRequestToStack = cb => {
    API.requestStack.push(cb);
  };

  _onTokenRefreshed = response => {
    LocalStore.setUserToken(response.data);
    API.requestStack.map(cb => cb(response.data.access_token));
    API.isRefreshing = false;
  };

  refreshAccessToken = async () => {
    return this.http
      .post("refresh-token", {
        json: {
          refresh_token: LocalStore.getRefreshToken()
        }
      })
      .json();
  };
}
