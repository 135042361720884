import { connect } from "react-redux";
import Calendar from "../components/Calendar";
import * as eventsActions from "redux/events/actions";
import * as eventsSelector from "selectors/events";
import * as brandsSelector from "selectors/brands";

const mapDispatchToProps = dispatch => ({
  getEvents: params => dispatch(eventsActions.getEvents.request(params)),
});

const mapStateToProps = state => ({
  events: searchParam => eventsSelector.eventsList(searchParam)(state),
  brandById: Id => brandsSelector.brandById(Id)(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);
