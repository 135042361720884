import React from "react";
import propTypes from "prop-types";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";

function BrandsSliderTitle({ title = "Brands", preTitle = "SELECT FROM OUR" }) {
  return (
    <div className="BrandsSlider__toolbar__title">
      <ContextElement elType="p" config={{
        color: THEME_KEYS.SECONDARY,
      }} className="lightHeaderSansSerif">
        {preTitle}
      </ContextElement>
      <h1>{title}</h1>
    </div>
  );
};

BrandsSliderTitle.propTypes = {
  title: propTypes.string,
  preTitle: propTypes.string,
};

export default BrandsSliderTitle;
