import API from "../API";

let instance = null;

class DocumentsService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  getQuickAccess = brandId => {
    return this.http.get(`quick/brand/${brandId}`).json();
  };

  setQuickAccess = ({ fileId, type }) => {
    return this.http.put(`quick/${type}/${fileId}`).json();
  };

  removeQuickAccess = ({ fileId, type }) => {
    return this.http.delete(`quick/${type}/${fileId}/delete`);
  };

  getDocumentRoot = brandId => {
    return this.http.get(`directory/brand/${brandId}/browse`).json();
  };

  getDocumentDirectory = directoryId => {
    return this.http.get(`directory/browse/${directoryId}`).json();
  };

  searchDocuments = ({ brand, filterParam }) => {
    return this.http.get(`document/search/brand/${brand}/${filterParam}`).json();
  };

  download = async(id, isFolder) => {
    if (isFolder) {
      return this.http.get(`directory/download/${id}`, {
        responseType: "arrayBuffer",
      }).arrayBuffer();
    } else {
      return this.http
        .get(`document/${id}`)
        .json();
    }
  };
}

export default DocumentsService;
