import API from "../API";

let instance = null;

class ContactsService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getBrandContacts = params => {
    return this.http.get(`contact/brand/${params}`).json();
  };

  getMainContact = ({ brandId, regionId }) => {
    return this.http.get(`contact/brand/${brandId}/region/${regionId}/main`).json();
  };
}

export default ContactsService;
