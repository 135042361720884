import { initialState } from "./../system/util";
import { FP } from "./actionTypes";

const forgotPasswordReducer = (state = initialState, action) => {
  if (action.type.includes(FP)) {
    switch (true) {
      case action.type.includes("_REQUEST"):
        return {
          ...state,
          working: true,
          failed: {
            hasFailed: false,
          },
        };
      case action.type.includes("_SUCCESS"):
        const results = action.payload;
        return {
          working: false,
          results: results,
          failed: {
            hasFailed: false,
          },
        };
      case action.type.includes("_FAIL"):
        const error = action.payload;
        return {
          ...state,
          working: false,
          failed: {
            error: error,
            hasFailed: true,
          },
        };
      default:
        return state;
    }
  }
  return state;
};

export default forgotPasswordReducer;
