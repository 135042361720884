import * as dashboardActionTypes from "redux/dashboard/actionTypes";
import * as dashboardDataActions from "redux/dashboard/actions";
import { put, takeEvery, call, select } from "redux-saga/effects";
import * as brandsSelector from "selectors/brands";
import * as dashboardSelector from "selectors/dashboard";
import * as newsActions from "redux/news/actions";
import handleThrow from "helpers/handleThrow";
import usefulInformationActions from "redux/usefulInformation/actions";

export const watchDashboardRequestFactory = dashboardService => {
  function * watchDashboardRequest() {
    yield takeEvery(
      `${dashboardActionTypes.DASHBOARD_DATA}_REQUEST`,
      getDashboardDataRequest
    );
    yield takeEvery(
      `${dashboardActionTypes.DASHBOARD_DATA}_SUCCESS`,
      getDashboardDataSuccess
    );
  }

  function * getDashboardDataRequest(action) {
    try {
      const brandId = yield select(brandsSelector.selectedBrandId);
      const dashboardBrandId = yield select(dashboardSelector.dashboardBrandId);
      if (brandId && dashboardBrandId !== brandId) {
        const response = yield call(dashboardService.getBrandDashboard, brandId);
        yield put(dashboardDataActions.dashboardDataActions.success(response.data));
        if (
          action.payload.callback &&
          typeof action.payload.callback === "function"
        ) {
          action.payload.callback();
        }
      } else {
        throw new Error("No Brand");
      }
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(dashboardDataActions.dashboardDataActions.fail(parsedError));
    }
  }

  function * getDashboardDataSuccess(action) {
    try {
      const brand = yield select(brandsSelector.selectedBrand);
      if (brand && brand.blogUrl) {
        yield put(newsActions.allNewsActions.request());
        yield put(newsActions.usefullInformationActions.request());
      }
      yield put(usefulInformationActions.getUsefulInformationActions.request());
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(dashboardDataActions.dashboardDataActions.fail(parsedError));
    }
  }

  return {
    watchDashboardRequest,
    getDashboardDataRequest,
  };
};
