import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Camera } from "assets/svg/camera";
import { Controls } from "./Avatar.css";
import Dropzone from "../Dropzone";
import { Image } from "react-image-and-background-image-fade";
import Loader from "components/shared/loader";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "../../../helpers/constants";

function Avatar({ image, size, canEdit, onImageSelect, initials }) {
  const [dropIsFocused, setDropFocus] = useState(false);
  const [isLoading, setLoading] = useState(false);

  return (
    <div className={cn("Avatar", { [`Avatar--${size}`]: size })}>
      <div
        className={cn("Avatar__imageContainer", {
          "Avatar__imageContainer--dropPhoto": dropIsFocused || isLoading,
          "Avatar__imageContainer--loading": isLoading,
        })}
      >
        {image ? (
          <Image
            lazyLoad
            wrapperClassName="Avatar__imageContainer__image"
            src={image}
            alt={"Profile"}
            renderLoader={({ hasLoaded, hasFailed }) => (
              <Loader hasFailed={hasFailed} hasLoaded={hasLoaded} />
            )}
            transitionTime="0.5s"
          />
        ) : (
          <ContextElement
            elType="div"
            config={{ backgroundColor: THEME_KEYS.SECONDARY_EIGHTY }}
            className="Avatar__imageContainer__initials"
          >
            <h1>{initials}</h1>
          </ContextElement>
        )}
      </div>
      {canEdit && (
        <Controls
          isLoading={isLoading}
          className={cn("Avatar__controls", "Avatar__controls--changePhoto", {
            "Avatar__controls--dropPhoto": dropIsFocused,
            "Avatar__controls--loading": isLoading,
          })}
        >
          <Camera
            className={cn("Avatar__controls__icon", {
              "Avatar__controls__icon--hide": isLoading,
            })}
          />
          <Dropzone
            fillParent
            setDropFocus={setDropFocus}
            setLoading={setLoading}
            multiple={false}
            onDropSuccess={result => {
              !!onImageSelect && onImageSelect(result[0].base64);
            }}
            base64
          />
        </Controls>
      )}
    </div>
  );
}
Avatar.defaultProps = {
  image: "",
};

Avatar.propTypes = {
  image: PropTypes.string,
  size: PropTypes.string,
  canEdit: PropTypes.bool,
  onImageSelect: PropTypes.func,
  initials: PropTypes.string,
};

export default Avatar;
