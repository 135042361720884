import API from "../API";

let instance = null;

class ContactService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  submitContactForm = ({ data, brandId }) => {
    return this.http.post(`contact-form/${brandId}`, {
      json: { ...data },
    }).json();
  };
}

export default ContactService;
