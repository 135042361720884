import React, { useState } from "react";
import propTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { LoginHeader } from "../headers";
import { ForgottenPasswordForm } from "../forms";
import { THEME_KEYS } from "helpers/constants";
import navigation from "navigation";
import RoundedButton from "components/shared/button/RoundedButton/";
import useForgotPassword from "../../../../hooks/useForgotPassword";
import AuthService from "services/auth/authService";

/* eslint-disable */
function ForgottenPassword({ reqestNewPassword, success, fail }) {
  const handleGoBack = () => {
    setGoBackToLogin(true);
  };
  const handleSubmit = ({ email }) => {
    setUserEmail(email);
    setIsSubmitClicked(true);
  };

  const { forgotResponse, setIsSubmitClicked, setUserEmail, successfulSubmit } = useForgotPassword(new AuthService());

  const [goBackToLogin, setGoBackToLogin] = useState(false);

  return goBackToLogin ? (
    <Redirect to={navigation.login} />
  ) : !successfulSubmit ? (
    <>
      <LoginHeader
        header="Forgotten Password"
        intro={`
            Please enter your email in the field below and we will issue
            out an email with instructions to reset your password
          `}
      />
      <ForgottenPasswordForm
        backLink={handleGoBack}
        handleSubmit={handleSubmit}
        successMessage={forgotResponse ? forgotResponse.message : null}
        failMessage={forgotResponse && forgotResponse.error ? forgotResponse.error.message : null}
      />
    </>
  ) : (
    <LoginHeader
      header="Reset instructions sent"
      intro={
        <>
          <div className="lineDivide">
            <p>
            We have now emailed you instructions for resetting your password. You should receive this 
            shortly provided that an account exists with the email address you entered.
            </p>
            <br />
            <p>
            If you don't receive an email, please make sure that you have entered the correct address 
            (the one that you registered your portal account with), and also ensure that you have doubled checked 
            your spam folder.
            </p>
          </div>
          <RoundedButton
            buttonColor={THEME_KEYS.PRIMARY}
            title={"Return to login"}
            type={"button"}
            onClick={() => handleGoBack()}
            modifiers={["medium"]}
          />
        </>
      }
    />
  );
}
/* eslint-enable */

ForgottenPassword.propTypes = {
  reqestNewPassword: propTypes.func,
  success: propTypes.string,
  fail: propTypes.string,
};

export default ForgottenPassword;
