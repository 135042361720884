import { useState, useEffect } from "react";
import { errorToast, successToast } from "../helpers/toastMsgs";
import { GENERAL_TOAST_MSG, NOTIFICATION_MSG } from "../helpers/constants";

export default notificationsService => {
  const [brandSettings, setBrandSettings] = useState([]);
  const [formResults, setFormResults] = useState([]);
  const [submitForm, setSubmitForm] = useState(false);
  const [working, setWorking] = useState(false);
  const [workingPatch, setWorkingPatch] = useState(false);

  useEffect(() => {
    const getSettings = async() => {
      setWorking(true);
      try {
        const response = await notificationsService.getNotificationSettings();
        const { data } = response;
        setBrandSettings(data.brands);
      } catch (error) {
        errorToast(GENERAL_TOAST_MSG.FAILED, true);
      }
      setWorking(false);
    };

    getSettings();
  }, [notificationsService]);

  useEffect(() => {
    const patchSettings = async() => {
      setWorkingPatch(true);
      const brandIsActive = {};
      formResults.brands.forEach(setting => (brandIsActive[setting] = true));
      const resultsToPatch = brandSettings.map(setting => {
        return { id: setting.id, active: !!brandIsActive[setting.id] };
      });

      try {
        await notificationsService.patchNotificationSettings({
          brands: resultsToPatch,
        });
        successToast(NOTIFICATION_MSG);
      } catch (error) {
        errorToast(NOTIFICATION_MSG);
      }

      setSubmitForm(false);
      setWorkingPatch(false);
    };

    if (submitForm) {
      patchSettings();
    }
  }, [brandSettings, formResults, notificationsService, submitForm]);

  return {
    brandSettings,
    formResults,
    submitForm,
    setBrandSettings,
    setSubmitForm,
    setFormResults,
    working,
    workingPatch,
  };
};
