import { initialState } from "./../system/util";
import { USER, RESET_USER, FIRST_LOGIN } from "./actionTypes";

const userDaraReducer = (state = initialState, action) => {
  if (action.type.includes(FIRST_LOGIN) && action.type.includes("_REQUEST")) {
    return {
      ...state,
      working: true,
      results: {
        ...state.results,
        first_login: false,
      },
      failed: {
        hasFailed: false,
      },
    };
  } else if (action.type.includes(USER)) {
    if (action.type.includes("_REQUEST")) {
      return {
        ...state,
        working: true,
        failed: {
          hasFailed: false,
        },
      };
    } else if (action.type.includes("_SUCCESS")) {
      const results = action.payload;
      return {
        working: false,
        results: results,
        failed: {
          hasFailed: false,
        },
      };
    } else if (action.type.includes("_FAIL")) {
      const error = action.payload;
      return {
        ...state,
        working: false,
        failed: {
          error,
          hasFailed: true,
        },
      };
    } else if (action.type.includes(RESET_USER)) {
      return {
        ...state,
        working: true,
        results: null,
        failed: {
          hasFailed: false,
        },
      };
    } else {
      return state;
    }
  }
  return state;
};

export default userDaraReducer;
