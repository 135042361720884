// As this might be complicated for the poor soul who takes this over here are some nice comments...
export default function(payload) {
  const params = new URLSearchParams();
  // Make a URLSeachParams object - we need the request to be a formData to send to JotForms...
  Object.keys(payload).forEach(key => {
    // For all the keys in the formik payload, map them out...
    // This will be the question identification numbers and their values form formik.

    // payload[key] = formikValues[JotForm-QID]
    if (typeof payload[key] === "object") {
      // If its a nested value, make sure those nested values get
      // converted to the lovely URLSearchParams nightmare structure.
      Object.keys(payload[key]).map(subKey => {
        // Go through the Object Keys of that particular formik value that happens to be an object...
        return params.set(`submission[${key}][${subKey}]`, payload[key][subKey]);
        // and add it to the params. All params need a "Submission" object name.
        // Objects in URLSearchParams are done[like][this]. Don't ask me why.
        // Output from this map function: submission[0][0]="foo", submission[0][1]="bar", etc....
      });
    } else {
      params.append(`submission[${key}]`, payload[key]);
      // This is just a normal key value pair form formik
      // just making sure "Submission" is attched and key is in brackets.
      // Output: submission[0]="foo"
    }
  });
  return decodeURI(params);
  // Decode that mo-fo because otherwise it makes it HTML safe for some ungodly reason.  (%7c?foo%7c?)....
}
