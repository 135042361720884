import React from "react";
import NotificationItem from "components/shared/NotificationItem";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";
import propTypes from "prop-types";
import navigation from "../../../../../navigation";
import RoundedButton from "components/shared/button/RoundedButton/";
import Loader from "components/shared/loader";

class Notifications extends React.Component {
  componentDidMount() {
    this.props.getNotifications();
  }

  handleNotificationSettingsClicked = () => {
    this.props.history.push(navigation.notificationSettings);
  };

  render() {
    const {
      working,
      notifications,
      setNotificationRead,
      brandById,
    } = this.props;
    return (
      <div className="Notifications animated fadeIn">
        <div className="Notifications__header">
          <ContextElement
            elType="h1"
            className="Notifications__header__title"
            config={{ color: THEME_KEYS.PRIMARY }}
          >
            Your Notifications
          </ContextElement>
          <RoundedButton
            title="Notification Settings"
            buttonColor={THEME_KEYS.PRIMARY}
            buttonTextColor={THEME_KEYS.PRIMARY}
            invertColors
            onClick={this.handleNotificationSettingsClicked}
            modifiers={["white"]}
          />
        </div>
        {!working ? (
          notifications && !!notifications.length ? (
            <div className="Notifications__notificationList">
              {notifications.map((notification, index) => (
                <NotificationItem
                  brandById={brandById}
                  setRead={setNotificationRead}
                  notification={notification}
                  key={`${notification.title}${index}`}
                />
              ))}
            </div>
          ) : (
            <p className="Notifications__noNotifications">No notifications.</p>
          )
        ) : (
          <div className="Notifications__loader">
            <Loader className="Loader--noBG" />
          </div>
        )}
      </div>
    );
  }
}

Notifications.propTypes = {
  notifications: propTypes.array,
  setNotificationRead: propTypes.func,
  getNotifications: propTypes.func,
  brandById: propTypes.func,
  history: propTypes.object,
  working: propTypes.bool,
};

export default Notifications;
