import API from "../API";

let instance = null;

class FAQsService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getFAQs = params => {
    return this.http.get("faq", {
      json: params,
    }).json();
  };
}

export default FAQsService;
