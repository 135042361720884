import React from "react";
import propTypes from "prop-types";
import { IconFolder } from "assets/svg/folder";
import ContextElement from "components/shared/elements/ContextElement";
import HoverComponent from "components/shared/util/Hover";
import { IconThreeDots } from "assets/svg/threeDots";
import SingleIconButton from "components/shared/button/SingleIconButton/";
import { THEME_KEYS } from "helpers/constants";

function FileViewItemComponent({
  handleFolderClicked,
  handleFolderRightClicked,
  name,
  color,
  amountOfItems,
  handleMoreOptionsClicked,
}) {
  return (
    <div
      onClick={handleFolderClicked}
      onContextMenu={handleFolderRightClicked}
      className="FileViewFolder"
    >
      <IconFolder />
      <div className="FileViewFolder__data">
        <ContextElement elType="p" config={{ color }} className="FileViewFolder__data__name">
          {name}
        </ContextElement>
        <p className="FileViewFolder__data__items">
          {amountOfItems} Items
        </p>
      </div>
      <HoverComponent defaultFill={THEME_KEYS.PRIMARY} hoverFill={THEME_KEYS.SECONDARY}
        wrapperClassName="FileViewFolder__dots">
        <SingleIconButton
          buttonType="button"
          className="FileViewDocument__card__menu__buttons__icon"
          Icon={IconThreeDots} onClick={handleMoreOptionsClicked} />
      </HoverComponent>
    </div>
  );
};

FileViewItemComponent.propTypes = {
  handleFolderClicked: propTypes.func,
  handleFolderRightClicked: propTypes.func,
  handleMoreOptionsClicked: propTypes.func,
  name: propTypes.string,
  color: propTypes.string,
  amountOfItems: propTypes.number,
};

export default FileViewItemComponent;
