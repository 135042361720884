import * as contactsActionTypes from "redux/contacts/actionTypes";
import * as contactActions from "redux/contacts/actions";
import { put, takeEvery, call, select } from "redux-saga/effects";
import * as brandsSelector from "selectors/brands";
import handleThrow from "helpers/handleThrow";
import * as userSelector from "selectors/user";

export const watchContactsRequestFactory = contactsService => {
  function * watchContactsRequest() {
    yield takeEvery(`${contactsActionTypes.GET_REGION_CONTACTS}_REQUEST`, getRegionContactsRequest);
    yield takeEvery(`${contactsActionTypes.GET_MAIN_CONTACT}_REQUEST`, getMainContactRequest);
  }

  function * getRegionContactsRequest(action) {
    try {
      const brandId = yield select(brandsSelector.selectedBrandId);
      const response = yield call(contactsService.getBrandContacts, brandId);
      yield put(contactActions.getBrandContactsActions.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(contactActions.getBrandContactsActions.fail(parsedError));
    }
  }

  function * getMainContactRequest(action) {
    try {
      const brandId = yield select(brandsSelector.selectedBrandId);
      const region = yield select(userSelector.userRegion);
      let regionId;
      if (region && brandId) {
        regionId = region.id;
        const response = yield call(contactsService.getMainContact, { brandId, regionId });
        yield put(contactActions.getMainContactActions.success(response.data));
      } else {
        throw new Error("No brand or region");
      }
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(contactActions.getMainContactActions.fail(parsedError));
    }
  }

  return {
    watchContactsRequest,
    getRegionContactsRequest,
    getMainContactRequest,
  };
};
