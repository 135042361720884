import { RESPONSE_STATUS_UNAUTHORIZED } from "./constants";

export default function(response) {
  if (!response || !response.status) {
    return false;
  }
  return (
    (response.status >= 400 && response.status <= 499) ||
    response.statusText === RESPONSE_STATUS_UNAUTHORIZED
  );
}
