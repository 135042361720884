import { initialState } from "./../system/util";
import { NOTIFICATIONS } from "./actionTypes";
import { isEmptyObject } from "../../helpers/isEmptyObject";

const notificationsReducer = (state = initialState, action) => {
  if (action.type.includes(NOTIFICATIONS)) {
    if (action.type.includes("_REQUEST")) {
      if (action.type.includes("READ_ALL") || action.type.includes("READ")) {
        return {
          working: true,
          results: state.results || null,
          failed: {
            hasFailed: false,
          },
        };
      }
      return {
        working: true,
        failed: {
          hasFailed: false,
        },
      };
    } else if (action.type.includes("_SUCCESS")) {
      switch (true) {
        case action.type.includes("GET"):
          const getResult = isEmptyObject(action.payload) ? null : action.payload;
          return {
            working: false,
            results: getResult,
            failed: {
              hasFailed: false,
            },
          };
        case action.type.includes("READ_ALL"):
          const readAllResult = state.results.map(item => {
            item.read = true;
            return item;
          });
          return {
            working: false,
            results: readAllResult,
            failed: {
              hasFailed: false,
            },
          };
        case action.type.includes("READ"):
          const readResult = state.results.map(item => {
            if (item.id === action.payload.id) {
              item.read = true;
            }
            return item;
          });
          return {
            working: false,
            results: readResult,
            failed: {
              hasFailed: false,
            },
          };
        default:
          return state;
      }
    } else if (action.type.includes("_FAIL")) {
      const error = action.payload;
      if (action.type.includes("READ_ALL")) {
        return {
          working: true,
          results: state.results,
          failed: {
            error: error,
            hasFailed: true,
          },
        };
      }
      return {
        working: false,
        failed: {
          error: error,
          hasFailed: true,
        },
      };
    }
    return state;
  }
  return state;
};

export default notificationsReducer;
