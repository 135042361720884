import { connect } from "react-redux";
import AgentHeader from "../components/AgentHeader";
import * as dashboardSelector from "selectors/dashboard";

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
  welcomeMsg: dashboardSelector.brandWelcomeMsg(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentHeader);
