import React from "react";
/* eslint max-len: 0 */
export const IconFolder = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30">
      <defs>
        <linearGradient id="a" x1="50%" x2="50%" y1="15.864%" y2=".537%">
          <stop offset="0%" stopColor="#B6D0FF" />
          <stop offset="100%" stopColor="#B2CEFF" />
        </linearGradient>
      </defs>
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M1.864 0C.834 0 0 .798 0 1.784V28.71c0 .623.528 1.128 1.178 1.128h37.297c.65 0 1.178-.505 1.178-1.128V4.667c0-.782-.835-1.71-1.716-1.71 0 0-17.474-.005-22.976-.005-1.243 0-2.06-2.952-3.518-2.952H1.864z"
        transform="translate(.093)"
      />
    </svg>
  );
};
