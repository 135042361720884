import API from "../API";

let instance = null;

class UserService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getUser = _ => {
    return this.http.get("user").json();
  };

  patchUser = payload => {
    return this.http.patch("user", { json: payload }).json();
  };

  registerUser = params => {
    return this.http
      .post("register", {
        json: params,
      })
      .json();
  };

  changePassword = payload => {
    return this.http.post("user/change-password", { json: payload }).json();
  };
}

export default UserService;
