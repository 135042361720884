import API from "../API";

let instance = null;

class GeneralService extends API {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }
  getCountries = () => {
    return this.http.get("country").json();
  };

  getPhoneCodes = () => {
    return this.http.get("phone-code").json();
  };

  getInterests = () => {
    return this.http.get("interest").json();
  };

  getRegions = () => {
    return this.http.get("region").json();
  };

  getTeams = () => {
    return this.http.get("team").json();
  };

  getRegistrationTypes = () => {
    return this.http.get("registration-type").json();
  };
}

export default GeneralService;
