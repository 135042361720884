import React from "react";
import PropTypes from "prop-types";
/* eslint max-len: 0 */
export const Camera = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18">
      <g fillRule="evenodd">
        <path d="M21.222 3.595h-3.035c-.932 0-1.688-.728-1.688-1.615 0-.884-.76-1.616-1.688-1.616H8.182c-.932 0-1.693.729-1.693 1.616 0 .888-.763 1.615-1.684 1.615h-3.03A1.69 1.69 0 0 0 .085 5.277V15.95c0 .92.761 1.678 1.688 1.678h19.448c.93 0 1.688-.76 1.688-1.678V5.277c0-.92-.76-1.682-1.688-1.682zm-9.727 11.631a4.83 4.83 0 0 1-4.839-4.828 4.826 4.826 0 0 1 4.84-4.827 4.829 4.829 0 0 1 4.841 4.827 4.829 4.829 0 0 1-4.842 4.828z" />
        <path d="M11.495 7.458c-1.63 0-2.962 1.321-2.962 2.945a2.957 2.957 0 0 0 2.962 2.95 2.96 2.96 0 0 0 2.965-2.95c.002-1.624-1.333-2.945-2.965-2.945zm-.168 2.777c-.677.593-1.508.737-1.873.323-.36-.412-.106-1.223.563-1.812.677-.59 1.517-.732 1.88-.323.363.414.108 1.222-.57 1.812z" />
      </g>
    </svg>
  );
};

Camera.propTypes = {
  className: PropTypes.string,
};
