import React, { Component } from "react";
import { withTheme } from "styled-components";
import ContextElement from "components/shared/elements/ContextElement";
import propTypes from "prop-types";
import { THEME_KEYS } from "helpers/constants";
import HoverComponent from "components/shared/util/Hover";
import { IconChevronLeft } from "assets/svg/chevronLeft";
import { IconChevronRight } from "assets/svg/chevronRight";

class PageSelector extends Component {
  handleBackClicked = () => {
    const { onBack } = this.props;

    onBack();
  };

  handleNextClicked = () => {
    const { onNext } = this.props;

    onNext();
  };
  render() {
    const { currentPage, allPagesCount } = this.props;
    const disableBack = currentPage === 1;
    const disableNext = currentPage === allPagesCount;
    return (
      <div className="PageSelector__toolbar__selector">
        <ContextElement
          elType="button"
          disabled={disableBack}
          className="PageSelector__toolbar__selector__button"
          config={{ borderColor: disableBack ? "#e4e4e4" : "secondary" }}
          onClick={this.handleBackClicked}
        >
          <HoverComponent hoverFill={disableBack ? "#e4e4e4" : THEME_KEYS.SECONDARY}
            defaultFill={disableBack ? "#e4e4e4" : THEME_KEYS.SECONDARY}>
            <IconChevronLeft />
          </HoverComponent>
        </ContextElement>
        <p>{`${this.props.currentPage} of ${this.props.allPagesCount}`}</p>
        <ContextElement
          elType="button"
          disabled={disableNext}
          className="PageSelector__toolbar__selector__button"
          config={{ borderColor: disableNext ? "#e4e4e4" : "secondary" }}
          onClick={this.handleNextClicked}
        >
          <HoverComponent hoverFill={disableNext ? "#e4e4e4" : THEME_KEYS.SECONDARY}
            defaultFill={disableNext ? "#e4e4e4" : THEME_KEYS.SECONDARY}>
            <IconChevronRight />
          </HoverComponent>
        </ContextElement>
      </div>
    );
  }
}

PageSelector.propTypes = {
  allPagesCount: propTypes.number,
  currentPage: propTypes.number,
  onNext: propTypes.func,
  onBack: propTypes.func,
};

export default withTheme(PageSelector);
