import WordPressApi from "./wordpressApi";

class NewsService {
  apiUrl = null;

  setUrl = (url, categories, offset) => {
    this.apiUrl = url + "/wp-json/wp/v2";
  };

  getAllNews = (categories, tags, offset, orderby = "date", order = "desc") => {
    const WPAPI = new WordPressApi(this.apiUrl, this.categories, this.offset);
    const api = WPAPI.http();
    return api.get(
      `${WPAPI.newsEndPoint}
?per_page=9
${categories && categories.length ? "&categories=" + categories.join(",") : ""}
${tags && tags.length ? "&tags=" + tags.join(",") : ""}
${offset ? "&offset=" + offset : ""}
${orderby ? "&orderby=" + orderby : ""}
${order ? "&order=" + order : ""}
&_embed`);
  };

  getCategories = () => {
    const WPAPI = new WordPressApi(this.apiUrl);
    const api = WPAPI.http();
    return api.get(`${WPAPI.categoriesEndPoint}`).json();
  };

  getTags = () => {
    const WPAPI = new WordPressApi(this.apiUrl);
    const api = WPAPI.http();
    return api.get(`${WPAPI.tagsEndPoint}`).json();
  };

  getNewsItemByID = id => {
    const WPAPI = new WordPressApi(this.apiUrl);
    const api = WPAPI.http();
    return api.get(WPAPI.singlePostEndPoint + id).json();
  };
}

export default NewsService;
