import React from "react";
import propTypes from "prop-types";
import RoundedButton from "components/shared/button/RoundedButton";
import classNames from "classnames";
import ContextElement from "components/shared/elements/ContextElement";
import { THEME_KEYS } from "helpers/constants";
import { Image } from "react-image-and-background-image-fade";
import isMobile from "helpers/isMobile";

function QuickGuidePopup({
  heading,
  content,
  image,
  skipToEnd,
  goToNext,
  index,
  total,
  arrowPointing,
}) {
  return (
    <div className="QuickGuidePopup__container">
      <div
        className={classNames("QuickGuidePopup__arrow", {
          [`QuickGuidePopup__arrow--side-${arrowPointing.sideOfThePopup}`]: !!arrowPointing.sideOfThePopup,
          [`QuickGuidePopup__arrow--position-${arrowPointing.positionOfTheArrow}`]: !!arrowPointing.positionOfTheArrow,
        })}
      />
      <div className="QuickGuidePopup__content">
        <ContextElement
          elType="div"
          config={{ borderColor: THEME_KEYS.SECONDARY }}
          className="QuickGuidePopup__content--spacing"
        >
          <ContextElement
            elType="h5"
            className="lightHeaderSansSerif"
            config={{
              color: THEME_KEYS.PRIMARY,
            }}
          >
            Tip {index + 1} of {total}
          </ContextElement>
          {isMobile() &&
          <Image
            src={image}
            wrapperClassName="QuickGuidePopup__content__image"
          />
          }
          <h5 className="headerSerif QuickGuidePopup__content__heading">
            {heading}
          </h5>
          <p className="QuickGuidePopup__content__type">{content}</p>
        </ContextElement>
        <div className="QuickGuidePopup__content__buttons">
          <RoundedButton
            buttonClass="QuickGuidePopup__content__buttons__button QuickGuidePopup__content__buttons__button--skip"
            buttonColor={THEME_KEYS.PRIMARY}
            modifiers={["white"]}
            title="Skip the tutorial"
            type="button"
            onClick={skipToEnd}
            invertColors
          />
          <RoundedButton
            buttonClass="QuickGuidePopup__content__buttons__button QuickGuidePopup__content__buttons__button--next"
            buttonColor={THEME_KEYS.SECONDARY}
            title="Next"
            type="button"
            onClick={goToNext}
          />
        </div>
      </div>
    </div>
  );
}

QuickGuidePopup.propTypes = {
  skipToEnd: propTypes.func,
  heading: propTypes.string,
  image: propTypes.any,
  content: propTypes.oneOfType([propTypes.element, propTypes.string]),
  goToNext: propTypes.func,
  index: propTypes.number,
  total: propTypes.number,
  arrowPointing: propTypes.object,
};

export default QuickGuidePopup;
