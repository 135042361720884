import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import SelectInput from "components/formElements/SelectInput";
import { Formik, Field } from "formik";

const BrandDropDown = props => {
  const [minWidth, setMinWidth] = useState(null);
  const handleBrandSelection = selection => {
    if (selection.constructor === Number) {
      props.setSelectedBrand(selection);
    }
  };

  const { brands, selectedBrand, modifiers } = props;
  const spanRef = React.createRef();

  useEffect(() => {
    if (spanRef) {
      setMinWidth(spanRef.current.offsetWidth);
    }
  }, [spanRef]);

  return brands ? (
    <>
      <span
        className="BrandDropdown__span"
        ref={spanRef}
      >{selectedBrand ? selectedBrand.name : ""}</span>
      <Formik
        enableReinitialize
        initialValues={{
          id: selectedBrand ? selectedBrand.id : null,
          name: selectedBrand ? selectedBrand.name : null,
        }}
        render={_ => (
          <Field
            options={
              brands &&
              brands.map(({ name, id, children }) => ({
                name,
                value: id,
                children: children.map(({ name, id }) => {
                  return {
                    name,
                    value: id,
                  };
                }),
              }))
            }
            className="BrandDropdown"
            placeholder="Select brand"
            name="brand"
            component={SelectInput}
            onSelect={handleBrandSelection}
            modifiers={modifiers}
            minWidth={minWidth}
          />
        )}
      />
    </>
  ) : (
    <React.Fragment />
  );
};

BrandDropDown.propTypes = {
  setSelectedBrand: propTypes.func,
  selectedBrand: propTypes.object,
  brands: propTypes.array,
  modifiers: propTypes.array,
};

export default React.memo(BrandDropDown);
