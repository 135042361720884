import React from "react";
import propTypes from "prop-types";

import RoundedButton from "components/shared/button/RoundedButton/";
import SectionHeader from "../../sectionHeader";
import DocumentItemSection from "../DocumentItemSection";
import { THEME_KEYS } from "helpers/constants";
import navigation from "./../../../../navigation";

class DocumentSection extends React.Component {
  goToDocuments = () => {
    this.props.history.push(navigation.digitalResources);
  };

  render() {
    return (
      <div className="DocumentSection">
        <div className="DocumentSection__header">
          <SectionHeader descriptor="LATEST" title="Resources" />
          <RoundedButton
            title="View All Resources"
            buttonColor={THEME_KEYS.PRIMARY}
            modifiers={["medium"]}
            onClick={this.goToDocuments}
          />
        </div>
        {
          this.props.documentSections &&
          Array.isArray(this.props.documentSections) &&
          this.props.documentSections.map(section => (
            <DocumentItemSection
              ignoreWindowPosition={this.props.ignoreWindowPosition}
              key={section.title} documentItemSection={section} />
          ))
        }
      </div>
    );
  }
}

DocumentSection.propTypes = {
  documentSections: propTypes.array,
  history: propTypes.object,
  ignoreWindowPosition: propTypes.bool,
};

export default DocumentSection;
