import moment from "moment";

export default function timeSince(date) {
  const mdate = moment(new Date(date));
  const now = new Date();
  const diff = mdate.diff(now, "hours") * -1;
  if (diff >= 48) {
    return mdate.format("DD MMM YYYY") + " at " + mdate.format("HH:MM");
  }
  if (diff >= 24) {
    return "Yesterday at " + mdate.format("HH:MM");
  }
  return mdate.fromNow();
}
