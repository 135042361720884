import React from "react";
import propTypes from "prop-types";
import cn from "classnames";
import Label from "./Label";
import HelperText from "./HelperText";
import PopInAnimation from "animationWrappers/PopInAnimation";
import { sanitize } from "dompurify";

function TextInput({
  field,
  form: { touched, errors, submitCount, setFieldValue },
  label,
  id,
  type = "text",
  mandatory,
  placeholder,
  modifiers,
  hideLabelOnType,
  elementType = "input",
  ...props
}) {
  const derivedId = id || field.name;
  const error = touched[field.name] && errors[field.name] ? errors[field.name] : submitCount > 0 && errors[field.name];
  const helperText = error ? errors[field.name] : null;

  const sanitizeValue = event => {
    const sanitized = sanitize(event.currentTarget.value);
    !sanitized ? setFieldValue(field.name, "") : setFieldValue(field.name, sanitized);
  };

  return (
    <div
      className={cn(
        "FormElement TextInput",
        { "TextInput--noLabel": !label && !modifiers.includes("noMargin") },
        modifiers && modifiers.map(mod => `FormElement--${mod}`)
      )}
    >
      {label && (
        <div
          className={cn(
            "TextInput__label FormInput__label",
            modifiers && modifiers.map(mod => `TextInput__label--${mod}`),
            {
              "TextInput__label--hide": hideLabelOnType && field.value,
            }
          )}
        >
          <Label htmlFor={derivedId}>{label}</Label>
        </div>
      )}
      {React.createElement(elementType, {
        ...props,
        ...field,
        placeholder,
        onBlur: sanitizeValue,
        type,
        id: derivedId,
        className: cn(
          "TextInput__input",
          {
            "TextInput__input--hasError": error,
          },
          modifiers && modifiers.map(mod => `TextInput__input--${mod}`)
        ),
      })}
      {helperText && <HelperText text={helperText} />}
    </div>
  );
}

TextInput.propTypes = {
  elementType: propTypes.string,
  field: propTypes.object.isRequired,
  form: propTypes.object.isRequired,
  label: propTypes.string,
  id: propTypes.string,
  type: propTypes.string,
  mandatory: propTypes.bool,
  placeholder: propTypes.string,
  modifiers: propTypes.array,
  hideLabelOnType: propTypes.bool,
};

export default PopInAnimation(TextInput);
