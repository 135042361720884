import { createSelector } from "reselect";

export const brandsSelector = state => state.brands && state.brands.results;
export const isWorking = state => state.brands && state.brands.working;

export const brandsList = createSelector(brandsSelector, items => {
  if (items) {
    return Array.from(items).map(brand => {
      return {
        name: brand.name,
        id: brand.id,
        children: brand.children
          ? brand.children.map(child => {
            return { name: child.name, id: child.id };
          })
          : null,
      };
    });
  }
  return [];
});

export const flattenedBrandsList = createSelector(brandsSelector, items => {
  if (items) {
    const nestedArrays = Array.from(items).map(brand => {
      if (brand.children) {
        return [brand, ...brand.children];
      }
      return [brand];
    });
    return [].concat.apply([], nestedArrays);
  }
  return null;
});

export const selectedBrandId = state =>
  state.selectedBrand && state.selectedBrand.results
    ? state.selectedBrand.results
    : null;

export const selectedBrand = createSelector(
  flattenedBrandsList,
  selectedBrandId,
  (items, id) => {
    if (items && id) {
      const brand = Array.from(items).filter(
        item => Number(item.id) === Number(id)
      );
      return brand[0] || null;
    }
    return null;
  }
);

export const showNews = createSelector(selectedBrand,
  brand => {
    return brand && !!brand.blogUrl;
  });

export const displayAppForm = createSelector(selectedBrand, brand => {
  if (brand) {
    return brand.applicationForm;
  }
});

export const brandById = brandId => {
  return createSelector(flattenedBrandsList, items => {
    if (items) {
      return items.filter(item => item.id === brandId)[0];
    }
    return null;
  });
};

export const brandByName = brandName => {
  return createSelector(flattenedBrandsList, items => {
    if (items) {
      return items.filter(item => item.name === brandName.replace(/\+/g, " "))[0];
    }
    return null;
  });
};
