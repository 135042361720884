import { createReducer } from "redux-starter-kit";
// import * as actions from "./actions";

export const initialState = {
  active: false,
};

/* NOTE: createReducer from redux-starter-kit allows for direct state mutation */
export const reducer = createReducer(initialState, {
  // [actions.SEARCH_ADDRESS_REQUEST]: (state, action) => {
  //   const { type } = action.payload;
  //   state.working.searchAddress = true;
  //   state.failed.searchAddress[type] = false;
  //   state.results.addresses[type] = [];
  // },
  // [actions.SEARCH_ADDRESS_SUCCESS]: (state, action) => {
  //   const { type, results } = action.payload;
  //   state.working.searchAddress = false;
  //   state.results.addresses[type] = results;
  // },
  // [actions.SEARCH_ADDRESS_FAIL]: (state, action) => {
  //   const { type } = action.payload;
  //   state.working.searchAddress = false;
  //   state.failed.searchAddress[type] = true;
  //   state.results.addresses[type] = [];
  // },
});

export default reducer;
