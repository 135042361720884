import { connect } from "react-redux";
import * as GeneralActions from "redux/general/actions";
import * as GeneralSelectors from "selectors/general";
import { Interests } from "./../../formParts/Interests";

const mapDispatchToProps = dispatch => ({
  fetchInterests: () => dispatch(GeneralActions.getInterestsActions.request()),
});

const mapStateToProps = (state, ownProps) => ({
  interests: GeneralSelectors.interests(state),
  formUsage: ownProps.formUsage,
  patchedAdditionalInterests:ownProps.patchedAdditionalInterests,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Interests);
