import { Route, Switch, Redirect } from "react-router-dom";
import React, { useState } from "react";

import BrowseBrands from "./pages/browseBrands";
import Calendar from "./pages/calendar";
import Contacts from "./pages/contacts";
import Dashboard from "./pages/dashboard";
import NotFound from "./pages/notFound";
import Profile from "./pages/editProfile";
import DigitalResources from "./pages/digitalResources";
import RegisterStudent from "./pages/registerStudent";
import NeedHelp from "./pages/needHelp";
import NotificationsSettings from "./pages/notificationsSettings";
import Notifications from "./pages/notifications";
import News from "./pages/news";
import MainHeader from "../../components/MainHeader/container";
import Sidebar from "../../components/Sidebar";
import navigation from "../../navigation";

export const DashboardLayout = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const toggleSideBarOpen = () => setIsSideBarOpen(!isSideBarOpen);

  return (
    <div className="DashboardLayout">
      <MainHeader toggleSideBarOpen={toggleSideBarOpen} />
      <Sidebar toggleSideBarOpen={toggleSideBarOpen} isOpen={isSideBarOpen} />
      <div className="DashboardLayout__content">
        <Switch>
          <Route exact path={navigation.home} component={Dashboard} />
          <Route exact path={navigation.profile} component={Profile} />
          <Route
            exact
            component={DigitalResources}
            path={
              [
                navigation.digitalResources,
                navigation.digitalResources + "/:brand",
                navigation.digitalResources + "/:brand/folder/:id",
                navigation.digitalResources + "/:brand/download/:downloadId",
                navigation.digitalResources + "/:brand/folder/:id/download/:downloadId",
              ]
            }
          />
          <Route exact path={navigation.registerStudent} component={RegisterStudent} />
          <Route path={navigation.news} component={News} />
          <Route path={navigation.usefulInformation} component={News} />
          <Route exact path={navigation.calendar} component={Calendar} />
          <Route exact path={navigation.calendar + "/:day/:month/:year/:eventId"} component={Calendar} />
          <Route exact path={navigation.contacts} component={Contacts} />
          <Route exact path={navigation.browseBrands} component={BrowseBrands} />
          <Route exact path={navigation.needHelp} component={NeedHelp} />
          <Route exact path={navigation.notifications} component={Notifications} />
          <Route exact path={navigation.notificationSettings} component={NotificationsSettings} />
          <Route exact path={navigation.notFound} component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </div>
    </div>
  );
};

export default DashboardLayout;
