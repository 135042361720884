import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { IconEmail } from "assets/svg/email";
import { IconPhone } from "assets/svg/phone";
import { Image } from "react-image-and-background-image-fade";
import ContextElement from "components/shared/elements/ContextElement";

class ContactBanner extends PureComponent {
  render() {
    const { first_name: firstName, last_name: lastName, job_title: title, email, phone_number: phone, image, imgAlt } =
      this.props.contact || {};

    return (
      <ContextElement className="ContactBanner" config={{ backgroundColor: "primary" }} elType="div">
        <Image lazyLoad wrapperClassName="ContactBanner__image" src={image} alt={imgAlt} />
        <div className="ContactBanner__details">
          <ContextElement
            className="ContactBanner__details__contactText lightHeaderSansSerif"
            config={{ color: "secondary" }}
            elType="p"
          >
            CONTACT
          </ContextElement>
          <h2>{firstName + " " + lastName}</h2>
          <p className="ContactBanner__details__title">{title}</p>
          <div className="ContactBanner__details__email">
            <IconEmail />
            <p>{email}</p>
          </div>
          <div className="ContactBanner__details__phone">
            <IconPhone />
            <p>{phone}</p>
          </div>
        </div>
      </ContextElement>
    );
  }
}

ContactBanner.propTypes = {
  contact: propTypes.shape({
    first_name: propTypes.string.isRequired,
    last_name: propTypes.string.isRequired,
    job_title: propTypes.string.isRequired,
    email: propTypes.string.isRequired,
    phone_number: propTypes.string.isRequired,
    image: propTypes.string,
    imgAlt: propTypes.string,
  }),
};

export default ContactBanner;
