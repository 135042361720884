import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import Avatar from "components/shared/avatar/Avatar";
import EditProfileForm from "./EditProfileForm";
import ContextElement from "components/shared/elements/ContextElement";
import Loader from "../../../../../components/shared/loader";

const EditProfile = ({
  user: {
    email = "",
    firstName = "Anonymous",
    lastName = "",
    image,
    phoneCode,
    contactNumber,
    address1,
    address2,
    town,
    postCode,
    country,
    companyName,
    regions,
    additionalInterests,
  },
  working,
  workingEdit,
  patchUserRequest,
  changePasswordRequest,
  editUserFailedMessage,
  getUser,
}) => {
  const [profileImage, setProfileImage] = useState(image);

  useEffect(() => {
    if (!email) {
      getUser();
    }
  }, [email, getUser]);

  useEffect(() => {
    setProfileImage(image || "");
  }, [image]);

  const handleSubmit = values => {
    if (typeof values.country !== "number") {
      values.country = values.country.id;
    }

    if (typeof values.areaCode !== "number") {
      values.areaCode = values.areaCode.id;
    }

    if (profileImage) {
      patchUserRequest({
        ...values,
        image: profileImage,
      });
    } else {
      patchUserRequest({
        ...values,
      });
    }
  };

  const userInitials = (firstName, lastName) => {
    const initials =
      (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "");
    return initials.toUpperCase();
  };

  return (
    <div className="EditProfile">
      {!working ? (
        <>
          <div className="EditProfile__photoAndName">
            <Avatar
              image={profileImage}
              size="large"
              canEdit
              onImageSelect={setProfileImage}
              initials={userInitials(firstName, lastName)}
            />
            <div className="EditProfile__photoAndName__name">
              <ContextElement
                elType="h1"
                config={{ color: "secondary" }}
                className="lightHeaderSansSerif"
              >
                Profile
              </ContextElement>
              <ContextElement
                elType="h2"
                config={{ color: "primary" }}
                className="headerSerif"
              >
                {`${firstName} ${lastName}`}
              </ContextElement>
            </div>
          </div>
          {email ? (
            <EditProfileForm
              prepopulatedFields={{
                firstName,
                lastName,
                emailAddress: email,
                areaCode: phoneCode,
                contactNumber,
                address1,
                address2,
                townCity: town,
                postcode: postCode,
                country,
                company: companyName,
                regions,
                additionalInterests,
              }}
              onSubmit={handleSubmit}
              changePasswordRequest={changePasswordRequest}
              working={workingEdit}
              editUserFailedMessage={editUserFailedMessage}
            />
          ) : (
            <div className="lightHeaderSansSerif fontsize--small">
              You seem to be signed out of your account. Try logging back in.
            </div>
          )}
        </>
      ) : (
        <div className="EditProfile__loader">
          <Loader className="Loader--noBG" />
        </div>
      )}
    </div>
  );
};

// results.email,
EditProfile.propTypes = {
  user: propTypes.object.isRequired,
  patchUserRequest: propTypes.func.isRequired,
  changePasswordRequest: propTypes.func.isRequired,
  working: propTypes.bool,
  workingEdit: propTypes.bool,
  editUserFailedMessage: propTypes.string,
  getUser: propTypes.func,
};

export default EditProfile;
