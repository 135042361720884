import * as eventsActionTypes from "redux/events/actionTypes";
import * as eventsActions from "redux/events/actions";
import { put, takeEvery, call } from "redux-saga/effects";
import * as brandsSelector from "selectors/brands";
import handleThrow from "helpers/handleThrow";
import waitFor from "./waitFor";

export const watchEventsRequestFactory = eventsService => {
  function * watchEventsRequest() {
    yield takeEvery(`${eventsActionTypes.GET_EVENTS}_REQUEST`, eventsRequest);
  }

  function * eventsRequest(action) {
    try {
      const brand = yield call(waitFor, brandsSelector.selectedBrand);
      const response = yield call(eventsService.getEventsByDate, { ...action.payload, brandId: brand.id });
      yield put(eventsActions.getEvents.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(eventsActions.getEvents.fail(parsedError));
    }
  }

  return {
    watchEventsRequest,
    eventsRequest,
  };
};
