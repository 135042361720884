import * as generalActionTypes from "redux/general/actionTypes";
import * as generalActions from "redux/general/actions";
import { put, takeEvery, call } from "redux-saga/effects";
import handleThrow from "helpers/handleThrow";

export const watchGeneralRequestFactory = generalService => {
  function * watchForGeneralRequests() {
    yield takeEvery(`${generalActionTypes.GET_PHONE_CODES}_REQUEST`, getPhoneCodes);
    yield takeEvery(`${generalActionTypes.GET_COUNTRIES}_REQUEST`, getCountries);
    yield takeEvery(`${generalActionTypes.GET_INTERESTS}_REQUEST`, getInterests);
    yield takeEvery(`${generalActionTypes.GET_REGIONS}_REQUEST`, getRegions);
    yield takeEvery(`${generalActionTypes.GET_TEAMS}_REQUEST`, getTeams);
    yield takeEvery(`${generalActionTypes.GET_REGISTRATION_TYPES}_REQUEST`, getRegistrationTypes);
  }

  function * getPhoneCodes(action) {
    try {
      const response = yield call(generalService.getPhoneCodes);
      yield put(generalActions.getPhoneCodeActions.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(generalActions.getPhoneCodeActions.fail(parsedError));
    }
  }

  function * getCountries(action) {
    try {
      const response = yield call(generalService.getCountries);
      yield put(generalActions.getCountriesActions.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(generalActions.getCountriesActions.fail(parsedError));
    }
  }

  function * getInterests() {
    try {
      const response = yield call(generalService.getInterests);
      yield put(generalActions.getInterestsActions.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(generalActions.getInterestsActions.fail(parsedError));
    }
  }

  function * getRegions() {
    try {
      const response = yield call(generalService.getRegions);
      yield put(generalActions.getRegionsActions.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(generalActions.getRegionsActions.fail(parsedError));
    }
  }

  function * getTeams() {
    try {
      const response = yield call(generalService.getTeams);
      yield put(generalActions.getTeamsActions.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(generalActions.getTeamsActions.fail(parsedError));
    }
  }

  function * getRegistrationTypes() {
    try {
      const response = yield call(generalService.getRegistrationTypes);
      yield put(generalActions.getRegistrationTypesActions.success(response.data));
    } catch (error) {
      const parsedError = yield handleThrow(error);
      yield put(generalActions.getRegistrationTypesActions.fail(parsedError));
    }
  }

  return {
    watchForGeneralRequests,
    getPhoneCodes,
    getCountries,
    getInterests,
    getRegions,
    getTeams,
    getRegistrationTypes,
  };
};
