import React from "react";
import propTypes from "prop-types";
/* eslint max-len: 0 */
export const IconChevronDown = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="10"
      viewBox="0 0 20 12"
      className={className}
    >
      <path
        fillRule="nonzero"
        d="M9.874 10.7a.936.936 0 0 1-.514-.253l-7.759-7.5a.941.941 0 0 1-.075-1.362.926.926 0 0 1 1.356.005L10 8.474 17.12 1.59a.926.926 0 0 1 1.355-.005.94.94 0 0 1-.075 1.362l-7.759 7.499a.927.927 0 0 1-.766.254z"
      />
    </svg>
  );
};

IconChevronDown.propTypes = {
  className: propTypes.string,
};
