import { connect } from "react-redux";
import Contacts from "../components/Contacts";
import * as contacts from "redux/contacts/actions";
import * as contactsSelector from "selectors/contacts";

const mapDispatchToProps = dispatch => ({
  getBrandContacts: () => dispatch(contacts.getBrandContactsActions.request()),
});

const mapStateToProps = state => ({
  contactListByRegion: param => contactsSelector.contactListByRegion(param)(state),
  workingRegion: contactsSelector.isWorking(state, "regionContacts"),
  regionList: contactsSelector.regionList(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contacts);
