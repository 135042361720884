import React from "react";
import propTypes from "prop-types";
import { Field } from "formik";
import ContextElement from "components/shared/elements/ContextElement";
import { RegistrationTypesContainer } from "components/formElements/fieldContainers";

function RegistrationType({ title = "Registration Type" }) {
  return (
    <>
      <ContextElement
        elType="h3"
        config={{ color: "secondary" }}
        className="UserInformationFormFields__section__heading lightHeaderSansSerif"
      >
        {title}
      </ContextElement>
      <Field
        placeholder="Type of Application"
        name="registrationType"
        id="registrationType"
        mandatory
        component={RegistrationTypesContainer}
        modifiers={["white", "grey-border"]}
      />
    </>
  );
}

RegistrationType.propTypes = {
  title: propTypes.string,
};

export default RegistrationType;
