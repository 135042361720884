import React from "react";
import propTypes from "prop-types";
import { Image } from "react-image-and-background-image-fade";

function LoginLayoutComponent({ logo, leftSide, rightSide }) {
  return (
    <div className="Login">
      <div className="Login__left">
        {logo && <Image wrapperClassName="Login__left__brandLogo" src={logo} />}
        <div className="Login__left__content">
          {leftSide}
        </div>
      </div>
      <div className="Login__right">
        {rightSide}
      </div>
    </div>
  );
};

LoginLayoutComponent.propTypes = {
  logo: propTypes.string,
  leftSide: propTypes.element,
  rightSide: propTypes.element,
};

export default LoginLayoutComponent;
